import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Header } from "./component/header/header";
import { Navbar } from "./component/navbar/navbar";
import { Footer } from "./component/footer/footer";
import { OrderTracking } from "./views/OrderTracking/orderTracking";
import "./App.css";
import { OrderList } from "./component/orderList/orderList";
import { Home } from "./component/home/home";
import { Team } from "./views/Team/team";
import { Landing } from "./views/Landing/landing";
import { Restore } from "./views/Restore/restore";
import { Settings } from "./views/Settings/settings";
import { AccountCreation } from "./views/Admin/AccountCreation/accountCreation";
import { OrderCreation } from "./views/Admin/OrderCreation/orderCreation";
import { getLoginUser, getUserRole } from "./LocalStorageService";
import { RestoreTracking } from "./component/restoreTracking/restoreTracking";
import { AdminOrderList } from "./views/Admin/Order/OrderList/orderList";
import { AdminOrderManagement } from "./views/Admin/Order/OrderManagement/orderManagement";
import { ClientList } from "./views/Admin/ClientList/clientList";
import { FeaturesProvider, useFeatures } from "./views/FeaturesProvider/featuresProvider";
import { useState } from "react";
import { NotFound } from "./views/NotFound/notFound";
import axios from "axios";
import { useEffect } from "react";
import { getApiUrl } from "./global";
import { FileServerTable } from "./component/fileServerTable/fileServerTable";

function App() {
  const navigate = useNavigate();
  //TODO remove following after RBAC
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdmin = queryParams.get('admin') == 'true';

  return (
    <div className="App">
      <FeaturesProvider> {/* Added for Role Based */}
        {getLoginUser() && <Header />}
        <div className="container-fluid">
          {getLoginUser() && (
            <Navbar onViewClicked={(viewSelected) => navigate(viewSelected)} />
          )}
          <div className={`${getLoginUser() ? "outLet" : ""}`}>
            <Routes>
              <Route path="/Landing" element={<Landing />} />
              {/*             <Route
              path="/Home"
              element={getLoginUser() ? <Home /> : <Landing />}
            />
            <Route
              path="/Orders"
              element={getLoginUser() ? ((getUserRole() === 'admin') ? <AdminOrderList /> : <OrderList /> ) : <Landing />}
            />
            <Route
              path="/OrderTracking/:orderId"
              element={getLoginUser() ? <OrderTracking /> : <Landing />}
            />
            <Route
              path="/Team"
              element={getLoginUser() ? ((getUserRole() === 'admin') ? <ClientList /> : <Team /> ) : <Landing />}
            />
            <Route
              path="/Restore"
              element={getLoginUser() ? <Restore /> : <Landing />}
            /> */}
              {/*             <Route
              path="/OrderCreation"
              element={getLoginUser() ? <AccountCreation /> : <Landing />}
            />
            <Route
              path="/JobCreation"
              element={getLoginUser() ? <OrderCreation /> : <Landing />}
            />
            <Route 
              path="/RestoreTracking/:orderId" 
              element={getLoginUser() ? <RestoreTracking /> : <Landing />} />
            <Route
              path="/OrderManagement/:orderId"
              element={getLoginUser() ? <AdminOrderManagement /> : <Landing />}
            />
            <Route
              path="/Customers"
              element={getLoginUser() ? <ClientList /> : <Landing />}
            /> */}
            </Routes>
            <GuardedRoutes />
          </div>
        </div>
      </FeaturesProvider>
    </div>
  );
}

export default App;

const GuardedRoutes = () => {

  const featureComponents = [
    {
      key: 'dashboard', routes: [
        { path: '/', component: <Home /> },
        { path: '/Home', component: <Home /> }
      ]
    },
    {
      key: 'order', routes: [
        { path: '/Orders', component: <AdminOrderList /> },
        { path: '/OrderCreation', component: <AccountCreation /> },
        { path: '/JobCreation', component: <OrderCreation /> },
        { path: '/OrderManagement/:orderId', component: <AdminOrderManagement /> },
        { path: '/Customers', component: <ClientList /> }
      ]
    },
    {
      key: 'track', routes: [
        { path: '/CustomerOrders', component: <OrderList /> },
        { path: '/OrderTracking/:orderId', component: <OrderTracking /> }
      ]
    },
    {
      key: 'user', routes: [
        { path: '/Team', component: <Team /> }
      ]
    },
    {
      key: 'restore', routes: [
        { path: '/CustomerOrders', component: <OrderList /> },
        { path: '/Restore', component: <Restore /> }
      ]
    }
  ];
  const { setFeatureList } = useFeatures();

  const { features } = useFeatures();

  //const [featuresList, setFeaturesList] = useState([]);

  const getFeatures = async () => {
    let url = await getApiUrl();
    const apiUrl = url + `${process.env.REACT_APP_API_BASE_URL}` + `/auth/user/`;
    axios
      .get(apiUrl)
      .then((response) => {
        setFeatureList(response.data.features);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isFeatureAvailable = (userFeature) => {
    if (getLoginUser() && (!features || features?.length === 0)) {
      getFeatures();
    }
    return features?.some((feature) => feature === userFeature);
  }

  useEffect(() => {
    if (getLoginUser() && (!features || features?.length === 0)) {
      getFeatures();
    }
  }, [])



  return (
    <>
      <Routes>
        <Route
          path="/Settings"
          element={getLoginUser() ? <Settings /> : <Landing />} />

        <Route
          path="/FileServer"
          element={getLoginUser() ? <FileServerTable /> : <Landing />} />
        <Route
          path="/"
          element={getLoginUser() ?
            ((isFeatureAvailable('dashboard') || isFeatureAvailable('admin_dashboard')) ?
              <Home /> : <NotFoundElement />) : <Landing />} />

        <Route
          path="/Home"
          element={getLoginUser() ?
            ((isFeatureAvailable('dashboard') || isFeatureAvailable('admin_dashboard')) ?
              <Home /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/OrderCreation"
          element={getLoginUser() ?
            (isFeatureAvailable('order') ?
              <AccountCreation /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/JobCreation"
          element={getLoginUser() ?
            (isFeatureAvailable('order') ?
              <OrderCreation /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/Orders"
          element={getLoginUser() ?
            (isFeatureAvailable('order') ?
              <AdminOrderList /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/OrderManagement/:orderId"
          element={getLoginUser() ?
            (isFeatureAvailable('order') ?
              <AdminOrderManagement /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/Customers"
          element={getLoginUser() ?
            (isFeatureAvailable('order') ?
              <ClientList /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/CustomerOrders"
          element={getLoginUser() ?
            ((!isFeatureAvailable('order') && isFeatureAvailable('track')) ?
              <OrderList /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/OrderTracking/:orderId"
          element={getLoginUser() ?
            ((!isFeatureAvailable('order') && isFeatureAvailable('track')) ?
              <OrderTracking /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/Team"
          element={getLoginUser() ?
            ((isFeatureAvailable('user')) ?
              <Team /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/Restore"
          element={getLoginUser() ?
            ((isFeatureAvailable('restore')) ?
              <Restore /> : <NotFoundElement />) : <Landing />} />
        <Route
          path="/RestoreTracking/:orderId"
          element={getLoginUser() ?
            ((isFeatureAvailable('restore')) ?
              <RestoreTracking /> : <NotFoundElement />) : <Landing />} />

        {/*       {featureComponents.map((featureComponent) => {
        if (features?.some((feature) => feature === featureComponent.key)){
          featureComponent.routes.map((route) => {
            return (
              <Route path={route.path} element={getLoginUser() ? route.component : <Landing />} />
            )
          })
        } else {
          return null;
        }
      })} */}
        {getLoginUser() && (<>
          <Route path="*"
            element={
              <>
                <div id="main">
                  <div class="fof">
                    <h1>Page Not Found</h1>
                  </div>
                </div>
              </>
            } />
        </>)}
      </Routes>
    </>
  );

};

const NotFoundElement = () => {
  return (
    <>
      <div id="main">
        <div class="fof">
          <h1>Page Not Found</h1>
        </div>
      </div>
    </>
  );
}