export const FEE_SERVICE_DURATION = [
    { key: 'free', label: 'Free' },
    { key: 'one_off', label: 'One Off' },
    { key: 'consumption', label: 'Per Usage/Unit/Consumption' },
    { key: 'recurring', label: 'Recurring' },
    { key: 'transactional', label: 'Transactional' },
];

export const FEE_UNITS = [
    { key: 'tape', label: 'Per Tape' },
    { key: 'vtl', label: 'Per VTL' },
    { key: 'site', label: 'Per Site' },
    { key: 'event', label: 'Per Event' },
    { key: 'box', label: 'Per Box' },
    { key: 'request', label: 'Per Request' },
    { key: 'other', label: 'Other' },
];
export const FEE_SERVICE = [
    // { key: "local_freight", service: "Local Freight", serviceFrequency: '', serviceRate: '' }, 
    // { key: "other_sales", service: "Other Sales", serviceFrequency: '', serviceRate: '' }, 
    // { key: "pilot_services_sales", service: "Pilot Services Sales", serviceFrequency: '', serviceRate: '' }, 
    // { key: "tape_audit", service: "Tape Audit (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "consulting", service: "Consulting (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "destruction_sales", service: "Destruction Sales (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "data_format_conversion", service: "Data format conversion (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "data_processing_&_analysis", service: "Data Processing & Analysis (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "data_reformatting", service: "Data Reformatting (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "data_transfer", service: "Data Transfer (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "freight", service: "Freight (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "hardware_sales", service: "Hardware Sales (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "tape_ingest", service: "Tape Ingest (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "media_sales", service: "Media Sales (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "setup/mobilisation", service: "Setup/Mobilisation (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "software_sales", service: "Software Sales (Project Services)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaas_tape_audit", service: "TCSaas Tape Audit (Initial)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_AWS_Cloud_Storage_MRR", service: "TCSaaS AWS Cloud Storage MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Destruction Sales", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Data Rotation MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Freight", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Google Cloud Storage MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Ingest (Initial)", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Tape Ingest MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Software Services Sales MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Microsoft Cloud Storage MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Data on Call Services MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Other Cloud Storage MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Physical Storage MRR", serviceFrequency: '', serviceRate: '' }, 
    // { key: "TCSaaS_destruction_sales", service: "TCSaaS Restore on Demand MRR", serviceFrequency: '', serviceRate: '' }, 

    { key: "legacy_hardware_removal", service: "Removal of legacy hardware", serviceFrequency: '', serviceRate: '' },
    { key: "account_creation", service: "Account Creation", serviceFrequency: '', serviceRate: '' },
    { key: "mobilisation", service: "Mobilisation", serviceFrequency: '', serviceRate: '' },
    { key: "decommisioning", service: "Decommisioning", serviceFrequency: '', serviceRate: '' },
    { key: "project_environment_setup", service: "Project and Environment Setup", serviceFrequency: '', serviceRate: '' },
    { key: "physical_tape_storage", service: "Physical Tape Storage/ per tape", serviceFrequency: '', serviceRate: '' },
    { key: "monthly_account_management", service: "Account Management Fee / fixed monthly fee", serviceFrequency: '', serviceRate: '' },
    { key: "aws_vtl_setup", service: "Establishment of AWS VTL (Per VTL)", serviceFrequency: '', serviceRate: '' },
    { key: "tape_audit", service: "Auditing of Tapes (Per Tape)", serviceFrequency: '', serviceRate: '' },
    { key: "restore_on_demand", service: "Restore on Demand (per restore event)", serviceFrequency: '', serviceRate: '' },
    { key: "tape_ingest", service: "Tape Ingest(Per Tape)", serviceFrequency: '', serviceRate: '' },
    { key: "physical_storage_tape_transport", service: "Transport of Tapes from Physical Storage(per event)", serviceFrequency: '', serviceRate: '' },
    { key: "out_of_hours_holiday_weekend", service: "Out of hours, holidays or weekend", serviceFrequency: '', serviceRate: '' },
    { key: "urgent_restores", service: "surcharge for urgent restores (Per Request)", serviceFrequency: '', serviceRate: '' },
    { key: "tape_retrieval", service: "Retrieval of Tapes from Physical", serviceFrequency: '', serviceRate: '' },
    { key: "storage", service: "Storage (Per Tape)", serviceFrequency: '', serviceRate: '' },
    { key: "storage_boxes", service: "Physical boxes for storage (Per Box)", serviceFrequency: '', serviceRate: '' },
    { key: "confidential_tape_destruction", service: "Confidentail Destruction of Tapes (Per Tape)", serviceFrequency: '', serviceRate: '' },
    { key: "network_data_transfer", service: "Network Data Transfer", serviceFrequency: '', serviceRate: '' },
    { key: "data_reformatting", service: "Data Reformatting (per tape)", serviceFrequency: '', serviceRate: '' },
    { key: "data_deletion", service: "Data Deletion", serviceFrequency: '', serviceRate: '' },
    { key: "data_indexing", service: "Data Indexing", serviceFrequency: '', serviceRate: '' },
    { key: "e_discovery", service: "e-Discovery", serviceFrequency: '', serviceRate: '' },
    { key: "consulting", service: "Consulting (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "tape_transport", service: "Transport of Tapes (Physical shipping)", serviceFrequency: '', serviceRate: '' },
    { key: "aws_fees", service: "AWS Cloud Storage and Processing Fees incurred on", serviceFrequency: '', serviceRate: '' },
    { key: "tapeark_paid", service: "behalf of Customer (AWS Charges accrued in the to the subaccount)", serviceFrequency: '', serviceRate: '' },
    { key: "international_freight", service: "International Freight", serviceFrequency: '', serviceRate: '' },
    { key: "interstate_freight", service: "Interstate Freight", serviceFrequency: '', serviceRate: '' },
    { key: "local_freight", service: "Local Freight", serviceFrequency: '', serviceRate: '' },
    { key: "other_sales", service: "Other Sales", serviceFrequency: '', serviceRate: '' },
    { key: "hardware_sales", service: "Hardware Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "media_sales", service: "Media Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
];

export const BACKUP_FORMATS = [
    { key: "seagate_backup", label: 'Seagate Backup' },
    { key: "iomega_ditto", label: 'Iomega Ditto' },
    { key: "hp_colorado_backup", label: 'HP Colorado Backup' },
    { key: "symantec_veritas_backup_exec", label: 'Symantec/Veritas Bacup Exec' },
    { key: "symantec_veritas_netbackup", label: 'Symantex/Veritas NetBackup' },
    { key: "ibm_spectrumprotect", label: 'INM SpectrumProtect' },
    { key: "tapeware", label: 'TapeWare' },
    { key: "ultraback", label: 'Ultraback' },
    { key: "emc", label: 'EMC' },
    { key: "veeam", label: 'VEEAM' },
    { key: "catalogic_dfpx", label: 'Catalogic DFPX' },
    { key: "hp_omniback_data_protector", label: 'HP/Omniback Data Protector' },
    { key: "ca_brightstor_arcserve", label: 'CA/Brightstor Arcserve' },
    { key: "quest_bakbone_netvault", label: 'Quest/Bakbone Netvault' },
    { key: "commvault_simpana", label: 'Commvault Simpana' },
    { key: "commvault_galaxy", label: 'Commvault Galaxy' },
    { key: "emc_legato_networker", label: 'EMX/Legato Networker' },
    { key: "avamar", label: 'Avamar' },
    { key: "data_domain", label: 'Data Domain' },
    { key: "tivoli_storage_manager", label: 'Tivoli Storage Manager' },
    { key: "tar", label: 'TAR' },
    { key: "microsoft_tape_format", label: 'Microsoft Tape Format' },
    { key: "nt_backup", label: 'NT Backup' },
    { key: "cheyenne", label: 'Cheyenne' },
    { key: "arcada", label: 'Arcada' },
    { key: "retrospect", label: 'Retrospect' },
    { key: "unix_tar", label: 'Unix TAR' },
    { key: "cpio", label: 'CPIO' },
    { key: "fbackup", label: 'FBackup' },
    { key: "fsdump", label: 'FSDump' },
    { key: "ufsdump", label: 'UFSDump' },
    { key: "compaq", label: 'Compaq' },
    { key: "vms_backup", label: 'VMS Backup' },
    { key: "intelliguard", label: 'Intelliguard' },
    { key: "sytron", label: 'Sytron' },

];


export const MEDIA_TYPES = [
    { key: "4mmdat", label: "4mm DAT", type: "subheader" },

    { key: "dds1", label: "DDS-1", type: "value" },
    { key: "dds2", label: "DDS-2", type: "value" },
    { key: "dds3", label: "DDS-3", type: "value" },
    { key: "dds4", label: "DDS-4", type: "value" },
    { key: "none", label: "None", type: "value" },
    { key: "dat72", label: "DAT-72", type: "value" },
    { key: "dat160", label: "DAT-160", type: "value" },
    { key: "dat320", label: "DAT-320", type: "value" },

    { key: "dlt", label: "DLT", type: "subheader" },
    { key: "dlts4", label: "DLT-S4", type: "value" },
    { key: "dlt1", label: "DLT1", type: "value" },
    { key: "dltvs80", label: "DLT-VS80", type: "value" },
    { key: "dlt2000", label: "DLT2000", type: "value" },
    { key: "dlt4000", label: "DLT4000", type: "value" },
    { key: "dlt7000", label: "DLT7000", type: "value" },
    { key: "dlt8000", label: "DLT8000", type: "value" },

    { key: "sdlt", label: "SDLT", type: "subheader" },
    { key: "sdlt220", label: "SDLT 220", type: "value" },
    { key: "sdlt320", label: "SDLT 320", type: "value" },
    { key: "sdlt600", label: "SDLT 600", type: "value" },

    { key: "3480", label: "3480", type: "subheader" },
    { key: "3480", label: "3480", type: "value" },

    { key: "3490", label: "3490", type: "subheader" },
    { key: "3490", label: "3490", type: "value" },
    { key: "3490e", label: "3490E", type: "value" },

    { key: "sd3", label: "SD-3", type: "subheader" },
    { key: "sd3", label: "SD-3", type: "value" },

    { key: "9840", label: "9840", type: "subheader" },
    { key: "t9840a", label: "T9840A", type: "value" },
    { key: "t9840b", label: "T9840B", type: "value" },
    { key: "t9840c", label: "T9840C", type: "value" },
    { key: "t9840d", label: "T9840D", type: "value" },

    { key: "9940", label: "9940", type: "subheader" },
    { key: "t9940a", label: "T9940A", type: "value" },
    { key: "t9940b", label: "T9940B", type: "value" },

    { key: "t10000", label: "T10000", type: "subheader" },
    { key: "t10000a", label: "T10000A", type: "value" },
    { key: "t10000b", label: "T10000B", type: "value" },
    { key: "t10000c", label: "T10000C", type: "value" },
    { key: "t10000d", label: "T10000D", type: "value" },

    { key: "3590", label: "3590", type: "subheader" },
    { key: "t3590b", label: "T3590B", type: "value" },
    { key: "t3590e", label: "T3590E", type: "value" },
    { key: "t3590h", label: "T3590H", type: "value" },

    { key: "3592", label: "3592", type: "subheader" },
    { key: "t3592j1a", label: "T3592-J1A (3592)", type: "value" },
    { key: "t3592e05", label: "T3592-E05 (TS1120)", type: "value" },
    { key: "t3592e06", label: "T3592-E06 (TS1130)", type: "value" },
    { key: "t3592e07", label: "T3592-E07 (TS1140)", type: "value" },
    { key: "t3592e08", label: "T3592-E08 (TS1150)", type: "value" },
    { key: "t359255f", label: "T3592-55F (TS1155)", type: "value" },

    { key: "lto", label: "LTO", type: "subheader" },
    { key: "lto1", label: "LTO-1", type: "value" },
    { key: "lto2", label: "LTO-2", type: "value" },
    { key: "lto3", label: "LTO-3", type: "value" },
    { key: "lto4", label: "LTO-4", type: "value" },
    { key: "lto5", label: "LTO-5", type: "value" },
    { key: "lto6", label: "LTO-6", type: "value" },
    { key: "lto7", label: "LTO-7", type: "value" },
    { key: "ltom8", label: "LTO M8", type: "value" },
    { key: "lto8", label: "LTO-8", type: "value" },
    { key: "lto9", label: "LTO-9", type: "value" },

    { key: "paper", label: "Paper", type: "value" },
    { key: "hdd", label: "HDD", type: "value" },
    { key: "server", label: "Server", type: "value" },
    { key: "appliance", label: "Appliance", type: "value" },
    { key: "dvd", label: "DVD", type: "value" },
    { key: "cd", label: "CD", type: "value" },
    { key: "blueray", label: "BlueRay", type: "value" },
    { key: "vhs", label: "VHS", type: "value" },
    { key: "betacam", label: "BetaCam", type: "value" },
    { key: "hdcam", label: "HD Cam", type: "value" },


];