import { CategoryScale, Chart, LinearScale } from "chart.js";

export class CustomLinearScale extends LinearScale {

    draw(chartArea) {
        try{
            const ctx = this.ctx;
            const { left, right, top, bottom } = chartArea;

            // Customize axis color and width
            ctx.save();
            ctx.strokeStyle = '#ECF0F3';
            ctx.lineWidth = 1;

            ctx.beginPath();
            ctx.moveTo(left, top);
            ctx.lineTo(left, bottom);
            ctx.stroke();

            ctx.restore();
            super.draw(chartArea);
        }catch(e){
            console.log(e);
        }
    }
}
CustomLinearScale.id = 'customLinearScale';

export class CustomCategoryScale extends CategoryScale {

    draw(chartArea) {
        try{
            const ctx = this.ctx;
            const { left, right, top, bottom } = chartArea;

            // Customize axis color and width
            ctx.save();
            ctx.strokeStyle = '#ECF0F3';
            ctx.lineWidth = 1;

            ctx.beginPath();
            ctx.moveTo(left, bottom);
            ctx.lineTo(right, bottom);
            ctx.stroke();

            ctx.restore();
            super.draw(chartArea);
        }catch(e){
            console.log(e);
        }
    }
}
CustomCategoryScale.id = 'customCategoryScale';

Chart.register(CustomLinearScale, CustomCategoryScale);