import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { borderRadius } from "@mui/system";
import { useEffect, useState } from "react";
import { getLocationDetails } from "../../../bindCountryState";
import moment from "moment";

export const Shipping = (props) => {
  // const [shippingMode, setShippingMode] = React.useState("self");
  // const [shippingLocations, setShippingLocations] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [currentEdit, setCurrentEdit] = React.useState(-1);
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const appendLocation = () => {
    let newLocation = {
      tempId: new Date().valueOf(),
      firstName: props.getValues("shippingLocations_firstName"),
      lastName: props.getValues("shippingLocations_lastName"),
      phone: props.getValues("shippingLocations_phone"),
      mobile: props.getValues("shippingLocations_mobile"),
      optional_email: props.getValues("optional_email"),
      pickup_date: props.getValues("pickup_date"),


      country: props.getValues("shippingLocations_country"),
      city: props.getValues("shippingLocations_city"),
      state: props.getValues("shippingLocations_state"),
      zipCode: props.getValues("shippingLocations_ZipCode"),
      address: props.getValues("shippingLocations_address"),
      ingestionSite: props.getValues("shippingLocations_ingestionSite"),
      shippingPartner: props.getValues("shippingLocations_partner"),
    };
    // props.setValue("shippingLocations", [...shippingLocations, newLocation]);

    // setShippingLocations([...shippingLocations, newLocation]);
    props.addShipment(newLocation);
  }
  // const syncShippingLocation=(newLocation)=>{
  //   setShippingLocations([...shippingLocations, newLocation]);
  // }

  const clearFields = () => {
    props.setValue("shippingLocations_firstName", '');
    props.setValue("shippingLocations_lastName", '');
    props.setValue("shippingLocations_phone", '');
    props.setValue("shippingLocations_mobile", '');
    props.setValue("optional_email", '');
    props.setValue("pickup_date", '');

    props.setValue("shippingLocations_country", '');
    props.setValue("shippingLocations_city", '');
    props.setValue("shippingLocations_state", '');
    props.setValue("shippingLocations_ZipCode", '');
    props.setValue("shippingLocations_address", '');
    props.setValue("shippingLocations_ingestionSite", '');
    props.setValue("shippingLocations_partner", '');
  };

  const removeRow = (index) => {
    let _shippingLocations = [...props.shippingLocations];
    props.removeShipment(index, _shippingLocations);
    // _shippingLocations.splice(index, 1);
    // props.setValue("shippingLocations", _shippingLocations);
    // setShippingLocations(_shippingLocations);
  }
  const editRow = (index) => {
    let _shippingLocations = [...props.shippingLocations];
    let _shippingLocation = _shippingLocations[index];
    props.setValue("shippingLocations_firstName", _shippingLocation.firstName)
    props.setValue("shippingLocations_lastName", _shippingLocation.lastName)
    props.setValue("shippingLocations_phone", _shippingLocation.phone)
    props.setValue("shippingLocations_mobile", _shippingLocation.mobile)
    props.setValue("optional_email", _shippingLocation.optional_email)
    props.setValue("pickup_date", _shippingLocation.pickup_date)

    props.setValue("shippingLocations_country", _shippingLocation.country)
    props.setValue("shippingLocations_city", _shippingLocation.city)
    props.setValue("shippingLocations_state", _shippingLocation.state)
    props.setValue("shippingLocations_ZipCode", _shippingLocation.zipCode)
    props.setValue("shippingLocations_address", _shippingLocation.address)
    props.setValue("shippingLocations_ingestionSite", _shippingLocation.ingestionSite)
    props.setValue("shippingLocations_shippingPartner", _shippingLocation.shippingPartner)
    setIngestionSite(_shippingLocation.ingestionSite);
    setShippingPartner(_shippingLocation.shippingPartner);
    setCurrentEdit(_shippingLocation.uuid);
    setIsEdit(true);
  }
  const updateRow = () => {
    let _shippingLocations = [...props.shippingLocations];
    let index = _shippingLocations.findIndex(e => e.uuid == currentEdit)
    if (index != -1) {
      let newLocation = {
        firstName: props.getValues("shippingLocations_firstName"),
        lastName: props.getValues("shippingLocations_lastName"),
        phone: props.getValues("shippingLocations_phone"),
        mobile: props.getValues("shippingLocations_mobile"),
        optional_email: props.getValues("optional_email"),
        pickup_date: props.getValues("pickup_date"),


        country: props.getValues("shippingLocations_country"),
        city: props.getValues("shippingLocations_city"),
        state: props.getValues("shippingLocations_state"),
        zipCode: props.getValues("shippingLocations_ZipCode"),
        address: props.getValues("shippingLocations_address"),
        ingestionSite: props.getValues("shippingLocations_ingestionSite"),
        shippingPartner: props.getValues("shippingLocations_partner"),
        uuid: _shippingLocations[index]['uuid'],
      };
      _shippingLocations[index] = newLocation;
      // props.setValue("shippingLocations", _shippingLocations);
      props.updateShipment(newLocation)
      // setShippingLocations(_shippingLocations);
      setIsEdit(false);
    }
  }
  const handleChangeShippingMode = (event, newShippingMode) => {
    props.setValue("shippingMode", newShippingMode);
    props.setShippingMode(newShippingMode);
  };
  useEffect(() => {
    try {
      // props.setValue("shippingMode", "self");
      props.setValue("newShippingLocation", "single");

      return () => {
      };
    } catch (e) {
      console.log(e);
    }
  }, []);
  const [isSingleShippingLocation, setIsSingleShippingLocation] =
    React.useState("single");
  const handleChangeShippingLocation = (event, newShippingLocation) => {
    props.setValue("newShippingLocation", newShippingLocation);
    setIsSingleShippingLocation(newShippingLocation);
  };
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [ingestionSite, setIngestionSite] = React.useState("");
  const handleChangeIngestionSite = (event) => {
    setIngestionSite(event.target.value);
  };
  const [shippingPartner, setShippingPartner] = React.useState("");
  const handleChangeShippingPartner = (event) => {
    setShippingPartner(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const styles = (theme) => ({
    select: {
      "&:before": {
        borderColor: "#ffffff",
      },
      "&:after": {
        borderColor: "#ffffff",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "#ffffff",
      },
    },
    textField: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
    },
    input: {
      color: "white",
    },
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const fillCityAndStateFields = (localities) => {
    let locality = localities[0]; //use the first city/state object
    props.setValue("shippingLocations_country", locality.country);
    props.setValue("shippingLocations_city", locality.city);
    props.setValue("shippingLocations_state", locality.state);
  }
  return (
    <>
      <Grid
        container
        style={{
          border: "1px solid #C7CCD0",
          borderRadius: "8px",
          marginTop: "20px",
          minHeight: "100vh",
          height: "max-content",
          marginBottom: "100px",
          alignItems: "start",
          display: "block",
        }}
      >
        <Grid container direction={"column"}>
          {/* Stepper */}
          <Grid item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                border: "8px 8px 0px 0px",
                border: "#272727",
                padding: "20px",
                background: "#272727",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Typography className={classes.shippingheading}>
                Shipping Details
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction={"column"} p={3}>
          <Grid item sx={{ display: "flex", justifyContent: "start" }}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{ alignItems: "center" }}
              >
                <Grid item className={classes.toggleContainer}>
                  <Typography
                    className={classes.modehead}
                    style={{ textAlign: "left" }}
                  >
                    Shipping Mode
                  </Typography>
                  <ToggleButtonGroup
                    {...props.register('shippingMode')}
                    color="primary"
                    value={props.shippingMode}
                    exclusive
                    onChange={handleChangeShippingMode}
                    aria-label="Platform"
                    style={{
                      borderRadius: "7px",
                      border: "1px solid #7B809A",
                      background: "var(--Charcoal, #3A3E3F)",
                      marginTop: "12px",
                      display: "flex",
                      width: "492px",
                    }}
                  >
                    <ToggleButton value="tapeark" className="h-40">
                      TapeArk to Collect
                    </ToggleButton>
                    <ToggleButton
                      value="self"
                      className="h-40"
                      style={{ borderLeft: "1px solid #C7CCD0" }}
                    >
                      Client to ship
                    </ToggleButton>
                    <ToggleButton
                      value="none"
                      className="h-40"
                      style={{ borderLeft: "1px solid #C7CCD0" }}
                    >
                      None of these
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {props.shippingMode == "self" && (
                    <>
                      <Grid
                        container
                        display="flex"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{
                          alignItems: "center",
                          marginTop: "15px",
                          padding: "15px 8px",
                        }}
                      >
                        <Grid
                          display="flex"
                          direction="column"
                          alignItems="center"
                        >{
                            props.shippingLinksFields.map((item, index) => {
                              return (<>
                                <span key={item.id} style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}                    >
                                  <Stack direction={"row"} gap={2}>
                                    {/* <input  name={`shippingLinks.${index}.firstName`} {...props.register(`shippingLinks.${index}.firstName`)}></input> */}
                                    <TextField
                                      {...props.register(`shippingLinks.${index}.link`)}
                                      // id="trackingLinks"
                                      label="Tracking Links"
                                      variant="outlined"
                                      className={classes.textField}
                                      style={{
                                        borderRadius: "8px",
                                        border: "1px solid #7B809A",
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          color: "#686868",
                                          fontFamily:
                                            '"Poppins", sans-serif !important',
                                          input: { color: "#fff" },
                                        },
                                      }}
                                      sx={{
                                        input: { color: "#fff" },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            border: "1px solid transparent",
                                          },
                                        },
                                      }}
                                    />
                                  </Stack>
                                  <Stack direction="row" spacing={2} ml={2}>
                                    {/* Add Link */}
                                    <Button
                                      onClick={() => props.shippingLinksAppend({ link: "" })}
                                      className={classes.plusMinBtn}
                                      variant="outlined"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.0249 4.95642L11.009 17.0431"
                                          stroke="#767676"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4.9563 10.9994H17.043"
                                          stroke="#767676"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </Button>
                                    {/* Remove Link */}
                                    <Button
                                      onClick={() => props.shippingLinksRemove(index, item.uuid)}
                                      className={classes.plusMinBtn}
                                      variant="outlined"
                                      href="#outlined-buttons"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                      >
                                        <path
                                          d="M4.9563 10.9994H17.043"
                                          stroke="#767676"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </Button>
                                  </Stack>
                                  <br></br>
                                </span >
                                <br></br>
                              </>)
                            })
                          }

                        </Grid>
                      </Grid>
                      <br />
                      <Stack direction={"row"} gap={4} alignItems="start">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            value={props.startDateFilter}
                            name="pickUpDate"
                            label="Pick up Date"
                            className={classes.dateinput}
                            type="date"
                            // value={startDateFilter}
                            onChange={(newValue) => props.setValue("pickUpDate", newValue)}
                            variant="outlined"
                            sx={{
                              svg: { color: "#737373" },
                              input: { color: "#fff" },
                              label: { color: "#686868" },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  border: "1px solid transparent",
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </>
                  )}
                  {props.shippingMode == "none" && (
                    <>
                      {/* <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{
                          alignItems: "center",
                          marginTop: "15px",
                          padding: "15px",
                        }}
                      >
                        <Grid container direction="row">
                          <Typography style={{ color: "#fff" }}>
                            None Data
                            <Button
                              variant="outlined"
                              onClick={handleClickOpen}
                            >
                              Open dialog
                            </Button>
                            <CustomizedDialogs
                              open={open}
                              handleClose={handleClose}
                            ></CustomizedDialogs>
                          </Typography>
                        </Grid>
                      </Grid> */}
                    </>
                  )}
                  {props.shippingMode == "tapeark" && (
                    <>
                      <Grid
                        container
                        display="flex"
                        direction="column"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{
                          alignItems: "start",
                          marginTop: "15px",
                          padding: "15px 8px",
                        }}
                      >
                        <Grid
                          display="flex"
                          direction="column"
                          alignItems="center"
                        >
                          <Stack direction={"row"} alignItems="center">
                            {/* <Checkbox {...label} {...props.register('chainOfCustody')} /> */}
                            <input type="checkbox" id="chainOfCustody"  {...props.register('chainOfCustody')} />
                            <Typography className={classes.modehead}>
                              Enable Chain Of Custody
                            </Typography>
                          </Stack>
                        </Grid>
                        <br />
                        {/* //label="Pick up Date" */}
                        {/* <Stack direction={"row"} gap={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              className={classes.dateinput}
                              type="date"
                              onChange={(newValue) => props.setValue("pickUpDateFromTapeArk", newValue)}
                              value={props.startDateFilter}
                              variant="outlined"
                              sx={{
                                svg: { color: "#737373" },
                                input: { color: "#fff" },
                                label: { color: "#686868" },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    border: "1px solid transparent",
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Stack>
                        <br /> */}
                        <Stack direction={"column"} gap={0} alignItems="start">
                          {props?.shippingLocations?.length ? <Typography className={classes.modehead}>
                            Shipping Location
                          </Typography> : ""}
                          {/* <ToggleButtonGroup
                            color="primary"
                            value={isSingleShippingLocation}
                            exclusive
                            onChange={handleChangeShippingLocation}
                            aria-label="Platform"
                            style={{
                              borderRadius: "7px",
                              border: "1px solid #7B809A",
                              background: "var(--Charcoal, #3A3E3F)",
                              marginTop: "12px",
                              display: "flex",
                            }}
                          >
                            <ToggleButton
                              value="single"
                              className="h-40 shippingSelection"
                            >
                              <>
                                {isSingleShippingLocation == "single" && (
                                  <CheckCircleIcon />
                                )}
                                Single
                              </>
                            </ToggleButton>
                            <ToggleButton
                              value="multiple"
                              className="h-40 shippingSelection"
                            >
                              <>
                                {isSingleShippingLocation == "multiple" && (
                                  <CheckCircleIcon />
                                )}
                                Multiple
                              </>
                            </ToggleButton>
                          </ToggleButtonGroup> */}
                        </Stack>
                        <br />
                        <div style={{ display: "flex" }}>
                          {
                            props.shippingLocations.map((cardDetail, index) => {
                              return (<>
                                <BasicCard key={index} cardDetail={cardDetail} removeRow={() => removeRow(index)} editRow={() => editRow(index)} isEdit={isEdit} updateRow={() => updateRow(index)}></BasicCard>
                              </>)
                            })

                          }
                        </div>
                        <>
                          <br />
                          <Grid container direction='column' spacing={2}>
                            <Grid item mb={1}>
                              <Typography align='left' className={classes.formInputHeading}>Collection Point</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                <Grid item xs={6}>
                                  <Typography align='left' className={classes.formInputHeading}>First Name</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_firstName'
                                    {...props.register(`shippingLocations_firstName`)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align='left' className={classes.formInputHeading}>Last Name</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_lastName'
                                    {...props.register(`shippingLocations_lastName`)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                <Grid item xs={6}>
                                  <Typography align='left' className={classes.formInputHeading}>Phone(optional)</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_phone'
                                    {...props.register(`shippingLocations_phone`)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align='left' className={classes.formInputHeading}>Mobile</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_mobile'
                                    {...props.register(`shippingLocations_mobile`)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                <Grid item xs={6}>
                                  <Typography align='left' className={classes.formInputHeading}>Email(optional)</Typography>
                                  <TextField
                                    type='email'
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='optional_email'
                                    {...props.register(`optional_email`)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography className={classes.formInputHeading} align='left'>Pick up Date</Typography>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                      slotProps={{
                                        textField: {
                                          required: true
                                        }
                                      }}
                                      variant="outlined"
                                      onChange={(newValue) => props.setValue("pickup_date", newValue)}
                                      defaultValue={props.watch('pickup_date') ? moment(props.watch('pickup_date')) : undefined}
                                      className={classes.dateinput}
                                      value={props.watch('pickup_date') ? moment(props.watch('pickup_date')) : undefined}
                                      sx={{
                                        width: '100%',
                                        svg: { color: "#737373" },
                                        input: { color: "#fff", width: '100%' },
                                        label: { color: "#686868" },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            border: "1px solid transparent",
                                          },
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={3} mb={4}>
                              <Typography align='left' className={classes.formInputHeading}>  Address</Typography>
                              <TextField
                                className={classes.selectLabel}
                                fullWidth={true}
                                name='shippingLocations_address'
                                {...props.register(`shippingLocations_address`)}
                              // onChange={handleChangeIngestionSite}
                              />
                            </Grid>
                            <Grid item>
                              <Grid container direction='row' justifyContent='flex-start' spacing={2} xs={12}>
                                <Grid item xs={3}>
                                  <Typography align='left' className={classes.formInputHeading}>ZipCode</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    {...props.register(`shippingLocations_ZipCode`, { onBlur: (e) => getLocationDetails(e, fillCityAndStateFields) })}
                                    name='shippingLocations_ZipCode' />
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align='left' className={classes.formInputHeading}>Town City</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_city' {...props.register(`shippingLocations_city`)}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align='left' className={classes.formInputHeading}>State</Typography>
                                  <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='shippingLocations_state' {...props.register('shippingLocations_state')} />
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align='left' className={classes.formInputHeading}>Country</Typography>
                                  <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    name='shippingLocations_country' {...props.register(`shippingLocations_country`)}
                                  />
                                </Grid>

                              </Grid>
                            </Grid>

                            <Grid item xs={12} mb={1}>
                              <Typography align='left' className={classes.formInputHeading}> Destination</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography align='left' className={classes.formInputHeading}> Ingestion Site</Typography>
                              <TextField
                                className={classes.selectLabel}
                                fullWidth={true}
                                value={ingestionSite}
                                {...props.register(`shippingLocations_ingestionSite`)}
                                select
                                onChange={handleChangeIngestionSite}
                              >
                                <MenuItem value='montreal_nearest'>Montreal Nearest</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography align='left' className={classes.formInputHeading}>  Shipping Partner</Typography>
                              <TextField
                                className={classes.selectLabel}
                                fullWidth={true}
                                name='shippingLocations_partner'  {...props.register(`shippingLocations_partner`)}
                                select
                                value={shippingPartner}
                                // label="Shipping Partner"
                                onChange={handleChangeShippingPartner}
                              >
                                <MenuItem value={'fedex'}>FedEx</MenuItem>
                                <MenuItem value={'ups'}>UPS</MenuItem>
                                <MenuItem value={'mnx'}>MNS</MenuItem>
                                <MenuItem value={'dhl'}>DHL</MenuItem>
                              </TextField>
                            </Grid>
                          </Grid>
                        </>
                        <Stack direction="row" gap={6}>
                          {
                            // isSingleShippingLocation == "multiple" && 
                            !isEdit && (
                              <Button
                                onClick={() => appendLocation()}
                                variant="contained"
                                sx={{
                                  marginTop: "30px",
                                  color: "var(--Yellow---Primary, #FFC540)",
                                  background: "transparent",
                                  width: "100px",
                                  height: "40px",
                                  padding: "12px",
                                  borderRadius: "30px",
                                  // color: '#FFFFFF',
                                  textTransform: "none",
                                  border: "1px solid #FFC540",
                                  // '&:hover': {
                                  //   backgroundColor: '#C7CCD0',
                                  // },
                                }}
                              >
                                Add
                              </Button>
                            )}
                          {
                            // isSingleShippingLocation == "multiple" &&
                            isEdit && (
                              <Button
                                onClick={() => updateRow()}
                                variant="contained"
                                sx={{
                                  marginTop: "30px",
                                  color: "var(--Yellow---Primary, #FFC540)",
                                  background: "transparent",
                                  width: "100px",
                                  height: "40px",
                                  padding: "12px",
                                  borderRadius: "30px",
                                  // color: '#FFFFFF',
                                  textTransform: "none",
                                  border: "1px solid #FFC540",
                                  // '&:hover': {
                                  //   backgroundColor: '#C7CCD0',
                                  // },
                                }}
                              >
                                Update
                              </Button>
                            )}
                          {isEdit && (
                            <Button
                              onClick={() => { clearFields(); setIsEdit(false); }}
                              variant="contained"
                              sx={{
                                marginTop: "30px",
                                color: "var(--Yellow---Primary, #FFC540)",
                                background: "transparent",
                                width: "100px",
                                height: "40px",
                                padding: "12px",
                                borderRadius: "30px",
                                // color: '#FFFFFF',
                                textTransform: "none",
                                border: "1px solid #FFC540",
                                // '&:hover': {
                                //   backgroundColor: '#C7CCD0',
                                // },
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </Stack>
                      </Grid >
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  );
};

export const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: "#ffc540",
    // borderRadius: "8px",
    color: "#3a3e3f",
    fontWeight: 600,
    fontSize: "14px",
  },
  "&.MuiToggleButton-root": {
    // color: "#404558",
    borderRadius: "8px",
    textTransform: "none !important",
    height: "5em !important",
    fontSize: "14px",
    width: "164px",
    // border-radius: 8px;
    background: "#3a3e3f",
    color: " #C7CCD0",
    fontFamily: '"Poppins", sans-serif !important',
  },
  "&.shippingSelection": {
    height: "64px !important",
  },
});
const useStyles = makeStyles({
  shippingheader: {
    textAlign: "left",
    display: "flex",
  },
  shippingheading: {
    color: "#fff",
    fontSize: "20px !important",
    fontWeight: "700 !important",
    fontFamily: '"Poppins", sans-serif !important',
  },
  modehead: {
    color: "#C7CCD0",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    fontFamily: '"Poppins", sans-serif !important',
  },
  plusMinBtn: {
    borderRadius: "3",
    border: " 1px solid #7B809A  !important",
    background: "var(--Charcoal, #3A3E3F)",
    width: "43px !important",
    height: "43px",
    minWidth: "47px !important",
  },
  dateinput: {
    borderRadius: "8px",
    border: " 1px solid #7B809A  !important",
    background: "var(--Charcoal, #3A3E3F)",
    overflow: "hidden",
  },
  formInputHeading: {
    fontFamily: 'Poppins !important',
    fontWeight: '400 !important',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#C7CCD0'
  },
  selectLabel: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: '#C7CCD0',
      },
      '&:hover fieldset': {
        borderColor: '#C7CCD0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#C7CCD0',
      }
    },
    '& .MuiOutlinedInput-input': {
      color: '#FFFFFF',
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.5)',
      '&.Mui-focused': {
        color: '#FFC540'
      },
    },
    '& svg': {
      color: '#FFFFFF'
    }
  },
});

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard(props) {
  return (
    <Card
      sx={{
        width: "301px !important",
        height: "400px !important",
        flexShrink: "0 !important",
        borderRadius: "12px !important",
        border: "2px solid #C7CCD0 !important",
        background: "var(--Charcoal, #3A3E3F) !important",
        boxShadow: "0px 7px 11px 0px rgba(0, 0, 0, 0.16) !important",
        marginRight: 4,
      }}
    >
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item>
            <Typography
              sx={{
                fontSize: 14,
                color: "var(--Yellow---Primary, #FFC540)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
              }}
              gutterBottom
            >
              {props.cardDetail.firstName + " " + props.cardDetail.lastName}
            </Typography>
          </Grid>
          {props?.cardDetail.optional_email ?
            <Grid item>
              <Typography
                sx={{
                  color: "var(--White, #FFF)",
                  textAlign: "center",
                  fontFamily: '"Poppins", sans-serif !important',

                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {props.cardDetail.optional_email}
              </Typography>
            </Grid> : ""
          }
          <Grid item>
            <Typography
              sx={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              {props.cardDetail.phone}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "32px",
              }}
            >
              {props.cardDetail.address}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              {props.cardDetail.shippingPartner}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              {props.cardDetail.zipCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "var(--White, #FFF)",
                textAlign: "center",
                fontFamily: '"Poppins", sans-serif !important',

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "32px",
              }}
            >
              {props.cardDetail.city} {props.cardDetail.state} {props.cardDetail.country}
            </Typography>
          </Grid>
          {props?.cardDetail?.pickup_date ?
            <Grid item>
              <Typography
                sx={{
                  color: "var(--White, #FFF)",
                  textAlign: "center",
                  fontFamily: '"Poppins", sans-serif !important',

                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {moment(props.cardDetail.pickup_date).format("MMM/DD/YYYY")}
              </Typography>
            </Grid> : ""
          }
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            px: "20px",
            py: '10px'
          }}
        >
          {/* Clear Button */}
          <Button
            variant="outlined"
            onClick={props.removeRow}
            sx={{
              color: "var(--White, #FFF)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",

              width: "100px",
              height: "40px",
              borderRadius: "30px",
              color: "#FFFFFF",
              textTransform: "none",
              border: "1px solid #FFFFFF",
              // '&:hover': {
              //   backgroundColor: '#C7CCD0',
              //       },
            }}
          >
            Delete
          </Button>
          {/* Next Button */}
          <Button
            onClick={props.editRow}
            variant="contained"
            sx={{
              width: "100px",
              height: "40px",
              borderRadius: "30px",
              background: "linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)",
              color: "#3A3E3F",
              textTransform: "none",
              "&.Mui-disabled": {
                background: "#C7CCD0",
                color: "#5F5F5F",
              },
            }}
          >
            Edit
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialog-paper": {
    borderRadius: "8px",
    backgroundColor: "var(--Charcoal, #3A3E3F)",
    width: "508px",
    height: "274px",
  },
}));

export function CustomizedDialogs(props) {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const classes = useStyles();

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title" color="var(--Yellow---Primary, #FFC540)" fontSize='20px' fontWeight='600'>
          Choose Courier
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              value={age}
              label="Age"
              onChange={handleChange}
              variant="outlined"
              className={classes.textField}
              sx={{
                borderRadius: "8px",
                height: "50px",
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                  border: "0.5px solid white",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              style={{
                color: "#fff",
                height: "40px",
                // top: '7px',
              }}
              InputLabelProps={{
                sx: {
                  color: "#686868",
                  fontFamily: '"Poppins", sans-serif !important',
                  input: { color: "#fff" },
                },
              }}

            // sx={{
            //   svg: { color: "#737373" },
            //   input: {
            //     color: "#fff",
            //     borderRadius: "8px",
            //     border: '1px solid rgb(123, 128, 154);}'
            //   },
            //   label: { color: "#686868" },
            //   "& .MuiOutlinedInput-root": {
            //     "&.Mui-focused fieldset": {
            //       border: "1px solid transparent"
            //     },
            //   },
            // }}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={props.handleClose}
            sx={{
              color: "#FFF",
              background: "transparent",
              width: "100px",
              height: "40px",
              padding: "12px",
              borderRadius: "30px",
              // color: '#FFFFFF',
              textTransform: "none",
              fontWeight: "400",
              border: "1px solid #fff",
              // '&:hover': {
              //   backgroundColor: '#C7CCD0',
              // },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={props.handleClose}
            sx={{
              color: "#3A3E3F",
              background: "var(--Yellow---Primary, #FFC540)",
              width: "100px",
              height: "40px",
              padding: "12px",
              borderRadius: "30px",
              // color: '#FFFFFF',
              textTransform: "none",
              border: "1px solid #FFC540",
              // '&:hover': {
              //   backgroundColor: '#C7CCD0',
              // },
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
