import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Paper, Typography, Card, TextField, SpeedDial, SpeedDialAction, SpeedDialIcon, Menu, alpha, Divider, Fab, Popper, Grow, ClickAwayListener, MenuList, Dialog, DialogContent } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { ButtonColor, ButtonColorGradient } from "../theme/theme";
import CardContent from '@mui/material/CardContent';
import { FileUp } from "../fileUp/file_up";
import { DataType } from "../dataType/dataType";
import { Backup } from "../backup/backup";
import { StoragePolicies } from "../storagePolicies/storagePolicies";
import CloseIcon from '@mui/icons-material/Close';
import "./home.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
// import * as faker from '@faker-js/faker';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FileServerTable } from "../fileServerTable/fileServerTable";
import { TapeDeletion } from "../tapeDeletion/tapes_deletion";
import LastAccessed from "../lastAccessed/lastAccessed";
import Usage from "../usage/usage";
import { Person, ShoppingCart, Add } from "@mui/icons-material";
import axios from "axios";
//import { env } from "../../env";
import { getApiUrl } from "./../../global";

import { getUserRole } from "../../LocalStorageService";
import { useFeatures } from "../../views/FeaturesProvider/featuresProvider";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { totals, ingested } from "../../global";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const options = {

    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRation: true,
    scales: {
        y: {
            ticks: {
                color: 'white', beginAtZero: true,
                callback: ((context, index) => {
                    // console.log(context);
                    return formatBytes(context)
                })
            }
        },

        x: {
            barThickness: 1,
            ticks: {
                color: 'white    ', beginAtZero: true
            }
        }
    },

    plugins: {
        legend: {
            position: 'top',
            lineWidth: 2,
        },

        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        legend: {
            display: false
        },

    },


};

const checkDate = (date) => {
    let check = moment(date, 'YYYY/MM/DD');
    let month = check.format('MM');
    let day = check.format('D');
    let year = check.format('YYYY');
    return {
        day: day,
        month: month,
        year: year
    }
}
const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
const convertNumberToKMGFormat = (number) => {
    if (number < 1000) {
        return number.toString();
    }
    if (number < 1000000) {
        return (number / 1000).toFixed(1) + "K";
    }
    if (number < 1000000000) {
        return (number / 1000000).toFixed(1) + "M";
    }
    return (number / 1000000000).toFixed(1) + "G";

}
const totalBoxStaticData = [{
    icon: '/assets/img/server.svg',
    data: 0,
    text: 'File Servers'
}, {
    icon: `/assets/img/Users.svg`,
    data: 0,
    text: 'Users'
}, {
    icon: '/assets/img/Storage.svg',
    data: 0,
    text: 'Storage'
}, {
    icon: '/assets/img/Critical.svg',
    data: 0,
    // text: 'Expiring Files'
    text: 'Number of Files'
}
    // , {
    //     icon: '/assets/img/g30110.svg',
    //     data: 0,
    //     text: 'Virtual Machines'
    // }
];
export const Home = () => {
    //TODO: Remove when RBAC implemented
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { features } = useFeatures();
    const [accountDialog, setAccountDialog] = React.useState(false);
    const [openFileServer , setOpenFileServer] = useState(false)
    const [clientList, setClientList] = React.useState([]);
    const [boxes, setBoxes] = React.useState(totalBoxStaticData);
    const [loading, setLoading] = React.useState({
        totalLoader: true,
        ingestedLoader: true
    });
    const [data, setData] = React.useState();
    const classes = useStyles();
    // const handleChange = (event, newAlignment) => {
    //     setAlignment(newAlignment);
    // };
    const navigate = useNavigate();
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        color: '#fff',
        textAlign: 'left',


    }));

    const getClientList = async () => {
        const apiUrl = await getApiUrl() + process.env.REACT_APP_API_BASE_URL + "/customers/";
        axios
            .get(apiUrl)
            .then(data => {
                setClientList(data.data);
                // console.log(data.data.results);
                // setCustomerList(data.data.results);
            })
            .catch(error => console.log(error));
    }
    const [date, setDate] = React.useState("month");
    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    const handleChange = (event, newAlignment) => {
        setDate(newAlignment);
    };

    // Admin Action menu
    const [adminActionMenuOpen, setAdminActionMenuOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleAdminActionMenuToggle = () => {
        setAdminActionMenuOpen((prevOpen) => !prevOpen);
    };

    const handleAdminActionMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setAdminActionMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setAdminActionMenuOpen(false);
        } else if (event.key === 'Escape') {
            setAdminActionMenuOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(adminActionMenuOpen);
    React.useEffect(() => {
        if (prevOpen.current === true && adminActionMenuOpen === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = adminActionMenuOpen;

    }, [adminActionMenuOpen]);
    React.useEffect(() => {
        getTotalData();
        getIngestionData();
    }, []);
    const [clientValue, setClientValue] = React.useState('')
    const handlechangeClientList = (e) => {
        setClientValue(e.target.value)
    }
    const handleContinue = () => {
        navigate(`/JobCreation`,
            {
                state: {
                    "uuid": clientValue,
                    "step": 1,
                    "iscomingFrom": "customerSelection"
                }
            });
    }
    const openAccountDialog = () => {
        getClientList();
        setAccountDialog(true);
    }
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        if (features && features?.find((feature) => feature === 'admin_dashboard')) {
            setIsAdmin(true);
        }
    }, [features])
    const getTotalData = () => {
        updateLoadingState('totalLoader', true);
        let payload = {
            type: 'rep_total',
            "request": {
                "cid": 15,
                "type": "totals"
            }
        };
        axios.post(totals, payload)
            .then(response => {
                if (response?.status == 200) {
                    let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
                    // let labels = response?.data?.body?.map((e) => e.name);
                    let data = [{
                        icon: '/assets/img/server.svg',
                        data: dynamicArray['server-count'],
                        text: 'File Servers'
                    }, {
                        icon: `/assets/img/Users.svg`,
                        data: dynamicArray['company-users'],
                        text: 'Users'
                    }, {
                        icon: '/assets/img/Storage.svg',
                        data: formatBytes(dynamicArray['total-storage'], 2),
                        text: 'Storage'
                    }, {
                        icon: '/assets/img/Critical.svg',
                        data: convertNumberToKMGFormat(dynamicArray['file-count']),
                        // text: 'Expiring Files'
                        text: 'Number of Files'
                    }
                        // , {
                        //     icon: '/assets/img/g30110.svg',
                        //     data: response?.data['vm-count'],
                        //     text: 'Virtual Machines'
                        // }
                    ];
                    setBoxes(data);
                    updateLoadingState('totalLoader', false);
                }
            })
            .catch(error => {
                console.log(error)
                updateLoadingState('totalLoader', false);

            });
    }
    const getIngestionData = () => {
        updateLoadingState('ingestedLoader', true);
        let payload = {
            "type": "rep_ingest",
            "request": {
                "cid": 15,
                "type": "ingested-timeline"
            }
        };
        axios.post(ingested, payload)
            .then(response => {
                if (response?.status == 200) {
                    let _allMonthData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Sep', 'Oct', 'Nov', 'Dec'];
                    let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
                   dynamicArray.forEach(element => {
                        let _date = checkDate(element['ingest-date']);

                        _allMonthData[_date.month - 1] = _allMonthData[_date.month - 1] + element?.bytes;

                        if (!labels[_date.month - 1].includes(_date.year))
                            labels[_date.month - 1] = labels[_date.month - 1] + "-" + _date.year;
                    });
                    let _data = {
                        labels: labels,
                        datasets: [
                            {
                                label: '',
                                data: _allMonthData,
                                borderWidth: 0,
                                categoryPercentage: 1,
                                backgroundColor: [
                                    "rgba(56, 170, 88, 1) ",
                                    "rgba(0, 126, 35, 1) ",
                                    "rgba(56, 170, 88, 1)",
                                    "rgba(0, 126, 35, 1)",
                                    "rgba(189, 95, 95, 1) "
                                ],
                                borderWidth: 1,
                                barPercentage: 0.4,
                                borderRadius: 4
                            }

                        ],

                    };
                    setData(_data)
                    updateLoadingState('ingestedLoader', false);
                }
            })
            .catch(error => {
                console.log(error)
                updateLoadingState('ingestedLoader', false);
            });
    }
    const updateLoadingState = (widgetName, isLoading) => {
        setLoading((prevLoader) => ({
            ...prevLoader, [widgetName]: isLoading
        }));
    }
    return (
        <Box Box p={4} pl={10} pr={10} >
         {openFileServer ?  <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        <FileServerTable/>
                    </Grid>
                </Grid> : 
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                        <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px', color :"#fff" }}>Your Account</Typography>

                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justifyContent="flex-end" >
                                <Grid item className={classes.toggleContainer}>
                                    <Paper elevation={1} sx={{
                                        border: '1px solid #FFC540',
                                        display: 'flex',
                                        background: 'transparent',
                                        flexWrap: 'wrap',
                                        padding: 0.5,
                                    }}>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={filter}
                                            exclusive
                                            onChange={(e)=> setFilter(e.target.value)}
                                            aria-label="Platform"
                                        >
                                            <ToggleButton value="current_month">This Month</ToggleButton>
                                            <ToggleButton value="last_month">Last Month</ToggleButton>
                                            <ToggleButton value="current_year">This Year</ToggleButton>
                                            <ToggleButton value="custom_date">Custom Date</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        {boxes.map(item => (
                            <Grid item md key={item}>
                                <Card variant="outlined" className="dashboardGridBg" onClick={()=> { if (item.text == 'File Servers') {navigate("/FileServer")}}}>
                                    <CardContent className="dashboardGrid" style={loading.totalLoader ? {
                                        position: 'relative',
                                        opacity: '0.2'
                                    } : {}}>
                                        {
                                            loading.totalLoader ?
                                                <CircularProgress style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }} /> : ""
                                        }
                                        <Typography>
                                            <div style={{
                                                height: '60px', width: '60px', display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: 'auto'
                                            }}>
                                                <img src={item.icon} alt="" />
                                            </div>
                                        </Typography>
                                        <Typography variant="h5" component="div" sx={{ fontSize: 24 }} color="text.secondary" gutterBottom className="dashboardConatiner">
                                            {item.data}</Typography>
                                        <Typography variant="h5" component="div" sx={{ fontSize: 14 }} color="white" className="dashboardText">
                                            {item.text}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        <Grid item xs={6}>
                            <div className={classes.mainContainer}>
                                <Box p={3}>
                                    <Grid container direction='column' spacing={2.3} style={loading.ingestedLoader ? {
                                        position: 'relative',
                                        opacity: '0.2'
                                    } : {}}>
                                        <Grid item>
                                            <Grid container direction='row' justifyContent='space-between' spacing={1}>
                                                <Grid item>
                                                    <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Ingested Data</Typography>
                                                </Grid>
                                                {/* <Grid item className={classes.closeIcon}>
                                                    <CloseIcon />
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ maxWidth: '100%' }}>
                                            {
                                                loading.ingestedLoader ?
                                                    <CircularProgress style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }} /> : ""
                                            }
                                            {data ? <Bar options={options} data={data} /> : ''}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <FileUp dateFilter={filter}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        <Grid item xs={6}>
                            <LastAccessed />
                        </Grid>
                        <Grid item xs={6}>
                            <DataType />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        <Grid item xs={6}>
                            <TapeDeletion />
                        </Grid>
                        <Grid item xs={6}>
                            <Usage />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        <Grid item xs={6}>
                            <Backup />
                        </Grid>
                        <Grid item xs={6}>
                            <StoragePolicies />
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>}

            {/* Admin Actions */}
            {features && features?.length > 0 && (
                <Fab
                    ref={anchorRef}
                    id="admin-action-button"
                    aria-controls={adminActionMenuOpen ? 'admin-action-menu' : undefined}
                    aria-expanded={adminActionMenuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleAdminActionMenuToggle}
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        '&.MuiFab-root': {
                            background: ButtonColorGradient,
                        },
                        visibility: (isAdmin) ? 'visible' : 'hidden',
                    }}
                >
                    <Add />
                </Fab>
            )}
            <Popper
                open={adminActionMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'right bottom',
                        }}
                    >
                        <Paper sx={{ backgroundColor: '#3A3E3F', border: '1px solid #FFFFFF', borderRadius: '8px' }}>
                            <ClickAwayListener onClickAway={handleAdminActionMenuClose}>
                                <MenuList
                                    autoFocusItem={adminActionMenuOpen}
                                    id="admin-action-menu"
                                    aria-labelledby="admin-action-button"
                                    onKeyDown={handleListKeyDown}
                                >

                                    <MenuItem sx={{ p: 2, gap: 2, }}>
                                        <Person sx={{ color: '#FFFFFF' }} />
                                        <Link to="/OrderCreation" paasHref style={{ textDecoration: 'none' }}>
                                            <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF' }}>Account Creation</Typography>
                                        </Link>
                                    </MenuItem>
                                    <Divider sx={{ my: 0.5, backgroundColor: '#FFFFFF' }} />
                                    <MenuItem sx={{ p: 2, gap: 2, }} onClick={openAccountDialog}>
                                        <ShoppingCart sx={{ color: '#FFFFFF' }} />
                                        <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF' }}>Job Creation</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Dialog open={accountDialog} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                <DialogContent>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Grid container direction='row' justifyContent='space-between'>
                                <Grid item>
                                    <Typography sx={{
                                        color: '#FFC540',
                                        fontWeight: 600,
                                        fontSize: '24px'
                                    }}>Select Account</Typography>
                                </Grid>
                                <Grid item >
                                    <CloseIcon onClick={() => setAccountDialog(false)} sx={{ color: 'white', cursor: 'pointer' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth={true}>
                                <TextField
                                    className={classes.selectLabel}
                                    fullWidth={true}
                                    onChange={handlechangeClientList}
                                    value={clientValue}
                                    select>
                                    {
                                        clientList.map((value, index) => {
                                            return (
                                                <MenuItem value={value.uuid}>{value.company_name}</MenuItem>
                                            )
                                        })}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='flex-end' spacing={2}>
                                <Grid item>
                                    <Button size='medium' sx={{ textTransform: 'none', borderRadius: '30px', backgroundColor: '#3A3E3F', border: '1px solid #FFFFFF', color: '#FFFFFF' }} variant='outlined' onClick={() => setAccountDialog(false)}>Cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button size='medium' sx={{ textTransform: 'none', borderRadius: '30px', color: '#3A3E3F', backgroundColor: '#EEAB10' }} variant='contained' onClick={handleContinue}>
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>

    );
};

export const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: '#ffc540',
        // borderRadius: "8px",
        color: '#3a3e3f',
        fontWeight: 600,
        fontSize: "14px",
        border: `1px solid #000`,
    },
    "&.MuiToggleButton-root": {
        // color: "#404558",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "35px",
        fontSize: "14px",
        width: "116px",
        border: "0 !important",
        // border-radius: 8px;
        background: "#3a3e3f",
        color: "#ffffff"
    },
});

const useStyles = makeStyles({
    mainContainer: {
        background: "#4E5152 !important",
        minHeight: '565px',
        maxHeight: '565px',
        borderRadius: 8,
        maxWidth: '100%',
        border: '1px solid #4a5058',
        background: '#3A3E3F',

    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    selectLabel: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#FFFFFF'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#FFFFFF'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFFFF'
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF'
        },
        '& .MuiInputLabel-root': {
            color: '#FFFFFF'
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFFFF'
        }
    },
    secondHeading: {
        fontWeight: 400,
        fontSize: '42px !important',
        lineHeight: '51px',
        color: '#FFFFFF',
    },
    years: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    data: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    per: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#697588'
    }
});

function TabsStepIcon() {
    const [alignment, setAlignment] = React.useState("web");
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
            >
                <ToggleButton value="web"> Month</ToggleButton>
                <ToggleButton value="android"> Year</ToggleButton>
            </ToggleButtonGroup>
        </>
    );
}