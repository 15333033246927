import { Button, Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ButtonColor } from '../../../../../component/theme/theme';
//import { env } from "../../env";
import { StyledButtonContained } from "../../../AccountCreation/accountCreation";
import { getApiUrl } from "./../../../../../global";
import {
    Checkbox, Divider, FormControl,
    IconButton, InputAdornment, InputBase,
    MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel,
    TextField, ThemeProvider,
    createTheme, tableCellClasses
} from "@mui/material";
import { PrimaryColor, flexStyles } from "../../../../../component/theme/theme";
//import { env } from "../../../../env";
import { Cancel, Search } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { useParams } from 'react-router-dom';

const fileType = {
    USERTAPELIST: 'user_tape_list'
}

//import { env } from "../../../../env";
const MenuProps = {
    // PaperProps: {
    //     style:
    //         { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 }
    // },
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: "top", horizontal: "center" },
    variant: "menu"
};

const adminOrderListTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC540',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontWeightRegular: 400,
        fontWeightBold: 700,
        caption: {
            fontFamily: 'Poppins',
            fontSize: '12px',
            lineHeight: '14.5px',
            color: '#B4B4B4',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                    color: '#3A3E3F',
                    '&.Mui-disabled': {
                        background: '#C7CCD0',
                        color: '#5F5F5F'
                    },
                },
                textPrimary: {
                    color: '#FFC540',
                },
                containedSecondary: {
                    background: '#3A3E3F',
                    color: '#FFC540',
                    border: '1px solid #FFC540',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 197, 64, 0.1)',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF',
                },
                containedError: {
                    background: '#FF6C65',
                    color: '#3A3E3F',
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFC540',
                },
                colorSecondary: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.outlined-input': {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '8px',
                                borderColor: '#C7CCD0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C7CCD0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#C7CCD0',
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            //fontFamily: 'Inter', 
                            color: '#FFFFFF',
                        },
                        '& .MuiInputLabel-root': {
                            //fontFamily: 'Inter', 
                            color: 'rgba(255, 255, 255, 0.5)',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                        '& svg': {
                            color: '#FFFFFF'
                        }
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    '&.statusBar': {
                        backgroundColor: '#565656',
                        height: 18,
                        borderRadius: 4,
                        width: '100%',
                        border: 'none',
                        '& .MuiLinearProgress-root': {
                        },
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#5D8150',
                        },
                    }
                }
            }
        }
    },
});
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    width: '10rem',
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '&:nth-of-type(odd)': {
            background: 'rgba(95,101,102,0.3)',
        },
        '&:nth-of-type(even)': {
            background: '#3A3E3F',
        },
        '&.Mui-selected': {
            //background: 'rgba(255, 197, 64, 0.1)',
            '&:hover': {
                '&:nth-of-type(odd)': {
                    background: 'rgba(95,101,102,0.3)',
                },
                '&:nth-of-type(even)': {
                    background: '#3A3E3F',
                },
            },
        },
        '& > *': {
            color: '#FFFFFF',
            border: 'unset',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const headCells = [
    {
        id: 'clientName',
        numeric: false,
        disablePadding: true,
        align: 'left',
        label: 'File',
    },
    {
        id: 'shippingMode',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Update Date',
    }
];

export const OrderDocuments = () => {
    const params = useParams();

    const [selected, setSelected] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const [customerList, setCustomerList] = useState([]);
    const [orderStatus, setOrderStatus] = useState('all');
    const [orderType, setOrderType] = useState('all');


    //const apiUrl = "/assets/json/getOrder";
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderCount, setOrderCount] = React.useState(0);

    const [orderList, setOrderList] = React.useState([]);
    const classes = useStyles();

    const [searchQuery, setSearchQuery] = useState('');


    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    }

    const handlePageChange = (event, page) => {
        // hangle page change
        setCurrentPage(page);
        console.log(page);
        //setCurrentPage(page);
    };
    useEffect(() => {
        getOrderData();
    }, [searchQuery, currentPage, orderStatus, orderType, selectedClient, sortColumn, sortOrder])
    const getOrderData = async () => {
        let url = await getApiUrl();
        let _orderUUID = params.orderId;
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents?page=${currentPage}`;
        axios
            .get(apiUrl, { search: searchQuery })
            .then(data => {
                let orderDoc = JSON.parse(JSON.stringify(data.data.results));
                orderDoc = orderDoc.filter((file) => file.file_type == fileType.USERTAPELIST);
                setOrderList(orderDoc);
                setOrderCount(data.data.count);
                setTotalPages(Math.ceil(data.data.count / 10));
            })
            .catch(error => {
                console.log(error);
            });

    };

    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, orderCount);
    const downloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank')
    }
    const handleSort = (columnName) => {
        const newOrder = sortColumn === columnName && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortColumn(columnName);
        setSortOrder(newOrder);
        // Perform sorting based on columnName and newOrder // Upd
    }
    return (
        <ThemeProvider theme={adminOrderListTheme}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', paddingTop: 4, paddingLeft: 4 }} className="hideElement">
                <img src="/assets/img/cart.svg" alt="" />
            </Box>
            <Grid container spacing={2} paddingBottom={6} sx={{ paddingX: { xs: 0.5, md: 4 } }} >
                <Grid item md={12} className="hideElement">
                    <Typography variant="h5" mt={4} sx={{
                        fontFamily: 'Lato',
                        fontSize: '28px',
                        fontWeight: 700,
                        lineHeight: '34px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#FFFFFF',
                    }}>
                        Order Documents
                    </Typography>
                </Grid>
                <Grid item xs={12} className="hideElement">
                    <Divider sx={{ background: '#EAEAEA', width: '100%' }} orientation='horizontal' />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} style={{ border: '1px solid #E1E8F0', borderRadius: '12px', background: 'rgb(58, 62, 63)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sx={{ color: '#FFFFFF', fontSize: 14, paddingLeft: headCell.id == 'clientName' ? '15px' : '' }}
                                        >
                                            <TableSortLabel
                                                style={{ color: 'white' }}
                                                active={sortColumn === headCell.id}
                                                direction={sortColumn === headCell.id ? sortOrder : 'asc'}
                                                onClick={() => handleSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {sortColumn === headCell.id ? (
                                                    <Box component="span" sx={{ display: 'none' }}>
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        {/* Actions */}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{
                                '& > *': {
                                    borderRadius: '8px'
                                }
                            }}>
                                {orderList.map((row, index) => {

                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            key={row.uuid}
                                            role="checkbox"
                                            tabIndex={-1}>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="15px">
                                                <Typography id={`order-name-${index}`} sx={{ color: '#FFFFFF', fontSize: 18, fontFamily: 'Poppins', whiteSpace: 'pre', }}>
                                                    {row.name}</Typography>
                                                <Typography sx={{ color: '#656565', fontSize: 14, fontFamily: 'Poppins', whiteSpace: 'pre', }}>
                                                    {row.file_size}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                    {moment(row.updated_at).format("MMM/DD/YYYY")}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <StyledButtonContained
                                                    onClick={() => downloadFile(row.file_url)}
                                                    variant='contained'
                                                    color='primary'
                                                    sx={{
                                                        borderRadius: '8px',
                                                    }}

                                                >Download</StyledButtonContained>
                                            </TableCell>

                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                        style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '55%' }}>
                        <Grid item ml={2}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                                {`Showing ${startIndex}-${endIndex} of ${orderCount} Orders`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Pagination
                                component="div"
                                rowsPerPage={10}
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        width: '30px',
                                        height: '30px',
                                        border: '1px solid #FFFFFF',
                                        background: '#3A3E3F',
                                        color: '#FFFFFF',
                                        margin: { md: '0 4px', xs: '0 2px' },
                                        '&.Mui-selected': {
                                            background: '#FFC540',
                                            color: '#3A3E3F',
                                        },
                                    },
                                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-page:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                        width: '100px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        border: '1px solid #FFFFFF',
                                        color: '#FFFFFF',
                                        background: '#3A3E3F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                }}

                                renderItem={(item) => (
                                    <PaginationItem {...item} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
};




const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: ButtonColor,
        borderRadius: "8px",
        color: '#404558',
        fontWeight: 600,
        fontSize: "14px",
        border: `1px solid ${ButtonColor}`,
    },
    "&.MuiToggleButton-root": {
        color: "white",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "34px",
        fontSize: "14px",
    },
});

const useStyles = makeStyles({
    mainContainer: {
        backgroundColor: "transparent",
    },
    secondaryConatiner: {
        borderRadius: "20px",
        border: "1px solid #C7C7C7",
        color: "white",
    },
    toggleContainer: {
        height: "40px",
        border: `1px solid ${ButtonColor}`,
        padding: "2px",
        borderRadius: "8px",
    },
    orderIdContainer: {
        backgroundColor: "transparent",
        border: "1px solid #A5A5A5",
    },
    orderIdHeading: {
        color: "#686868",
        fontSize: "18px",
        fontWeight: 400
    },
    orderidd: {
        color: '#FFC540',
        fontWeight: 700,
        fontSize: '18px'
    },
    orderIdData: {
        color: ButtonColor,
        fontWeight: 700,
    },
    orderStatusContainer: {
        backgroundColor: 'grey',
        borderRadius: "20px",
        width: '431px'
    },
    orderStatusTextHeading: {
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: 700,
    },
    orderstatusData: {
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 400,
    },
    viewButton: {
        width: "280px",
        backgroundColor: ButtonColor,
        borderRadius: "30px",
        height: "60px",
        textTransform: "none",
    },
});

const CustomLinearProgress = ({ value, label }) => {
    return (
        <Box sx={{ minWidth: '75px', width: '100%', margin: 'auto', borderRadius: '4px', backgroundColor: '#565656', overflow: 'hidden', position: 'relative' }}>
            <Box sx={{ width: `${value}%`, height: '22px', backgroundColor: '#5D8150', borderRadius: 'inherit', }} />
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: 'white',
                width: '100%',
                zIndex: 1,
            }}> <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: (value > 0) ? 700 : 400,
                color: (value > 0) ? '#FFFFFF' : '#ACACAC'
            }} variant="body2">{label}</Typography>
            </Box>
        </Box>
    );
}