import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import {
  getLoginUser,
  setLoginUser,
  removeLoginUser,
} from "./LocalStorageService";
//import { env } from "./env";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getApiUrl } from "./global";
import { GlobalStyle, theme } from "./GlobalStyle";
import { ThemeProvider, createTheme } from "@mui/material/styles";

var isLoaderVisible = false;
const CircularIndeterminate = () => {
  return (
    <Box
      style={{ display: "none" }}
      id="centerLoader"
      sx={{
        display: "flex",
        zIndex: "99999",
        position: "absolute",
        height: "100vh",
        width: "100vw",
        opacity: "0.5",
        background: "white",
      }}
    >
      <CircularProgress
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      />
    </Box>
  );
};

const myFunction = (error) => {
  var x = document.getElementById("snackbarTemp");
  x.innerHTML = "";

  if (error?.response?.data) {
    if (
      typeof error?.response?.data === "object" &&
      !Array.isArray(error?.response?.data) &&
      error?.response?.data !== null
    ) {
      for (let key in error?.response?.data) {
        let a = 0;

        x.innerHTML += `<span> ${key} </span> : `;
        if (key == "detail" || key == "error") {
          const element = error?.response?.data[key];
          x.innerHTML += `<span> ${element} </span><br/> `;
        } else {
          for (
            let index = 0;
            index < error?.response?.data[key].length;
            index++
          ) {
            const element = error?.response?.data[key][index];
            x.innerHTML += `<span> ${element} </span><br/> `;
          }
        }

        // x.innerHTML += `<br/> `;

        // code block to be executed
      }
    } else if (
      Array.isArray(error?.response?.data) &&
      error?.response?.data !== null
    ) {
      for (let index = 0; index < error?.response?.data.length; index++) {
        const element = error?.response?.data[index];
        x.innerHTML += `<span> ${element} </span><br/> `;
      }
    }
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 10000);
  } else if (error?.error) {
    const element = error?.error;
    x.innerHTML += `<span> ${element} </span><br/> `;
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 10000);
  }
};
// For GET requests
axios.interceptors.request.use(
  (config) => {
    const token = getLoginUser() ? getLoginUser().access : null;
    if (token && config.url.includes(`${process.env.REACT_APP_GRAPH_ENDPOINT}`)) {
      config.headers["x-api-key"] = `${process.env.REACT_APP_X_API_KEY}`;
    } else if (token && !config.url.includes("https://maps.googleapis.com")) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    isLoaderVisible = true;
    // let _centerLoader=document.getElementById("centerLoader");
    // _centerLoader.style.visibility="visible";
    document.getElementById("centerLoader").style.display = "block";

    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// For POST requests
axios.interceptors.response.use(
  (response) => {
    document.getElementById("centerLoader").style.display = "none";
    if (response?.status == 200 && response?.data?.error) {
      myFunction(response?.data);
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === "http://127.0.0.1:3000/v1/auth/token"
    // ) {
    //   router.push("/login");
    //   return Promise.reject(error);
    // }
    isLoaderVisible = false;
    // let _centerLoader=document.getElementById("centerLoader");
    // _centerLoader.style.visibility="hidden";
    document.getElementById("centerLoader").style.display = "none";
    let url = await getApiUrl();
    const apiUrl =
      url + process.env.REACT_APP_API_BASE_URL + "/auth/token/refresh/";
    if (
      !originalRequest?.url?.includes("token/refresh/") &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      // console.log();
      const refreshToken = getLoginUser() ? getLoginUser().refresh : null;
      // const refreshToken = localStorageService.getRefreshToken();
      return axios
        .post(apiUrl, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            let loginUserDetails = getLoginUser() ? getLoginUser() : null;
            if (loginUserDetails) {
              loginUserDetails.access = res.data.access;
              setLoginUser(loginUserDetails);
            }
            const token = getLoginUser() ? getLoginUser().access : null;
            // localStorageService.setToken(res.data);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (error.response.status === 404) {
      myFunction(error);
      // return (
      //   <Stack spacing={2} sx={{ width: "100%" }}>
      //     <Alert severity="error">This is an error message!</Alert>
      //   </Stack>
      // )
    } else if (error.response.status === 400) {
      myFunction(error);
      // return (
      //   <Stack spacing={2} sx={{ width: "100%" }}>
      //     <Alert severity="error">This is an error message!</Alert>
      //   </Stack>
      // )
    } else if (
      originalRequest?.url?.includes("token/refresh/") &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      removeLoginUser();
    }
    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle></GlobalStyle>
        <CircularIndeterminate />
        <div id="snackbarTemp"></div>
        <ScrollToTop />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}