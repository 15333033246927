import React, { useState } from 'react';
import {
    TableContainer, Table, TableHead, TableRow, TableBody, IconButton, Toolbar, Pagination,
    InputBase, InputAdornment, Avatar, Button, Grid, ToggleButton, ToggleButtonGroup, Box, Paper,
    Typography, Stack, PaginationItem
} from '@mui/material';
import { ButtonColor, PrimaryColor } from '../theme/theme';
import SearchIcon from '@mui/icons-material/Search';
import { ArrowDownward, ArrowDropDown, GridViewRounded } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from '@emotion/styled';
import { StyledButtonOutlined } from '../../views/Admin/AccountCreation/accountCreation';

import axios from "axios";
import { servers } from '../../global';


const useStyles = makeStyles({
    tableContainer: {
        marginTop: 48,
        maxWidth: '100%',
        height: '909px',
        borderRadius: '4px',
        background: '#4e5152',
        boxShadow: '0px 4px 11px 0px #00000029',
    },
    columnHeader: {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#FFFFFF',
    },
    customTableRow: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',

    },
});

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '& > *': {
            color: '#FFFFFF',
            backgroundColor: '#3A3E3F',
            border: '20px solid #FFFFFF1A',
            borderRadius: '8px',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const greenStatus = 'linear-gradient(180deg, #009621 0%, #21CB46 100%)'; const yellowStatus = '#FFC540'; const greyStatus = '#C7CCD0';

const data = [
    { name: 'Prac21299', updatedOn: '2023-10-05T14:48:00.000Z', tags: true, noOfBackUp:5,lastBackup: '2023-10-01T14:48:00.000Z', size: '144', status: greenStatus },
    { name: 'Prac21320', updatedOn: '2023-12-09T14:48:00.000Z', tags: true, noOfBackUp:2,lastBackup: '2023-11-23T14:48:00.000Z', size: '288', status: greenStatus },
    { name: 'Prac21355', updatedOn: '2023-11-25T14:48:00.000Z', tags: true, noOfBackUp:9,lastBackup: '2023-11-15T14:48:00.000Z', size: '2', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:3,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:1,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:4,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:13,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:5,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:7,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
    { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:1,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus }
];

export const FileServerTable = ({ }) => {//data
    const classes = useStyles();
    const totalPages = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] =useState('')
    const [data ,setData] = useState([
        { name: 'Prac21299', updatedOn: '2023-10-05T14:48:00.000Z', tags: true, noOfBackUp:5,lastBackup: '2023-10-01T14:48:00.000Z', size: '144', status: greenStatus },
        { name: 'Prac21320', updatedOn: '2023-12-09T14:48:00.000Z', tags: true, noOfBackUp:2,lastBackup: '2023-11-23T14:48:00.000Z', size: '288', status: greenStatus },
        { name: 'Prac21355', updatedOn: '2023-11-25T14:48:00.000Z', tags: true, noOfBackUp:9,lastBackup: '2023-11-15T14:48:00.000Z', size: '2', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:3,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:1,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:4,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:13,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:5,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:7,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus },
        { name: 'Prac21280', updatedOn: '2023-10-06T14:48:00.000Z', tags: true, noOfBackUp:1,lastBackup: '2023-12-15T14:48:00.000Z', size: '0.9', status: greenStatus }
    ])
    const [sortBy, setSortBy] = useState('columnName');
    const [sortOrder, setSortOrder] = useState('asc');


    const handlePageChange = (page) => {
        // hangle page change
        console.log(page);
        //setCurrentPage(page);
    };

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    React.useEffect(() => {
        // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
      
        let payload = {
          type: "rep_server",
          request: {
            cid: 15,
            type: "rep_server",
          },
        };
     
        axios
          .post(servers, payload)
          .then((response) => {
            if (response?.status == 200) {
              let dynamicArray = ''
                        const dynamicObject = Object.values(response.data.result)
                       dynamicObject.forEach(i => 
                       dynamicArray = i.data )
           setData(dynamicArray)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);

    return (
        <TableContainer className={classes.tableContainer}>
            <Typography sx={{ textAlign: 'left', fontSize: 20, color: '#FFFFFF', fontWeight: 400, marginLeft: 5, mt: 4 }} >File Servers</Typography>
            <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', my: 1 }}>
                <Stack direction={'row'} className='col-md-3'>
                    {/* Button */}
                    <StyledButtonOutlined variant="outlined"
                        endIcon={
                            <ArrowDropDown />
                        }
                        sx={{
                            width: '75x',
                            height: '35px'
                            }}>
                        Age
                    </StyledButtonOutlined>
                    <StyledButtonOutlined variant="outlined"
                        endIcon={
                            <ArrowDropDown />
                        }
                        sx={{
                            width: '75x',
                            height: '35px'
                            }}>
                        Size
                    </StyledButtonOutlined>
                    <StyledButtonOutlined variant="outlined"
                        endIcon={
                            <ArrowDropDown />
                        }
                        sx={{
                            width: '75x',
                            height: '35px'
                            }}>
                        Tags
                    </StyledButtonOutlined>
                </Stack>
                <Stack direction={'row'} className='col-md-9'>
                    {/* Seach bar */}
                    <InputBase
                        type="text"
                        placeholder="Search"
                        startAdornment={
                            <InputAdornment position="start" sx={{ color: '#A6AEC1' }} >
                                <SearchIcon sx={{ marginRight: '4px' }} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position='end'>
                                <Stack direction={'row'}>
                                    <Divider sx={{ backgroundColor: '#FFFFFF' }} orientation='vertical' flexItem />
                                    <IconButton>
                                        <TuneIcon sx={{ color: '#FFFFFF' }} />
                                    </IconButton>
                                </Stack>

                            </InputAdornment>
                        }
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '15px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            width: '241px',
                            height: '41px',
                            backgroundColor: PrimaryColor,
                            borderRadius: '20px',
                            paddingLeft: '20px',
                            border: '1px solid #FFFFFF',
                            outline: 'none',
                            marginLeft: 3,
                            color: '#FFFFFF'
                        }}

                    />
                    {/* Toggle */}
                    <Paper elevation={1} sx={{
                        border: '1px solid #FFFFFF',
                        marginLeft: 3,
                        height: 42,
                        padding: 0, display: 'flex',
                        backgroundColor: PrimaryColor, flexWrap: 'wrap',
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            sx={{
                                '& .MuiToggleButtonGroup-grouped': {
                                    margin: 0.5,
                                    border: 0,
                                    '&.Mui-selected': {
                                        color: '#3E3A3F',
                                    }
                                }
                            }}
                            //value={}
                            exclusive
                            // onChange={}
                            aria-label="Platform"
                        >
                            <ToggleButton value="thismonth"
                                sx={{ height: 32, width: 87, fontSize: 12, color: '#FFFFFF', backgroundColor: ButtonColor, textTransform: 'none' }}>This Month</ToggleButton>
                            <ToggleButton value="lastmonth"
                                sx={{ height: 32, width: 87, fontSize: 12, color: '#FFFFFF', textTransform: 'none' }}>Last Month</ToggleButton>
                            <ToggleButton value="thisyear"
                                sx={{ height: 32, width: 87, fontSize: 12, color: '#FFFFFF', textTransform: 'none' }}>This Year</ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                    {/* Grid View Icon */}
                    <Paper elevation={1} sx={{
                        border: '1px solid #FFFFFF',
                        marginLeft: 3,
                        padding: 0, display: 'flex',
                        height: 42,
                        width: 42,
                        backgroundColor: PrimaryColor, flexWrap: 'wrap',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center'
                    }}>
                        <IconButton sx={{ paddingLeft: '12px', }} aria-label="notifications">
                            <GridViewRounded sx={{ color: ButtonColor }} />
                        </IconButton>
                    </Paper>
                </Stack>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow sx={{ '& > *': { color: '#FFFFFF',    position: 'sticky' } }}>
                        <TableCell style={{background: '#606263'}}>
                        </TableCell>
                        <TableCell style={{background: '#606263'}}></TableCell>
                        <TableCell style={{background: '#606263'}} className={classes.columnHeader}>
                            <TableSortLabel
                                IconComponent={() => (
                                    <ArrowDropDown style={{ color: 'white' }}
                                    />)}
                                active={true} //sortBy === 'columnName'
                                direction={'asc'} //sortOrder
                                onClick={() => handleSort('columnName')}
                            >
                                <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Name</Typography>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{background: '#606263'}} className={classes.columnHeader}>
                            <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Host Name</Typography>
                        </TableCell>
                        <TableCell style={{background: '#606263'}} className={classes.columnHeader}>
                            <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Tags</Typography>
                        </TableCell>
                        <TableCell style={{background: '#606263'}} className={classes.columnHeader}>
                            <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>No. of Backups</Typography>
                        </TableCell>
                        <TableCell style={{background: '#606263'}} className={classes.columnHeader}>
                            <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Size (Bytes)</Typography>
                        </TableCell>
                        <TableCell style={{background: '#606263'}}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody sx={{
                    '& > *': {
                        height: 25,
                        backgroundColor: '#3A3E3F',

                        border: '20px solid #4e5152',
                        borderRadius: '8px'
                    }
                }}>
                    {data.slice(0, 8).map((row) => (
                        <TableRow className={classes.customTableRow} key={row.id}
                            sx={{
                                height: 25,
                                '& > *': {
                                    height: 25,
                                    border: 'unset'

                                }
                            }}>
                            <TableCell sx={{ py: 0, px: 0, width: 12, backgroundColor: '#4e5152' }}>
                                {/* Status */}
                                <Box sx={{ marginLeft: 2, marginTop: 0, marginBottom: 0, width: 12, height: '100%', borderRadius: '7px 0px 0px 7px', background: row.status, }} />
                            </TableCell>
                            <TableCell width={10}><Avatar sx={{ height: 40, width: 40, marginRight: '2px' }} alt=' ' src="/path/to/avatar.png" /></TableCell>
                            <TableCell>
                                <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>{row.displayname}</Typography>
                                {/* <Typography sx={{ color: '#656565', fontSize: 12 }}>{'configured'}</Typography> */}
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>
                                    {(row.hostname)}
                                </Typography>
                            </TableCell>
                            <TableCell><Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>{(row.tags) ? 'Yes' : 'No'}</Typography></TableCell>
                            <TableCell>
                                <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>
                                    {/* {new Date(row.lastBackup).toLocaleDateString(undefined, dateOptions)} */}
                                    {row.noOfBackUp}
                                </Typography>
                            </TableCell>
                            <TableCell><Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>{row.bytes}</Typography></TableCell>
                            <TableCell>
                                <IconButton aria-label="right-arrow" sx={{ justifyContent: 'right', color: '#FFFFFF' }}>
                                    <ArrowRightIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Pagination */}
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                padding={2}
            >
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{
                        '& .MuiPaginationItem-root': {
                            width: '30px',
                            height: '30px',
                            border: '1px solid #FFFFFF',
                            background: '#3A3E3F',
                            color: '#FFFFFF',
                            margin: '0 4px',
                            '&.Mui-selected': {
                                background: '#FFC540',
                                color: '#3A3E3F',
                            },
                        },
                        '& .MuiPaginationItem-page.Mui-selected:hover': {
                            background: '#FFC540',
                            color: '#3A3E3F',
                        },
                        '& .MuiPaginationItem-page:hover': {
                            background: '#FFC540',
                            color: '#3A3E3F',
                        },
                        '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                            width: '100px',
                            height: '40px',
                            borderRadius: '8px',
                            border: '1px solid #FFFFFF',
                            color: '#FFFFFF',
                            background: '#3A3E3F',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                            background: '#FFC540',
                            color: '#3A3E3F',
                        },
                    }}

                    renderItem={(item) => (
                        <PaginationItem {...item} />
                    )}
                />
            </Box>

        </TableContainer>
    );
};