import { createContext, useContext, useState } from 'react';

const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
    const [features, setFeatures] = useState([]);

    const setFeatureList = (featureList) => {
        setFeatures(featureList)
    };

    return (
        <FeaturesContext.Provider value={{ features, setFeatureList}}>
            {children}
        </FeaturesContext.Provider>
    );
};

export const useFeatures = () => {
    return useContext(FeaturesContext);
}