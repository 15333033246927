import React, { useState, useEffect } from 'react';
import {
    TableContainer, Table, TableHead, TableRow, TableBody, IconButton, Toolbar, Pagination,
    InputBase, InputAdornment, Avatar, Button, Grid, ToggleButton, ToggleButtonGroup, Box, Paper,
    Typography, Stack, PaginationItem, Dialog, DialogContent
} from '@mui/material';
import { ButtonColor, PrimaryColor } from '../../component/theme/theme';
import SearchIcon from '@mui/icons-material/Search';
import { ArrowDownward, ArrowDropDown, GridViewRounded } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./team.css";
import { AddUser } from '../../component/addUser/addUser';
//import { env } from '../../env';
import { getApiUrl } from "./../../global";

import axios from 'axios';
import { getLoginCustomerId } from '../../LocalStorageService';
import { StyledButtonContained, StyledButtonOutlined } from '../Admin/AccountCreation/accountCreation';
const useStyles = makeStyles({
    tableContainer: {
        marginTop: 48,
        maxWidth: '100%',
        // height: '909px',
        borderRadius: '4px',
        background: '#545353',
    },
    rootMenuItem: {
        '&:hover': {
            border: 'none',
        }
    },
    emptyStar: {
        color: "white"
    },
    columnHeader: {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#FFFFFF',
        padding: '10px'
    },
    customTableRow: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',

    },
});

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '& > *': {
            color: '#FFFFFF',
            backgroundColor: '#3A3E3F',
            border: '20px solid #FFFFFF1A',
            borderRadius: '8px',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const greenStatus = 'linear-gradient(180deg, #009621 0%, #21CB46 100%)'; const yellowStatus = '#FFC540'; const greyStatus = '#C7CCD0';

const data = [
    // { name: 'Prac21299', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21320', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21355', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" }
];

export const Team = ({ }) => {//data
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [userCount, setUserCount] = React.useState(1);
    const [sortBy, setSortBy] = useState('columnName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [open, setOpen] = useState(false);

    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = useState(false);
    const [roleChangePrevious, setRoleChangePrevious] = useState(null);
    const [roleChangeCurrent, setRoleChangeCurrent] = useState(null);
    const [roleChangeUserName, setRoleChangeUserName] = useState(null);
    const [roleChangeUserId, setRoleChangeUserId] = useState(null);

    const handleRoleChange = (event, role, email, id) => {
        setRoleChangeUserId(id);
        setRoleChangeCurrent(event.target.value);
        setRoleChangePrevious(role);
        setRoleChangeUserName(email);
        setRoleChangeDialogOpen(true);
        console.log(event);
    }

    const handleRoleChangeCancel = (event) => {
        setRoleChangeDialogOpen(false);
        setRoleChangeCurrent(null);
        setRoleChangePrevious(null);
        setRoleChangeUserName(null);
        setRoleChangeUserId(null);
    }

    const handleRoleChangeSubmit = async (event) => {
        const customerId = getLoginCustomerId();
        let url = await getApiUrl();

        const usersApiUrl = url + `${process.env.REACT_APP_API_BASE_URL}` + `/customers/${customerId}/users/${roleChangeUserId}/`;

        const payload = {
            role: roleChangeCurrent,

        };
        axios
            .patch(usersApiUrl, payload)
            .then(data => {
                console.log(data);
                setRoleChangeDialogOpen(false);
                getUsersList();
            })
            .catch(error => {
                console.log(error);
                setRoleChangeDialogOpen(false);
            });
    }


    const [activeToggleDialogOpen, setActiveToggleDialogOpen] = useState(false);
    const [activeToggleUserName, setActiveToggleUserName] = useState(null);
    const [activeToggleUserId, setActiveToggleUserId] = useState(null);
    const [activeToggleUserStatus, setActiveToggleUserStatus] = useState(null);

    const handleActiveToggle = (email, id, status) => {
        setActiveToggleUserId(id);
        setActiveToggleUserName(email);
        setActiveToggleUserStatus(status);
        setActiveToggleDialogOpen(true);
    }

    const handleActiveToggleCancel = (event) => {
        setActiveToggleDialogOpen(false);
        setActiveToggleUserName(null);
        setActiveToggleUserStatus(null);
        setActiveToggleUserId(null);

    }

    const handleActiveToggleSubmit = async (event) => {
        const customerId = getLoginCustomerId();//;'a484c8d1-6ecc-412b-87e0-0584b9d1286b'
        let url = await getApiUrl();

        const usersApiUrl = url + `${process.env.REACT_APP_API_BASE_URL}` + `/customers/${customerId}/users/${activeToggleUserId}/`;

        const payload = {
            is_active: (activeToggleUserStatus === 'True')?'False':'True',

        };
        axios
            .patch(usersApiUrl, payload)
            .then(data => {
                console.log(data);
                setActiveToggleDialogOpen(false);
                getUsersList();
            })
            .catch(error => {
                console.log(error);
                setActiveToggleDialogOpen(false);
            });
    }

    // To open the dialog
    const handleOpenDialog = () => {
        setOpen(true);
    };
    // To close the dialog
    const handleCloseDialog = () => {
        setOpen(false);
        getUsersList();
    };

    const handlePageChange = (event, page) => {
        // hangle page change
        console.log(page);
        setCurrentPage(page);
    };

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const [usersList, setUsersList] = useState([]);
    const getUsersList = async () => {

        const customerId = getLoginCustomerId();//;'a484c8d1-6ecc-412b-87e0-0584b9d1286b'
        let url = await getApiUrl();

        const usersApiUrl = url + `${process.env.REACT_APP_API_BASE_URL}` + `/customers/${customerId}/users/` + `?page=${currentPage}`;

        axios
            .get(usersApiUrl)
            .then(data => {
                setUsersList(data.data.results);
                setUserCount(data.data.count);
                setTotalPages(Math.ceil(data.data.count / 10));
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        getUsersList();
    }, [currentPage]);

    return (
        <>
            <TableContainer className={classes.tableContainer} style={{ background: 'rgb(58, 62, 63)' }}>
                <Typography sx={{ fontFamily: 'Lato', textAlign: 'left', fontSize: 20, color: '#FFFFFF', fontWeight: 400, marginLeft: 5, mt: 4 }} style={{ fontWeight: 700, fontSize: '20px', color :"#fff" }} >Team</Typography>
                <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', my: 1, justifyContent: 'end' }}>
                    <Stack direction={'row'} className='col-md-9'>
                        {/* <InputBase
                            type="text"
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start" sx={{ color: '#A6AEC1' }} >
                                    <SearchIcon sx={{ marginRight: '4px' }} />
                                </InputAdornment>
                            }
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '15px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                width: '241px',
                                height: '41px',
                                backgroundColor: PrimaryColor,
                                borderRadius: '20px',
                                paddingLeft: '20px',
                                border: '1px solid #FFFFFF',
                                outline: 'none',
                                marginLeft: 3,
                                color: '#FFFFFF'
                            }}

                        /> */}
                        <Paper elevation={1} sx={{
                            marginLeft: 3,
                            height: 42,
                            padding: 0, display: 'flex',
                            backgroundColor: PrimaryColor, flexWrap: 'wrap',
                        }}>
                            <StyledButtonContained
                                type='submit'
                                size="large"
                                fullWidth={true}
                                variant="contained"
                                onClick={handleOpenDialog}
                                style={{
                                    width: "inherit"
                                   }}
                            >
                                + Add User
                            </StyledButtonContained>
                        </Paper>
                    </Stack>
                </Toolbar>
                <div style={{
                    width: '95%',
                    padding: '20px',
                    borderRadius: '20px',
                    marginLeft: '40px',
                    border: '1px solid #616565',
                    background: '#3A3E3F'
                }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ '& > *': { color: '#FFFFFF', } }}>
                                <TableCell className={classes.columnHeader}>
                                </TableCell>
                                <TableCell className={classes.columnHeader}>
                                    <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Name</Typography>
                                </TableCell>
                                <TableCell className={classes.columnHeader}>
                                    <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.columnHeader}>
                                    <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Email</Typography>
                                </TableCell>
                                <TableCell className={classes.columnHeader}>
                                    <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Role</Typography>
                                </TableCell>
                                {/*                                 <TableCell className={classes.columnHeader}>
                                </TableCell> */}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                '& > *': {
                                    height: 25,
                                    backgroundColor: '#3A3E3F',
                                    borderRadius: '8px'
                                }

                            }}>
                            {usersList?.map((row, index) => (
                                <TableRow className={classes.customTableRow} key={row.id}
                                    sx={{
                                        height: 25,
                                        '& > *': {
                                            height: 25,
                                            border: 'unset'

                                        }
                                    }}>
                                    {/*                                     <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell sx={{
                                        opacity: (row?.is_active === 'False') ? 0.5 : 1,
                                        pointerEvents: (row?.is_active === 'False') ? 'none' : 'all',}}>
                                        <Avatar sx={{ height: 40, width: 40, marginRight: '20px' }} alt=' ' src={row.profile_picture} />
                                    </TableCell>
                                    <TableCell sx={{
                                        opacity: (row?.is_active === 'False') ? 0.5 : 1,
                                        pointerEvents: (row?.is_active === 'False') ? 'none' : 'all',}}>
                                        <Typography sx={{
                                            color: '#FFFFFF', fontSize: 14, display: 'flex',
                                            alignItems: 'center', fontWeight: 700
                                        }}>
                                            <span >{`${row.first_name} ${row.last_name}`}</span></Typography>
                                    </TableCell>
                                    <TableCell align={'center'} sx={{
                                        opacity: (row?.is_active === 'False') ? 0.5 : 1,
                                        pointerEvents: (row?.is_active === 'False') ? 'none' : 'all',}}>
                                        <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>
                                            {(row?.is_active === 'True') ? <StatusActiveIcon /> : <StatusDeActiveIcon />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{
                                        opacity: (row?.is_active === 'False') ? 0.5 : 1,
                                        pointerEvents: (row?.is_active === 'False') ? 'none' : 'all',}}>
                                            <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}> {row.email}</Typography>
                                    </TableCell>
                                    <TableCell sx={{
                                        opacity: (row?.is_active === 'False') ? 0.5 : 1,
                                        pointerEvents: (row?.is_active === 'False') ? 'none' : 'all',}}>
                                        <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>
                                            <Grid item>
                                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" classes={{ selected: classes.selected, root: classes.rootMenuItem }}>
                                                    <Select
                                                        sx={{
                                                            "& fieldset": {
                                                                border: 'unset',
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                color: "white",
                                                                fontFamily: 'Poppins',
                                                                fontSize: '14px',
                                                            },
                                                            "& .MuiSvgIcon-root": {
                                                                color: "white"
                                                            },

                                                        }}

                                                        labelId="demo-select-small-label"
                                                        id="demo-simple-select"
                                                        value={row?.role}
                                                        defaultValue={row?.role}
                                                        onChange={(event) => { handleRoleChange(event, row?.role, row?.email, row?.uuid) }}

                                                    >
                                                        <MenuItem value={'owner'}>Admin/Owner</MenuItem>
                                                        <MenuItem value={'creator'}>Order</MenuItem>
                                                        <MenuItem value={'member'}>Member</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Typography>
                                    </TableCell>
                                    {/*                                     <TableCell style={{ textAlign: 'center' }}>
                                        <StarBorderIcon className={classes.emptyStar} ></StarBorderIcon>
                                        <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>Make Admin</Typography>
                                    </TableCell> */}
                                    <TableCell align='center'>
                                        <IconButton onClick={() => { handleActiveToggle(row?.email, row?.uuid, row?.is_active) }}>
                                            <PowerSettingsNewIcon className={classes.emptyStar}></PowerSettingsNewIcon>
                                        </IconButton>
                                        <Typography sx={{ color: '#FFFFFF', fontSize: 14 }}>{(row?.is_active === 'True') ? 'Deactivate' : 'Activate'}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {(!usersList || usersList.length === 0) && (
                        <Grid container direction='row' justifyContent='center' alignItems='center' alignContent='center'>
                            <Grid item>
                                <div className='no-user-found'>
                                    <div className='' >
                                        <img src="/assets/img/no-user-found.svg" alt="" />
                                        <Typography className='user-heading' style={{ fontWeight: 400, size: '24px', color: '#FFFFFF' }}>No users added</Typography>
                                        <StyledButtonContained
                                            type='submit'
                                            size="large"
                                            fullWidth={true}
                                            variant="contained"
                                            onClick={handleOpenDialog}
                                            style={{
                                                width: "inherit",
                                                // backgroundColor: ButtonColor,
                                                // color: '#3A3E3F',
                                                height: "inherit",
                                                // textTransform: "none",
                                                // fontWeight: 700
                                            }}
                                        >
                                            + Add User
                                        </StyledButtonContained>
                                    </div>

                                </div>
                            </Grid>
                        </Grid>
                    )}
                </div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    padding={2}
                >
                    <Pagination
                        rowsPerPage={10}
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{
                            '& .MuiPaginationItem-root': {
                                width: '30px',
                                height: '30px',
                                border: '1px solid #FFFFFF',
                                background: '#3A3E3F',
                                color: '#FFFFFF',
                                margin: '0 4px',
                                '&.Mui-selected': {
                                    background: '#FFC540',
                                    color: '#3A3E3F',
                                },
                            },
                            '& .MuiPaginationItem-page.Mui-selected:hover': {
                                background: '#FFC540',
                                color: '#3A3E3F',
                            },
                            '& .MuiPaginationItem-page:hover': {
                                background: '#FFC540',
                                color: '#3A3E3F',
                            },
                            '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                width: '100px',
                                height: '40px',
                                borderRadius: '8px',
                                border: '1px solid #FFFFFF',
                                color: '#FFFFFF',
                                background: '#3A3E3F',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                            '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                background: '#FFC540',
                                color: '#3A3E3F',
                            },
                        }}

                        renderItem={(item) => (
                            <PaginationItem {...item} />
                        )}
                    />
                </Box>
            </TableContainer>

            <Dialog open={activeToggleDialogOpen} onClose={handleActiveToggleCancel} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                <DialogContent>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography style={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px', color: '#FFC540' }}>Are you sure?</Typography>
                        </Grid>
                        <Grid item p={4}>
                            {(activeToggleUserStatus === 'True') ? (
                                <Typography style={{ width: 'inherit', color: '#FFFFFF', fontFamily: 'Poppins'}}>{`You are moving ${activeToggleUserName} to an "Inactive" state. This means this person will not be able to access their account until it is re-activated by an Admin/Owner.`}</Typography>
                            ) : (
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'Poppins',}}>{`You are moving ${activeToggleUserName} to an "Active" state. `}</Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='space-between'>
                                <Grid item>
                                    <StyledButtonOutlined variant='outlined' onClick={handleActiveToggleCancel}
                                        sx={{
                                            width: '200px',
                                            height: '60px'
                                         }}
                                    >Cancel</StyledButtonOutlined>
                                </Grid>
                                <StyledButtonContained variant='contained'
                                    onClick={() => { handleActiveToggleSubmit(); }}
                                    sx={{
                                        width: '200px',
                                        height: '60px',
                                        }}
                                >
                                    Submit
                                </StyledButtonContained>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={roleChangeDialogOpen} onClose={handleRoleChangeCancel} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                <DialogContent>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography style={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px', color: '#FFC540' }}>Are you sure?</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#FFFFFF', fontFamily: 'Poppins' }}>You are changing the following: </Typography>
                        </Grid>
                        <Grid item sx={{ background: '#FFFFFF1A', mx: 4, my: 4, p: 2 }}>
                            <Typography style={{ color: '#FFFFFF', fontFamily: 'Poppins', whiteSpace: 'pre' }}>{`${roleChangeUserName}  :  ${roleChangePrevious}  ->  ${roleChangeCurrent}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='space-between'>
                                <Grid item>
                                    <StyledButtonOutlined variant='outlined' onClick={handleRoleChangeCancel}
                                        sx={{
                                            width: '200px',
                                            height: '60px',
                                            borderRadius: '30px',
                                            }}
                                    >Cancel</StyledButtonOutlined>
                                </Grid>
                                <StyledButtonContained variant='contained'
                                    onClick={() => { handleRoleChangeSubmit(); }}
                                    sx={{
                                        width: '200px',
                                        height: '60px',
                                        borderRadius: '30px'
                                    }}
                                >
                                    Submit
                                </StyledButtonContained>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <AddUser open={open} onClose={handleCloseDialog}></AddUser>
        </>
    );
};

export const StatusActiveIcon = () => {
    return (
        <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <circle cx="4.5" cy="4.5" r="4.5" fill="url(#paint0_linear_2025_1756)" />
                <defs>
                    <linearGradient id="paint0_linear_2025_1756" x1="4.5" y1="0" x2="4.5" y2="9" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#009621" />
                        <stop offset="1" stop-color="#21CB46" />
                    </linearGradient>
                </defs>
            </svg>
            <Typography>Active</Typography>
        </Stack>
    )
}
export const StatusDeActiveIcon = () => {
    return (
        <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <circle opacity="0.5" cx="4.5" cy="4.5" r="4.5" fill="#D9D9D9" />
            </svg>
            <Typography>Inactive</Typography>
        </Stack>
    )
}