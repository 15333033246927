import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
    ArcElement, BarElement,
    CategoryScale, Chart as ChartJS, Legend, LinearScale,
    Title, Tooltip
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { formats } from "../../global";
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            display: true
        }
    },
};
const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const Backup = () => {
    const classes = useStyles();
    const [date, setDate] = React.useState("month");
    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    const handleChange = (event, newAlignment) => {
        setDate(newAlignment);
    };
    const [data, setData] = React.useState(null);
    const [totalStorage, setTotalStorage] = React.useState(null);

    React.useEffect(() => {
        let payload = {
            type:'rep_format',
            "request": {
                "cid": 15,
                "type": "formats"
            }
        };
        axios.post(formats, payload)
            .then(response => {
                if (response?.status == 200) {
                    let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
                    let labels = dynamicArray.map((e) => e.format);
                    let data = {
                        labels,
                        datasets: [
                            {
                                label: '',
                                data: dynamicArray.map((e) => e.bytes),
                                backgroundColor: [
                                    'rgba(92,140,203, 1)',
                                    'rgba(255,212,108, 1)',
                                    'rgba(64,176,97, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(220,220,220, 1)',
                                    'rgba(255, 159, 64, 1)',
                                ],
                                borderColor: [
                                    'rgba(92,140,203, 1)',
                                    'rgba(255,212,108, 1)',
                                    'rgba(64,176,97, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(220,220,220, 1)',
                                    'rgba(255, 159, 64, 1)',
                                ],
                                borderWidth: 1,
                                // barPercentage: 0.4,
                            }

                        ],
                    };
                    setData(data);
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, []);
    return (
        <div className={classes.mainContainer}>
            <Box p={3}>
                <Grid container direction='column' spacing={2.3}>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Backup</Typography>
                            </Grid>
                            {/* <Grid item className={classes.closeIcon}>
                                <CloseIcon />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1} sx={{ alignItems: 'center' }}>
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.secondHeading}>{totalStorage}</Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item p={0} style={{ maxWidth: '100%' ,}}>
                        <div id="canvas-container">
                            {data ? <Pie options={options} data={data} /> : ''}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
const useStyles = makeStyles({
    mainContainer: {
        background: "#4E5152 !important",
        // height:'630px',
        // height: '466px',
        minHeight: '565px',
        maxHeight: '565px',
        // width: '698px',
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
        maxWidth: '100%'
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    secondHeading: {
        fontWeight: 400,
        fontSize: '42px !important',
        lineHeight: '51px',
        color: '#FFFFFF',
    },
    years: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    data: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    per: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#697588'
    }
});