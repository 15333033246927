import { Button } from "@mui/material"
import { styled } from "@mui/styles"

export const StyledButtonContained = styled(Button)({
    textTransform:'none',
    fontWeight:700,
    background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
    color: '#3A3E3F',
    borderRadius: '30px',
    "&:hover":{
        backgroundColor:'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
        color: '#3A3E3F'

    }
})
export const StyledButtonOutlined = styled(Button)({
    textTransform:'none',
    fontWeight:700,
     background: 'transparent',
    color: '#FFC540',
    borderRadius: '30px',
    border:'1px solid #FFC133',
    "&:hover":{
        backgroundColor: 'transparent',
        color: '#FFC540',
        border:'1px solid #FFC133',

    }
})

export const StyledButtonText = styled(Button)({
    
})