import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 37),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 49),
];

export const Dashboard = () => {
    const classes = useStyles();
    return (
        <>
            <Grid container direction='column' spacing={1}>
                <Grid item>
                    <Typography align='left'>Nov/7/23</Typography>
                </Grid>
                <Grid item>
                <Typography align='left'>Today</Typography>
                </Grid>
                <Grid item>
                    <div style={{ border: '1px solid #5F5F5F'}}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}>
                                            <TableCell align='center' className={classes.tableRightborder} style={{borderBottom:'none',color:'white',fontWeight:400,fontSize:'18px',lineHeight:'22px'}}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align='center' className={classes.tableRightborder} style={{borderBottom:'none',color:'white',fontWeight:400,fontSize:'18px',lineHeight:'22px'}}>{row.calories}</TableCell>
                                            <TableCell align='center' className={classes.tableRightborder} style={{borderBottom:'none',color:'white',fontWeight:400,fontSize:'18px',lineHeight:'22px'}}>{row.fat}</TableCell>
                                            <TableCell align='center' style={{borderBottom:'none',color:'white',fontWeight:400,fontSize:'18px',lineHeight:'22px'}}>{row.carbs}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableRightborder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: '#5F5F5F',
        borderStyle: 'solid',
        borderBottom :'none'
        //color:'#FFFFFF'

    }

}));