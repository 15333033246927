import { useEffect, useState } from "react";
import {
    Grid, Box, Typography, Button, TextField, FormGroup, FormControl, MenuItem, IconButton, InputAdornment,
    Radio, FormControlLabel, Divider, RadioGroup, FormLabel, Dialog, DialogContent, Paper, List, ListItem, DialogTitle, DialogActions, Autocomplete, createFilterOptions, DialogContentText, Snackbar
} from "@mui/material";
import { CheckCircle, Error, Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from "prop-types";
import axios from "axios";
//import { env } from "../../../../env";
import { flexStyles } from "../../../theme/theme";
import { FEE_SERVICE, FEE_SERVICE_DURATION, FEE_UNITS } from "../../../../utils/constants";
import { getApiUrl } from "../../../../global";
import { StyledButtonContained } from "../../../../views/Admin/AccountCreation/accountCreation";

const serviceFrequencies = FEE_SERVICE_DURATION;

const units = FEE_UNITS;
export const AdminFeeCreate = ({ item, open, onClose }) => {

    const [state, setState] = useState({
        openSb: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const { vertical, horizontal, openSb } = state;

    const openSnackbar = (message) => {
        setSnackbarMessage(message)
        setState({
            vertical: 'top', horizontal: 'right', openSb: true
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: 'top', horizontal: 'right', openSb: false
        });
    };

    const [serviceList, setServiceList] = useState(FEE_SERVICE);
    const [service, setService] = useState(null);
    const [serviceFrequency, setServiceFrequency] = useState('');
    const [unit, setUnit] = useState('');
    const [serviceRate, setServiceRate] = useState(10);
    const [payment, setPayment] = useState('');

    let _orderUUID = window?.location?.href?.split("uuid=")[1];

    const handleClear = () => {
        setServiceValue({ service: '', serviceFrequency: '', serviceRate: '' });
        setService('');
        setServiceFrequency('');
        setServiceRate('');
        setUnit('');
        setPayment('');
    }


    useEffect(() => {
        //const services = props.watch('fee.services');
        //if (services) {
        //const servicesApiUrl =  process.env.REACT_APP_API_BASE_URL + `/service/`;
        //axios.get(servicesApiUrl)
        //    .then(data => {
        //        setServiceList(data.data.results);
        //    })
        //    .catch(error => {
        //        console.log(error)
        //    });
        //} else {
        //    setServiceList([]);
        //}
    }, []);//props.watch('fee.services')

    useEffect(() => {
        if (item) {
            setService(serviceList.find(service => service?.key === item?.service));
            setServiceFrequency(item?.service_duration);
            setServiceRate(item?.service_rate);
            setUnit(item?.service_unit);
            setPayment(item?.service_fee);
        }
    }, [item]);

    const isEditing = !!item;
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        handleClear();
        onClose();
    };
    const handleSubmit = async () => {
        let url = await getApiUrl();

        if (_orderUUID !== "undefined") {
            const serviceKey = serviceList.find(s => s.service === service?.key)?.key; //TODO Change
            const feePayload = {
                'service': (service?.key) ? service?.key : serviceKey,
                'service_rate': serviceRate,
                'service_duration': serviceFrequency,
                'order': _orderUUID,
                'service_fee': payment,
                'service_payment_milestone': 'end_of_month',
                'service_unit': (unit) ? unit : 'tape',
            };

            if (isEditing) {
                const feeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/fees/" + item?.uuid + '/';
                axios.put(feeApiUrl, feePayload)
                    .then((res) => {
                        if (res?.status === 200){
                            openSnackbar('Fee saved successfully');
                        }
                        handleClose();
                    })
                    .catch(error => {
                        console.log(error);
                        handleClose();
                    });
            } else {
                const feeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/fees/";
                axios.post(feeApiUrl, feePayload)
                    .then((res) => {
                        if (res?.status === 201){
                            openSnackbar('Fee saved successfully');
                        }
                        handleClose();
                    })
                    .catch(error => {
                        console.log(error);
                        handleClose();
                    });
            }
        }
    };




    //////////////
    const serviceFilter = createFilterOptions();

    const [serviceValue, setServiceValue] = useState(null);
    const [servicesDialogOpen, toggleServicesDialogOpen] = useState(false);

    const handleServiceClose = () => {
        setNewServiceValue({
            service: '',
            serviceFrequency: '',
            serviceRate: '',
        });
        toggleServicesDialogOpen(false);
    };

    const [newServiceValue, setNewServiceValue] = useState({
        service: '',
        serviceFrequency: '',
        serviceRate: '',
    });

    const handleServiceSubmit = (event) => {
        event.preventDefault();
        setServiceValue({
            service: newServiceValue?.service,
            serviceFrequency: newServiceValue?.serviceFrequency,
            serviceRate: newServiceValue?.serviceRate,
        });
        handleServiceClose();
    };

    const handleServiceRateChange = (event) => {
        const newValue = event.target.value.replace(/[^0-9.]/g, '');

        const dotCount = (newValue.match(/\./g) || []).length;
        if (dotCount > 1) {
            return;
        }

        setServiceRate(newValue);
    };

    const handlePaymentChange = (event) => {
        const newValue = event.target.value.replace(/[^0-9.]/g, '');

        const dotCount = (newValue.match(/\./g) || []).length;
        if (dotCount > 1) {
            return;
        }

        setPayment(newValue);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSb}
                onClose={closeSnackbar}
                message={snackbarMessage}
                key={vertical + horizontal}
            />
            <Dialog
                open={open && !servicesDialogOpen}
                fullWidth
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: "8px",
                        backgroundColor: '#3A3E3F',
                        color: '#FFFFFF',
                        p: 2,
                    }
                }}>
                <DialogTitle color={'primary'}>
                    Create New Fee
                </DialogTitle>
                <Divider sx={{ backgroundColor: '#5F5F5F', width: '100%' }} />
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/*                             <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Service"
                                    fullWidth
                                    value={service}
                                    onChange={(e) => setService(e.target.value)}
                                    required>
                                    {serviceList.map((r) => (
                                        <MenuItem key={r} value={r}>
                                            {r}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl> */}
                                <>
                                    <Autocomplete
                                        value={service}
                                        className="outlined-input"
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                // timeout to avoid instant validation of the dialog's form.
                                                setTimeout(() => {
                                                    toggleServicesDialogOpen(true);
                                                    setNewServiceValue({
                                                        service: newValue,
                                                        serviceFrequency: '',
                                                        serviceRate: ''
                                                    });
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                toggleServicesDialogOpen(true);
                                                setNewServiceValue({
                                                    service: newValue.inputValue,
                                                    serviceFrequency: '',
                                                    serviceRate: ''
                                                });
                                            } else {
                                                setService(newValue);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = serviceFilter(options, params);

                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    service: `Add New Service "${params.inputValue}"`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        id="service-list"
                                        options={serviceList}
                                        getOptionLabel={(option) => {
                                            // e.g. value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option?.service;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        renderOption={(props, option) => <li {...props}>{option?.service}</li>}
                                        sx={{
                                            width: 300,
                                            ...((isEditing === true) && { pointerEvents: 'none' })
                                        }}
                                        freeSolo
                                        renderInput={(params) => <TextField {...params} label="Service" />}
                                    />
                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label="Service Frequency"
                                        value={serviceFrequency}
                                        onChange={(e) => setServiceFrequency(e.target.value)}
                                        required
                                        className="outlined-input"
                                    >
                                        {serviceFrequencies.map((r) => (
                                            <MenuItem key={r.key} value={r.key}>
                                                {r.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label="Unit"
                                        value={unit}
                                        onChange={(e) => setUnit(e.target.value)}
                                        required
                                        className="outlined-input"
                                        sx={{
                                            visibility: (serviceFrequency === 'consumption') ? 'visible' : 'hidden',
                                        }}>
                                        {units.map((r) => (
                                            <MenuItem key={r.key} value={r.key}>
                                                {r.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Tax Rate"
                                        fullWidth
                                        value={serviceRate}
                                        onInput={handleServiceRateChange}
                                        /* onChange={(e) => {
                                            setServiceRate(e.target.value);
                                        }} */
                                        //helperText="Enter a valid Service rate value"
                                        required
                                        className="outlined-input" /></FormControl>
                            </Grid>
                            <Grid item xs={12} alignItems={'center'} sx={flexStyles.flexRowStart}>
                                <FormControl>
                                    <TextField
                                        label="Payment"
                                        sx={{
                                            width: '90%',
                                            '& fieldset': {
                                                height: '8ch',
                                            },
                                            '& input': {
                                                fontSize: 24,
                                            },
                                            '&.MuiOutlinedInput-label': {
                                                fontSize: 24,
                                            }
                                        }}
                                        value={payment}
                                        onInput={handlePaymentChange}
                                        /*                                         onChange={(e) => {
                                                                                    setPayment(e.target.value);
                                                                                }} */
                                        className="outlined-input"
                                    />
                                </FormControl>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: 24 }}>{` + ${serviceRate}%`}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper display={'inline-flex'} sx={{ alignItems: 'center', backgroundColor: '#5F5F5F', color: '#B5B5B5', }}>
                                    <ul>
                                        <li>
                                            <Typography fontWeight='bold' variant="caption">Inclusive of Tax</Typography>
                                        </li>
                                        <li>
                                            <Typography fontWeight='bold' variant="caption">No GST Included</Typography>
                                        </li>
                                    </ul>
                                </Paper>
                            </Grid>
                            {/* Buttons */}
                            <Grid item xs={8}>
                                {/*                                 <Button
                                    variant='contained'
                                    color="secondary"
                                    size="medium"
                                    sx={{ borderRadius: '30px', px: 2, }}>
                                    Add New
                                </Button> */}
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant='text'
                                    color="primary"
                                    onClick={() => { handleClear(); handleClose(); }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <StyledButtonContained
                                    variant="contained"
                                    color="primary"
                                    sx={{px: 3, }}
                                    //type="submit"
                                    onClick={handleSubmit}
                                    disabled={(serviceRate === '' || service === '' || serviceFrequency === '')}>
                                    Save
                                </StyledButtonContained>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={servicesDialogOpen}
                onClose={handleServiceClose}
                PaperProps={{
                    sx: {
                        borderRadius: "8px",
                        backgroundColor: '#3A3E3F',
                        color: '#FFFFFF',
                        p: 2,
                    }
                }}>
                <form onSubmit={handleServiceSubmit}>
                    <DialogTitle color={'primary'}>Create New Service</DialogTitle>
                    <Divider sx={{ backgroundColor: '#5F5F5F', width: '100%' }} />
                    <DialogContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="service"
                                    fullWidth
                                    value={newServiceValue?.service}
                                    onChange={(event) =>
                                        setNewServiceValue({
                                            ...newServiceValue,
                                            service: event.target.value,
                                        })
                                    }
                                    className="outlined-input"
                                    label="Service"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    id="service-frequency"
                                    sx={{ width: '60%' }}
                                    label="Service Frequency"
                                    value={serviceFrequency}
                                    onChange={(e) => setServiceFrequency(e.target.value)}
                                    required
                                    className="outlined-input"
                                >
                                    {serviceFrequencies.map((r) => (
                                        <MenuItem key={r.key} value={r.label}>
                                            {r.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="service-rate"
                                    sx={{ width: '40%' }}
                                    value={newServiceValue?.serviceRate}
                                    onChange={(event) =>
                                        setNewServiceValue({
                                            ...newServiceValue,
                                            serviceRate: event.target.value,
                                        })
                                    }
                                    label="Tax Rate"
                                    className="outlined-input"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleServiceClose}>Cancel</Button>
                        <StyledButtonContained variant="contained" color="primary" type="submit">Add</StyledButtonContained>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};