import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
    Button, Checkbox, Divider, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, LinearProgress,
    MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Toolbar,
    Typography, createTheme, tableCellClasses, Pagination, PaginationItem, TableSortLabel
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { ButtonColor, PrimaryColor, flexStyles } from "../../../../component/theme/theme";
import axios from "axios";
import { Link } from 'react-router-dom';
import moment from "moment";
//import { env } from "../../../../env";
import { Cancel, Search } from "@mui/icons-material";
import { getApiUrl } from "./../../../../global";
import { StyledButtonContained } from "../../AccountCreation/accountCreation";
import { ClientList } from "../../ClientList/clientList";
import Select from "@mui/material/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
const MenuProps = {
    // PaperProps: {
    //     style:
    //         { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 }
    // },
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: "top", horizontal: "center" },
    variant: "menu"
};

const adminOrderListTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC540',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontWeightRegular: 400,
        fontWeightBold: 700,
        caption: {
            fontFamily: 'Poppins',
            fontSize: '12px',
            lineHeight: '14.5px',
            color: '#B4B4B4',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                    color: '#3A3E3F',
                    '&.Mui-disabled': {
                        background: '#C7CCD0',
                        color: '#5F5F5F'
                    },
                },
                textPrimary: {
                    color: '#FFC540',
                },
                containedSecondary: {
                    background: '#3A3E3F',
                    color: '#FFC540',
                    border: '1px solid #FFC540',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 197, 64, 0.1)',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF',
                },
                containedError: {
                    background: '#FF6C65',
                    color: '#3A3E3F',
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFC540',
                },
                colorSecondary: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.outlined-input': {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '8px',
                                borderColor: '#C7CCD0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C7CCD0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#C7CCD0',
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            //fontFamily: 'Inter', 
                            color: '#FFFFFF',
                        },
                        '& .MuiInputLabel-root': {
                            //fontFamily: 'Inter', 
                            color: 'rgba(255, 255, 255, 0.5)',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                        '& svg': {
                            color: '#FFFFFF'
                        }
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    '&.statusBar': {
                        backgroundColor: '#565656',
                        height: 18,
                        borderRadius: 4,
                        width: '100%',
                        border: 'none',
                        '& .MuiLinearProgress-root': {
                        },
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#5D8150',
                        },
                    }
                }
            }
        }
    },
});
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    width: '10rem',
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '&:nth-of-type(odd)': {
            background: 'rgba(95,101,102,0.3)',
        },
        '&:nth-of-type(even)': {
            background: '#3A3E3F',
        },
        '&.Mui-selected': {
            //background: 'rgba(255, 197, 64, 0.1)',
            '&:hover': {
                '&:nth-of-type(odd)': {
                    background: 'rgba(95,101,102,0.3)',
                },
                '&:nth-of-type(even)': {
                    background: '#3A3E3F',
                },
            },
        },
        '& > *': {
            color: '#FFFFFF',
            border: 'unset',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const headCells = [
    {
        id: 'clientName',
        numeric: false,
        disablePadding: true,
        align: 'left',
        label: 'Client Name',
    },
    {
        id: 'shippingMode',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Shipping Mode',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Status',
    },
    {
        id: 'details',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Details',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        align: 'left',
        label: 'Created Date',
    }
];

export const AdminOrderList = () => {
    const [selected, setSelected] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const [customerList, setCustomerList] = useState([]);
    const [orderStatus, setOrderStatus] = useState('all');
    const [orderType, setOrderType] = useState('all');


    //const apiUrl = "/assets/json/getOrder";
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderCount, setOrderCount] = React.useState(0);

    const [orderList, setOrderList] = React.useState([]);
    const classes = useStyles();

    const [searchQuery, setSearchQuery] = useState('');


    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    }

    const handlePageChange = (event, page) => {
        // hangle page change
        setCurrentPage(page);
        console.log(page);
        //setCurrentPage(page);
    };

    useEffect(() => {
        getOrderData();
    }, [searchQuery, currentPage, orderStatus, orderType, selectedClient, sortColumn, sortOrder])
    const getOrderData = async () => {
        let options = {};
        if (searchQuery) {
            options = {
                ...options,
                search: searchQuery
            }
        }
        let url = await getApiUrl();
        const apiUrl = url + `${process.env.REACT_APP_API_BASE_URL}` + "/orders/";
        let _selectedClient = "";
        if (selectedClient?.length) {
            let _clientlist = JSON.parse(JSON.stringify(selectedClient));
            _clientlist.forEach(e => {
                _selectedClient = _selectedClient + `&customer__company_name=${e}`;
            })
        }
        let sort = '';
        if (sortColumn && sortOrder) {
            sort = '&ordering=' + (sortOrder == 'asc' ? '' : '-') + sortColumn;
        }
        let apiUrlWithFilters = apiUrl + `?order_type=${orderType == 'all' ? "" : orderType}&status=${orderStatus == 'all' ? "" : orderStatus}&search=${searchQuery}&page=${currentPage}` + _selectedClient + sort
        axios
            .get(apiUrlWithFilters, { search: searchQuery })
            .then(data => {
                setOrderList(data.data.results);
                setOrderCount(data.data.count);
                setTotalPages(Math.ceil(data.data.count / 10));
            })
            .catch(error => {
                console.log(error);
            });

    };
    useEffect(() => {
        getClientList();
    }, [])
    const getClientList = async () => {
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/customers";
        axios
            .get(apiUrl)
            .then(data => {
                setCustomerList(data.data);
                let company_name = data.data.map(e => e.company_name);
                setSelectedClient(company_name);
                // if (data?.data?.length) {
                //     props.setValue('tenant_id', data.data[data.data.length - 1].uuid);
                // }

            })
            .catch(error => console.log(error));
    }

    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, orderCount);
    const orderStatusList = [{
        key: "all", value: "All", width: '20%'
    }, {
        key: "draft", value: "Draft", width: '20%'
    }, {
        key: "preparing_for_shipment", value: "Preparing For Shipment", width: '20%'
    }, {
        key: "courier_en_route", value: "Courier En route", width: '20%'
    }, {
        key: "shipment_collected", value: "Shipment Collected", width: '20%'
    }, {
        key: "out_for_delivery", value: "Out For Delivery", width: '20%'
    }, {
        key: "verified_and_completed", value: "Verified & Completed", width: '20%'
    }, {
        key: "audit_started", value: "Audit Started", width: '80%'
    }, {
        key: "audit_complete", value: "Audit Complete", width: '90%'
    }, {
        key: "ingestion_started", value: "Ingestion Started", width: '100%'
    }, {
        key: "ingestion_complete", value: "Ingestion Complete", width: '100%'
    }]
    const orderState = [
        { key: "draft", value: "Draft", substates: [] },
        // { key: "request_received", value: "Request Received", substates: [] },
        {
            key: 'shipment', value: 'Shipment', substates:
                [{ key: "preparing_for_shipment", value: "Preparing for Shipment" },
                { key: "courier_en_route", value: "Courier En route" },
                { key: "shipment_collected", value: "Shipment Collected" },
                { key: "out_for_delivery", value: "Out for delivery" },
                { key: "verified_and_completed", value: "Verified & Completed" },]
        },
        {
            key: 'audit', value: 'Audit', substates:
                [{ key: "audit_started", value: "Audit Started" },
                { key: "audit_complete", value: "Audit Complete" },]
        },
        {
            key: 'ingestion', value: 'Ingestion', substates:
                [{ key: "ingestion_started", value: "Ingestion Started" },
                { key: "ingestion_complete", value: "Ingestion Complete" },]
        },
    ];

    function getStateLabel(_state) {
        let stepLabel;
        if (_state === 'draft') {
            return 'Draft';
        }
        if (_state === 'request_received') {
            return 'Request Received';
        }
        orderState.forEach((e) => {
            if (e.substates.some((s) => s.key.toLowerCase() === _state)) {
                stepLabel = e.substates.find(s => s.key.toLowerCase() === _state).value;
            }
        });
        return stepLabel ? stepLabel : _state;
    }

    const calculateProgress = (currentState, activeSubstate) => {

        if (activeSubstate === 'draft' || activeSubstate === 'request_received') {
            return 0;
        }

        const activeState = orderState.find((state) => state.substates.some(substate => substate.key === activeSubstate));
        const activeSubstateIndex = activeState?.substates.findIndex((s) => s?.key === activeSubstate);
        const activeTotalIndex = activeState?.substates?.length;
        if (activeState?.key === 'ingestion') {
            if (currentState === 'shipment' || currentState === 'audit') {
                return 100;
            }
            return ((activeSubstateIndex + 1) / activeTotalIndex) * 100;
        };
        if (activeState?.key === 'audit') {
            if (currentState === 'shipment') {
                return 100;
            }
            if (currentState === 'ingestion') {
                return 0;
            }
            return ((activeSubstateIndex + 1) / activeTotalIndex) * 100;
        };
        if (activeState?.key === 'shipment') {
            if (currentState === 'ingestion' || currentState === 'audit') {
                return 0;
            }
            return ((activeSubstateIndex + 1) / activeTotalIndex) * 100;
        };
        return 0;
    };
    //const [order, setOrder] = useState('asc');
    //const [orderBy, setOrderBy] = useState('feature');
    //const [page, setPage] = useState(0);
    //const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = orderList.map((n) => n.uuid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    /*       const handleChangePage = (event, newPage) => {
            setPage(newPage);
          };
        
          const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }; */


    const isSelected = (id) => selected.indexOf(id) !== -1;

    const mapShippingModeText = (mode) => {
        const shippingMode = {
            'self': 'Self',
            'tapeark': 'Tape Ark',
            'none': 'None'
        };
        return shippingMode[mode];
    }

    const isAllSelected = customerList.length > 0 && selectedClient.length === customerList.length;
    const handleChange = (event) => {
        const value = event.target.value;
        debugger
        if (value[value.length - 1] === "all") {
            if (selectedClient.length === customerList.length) {
                setSelectedClient([]);
                return;
            } else {
                let companyName = customerList.map(e => e.company_name);
                setSelectedClient(companyName);
                return;
            }
        }
        setSelectedClient(value);
    };
    const handleSort = (columnName) => {
        const newOrder = sortColumn === columnName && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortColumn(columnName);
        setSortOrder(newOrder);
        // Perform sorting based on columnName and newOrder // Upd
    }
    return (
        <ThemeProvider theme={adminOrderListTheme}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', paddingTop: 4, paddingLeft: 4 }} className="hideElement">
                <img src="/assets/img/cart.svg" alt="" />
            </Box>
            <Grid container spacing={2} paddingBottom={6} sx={{ paddingX: { xs: 0.5, md: 4 } }} >
                <Grid item md={12} className="hideElement">
                    <Typography variant="h5" mt={4} sx={{
                        fontFamily: 'Lato',
                        fontSize: '28px',
                        fontWeight: 700,
                        lineHeight: '34px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#FFFFFF',
                    }}>
                        Orders
                    </Typography>
                </Grid>
                <Grid item xs={12} className="hideElement">
                    <Divider sx={{ background: '#EAEAEA', width: '100%' }} orientation='horizontal' />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={'row'}>
                        <Grid item md={6} xs={12} sx={flexStyles.flexRowStart} alignItems={'flex-end'} gap={2}>
                            <FormControl sx={{ width: '25%' }}>
                                <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF', textAlign: 'left', fontSize: '16px' }}>Order Type:</Typography>
                                <TextField
                                    defaultValue={'all'}
                                    className="outlined-input"
                                    variant="outlined"
                                    select
                                    value={orderType}
                                    onChange={(e) => setOrderType(e.target.value)}>
                                    <MenuItem key={'all'} value={'all'}>{'All'}</MenuItem>
                                    <MenuItem key={'audit'} value={'audit'}>{'Audit'}</MenuItem>
                                    <MenuItem key={'ingest'} value={'ingest'}>{'Ingest'}</MenuItem>
                                    <MenuItem key={'restore'} value={'restore'}>{'Restore'}</MenuItem>
                                </TextField>
                            </FormControl>
                            <FormControl sx={{ width: '25%' }}>
                                <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF', textAlign: 'left', fontSize: '16px' }}>Order Status</Typography>
                                <TextField
                                    value={orderStatus}
                                    defaultValue={'all'}
                                    className="outlined-input"
                                    variant="outlined"
                                    onChange={(e) => setOrderStatus(e.target.value)}
                                    select>
                                    {
                                        orderStatusList.map((element) => {
                                            return <MenuItem key={element.key} value={element.key}>{element.value}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                            {/* <FormControl sx={{ width: '25%' }}>
                                    <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF', textAlign: 'left', fontSize: '16px' }}>Services</Typography>
                                    <TextField
                                        defaultValue={'all'}
                                        className="outlined-input"
                                        variant="outlined"
                                        select>
                                        <MenuItem key={'all'} value={'all'}>{'All'}</MenuItem>
                                    </TextField>
                                </FormControl> */}
                            <FormControl sx={{ width: '25%' }}>
                                {/* <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF', textAlign: 'left', fontSize: '16px' }}>Client</Typography>
                                <TextField
                                    defaultValue={'all'}
                                    className="outlined-input"
                                    variant="outlined"
                                    select>
                                    <MenuItem key={'all'} value={'all'}>{'All'}</MenuItem>
                                </TextField> */}
                                <Typography sx={{ fontFamily: 'Poppins', color: '#FFFFFF', textAlign: 'left', fontSize: '16px' }}>Client</Typography>
                                <Select
                                    labelId="mutiple-select-label"
                                    multiple
                                    value={selectedClient}
                                    onChange={handleChange}
                                    renderValue={(selectedClient) => selectedClient.join(", ")}
                                    MenuProps={MenuProps}
                                    defaultValue={'all'}
                                    className="outlined-input"
                                    variant="outlined"
                                    style={{
                                        borderRadius: '4px',
                                        border: '1px solid white',
                                        color: 'white'
                                    }}
                                >
                                    <MenuItem value="all" classes={{ root: isAllSelected ? classes.selectedAll : "" }}>
                                        <ListItemIcon>
                                            <Checkbox classes={{ indeterminate: classes.indeterminateColor }} checked={isAllSelected} indeterminate={selectedClient.length > 0 && selectedClient.length < customerList.length}>
                                            </Checkbox>
                                        </ListItemIcon>
                                        <ListItemText primary="Select All"></ListItemText>
                                    </MenuItem>
                                    {customerList.map((option) => (
                                        <MenuItem key={option.company_name} value={option.company_name}>
                                            <ListItemIcon>
                                                <Checkbox checked={selectedClient.indexOf(option.company_name) > -1}>
                                                </Checkbox>
                                            </ListItemIcon>
                                            <ListItemText primary={option.company_name}></ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12} sx={flexStyles.flexRowEnd} alignItems={'flex-end'}>
                            <InputBase
                                id="search-orders"
                                className="search-orders"
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearchQuery}
                                startAdornment={
                                    <InputAdornment position="start" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                        <Search />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    (searchQuery !== '') && <InputAdornment position="end" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                        <IconButton onClick={() => { setSearchQuery(''); }} ><Cancel sx={{ color: '#FFFFFF' }} /></IconButton>
                                    </InputAdornment>
                                }
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '15px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    width: '28rem',
                                    height: '60px',
                                    backgroundColor: PrimaryColor,
                                    borderRadius: '12px',
                                    px: '20px',
                                    border: '1px solid #C7CCD0',
                                    outline: 'none',
                                    color: '#FFFFFF',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} style={{ border: '1px solid #E1E8F0', borderRadius: '12px', background: 'rgb(58, 62, 63)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selected.length > 0 && selected.length < orderList.length}
                                                checked={orderList.length > 0 && selected.length === orderList.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all orders',
                                                }}
                                            />
                                        </TableCell> */}
                                    {headCells.map((headCell) => (
                                        <TableCell

                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}

                                            //sortDirection={sortColumn === headCell.id ? order : false}
                                            sx={{ color: '#FFFFFF', fontSize: 14, paddingLeft: headCell.id == 'clientName' ? '15px' : '' }}
                                        >
                                            <TableSortLabel
                                                style={{ color: 'white' }}
                                                active={sortColumn === headCell.id}
                                                direction={sortColumn === headCell.id ? sortOrder : 'asc'}
                                                onClick={() => handleSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {sortColumn === headCell.id ? (
                                                    <Box component="span" sx={{ display: 'none' }}>
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        {/* Actions */}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{
                                '& > *': {
                                    borderRadius: '8px'
                                }
                            }}>
                                {orderList.map((row, index) => {

                                    const isItemSelected = isSelected(row.uuid);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            key={row.uuid}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}>

                                            {/* <TableCell
                                                    onClick={(event) => handleClick(event, row.uuid)}
                                                    sx={{ cursor: 'pointer' }}
                                                    padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell> */}
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="15px">
                                                <Typography id={`order-name-${index}`} sx={{ color: '#FFFFFF', fontSize: 18, fontFamily: 'Poppins', whiteSpace: 'pre', }}>
                                                    {row.customer_name}</Typography>
                                                <Typography sx={{ color: '#656565', fontSize: 14, fontFamily: 'Poppins', whiteSpace: 'pre', }}>
                                                    {row.order_number}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                    {mapShippingModeText(row.shipping_mode)}
                                                </Typography>
                                            </TableCell>
                                            <StyledTableCell padding="none" sx={{ py: 1, pr: 8 }}>
                                                <Stack direction='column' spacing={1}>
                                                    {orderState.map((state, index) => {
                                                        if (state.key === 'draft' || state.key === 'request_received') {
                                                            return null;
                                                        }
                                                        return (
                                                            <CustomLinearProgress key={state.key} value={calculateProgress(state.key, row.status)} label={state.value} />
                                                        )
                                                    })}
                                                </Stack>
                                            </StyledTableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                    {getStateLabel(row.status)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                    {moment(row.created_at).format("MMM/DD/YYYY")}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Link to={`/OrderManagement/${row.uuid}`} passHref state={{ data: row }}>
                                                    <StyledButtonContained
                                                        variant='contained'
                                                        color='primary'
                                                        sx={{
                                                            borderRadius: '8px',
                                                        }}>Modify</StyledButtonContained>
                                                </Link>
                                            </TableCell>

                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                        style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '55%' }}>
                        <Grid item ml={2}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                                {`Showing ${startIndex}-${endIndex} of ${orderCount} Orders`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Pagination
                                component="div"
                                rowsPerPage={10}
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        width: '30px',
                                        height: '30px',
                                        border: '1px solid #FFFFFF',
                                        background: '#3A3E3F',
                                        color: '#FFFFFF',
                                        margin: { md: '0 4px', xs: '0 2px' },
                                        '&.Mui-selected': {
                                            background: '#FFC540',
                                            color: '#3A3E3F',
                                        },
                                    },
                                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-page:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                        width: '100px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        border: '1px solid #FFFFFF',
                                        color: '#FFFFFF',
                                        background: '#3A3E3F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                }}

                                renderItem={(item) => (
                                    <PaginationItem {...item} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
};




const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: ButtonColor,
        borderRadius: "8px",
        color: '#404558',
        fontWeight: 600,
        fontSize: "14px",
        border: `1px solid ${ButtonColor}`,
    },
    "&.MuiToggleButton-root": {
        color: "white",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "34px",
        fontSize: "14px",
    },
});

const useStyles = makeStyles({
    mainContainer: {
        backgroundColor: "transparent",
    },
    secondaryConatiner: {
        borderRadius: "20px",
        border: "1px solid #C7C7C7",
        color: "white",
    },
    toggleContainer: {
        height: "40px",
        border: `1px solid ${ButtonColor}`,
        padding: "2px",
        borderRadius: "8px",
    },
    orderIdContainer: {
        backgroundColor: "transparent",
        border: "1px solid #A5A5A5",
    },
    orderIdHeading: {
        color: "#686868",
        fontSize: "18px",
        fontWeight: 400
    },
    orderidd: {
        color: '#FFC540',
        fontWeight: 700,
        fontSize: '18px'
    },
    orderIdData: {
        color: ButtonColor,
        fontWeight: 700,
    },
    orderStatusContainer: {
        backgroundColor: 'grey',
        borderRadius: "20px",
        width: '431px'
    },
    orderStatusTextHeading: {
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: 700,
    },
    orderstatusData: {
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 400,
    },
    viewButton: {
        width: "280px",
        backgroundColor: ButtonColor,
        borderRadius: "30px",
        height: "60px",
        textTransform: "none",
    },
});

const CustomLinearProgress = ({ value, label }) => {
    return (
        <Box sx={{ minWidth: '75px', width: '100%', margin: 'auto', borderRadius: '4px', backgroundColor: '#565656', overflow: 'hidden', position: 'relative' }}>
            <Box sx={{ width: `${value}%`, height: '22px', backgroundColor: '#5D8150', borderRadius: 'inherit', }} />
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: 'white',
                width: '100%',
                zIndex: 1,
            }}> <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: (value > 0) ? 700 : 400,
                color: (value > 0) ? '#FFFFFF' : '#ACACAC'
            }} variant="body2">{label}</Typography>
            </Box>
        </Box>
    );
}