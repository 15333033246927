import React from 'react';
import { Box, Button, Dialog, DialogContent, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { getLocationDetails } from "../../../bindCountryState";
import { useForm, useFieldArray } from "react-hook-form";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButtonContained } from '../AccountCreation/accountCreation';

export const Account = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, setValue, control, getValues } = useForm(
        {
            defaultValues: {
                domain: [{ name: "" }],
            }
        }
    );
    const { fields: anotherDomainField, append } = useFieldArray({
        control,
        name: 'domain'
    })
    const classes = useStyles();
    const [peopleopen, setPeopleopen] = React.useState(false);
    const [domainopen, setDomainopen] = React.useState(false);
    const [anotherDomain, setAnotherDomain] = React.useState([]);
    // const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const [peopledata, setPeopledata] = React.useState([]);
    const [domaindata, setDomaindata] = React.useState([]);
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleopenPeople = () => {
        setPeopleopen(true);
    }
    // const [twoFAMode, setTwoFAMode] = React.useState("verify_by_email");
    const updateTwoFAMode = (mode) => {
        props.setValue("two_step_verification", mode);
        props.setTwoFAMode(mode);
    }
    const handleopenDomain = () => {
        setDomainopen(true);
    }
    const handlecloseDomain = () => {
        setDomainopen(false);
        setAnotherDomain(true);
    }
    const handleopenAnotherDomain = () => {
        // setAnotherDomain(true);
        append({ name: "" })
    }
    const fillCityAndStateFields = (localities) => {
        let locality = localities[0]; //use the first city/state object
        props.setValue("City", locality.city);
        props.setValue("Country", locality.country);
        props.setValue("State", locality.state);
    }
    const fillCityAndStateFieldsaddPeople = (localities) => {
        let locality = localities[0]; //use the first city/state object
        setValue("people_country", locality.country);
        setValue("people_state", locality.state);
    }
    const peopledataSubmit = () => {
        let formValues = getValues();
        let currentValue = {
            "people_company": formValues.people_company,
            "people_workLocation": formValues.people_workLocation,
            "people_email": formValues.people_email,
            "people_role": formValues.people_role,
            "people_identify": formValues.people_identify,
            "people_firstName": formValues.people_firstName,
            "people_middleName": formValues.people_middleName,
            "people_lastName": formValues.people_lastName,
            "people_addressLine1": formValues.people_addressLine1,
            "people_addressLine2": formValues.people_addressLine2,
            "people_ZipCode": formValues.people_ZipCode,
            "people_country": formValues.people_country,
            "people_state": formValues.people_state,
            "people_phone": formValues.people_phone,
            "people_mobile": formValues.people_mobile,
        };
        setPeopledata([...peopledata, currentValue]);
        props.setValue("peopledata", [...peopledata, currentValue]);
        setPeopleopen(false);
    }
    const handleDomainChange = (e) => {
        setDomaindata(e.target.value)
    }
    const domainSubmit = () => {
        let formValues = getValues();
        setDomaindata(formValues.domain);
        setDomainopen(false);
        props.setValue("domain", formValues.domain);
        console.log("domaindata", formValues.domain);

    }
    const handleDeletePeople = (index) => {
        let _peopledata = JSON.parse(JSON.stringify(peopledata));
        _peopledata.splice(index, 1);
        setPeopledata(_peopledata);
        // console.log("_peopledata", _peopledata)
        let formState = [];
        _peopledata.forEach(e => {
            formState.push(e)
        })
        props.setValue("peopledata", _peopledata);
    }
    const handleDeleteDomain = (index) => {
        let _domaindata = JSON.parse(JSON.stringify(domaindata));
        _domaindata.splice(index, 1);
        setDomaindata(_domaindata);
        console.log("_domaindata", _domaindata)
        let formState = [];
        _domaindata.forEach(element => {
            formState.push({ name: element });
        });
        props.setValue("domain", formState);
    }
    const handleClickDomain = () => {

    }
    let _orderUUID = window?.location?.href?.split("uuid=")[1];
    const onhandlechangeAccount = (e, newValue) => {
        props.setValue('StorageOption', newValue);
        console.log(newValue, "account-form")
    }
    return (
        <Box p={4}>
            <Grid container direction='column' spacing={8}>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item xs={8}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <Typography align='left' sx={{
                                        color: '#FFFFFF'
                                    }}><b>Personal</b></Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required autoComplete={'off'} className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : 'Company Name'} variant="outlined" fullWidth={true} name='companyName' {...props.register('companyName')} />
                                </Grid>
                                <Grid item>
                                    <TextField required autoComplete={'off'} className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : 'Ark Bridge Customer ID'} variant="outlined" fullWidth={true} name='arkbridge_customer_id' {...props.register('arkbridge_customer_id')} />
                                </Grid>
                                <Grid item>
                                    <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Primary Email Address"} variant="outlined" fullWidth={true} name='primaryEmail' {...props.register('primaryEmail')} />
                                </Grid>
                                <Grid item>
                                    <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Work Location"} variant="outlined" fullWidth={true} name='WorkLocation' {...props.register('WorkLocation')} />
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between' spacing={1}>
                                        <Grid item xs={2}>
                                            {/*                                             {props.watch('indentifyAs') && _orderUUID?
                                            ( */}
                                            <FormControl fullWidth={true} >
                                                <TextField
                                                    required
                                                    className={classes.selectLabel}
                                                    label="Identify as"
                                                    fullWidth={true}
                                                    name='indentifyAs'
                                                    {...props.register("indentifyAs")}
                                                    defaultValue={props.watch("indentifyAs")}
                                                    select
                                                >
                                                    <MenuItem value="mr">Mr</MenuItem>
                                                    <MenuItem value="mrs">Mrs</MenuItem>
                                                    <MenuItem value="ms">Ms</MenuItem>
                                                </TextField>
                                            </FormControl>
                                            {/*                                             ) :  <FormControl fullWidth={true} >
                                            <TextField
                                                className={classes.selectLabel}
                                                label="Identify as"
                                                fullWidth={true}
                                                name='identifyAs' 
                                               // defaultValue={props.watch("indentifyAs")}
                                                {...props.register("indentifyAs")}
                                                select
                                           >
                                                <MenuItem value="mr">Mr</MenuItem>
                                                <MenuItem value="mrs">Mrs</MenuItem>
                                                <MenuItem value="ms">Ms</MenuItem>
                                            </TextField>
                                        </FormControl>} */}

                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "First Name"} variant="outlined" fullWidth={true} name='FirstName' {...props.register('FirstName')} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Middle Name"} variant="outlined" fullWidth={true} name='MiddleName' {...props.register('MiddleName')} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Last Name"} variant="outlined" fullWidth={true} name='LastName' {...props.register('LastName')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={4}>
                                <UploadAndDisplayImage selectedImage={props.selectedImage}
                                    setSelectedImage={props.setSelectedImage}></UploadAndDisplayImage>
                                {/* <Typography sx={{ color: '#C7CCD0' }}>Upload Photo</Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Address */}
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item xs={8}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <Typography align='left' sx={{ color: '#FFFFFF' }}><b>Contact Person</b></Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Address Line 1"} variant="outlined" fullWidth={true} name='Address1' {...props.register('Address1')} />
                                </Grid>
                                <Grid item>
                                    <TextField className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Address Line 2"} variant="outlined" fullWidth={true} name='Address2' {...props.register('Address2')} />
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Enter Zipcode"} variant="outlined" fullWidth={true} name='ZipCode'
                                                {...props.register('ZipCode', {
                                                    onBlur: (e) => getLocationDetails(e, fillCityAndStateFields)
                                                })} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="City" placeholder='City *' variant="outlined" fullWidth={true} name='City' {...props.register('City')} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="Country" placeholder='Country *' variant="outlined" fullWidth={true} name='Country' {...props.register('Country')} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField required className={classes.selectLabel} id="State" placeholder='State *' variant="outlined" fullWidth={true} name='State' {...props.register('State')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Enter Phone Number"} variant="outlined" fullWidth={true} name='Phone' {...props.register('Phone')} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required className={classes.selectLabel} id="outlined-basic" label={_orderUUID ? "" : "Enter Mobile Number"} variant="outlined" fullWidth={true} name='Mobile' {...props.register('Mobile')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Account */}
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item xs={6}>
                            <Grid container direction='column' spacing={1}>
                                <Grid item>
                                    <Typography align='left' style={{ color: '#FFFFFF' }}><b>Account</b></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align='left' style={{ color: '#C7CCD0' }}>Storage Option</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='flex-start'>
                                        <Grid item>
                                            {
                                                props.watch('StorageOption') ? (
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            name='StorageOption'
                                                            // {...props.register('StorageOption')} 
                                                            onChange={onhandlechangeAccount}
                                                            value={props.watch('StorageOption')}
                                                        >
                                                            <FormControlLabel style={{ color: '#C7CCD0' }} value="tapeark"
                                                                control={<Radio sx={{ color: '#C7CCD0' }} />} label="Tape Ark Account" />
                                                            <FormControlLabel style={{ color: '#C7CCD0' }} value="self" control={<Radio sx={{
                                                                color: '#C7CCD0'
                                                            }} />} label="Own Account" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                ) : (
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            name='StorageOption'
                                                            //{...props.register('StorageOption')}
                                                            //value={props.watch('StorageOption')} 
                                                            onChange={onhandlechangeAccount}
                                                            value={props.watch('StorageOption')}
                                                        >
                                                            <FormControlLabel style={{ color: '#C7CCD0' }} value="tapeark"
                                                                control={<Radio sx={{ color: '#C7CCD0' }} />} label="Tape Ark Account" />
                                                            <FormControlLabel style={{ color: '#C7CCD0' }} value="self" control={<Radio sx={{
                                                                color: '#C7CCD0'
                                                            }} />} label="Own Account" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item mt={4}>
                                    <Typography align='left' style={{ color: '#FFFFFF' }}><b>Domain</b></Typography>
                                </Grid>
                                {domaindata && domaindata.map((value, index) => {
                                    return (
                                        <Grid item >
                                            <Stack direction="row" spacing={1} >
                                                <Chip
                                                    style={{
                                                        borderRadius: '20px',
                                                        border: '1px solid #C7CCD0',
                                                        backgroundColor: '#3A3E3F',
                                                        color: 'white',
                                                        fontSize: '12px',
                                                        fontWeight: 400
                                                    }}
                                                    label={`${value.name}`}
                                                    onClick={handleClickDomain}
                                                    onDelete={() => handleDeleteDomain(index)}
                                                    deleteIcon={<CloseIcon />}

                                                />
                                            </Stack>
                                        </Grid>
                                    )
                                })}
                                <Grid item>
                                    <Grid container direction='row' justifyContent='flex-start'>
                                        <Grid item>
                                            <StyledButtonContained variant='contained' size='medium'
                                                onClick={handleopenDomain}>Add</StyledButtonContained>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item mt={4}>
                                    <Typography align='left' style={{ color: '#FFFFFF' }}><b>People</b></Typography>
                                </Grid>
                                {peopledata && peopledata.map((value, index) => {
                                    return (
                                        <Grid item >
                                            <Stack direction="row" spacing={1} >
                                                <Chip
                                                    style={{
                                                        borderRadius: '20px',
                                                        border: '1px solid #C7CCD0',
                                                        backgroundColor: '#3A3E3F',
                                                        color: 'white',
                                                        fontSize: '12px',
                                                        fontWeight: 400
                                                    }}
                                                    label={`${value.people_firstName} ${value.people_lastName}`}
                                                    // onClick={handleClickPeople}
                                                    onDelete={handleDeletePeople}
                                                    deleteIcon={<CloseIcon />}
                                                />
                                            </Stack>
                                        </Grid>
                                    )
                                })} */}
                                {/* <Grid item>
                                    <Grid container direction='row' justifyContent='flex-start'>
                                        <Grid item>
                                            <Button variant='contained' size='medium' sx={{ textTransform: 'none', borderRadius: '30px', color: '#3A3E3F', backgroundColor: '#EEAB10' }} onClick={handleopenPeople}>Add</Button>
                                        </Grid>
                                    </Grid>

                                </Grid> */}
                                <Grid item mt={4}>
                                    <Typography align='left' style={{ color: '#C7CCD0' }}><b>2 Steps Verification</b></Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between'>
                                        <Grid item>
                                            <Typography style={{ color: '#C7CCD0' }}>Verify By SMS</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button style={props.twoFAMode == 'verify_by_sms' ? {
                                                background: '#f5b51f',
                                                color: 'black',
                                                border: '1px solid black'
                                            } : {}} onClick={() => updateTwoFAMode("verify_by_sms")} variant='outlined' size='medium' sx={{ textTransform: 'none', borderRadius: '30px', backgroundColor: '#3A3E3F', border: '1px solid #EEAB10', color: '#EEAB10' }}>Enable</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between'>
                                        <Grid item>
                                            <Typography style={{ color: '#C7CCD0' }}>Verify by Email</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button style={props.twoFAMode == 'verify_by_email' ? {
                                                background: '#f5b51f',
                                                color: 'black',
                                                border: '1px solid black'
                                            } : {}} onClick={() => updateTwoFAMode("verify_by_email")} variant='outlined' size='medium' sx={{ textTransform: 'none', borderRadius: '30px', backgroundColor: '#3A3E3F', border: '1px solid #EEAB10', color: '#EEAB10' }}>Enable</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' justifyContent='space-between'>
                                        <Grid item>
                                            <Typography style={{ color: '#C7CCD0' }}>Verify by Authenticator</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button style={props.twoFAMode == 'verify_by_authenticator' ? {
                                                background: '#f5b51f',
                                                color: 'black',
                                                border: '1px solid black'
                                            } : {}} onClick={() => updateTwoFAMode("verify_by_authenticator")} variant='outlined' size='medium' sx={{ textTransform: 'none', borderRadius: '30px', backgroundColor: '#3A3E3F', border: '1px solid #EEAB10', color: '#EEAB10' }}>Enable</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Button type='submit'>submit</Button> */}
                {/* </form> */}
            </Grid>
            {/* add People Dialog here... */}
            {/* <Dialog open={peopleopen} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                <form>
                    <DialogContent>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading}>People</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography align='left' className={classes.formInputHeading}>Company</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='company' {...register('people_company', { required: true })} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography align='left' className={classes.formInputHeading}>Work Location</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='workLocation' {...register('people_workLocation', { required: true })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography align='left' className={classes.formInputHeading}>Email</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='email' {...register('people_email', { required: true })} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography align='left' className={classes.formInputHeading}>Role</Typography>
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                className={classes.selectLabel}
                                                fullWidth={true}
                                                select
                                                name='role'
                                                {...register('people_role', { required: true })}
                                            >
                                                <MenuItem value='contractor'>Contractor</MenuItem>
                                                <MenuItem value='permanent'>Permanent</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography align='left' className={classes.formInputHeading}>Idenfy As</Typography>
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                className={classes.selectLabel}
                                                fullWidth={true}
                                                select
                                                name='people_identify'
                                                {...register('people_identify', { required: true })}
                                            >
                                                <MenuItem value='mr'>Mr.</MenuItem>
                                                <MenuItem value='mrs'>Mrs.</MenuItem>
                                                <MenuItem value='miss'>Miss</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align='left' className={classes.formInputHeading}>First Name</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='firstName' {...register('people_firstName', { required: true })} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align='left' className={classes.formInputHeading}>Middle Name</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='middleName' {...register('people_middleName')} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align='left' className={classes.formInputHeading}>Last Name</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='lastName' {...register('people_lastName', { required: true })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid item>
                                    <Typography align='left' className={classes.formInputHeading}>Address Line 1</Typography>
                                    <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='addressLine1' {...register('people_addressLine1', { required: true })} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography align='left' className={classes.formInputHeading}>Address Line 2</Typography>
                                <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='addressLine2' {...register('people_addressLine2', { required: true })} />
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography align='left' className={classes.formInputHeading}>ZipCode</Typography>
                                        <TextField
                                            className={classes.selectLabel}
                                            fullWidth={true}
                                            name='ZipCode' {...register('people_ZipCode', { onBlur: (e) => getLocationDetails(e, fillCityAndStateFieldsaddPeople) })} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography align='left' className={classes.formInputHeading}>Country</Typography>
                                        <TextField
                                            className={classes.selectLabel}
                                            fullWidth={true}
                                            name='country' {...register('people_country', { required: true })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography align='left' className={classes.formInputHeading}>State</Typography>
                                        <TextField
                                            className={classes.selectLabel}
                                            fullWidth={true}
                                            name='state' {...register('people_state', { required: true })}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item>
                                        <Typography align='left' className={classes.formInputHeading}>Phone</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='Phone' {...register('people_phone', { required: true })} />
                                    </Grid>
                                    <Grid item>
                                        <Typography align='left' className={classes.formInputHeading}>Mobile</Typography>
                                        <TextField className={classes.selectLabel} id="outlined-basic" variant="outlined" fullWidth={true} name='mobile' {...register('people_mobile', { required: true })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-end' spacing={2}>
                                    <Grid item>
                                        <Button variant='text'
                                            onClick={() => setPeopleopen(false)}
                                            sx={{
                                                borderRadius: '20px',
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                color: '#FFFFFF',
                                                textTransform: 'none'
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained'
                                            type='button'
                                            onClick={peopledataSubmit}
                                            sx={{
                                                borderRadius: '20px',
                                                width: '100px',
                                                height: '41px',
                                                backgroundColor: '#EEAB10',
                                                color: '#3A3E3F',
                                                textTransform: 'none'

                                            }}
                                        >Save</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </form>
            </Dialog> */}
            {/* Domain Dialog here.. */}
            <Dialog open={domainopen} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                <form id="addDomain">
                    <DialogContent>
                        <Grid container direction='column' spacing={1}>
                            {anotherDomainField.map((item, index) => {
                                return (
                                    <Grid item key={item.id}>
                                        <Typography align='left' className={classes.formInputHeading}>Domain</Typography>
                                        <TextField className={classes.selectLabel} {...register(`domain.${index}.name`)} id="outlined-basic" variant="outlined" fullWidth={true} />
                                    </Grid>
                                )
                            })
                            }
                            <Grid item>
                                <Typography align='left' style={{ fontWeight: 400, fontSize: '17px', color: '#FFFFFF', lineHeight: '21px', cursor: 'pointer' }} onClick={handleopenAnotherDomain}>+ Add another Domain</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent='flex-end' spacing={2}>
                                    <Grid item>
                                        <Button variant='text'
                                            onClick={handlecloseDomain}
                                            sx={{
                                                borderRadius: '20px',
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                color: '#FFFFFF',
                                                textTransform: 'none'
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button form="addDomain" variant='contained'
                                            type='button'
                                            onClick={domainSubmit}
                                            sx={{
                                                borderRadius: '20px',
                                                width: '100px',
                                                height: '41px',
                                                backgroundColor: '#EEAB10',
                                                color: '#3A3E3F',
                                                textTransform: 'none'

                                            }}
                                        >Save</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </form>
            </Dialog>
        </Box>
    )
}

const useStyles = makeStyles({
    selectLabel: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
    mainHeading: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    formInputHeading: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    }
});
const UploadAndDisplayImage = (props) => {
    const getBase64 = (file) => {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const handleFileInputChange = e => {
        let file = e.target.files[0];
        getBase64(file)
            .then(result => {
                file["base64"] = result;
                props.setSelectedImage({
                    base64URL: result,
                    file
                });
                console.log("base64", file["base64"])
            })
            .catch(err => {
                console.log(err);
            });
    };
    const uploadContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const containerStyle = {
        width: '170px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '45px',
        height: '170px',
        border: 'dashed 4px #808384',
        color: '#909293',
        fontSize: '14px',
        fontWeight: '600',
        cursor: 'pointer'
    };
    return (
        <div>
            {/* <h1>Upload and display image</h1> */}
            {
                (props?.selectedImage?.base64URL || props?.selectedImage?.url) && (
                    <div>
                        <img width={200} src={(props?.selectedImage?.base64URL) ? props.selectedImage.base64URL :
                            (props?.selectedImage?.url) ? props?.selectedImage?.url : ''} />
                        <br />
                        <label for="uploadimageFile1" style={{
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                            textTransform: 'none',
                            fontWeight: '700',
                            background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                            color: '#3A3E3F',
                            borderRadius: '30px',
                            width: '85px',
                            height: '35px',
                            display:'inline-flex',
                            alignItems:'center',
                            justifyContent:'center',
                            cursor:'pointer'
                        }}>
                            {/* <button type='button'> */}
                                Upload
                            {/* </button> */}
                        </label>
                        <input id="uploadimageFile1" type='file' name='myImage1' style={{ visibility: 'hidden', width: '0px' }} onChange={handleFileInputChange} />

                        {/* <button onClick={() => props.setSelectedImage()}>Remove</button> */}
                    </div>

                )
            }
            {!(props?.selectedImage?.base64URL || props?.selectedImage?.url) && (
                <>
                    <Box style={uploadContainerStyle}>
                        <label for="uploadimageFile" style={containerStyle}>
                            <Typography>Upload Image</Typography>
                        </label>
                    </Box>
                    {/* <img width={200} src="/assets/img/uploadimage.png"></img> */}
                    <input id="uploadimageFile" type='file' name='myImage' style={{ visibility: 'hidden', width: '0px' }} onChange={handleFileInputChange} />
                </>
            )
            }
        </div >
    )

}




