import { createGlobalStyle } from 'styled-components';
import { createTheme } from "@mui/material/styles";

export const GlobalStyle = createGlobalStyle`body {
    font-family: Lato, sans-serif;
}`

export const theme = createTheme({
    typography: {
        fontFamily: 'Lato, sans-serif'
    }
})