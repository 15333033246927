import React, { useEffect, useState } from 'react';
import {
    TableContainer, Table, TableHead, TableRow, TableBody, IconButton, Toolbar, Pagination,
    InputBase, InputAdornment, Avatar, Button, Grid, ToggleButton, ToggleButtonGroup, Box, Paper,
    Typography, Stack, PaginationItem, Card, CardContent, Menu
} from '@mui/material';
import { ButtonColor, PrimaryColor, flexStyles } from '../../../component/theme/theme';
import { ArrowDownward, ArrowDropDown, Cancel, GridViewRounded, MoreVert, Search } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./clientList.css";
//import { env } from '../../../env';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getApiUrl } from "./../../../global";
import debounce from 'lodash/debounce';

//import { AddUser } from '../../component/addUser/addUser';
const useStyles = makeStyles({
    tableContainer: {
        background: '#545353',
    },
    rootMenuItem: {
        "&$selected": {
            backgroundColor: "red",
            "&:hover": {
                backgroundColor: "green",
                borderRadius: 12,
                border: '1px solid var(--White, #FFF)',
            }
        },
        '&:hover': {
            backgroundColor: 'transparent',
            border: 0,
            borderRadius: 12,
            border: '1px solid var(--White, #FFF)',
        }
    },
    emptyStar: {
        color: "white"
    },
    columnHeader: {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#FFFFFF',
        padding: '10px'
    },
    customTableRow: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',

    },
});

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '&:nth-of-type(odd)': {
            background: '#323334',
        },
        '&:nth-of-type(even)': {
            background: '#3A3E3F',
        },
        '&.Mui-selected': {
            //background: 'rgba(255, 197, 64, 0.1)',
            '&:hover': {
                '&:nth-of-type(odd)': {
                    background: 'rgba(95,101,102,0.3)',
                },
                '&:nth-of-type(even)': {
                    background: '#3A3E3F',
                },
            },
        },
        '& > *': {
            color: '#FFFFFF',
            border: 'unset',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const greenStatus = 'linear-gradient(180deg, #009621 0%, #21CB46 100%)'; const yellowStatus = '#FFC540'; const greyStatus = '#C7CCD0';

const data = [
    //{ name: 'Prac21299', status: 1, email: "k@gmail.com", role: "tester" },
    //{ name: 'Prac21320', status: 1, email: "k@gmail.com", role: "tester" },
    //{ name: 'Prac21355', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 0, email: "k@gmail.com", role: "tester" },
    // { name: 'Prac21280', status: 1, email: "k@gmail.com", role: "tester" }
];

export const ClientList = ({ }) => {//data
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('columnName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [open, setOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchQuery = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        // debounceSearch(() => {
        //     getCustomers(value);
        // }, 500)();
    }

    const [customerList, setCustomerList] = useState(data);
    const [allCustomerList, setAllCustomerList] = useState([]);
    const [customerCount, setCustomerCount] = useState(0);
    const getCustomers = async () => {
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/customers/" + `?page=${currentPage}&search=${searchQuery}`;
        axios
            .get(apiUrl)
            .then(data => {
                debugger
                let response = JSON.parse(JSON.stringify(data.data));
                setAllCustomerList(response);
                data.data = data.data.slice(0, 10);
                setCustomerList(data.data);
                setCustomerCount(response.length);
                setTotalPages(Math.ceil(response.length / 10));
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        getCustomers();
    }, [searchQuery]);
    // const debounceSearch = debounce(search => {
    //     console.log("search",search)
    //     getCustomers(search);
    // }, 1000)
    // const debounceSearch = (func, delay) => {
    //     let timer
    //     return function (...args) {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => {
    //             func.apply(this, args)
    //         }, delay);
    //     }
    // }
    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    // To open the dialog
    const handleOpenDialog = () => {
        setOpen(true);
    };
    // To close the dialog
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handlePageChange = (event, page) => {
        // hangle page change
        setCurrentPage(page);
        let _slice = allCustomerList.slice((page - 1) * 10, (((page - 1) * 10) + 10))
        setCustomerList(_slice)
        console.log(page);
        //setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, customerCount);

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowName, setSelectedRowName] = useState(null);

    const handleClientListClick = (event, uuid, name) => {
        setSelectedRow(uuid);
        setSelectedRowName(name);
        setAnchorEl(event.currentTarget);
    };
    const handleClientListClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();

    const handleEditCustomer = () => {
        navigate(`/OrderCreation`,
            {
                state: {
                    isEditClient: true,
                    customerId: selectedRow,
                    customerName: selectedRowName
                }
            });
    }

    const handleCreateJob = () => {
        navigate(`/JobCreation`,
            {
                state: {
                    "uuid": selectedRow,
                    "step": 1,
                    "iscomingFrom": "customerSelection"
                }
            });
    }

    const boxes = [{
        icon: `/assets/img/total_customers.svg`,
        data: customerCount,
        text: 'Total Customers'
    }, {
        icon: '/assets/img/expiring_soon.svg',
        data: '1',
        text: 'Expiring This Month'
    }, {
        icon: '/assets/img/pending_renewal.svg',
        data: '2',
        text: 'Pending Renewal'
    }, {
        icon: '/assets/img/jobs_in_progress.svg',
        data: customerCount,
        text: 'Jobs in Progress'
    }];

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', paddingTop: 4, paddingLeft: 4 }} className="hideElement">
                <img src="/assets/img/account.png" alt="" style={{ filter: 'brightness(10)' }} />
            </Box>

            {/* Header */}
            <Grid container spacing={2} paddingBottom={6} sx={{ paddingX: { xs: 0.5, md: 4 } }}>
                <Grid item md={12} className="hideElement">
                    <Typography variant="h5" mt={4} sx={{
                        fontFamily: 'Lato',
                        fontSize: '28px',
                        fontWeight: 700,
                        lineHeight: '34px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#FFFFFF',
                    }}>
                        Customers
                    </Typography>
                </Grid>
                <Grid item xs={12} className="hideElement">
                    <Divider sx={{ background: '#EAEAEA', width: '100%' }} orientation='horizontal' />
                </Grid>
                <Grid item lg={8} xs={12}>
                    <Grid container direction='row' justifyContent='space-between' spacing={2}>
                        {boxes.map(item => (
                            <Grid item md={3} xs={6} key={item.text}>
                                <Card variant="outlined" className="dashboardGridBg">
                                    <CardContent className="dashboardGrid">
                                        <Typography>
                                            <div style={{
                                                height: '60px', width: '60px', display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: 'auto'
                                            }}>
                                                <img src={item.icon} alt="" />
                                            </div>
                                        </Typography>
                                        <Typography variant="h5" component="div" sx={{ fontSize: 24 }} color="text.secondary" gutterBottom className="dashboardConatiner">
                                            {item.data}</Typography>
                                        <Typography variant="h5" component="div" sx={{ fontSize: 14 }} color="white" className="dashboardText">
                                            {item.text}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={'row'}>
                        <Grid item md={4} xs={12}>
                            {/* Seach bar */}
                            <InputBase
                                id="search-restores"
                                className="search-restores"
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearchQuery}
                                startAdornment={
                                    <InputAdornment position="start" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                        <Search />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    (searchQuery !== '') && <InputAdornment position="end" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                        <IconButton onClick={() => { setSearchQuery(''); }} ><Cancel sx={{ color: '#FFFFFF' }} /></IconButton>
                                    </InputAdornment>
                                }
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '15px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    width: '100%',
                                    height: '60px',
                                    backgroundColor: PrimaryColor,
                                    borderRadius: '12px',
                                    px: '20px',
                                    border: '1px solid #C7CCD0',
                                    outline: 'none',
                                    color: '#FFFFFF',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} style={{ border: '1px solid #E1E8F0', borderRadius: '12px', background: 'rgb(58, 62, 63)' }}>
                        {/* <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', my: 1, justifyContent: 'flex-start' }}>

                        </Toolbar> */}
                        {/*                         <div style={{
                            border: '1px solid #E1E8F0',
                            borderRadius: '12px',
                            paddingTop: '28px',
                        }}> */}
                        <Table>
                            <TableHead>
                                <TableRow sx={{ '& > *': { color: '#FFFFFF', } }}>
                                    <TableCell className={classes.columnHeader}>
                                    </TableCell>
                                    <TableCell className={classes.columnHeader}>
                                        <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Client</Typography>
                                    </TableCell>
                                    <TableCell className={classes.columnHeader}>
                                        <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Contact Person</Typography>
                                    </TableCell>
                                    <TableCell className={classes.columnHeader}>
                                        <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Start Date</Typography>
                                    </TableCell>
                                    <TableCell className={classes.columnHeader}>
                                        <Typography sx={{ marginBottom: 0, color: '#FFFFFF', fontSize: 14 }}>Valid till</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    '& > *': {
                                        height: 25,
                                        backgroundColor: '#3A3E3F',
                                        borderRadius: '8px'
                                    }

                                }}>
                                {customerList.map((row) => (
                                    <StyledTableRow className={classes.customTableRow} key={row.uuid}
                                        sx={{
                                            height: 25,
                                            '& > *': {
                                                height: 25,
                                                border: 'unset'

                                            }
                                        }}>
                                        <TableCell>
                                            <Avatar variant='rounded' alt=' ' src={(row?.company_logo_url) ? row?.company_logo_url : ' '} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{
                                                color: '#FFFFFF', fontSize: 14, display: 'flex',
                                                alignItems: 'center', fontWeight: 700
                                            }}>{row.company_name}</Typography>
                                            <Typography sx={{ color: '#656565', fontSize: 12 }}>{row.work_location}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{
                                                color: '#FFFFFF', fontSize: 14, display: 'flex',
                                                alignItems: 'center', fontWeight: 700
                                            }}>{row.email}</Typography>
                                            <Typography sx={{ color: '#656565', fontSize: 12 }}>{row.phone_number}</Typography>
                                        </TableCell>
                                        <TableCell><Typography sx={{ color: '#FFFFFF', fontSize: 14 }}> {'12-01-2024'}</Typography></TableCell>
                                        <TableCell>
                                            {'12-01-2024'}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <div>
                                                <IconButton
                                                    id="client-list-menu"
                                                    aria-controls={menuOpen ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={menuOpen ? 'true' : undefined}
                                                    onClick={(event) => { handleClientListClick(event, row.uuid, row.company_name) }}
                                                >
                                                    <MoreVert sx={{ color: '#FFFFFF' }} />
                                                </IconButton>
                                                <Menu
                                                    id="client-list-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={menuOpen}
                                                    onClose={handleClientListClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >

                                                    <MenuItem onClick={() => { handleEditCustomer(); }} >Edit Account</MenuItem>

                                                    <MenuItem onClick={() => { handleCreateJob(); }}>Create Job</MenuItem>
                                                </Menu>
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {(!customerList || customerList.length === 0) && (
                            <Grid container direction='row' justifyContent='center' alignItems='center' alignContent='center'>
                                <Grid item mb={4}>
                                    <div className='no-user-found'>
                                        <div>
                                            <img src="/assets/img/no-user-found.svg" alt="" />
                                            <Typography className='user-heading' style={{ fontWeight: 400, size: '24px', color: '#FFFFFF', fontFamily: 'Poppins', }}>No Customers added</Typography>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}

                        {/*                         </div>
 */}                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                        style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '55%' }}>
                        <Grid item ml={2}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                                {`Showing ${startIndex}-${endIndex} of ${customerCount} Customers`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Pagination
                                component="div"
                                rowsPerPage={10}
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        width: '30px',
                                        height: '30px',
                                        border: '1px solid #FFFFFF',
                                        background: '#3A3E3F',
                                        color: '#FFFFFF',
                                        margin: '0 4px',
                                        '&.Mui-selected': {
                                            background: '#FFC540',
                                            color: '#3A3E3F',
                                        },
                                    },
                                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-page:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                        width: '100px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        border: '1px solid #FFFFFF',
                                        color: '#FFFFFF',
                                        background: '#3A3E3F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                }}

                                renderItem={(item) => (
                                    <PaginationItem {...item} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >

        </>
    );
};
