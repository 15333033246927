import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, FormControl, TextField, MenuItem, FormControlLabel, InputLabel, Button, FormGroup, Stack, InputAdornment, LinearProgress, IconButton, createTheme, ThemeProvider, CircularProgress, ListSubheader } from '@mui/material';
import { makeStyles } from "@mui/styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import { useFieldArray } from 'react-hook-form';
import { ArrowRight, ChevronRight, Close } from '@mui/icons-material';
//import { env } from '../../../env';
import axios from 'axios';
import { flexStyles } from '../../../component/theme/theme';
import { BACKUP_FORMATS, MEDIA_TYPES } from '../../../utils/constants';
import { getApiUrl } from "./../../../global";

const adminTapeTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.fileUploadBtn': {
                        height: '64px',
                        width: '164px',
                        borderRadius: '8px',
                        border: '1px solid #FFFFFF',
                        color: '#FFFFFF',
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                        lineHeight: '24px'
                    }
                }
            }
        }
    }
});

const mediaTypes = MEDIA_TYPES;

const backupFormats = BACKUP_FORMATS;

const allowedMimeTypes = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

const FileInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const AdminTapeForm = (props) => {

    const { fields: tapeFields, append: appendTape, prepend, remove: removeTape, swap, move, insert } = useFieldArray({
        control: props.control,
        name: 'tape.tapeDetails'
    });

    const classes = useStyles();
    let _orderUUID = window?.location?.href?.split("uuid=")[1];
    const [tapeDetails, setTapeDetails] = []; //TODO evaluate controlled vs local

    const [numberOfBoxes, setNumberOfBoxes] = React.useState(0);

    const [typeOfBackup, setTypeOfBackup] = React.useState('virtualize');
    const handleChangeTypeOfBackup = (event, newTypeOfBackup) => {
        if (newTypeOfBackup !== null) {
            props.setValue('tape.backupType', newTypeOfBackup);
        }    
    };
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [accountOptions, setAccountOptions] = React.useState('enterprise');
    const handleChangeAccountOptions = (event, newAccountOptions) => {
        setAccountOptions(newAccountOptions);
    };

    const [volserAvailable, setVolserAvailable] = React.useState('yes');
    const handleChangeVolserAvailable = (event, newVolserAvailable) => {
        if (newVolserAvailable !== null) {
            props.setValue('tape.volserAvailable', newVolserAvailable);
        }
    };


    //Document upload

    const [tLuuid, setTLuuid] = useState(null);
    const [vMuuid, setVMuuid] = useState(null);
    useEffect(() => {
        if(props.watch('tape.orderdocuments.tLuuid')){
            setTLuuid(props.watch('tape.orderdocuments.tLuuid'));
        };
        if(props.watch('tape.orderdocuments.vMuuid')){
            setVMuuid(props.watch('tape.orderdocuments.vMuuid'));
        }
    },[props.watch('tape.orderdocuments.tLuuid'), props.watch('tape.orderdocuments.vMuuid')]);

    useEffect(() => {
        if (props.watch('tape.orderdocuments.tapeList')) {
            setTapeListFile(props.watch('tape.orderdocuments.tapeList'))
        };
        if (props.watch('tape.orderdocuments.virtualMachine')) {
            setVirtualMachineFile(props.watch('tape.orderdocuments.virtualMachine'))
        };

    }, [props.watch('tape.orderdocuments.tapeList'), props.watch('tape.orderdocuments.virtualMachine')]);

    const controller = new AbortController();

    const [tapeListFile, setTapeListFile] = useState(null);
    const [tapeListFileProgress, setTapeListFileProgress] = useState(0);

    const [virtualMachineFile, setVirtualMachineFile] = useState(null);
    const [virtualMachineFileProgress, setVirtualMachineFileProgress] = useState(0);

    const handleTapeListFileSelect = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let selectedFile = e.target.files[0];
            //if (allowedMimeTypes.includes(selectedFile.type)){
            setTapeListFile(selectedFile);
            props.setValue('tape.orderdocuments.tapeList', selectedFile);
            uploadTapeListFile();
            //}          
        }
    };

    const removeTapeListFileAttachment = async () => {
        const uuid = tLuuid;
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/documents/' + uuid + '/';
        if (uuid) {
            axios.delete(apiUrl).then((res) => {
                props.setValue('tape.orderdocuments.tapeList', null);
                setTapeListFile(null);// +
            }).catch(err => console.log(err));
        };
    };

    const handleVirtualMachineFileSelect = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let selectedFile = e.target.files[0];
            setVirtualMachineFile(selectedFile);
            props.setValue('tape.orderdocuments.virtualMachine', selectedFile);
            uploadVirtualMachineFile();
        }
    };

    const uploadTapeListFile = async () => {
        let url = await getApiUrl();

        const tapeListFile = props.watch('tape.orderdocuments.tapeList');
        const orderId = props.watch('orderId');
        if (_orderUUID !== "undefined") {
            let documentPayload;

            let reader = new FileReader();
            reader.onload = function () {

                const base64String = reader.result;
                documentPayload = {
                    "name": tapeListFile.name,
                    "file_type": 'tape_list',
                    "order": _orderUUID,
                    "file": base64String.split(',')[1],
                };

                const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/documents/';

                axios.post(apiUrl, documentPayload, {
                    onUploadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                        setTapeListFileProgress(percentCompleted);
                    },
                    signal: controller.signal
                }).then((res) => {
                    props.setValue('tape.orderdocuments.tapeList', tapeListFile);
                    props.setValue('tape.orderdocuments.tLuuid', res?.data?.uuid);//-
                    console.log(res);
                }).catch((err) => {
                    console.log(err);
                    setTapeListFileProgress(0);
                    props.setValue('tape.orderdocuments.tapeList', null);
                    setTapeListFile(null);

                });

            }
            reader.readAsDataURL(tapeListFile);
        }


    };

    const uploadVirtualMachineFile = async () => {
        let url = await getApiUrl();

        const virtualMachineFile = props.watch('tape.orderdocuments.virtualMachine');
        const orderId = props.watch('orderId');
        if (_orderUUID !== 'undefined') {
            let documentPayload;

            let reader = new FileReader();
            reader.onload = function () {

                const base64String = reader.result;
                documentPayload = {
                    "name": virtualMachineFile.name,
                    "file_type": 'virtual_machine',
                    "order": _orderUUID,
                    "file": base64String.split(',')[1],
                };

                const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/documents/';

                axios.post(apiUrl, documentPayload, {
                    onUploadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                        setVirtualMachineFileProgress(percentCompleted);
                    },
                    signal: controller.signal
                }).then((res) => {
                    props.setValue('tape.orderdocuments.virtualMachine', virtualMachineFile);
                    props.setValue('tape.orderdocuments.vMuuid', res?.data?.uuid);//-
                    console.log(res);
                }).catch((err) => {
                    console.log(err);
                    setVirtualMachineFileProgress(0);
                    props.setValue('tape.orderdocuments.virtualMachine', null);
                    setVirtualMachineFile(null);
                });

            }
            reader.readAsDataURL(virtualMachineFile);
        }
    }
    const uploadVirtualMachineLink = async () => {
        let url = await getApiUrl();

        const vMLink = props.watch('tape.orderdocuments.vMLink');
        const orderId = props.watch('orderId');

        if (vMLink && (_orderUUID !== 'undefined')) {
            axios.get(vMLink, {responseType: 'blob'}).then((res) => {
                let reader = new FileReader();
                reader.onload = function () {
    
                    const base64String = reader.result;
                    const documentPayload = {
                        "name": (res?.name) ? res?.name : vMLink.split("\\")[-1],
                        "file_type": 'virtual_machine',
                        "order": _orderUUID,
                        "file": base64String.split(',')[1],
                    };
    
                    const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/documents/';
    
                    axios.post(apiUrl, documentPayload, {
                        onUploadProgress: (progressEvent) => {
                            let percentCompleted = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                            setVirtualMachineFileProgress(percentCompleted);
                        },
                        signal: controller.signal
                    }).then((res) => {
                        props.setValue('tape.orderdocuments.virtualMachine', virtualMachineFile);
                        props.setValue('tape.orderdocuments.vMuuid', res?.data?.uuid);//-
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                        setVirtualMachineFileProgress(0);
                        props.setValue('tape.orderdocuments.virtualMachine', null);
                        setVirtualMachineFile(null);
                    });
    
                }
                reader.readAsDataURL(res?.data);

            }).catch(err => console.log(err));
        }

    };
    const removeVirtualMachineFileAttachment = async () => {
        let url = await getApiUrl();

        const uuid = vMuuid;
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/documents/' + uuid + '/';
        if (uuid) {
            axios.delete(apiUrl).then((res) => {
                props.setValue('tape.orderdocuments.virtualMachine', null);
                setVirtualMachineFile(null);
            }).catch(err => console.log(err));
        };
    };

    const deleteTape = async (index) => {
        let url = await getApiUrl();

        const uuid = props.watch(`tape.tapeDetails[${index}].uuid`);
        const tapeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/tapes/" + uuid + '/';
        if (uuid) {
            axios.delete(tapeApiUrl).
                then((res) => {
                    console.log(res);
                    if (tapeFields.length === 1) {
                        props.setValue(`tape.tapeDetails[0]`, { tapeType: '', number: 0, tapeFormat: '', uuid: '' });
                    } else {
                        removeTape(index);
                    }
                }).catch(err => console.log(err));
        } else {
            if (tapeFields.length === 1) {
                return;
            }
            removeTape(index);
        }
    }

    return (
        <ThemeProvider theme={adminTapeTheme}>
            <Grid
                container
                style={{
                    border: "1px solid #C7CCD0",
                    borderRadius: "8px",
                    marginTop: "20px",
                    minHeight: "100vh",
                    height: "max-content",
                    marginBottom: '100px',
                    alignItems: "start",
                    display: "block",
                }}
            >
                <Grid container direction={"column"}>
                    {/* Stepper */}
                    <Grid item>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                border: "8px 8px 0px 0px",
                                border: "#272727",
                                padding: "20px",
                                background: "#272727",
                                borderRadius: "8px 8px 0px 0px",
                            }}
                        >
                            <Typography className={classes.shippingheading}>
                                Media Details
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
                    <Grid container direction='column' spacing={2} xs={12}>
                        <Grid item>
                            {tapeFields.map((field, index) => (
                                <Grid key={field.id} container direction='row' justifyContent='flex-start' spacing={2} mb={1}>
                                    <Grid item>
                                        <Typography align='left' className={classes.heading}>Media Type</Typography>
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                {...props.register(`tape.tapeDetails[${index}].tapeType`)}
                                                name={`tape.tapeDetails[${index}].tapeType`}
                                                className={classes.selectLabel}
                                                defaultValue={field.tapeType}
                                                sx={{ width: '15ch', }}
                                                required
                                                select>
                                                {mediaTypes.map(mediaType => {
                                                    if (mediaType.type === 'subheader') {
                                                        // return (<ListSubheader sx={{background: '#4e5152', color: 'rgba(255,255,255,0.5)'}}>{mediaType.label}</ListSubheader>)
                                                    } else {
                                                        return (<MenuItem key={mediaType.key} value={mediaType.key}>{mediaType.label}</MenuItem>)
                                                    }
                                                })}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1} xs={1}>
                                        <Typography align='left' className={classes.heading}>Number</Typography>
                                        <TextField
                                            {...props.register(`tape.tapeDetails[${index}].number`)}
                                            type='number'
                                            name={`tape.tapeDetails[${index}].number`}
                                            defaultValue={field.number}
                                            className={classes.selectLabel} id="outlined-basic"
                                            required
                                            variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid item md={3} xs={3}>
                                        <Typography align='left' className={classes.heading}>Backup Format</Typography>
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                className={classes.selectLabel}
                                                {...props.register(`tape.tapeDetails[${index}].tapeFormat`)}
                                                name={`tape.tapeDetails[${index}].tapeFormat`}
                                                defaultValue={field.tapeFormat}
                                                // sx={{ width: '15ch', }}
                                                required
                                                select>
                                                {backupFormats.map(backupFormat => (
                                                    <MenuItem key={backupFormat.key} value={backupFormat.key}>{backupFormat.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Box mt={3}>
                                            <Button
                                                className={classes.plusMinBtn}
                                                variant="outlined"
                                                onClick={() => { deleteTape(index) }}
                                            >
                                                <DeleteOutlineIcon sx={{ color: '#FFFFFF' }} />
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item sx={{visibility: (index === tapeFields.length -1)?'visible':'hidden'}}>
                                        <Box mt={3}>
                                            <Button
                                                className={classes.plusMinBtn}
                                                variant="outlined"
                                                onClick={() => appendTape({ tapeType: '', number: 0, tapeFormat: '', uuid: '' })}
                                            >
                                                <AddIcon sx={{ color: '#FFFFFF' }} />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item md={3} xs={12} sx={{ width: '17.5%' }}>
                            <Typography align='left' className={classes.heading}>Number of Boxes Required (Max 30 tapes in a box)</Typography>
                        </Grid>
                        <Grid item md={3} xs={12} sx={{ width: '10%' }}>
                            <FormControl fullWidth={true} {...props.register(`tape.numberOfBoxes`)}>
                                <TextField
                                    className={classes.selectLabel}
                                    name={`tape.numberOfBoxes`}
                                    //defaultValue={}
                                    type='number'>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align='left' className={classes.heading}>Type of Service</Typography>
                        </Grid>
                        <Grid item  md={3} xs={12} display={'flex'} justifyContent={'flex-start'}>
                            <ToggleButtonGroup
                                color="primary"
                                name='tape.backupType'
                                value={props.watch('tape.backupType')}
                                onChange={handleChangeTypeOfBackup}
                                exclusive
                                aria-label="Platform"
                                style={{
                                    borderRadius: "7px",
                                    border: "1px solid #7B809A",
                                    background: "var(--Charcoal, #3A3E3F)",
                                }}
                            >
                                <ToggleButton value="audit_only" className="h-40" >
                                    Audit Only
                                </ToggleButton>
                                <ToggleButton value="virtualize" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }} >
                                    Virtualize
                                </ToggleButton>
                                <ToggleButton value="restore" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Restore
                                </ToggleButton>
                                <ToggleButton value="destruction" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Destruction
                                </ToggleButton>
                                <ToggleButton value="other" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Other
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align='left' className={classes.heading}>Account Options</Typography>
                        </Grid>
                        <Grid item md={3} xs={12} display={'flex'} justifyContent={'flex-start'}>
                            <ToggleButtonGroup
                                color="primary"
                                name='tape.accountOptions'
                                value={props.watch('tape.accountOptions')}
                                onChange={(event, newValue) => props.setValue('tape.accountOptions', newValue)}
                                exclusive
                                aria-label="Platform"
                                style={{
                                    borderRadius: "7px",
                                    border: "1px solid #7B809A",
                                    background: "var(--Charcoal, #3A3E3F)",
                                }}
                            >
                                <ToggleButton value="enterprise" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Enterprise
                                </ToggleButton>
                                <ToggleButton value="gov_cloud" className="h-40" >
                                    Gov't. Cloud
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align='left' className={classes.heading}>Volser List Available</Typography>
                        </Grid>
                        <Grid item md={3} xs={12} display={'flex'} justifyContent={'flex-start'}>
                            <ToggleButtonGroup
                                color="primary"
                                name={'tape.volser_available'}
                                value={props.watch('tape.volserAvailable')}
                                onChange={handleChangeVolserAvailable}
                                exclusive
                                aria-label="Platform"
                                style={{
                                    borderRadius: "7px",
                                    border: "1px solid #7B809A",
                                    background: "var(--Charcoal, #3A3E3F)",
                                }}
                            >
                                <ToggleButton value={true} className="h-40" >
                                    Yes
                                </ToggleButton>
                                <ToggleButton value={false} className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    No
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item>
                            <Typography align='left' mt={4} mb={2} className={classes.heading}>Uploads</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='flex-start' alignItems={'center'}>
                                <Grid item xs={2}>
                                    <Typography align='left' className={classes.heading2}>Tape List</Typography>
                                </Grid>
                                <Grid item xs={10} sx={flexStyles.flexRowStart}>
                                    {(tapeListFile) ? (
                                        (tapeListFileProgress > 0 && tapeListFileProgress < 100) ? (
                                            <Stack direction={'row'} width={'100%'} alignItems={'center'}>
                                                <LinearProgress variant='determinate' value={tapeListFileProgress} sx={{
                                                    backgroundColor: '#3A3E3F',
                                                    height: 15,
                                                    borderRadius: 8,
                                                    width: '25%',
                                                    border: '2px solid #FFFFFF',
                                                    '& .MuiLinearProgress-root': {
                                                        borderColor: '#FFFFFF',
                                                    },
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: '#5F5F5F',
                                                    }
                                                }} />
                                                <IconButton onClick={() => { controller.abort(); removeTapeListFileAttachment(); }}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                                            </Stack>
                                        ) : (
                                            <Stack direction={'row'} sx={{
                                                height: '64px',
                                                width: '218px',
                                                borderRadius: '8px',
                                                background: '#C7CCD0',
                                                color: '#3A3E3F',
                                                alignItems: 'center',
                                            }}>
                                                <Typography width={'90%'} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}>{tapeListFile?.name}</Typography>
                                                <IconButton width={'10%'} onClick={() => { removeTapeListFileAttachment() }}><Close /></IconButton>
                                            </Stack>
                                        )
                                    ) : (
                                        <Button
                                            component='label'
                                            variant='outlined'
                                            className='fileUploadBtn'>
                                            Upload File
                                            <FileInput
                                                type='file'
                                                name='tape.orderdocuments.tapeList'
                                                //{...props.register('tape.orderdocuments.tapeList')} 
                                                multiple
                                                onChange={handleTapeListFileSelect} />
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='flex-start' alignItems={'center'}>
                                <Grid item xs={2}>
                                    <Typography align='left' className={classes.heading2}>Virtual Machine</Typography>
                                </Grid>
                                <Grid item xs={3} sx={flexStyles.flexRowStart}>
                                    {(virtualMachineFile) ? (
                                        (virtualMachineFileProgress > 0 && virtualMachineFileProgress < 100) ? (
                                            <Stack direction={'row'} width={'100%'} alignItems={'center'}>
                                                <LinearProgress variant='determinate' value={virtualMachineFileProgress} sx={{
                                                    backgroundColor: '#3A3E3F',
                                                    height: 15,
                                                    borderRadius: 8,
                                                    width: '100%',
                                                    border: '2px solid #FFFFFF',
                                                    '& .MuiLinearProgress-root': {
                                                        borderColor: '#FFFFFF',
                                                    },
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: '#5F5F5F',
                                                    }
                                                }} />
                                                <IconButton onClick={() => { controller.abort(); removeVirtualMachineFileAttachment(); }}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                                            </Stack>
                                        ) : (
                                            <Stack direction={'row'} sx={{
                                                height: '64px',
                                                width: '218px',
                                                borderRadius: '8px',
                                                background: '#C7CCD0',
                                                color: '#3A3E3F',
                                                alignItems: 'center',
                                                gap: 2,
                                                justifyContent: 'center',
                                            }}>
                                                <Typography width='90%' sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}>{virtualMachineFile?.name}</Typography>
                                                <IconButton width='10%' onClick={() => { removeVirtualMachineFileAttachment() }}><Close /></IconButton>
                                            </Stack>
                                        )
                                    ) : (
                                        <Button
                                            component='label'
                                            variant='outlined'
                                            className='fileUploadBtn'>
                                            Upload File
                                            <FileInput type='file'
                                                name='tape.orderdocuments.virtualMachine'
                                                //{...props.register('tape.orderdocuments.virtualMachine')} 
                                                multiple
                                                onChange={handleVirtualMachineFileSelect} />
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{
                                        lineHeight: '24px',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Poppins',
                                        color: '#FFFFFF',
                                        textAlign: 'center',
                                    }}>Or</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Stack direction={'row'}>
                                        <TextField
                                            sx={{
                                                width: '30ch',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderRadius: '8px 0px 0px 8px',
                                                        borderRight: 'unset',
                                                        borderColor: '#C7CCD0',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#C7CCD0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#C7CCD0',
                                                    },
                                                    '&.Mui-disabled fieldset': {
                                                        borderColor: '#C7CCD0',
                                                    }
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    color: '#FFFFFF',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'rgba(255, 255, 255, 0.5)',
                                                    '&.Mui-focused': {
                                                        color: '#FFC540'
                                                    },
                                                },
                                            }}
                                            label='Add Link'
                                            variant="outlined"
                                            name='tape.orderdocuments.vMLink'
                                            disabled={virtualMachineFile !== null}
                                            {...props.register('tape.orderdocuments.vMLink')}
                                        />
                                        <Button
                                            onClick={uploadVirtualMachineLink}
                                            className={classes.vMLinkButton} variant="contained"><ChevronRight sx={{ color: '#FFFFFF' }} /></Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*                         <Grid item>
                            <Grid container direction='row' justifyContent='flex-start'  alignItems={'center'}>
                                <Grid item xs={2}>
                                    <Typography align='left' className={classes.heading2}>Encryption Keys</Typography>
                                </Grid>
                                <Grid item xs={10} sx={flexStyles.flexRowStart}>
                                    <Button variant='outlined' className='fileUploadBtn'>Upload File</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justifyContent='flex-start'  alignItems={'center'}>
                                <Grid item xs={2}>
                                    <Typography align='left' className={classes.heading2}>S3 Amazon Account</Typography>
                                </Grid>
                                <Grid item xs={10} sx={flexStyles.flexRowStart}>
                                    <Button variant='outlined' className='fileUploadBtn'>Upload File</Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Box>
            </Grid >
        </ThemeProvider>
    )
}
export const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: "#ffc540",
        // borderRadius: "8px",
        color: "#3a3e3f",
        fontWeight: 600,
        fontSize: "14px",
    },
    "&.MuiToggleButton-root": {
        // color: "#404558",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "5em !important",
        fontSize: "14px",
        width: "164px",
        // border-radius: 8px;
        background: "#3a3e3f",
        color: " #C7CCD0",
        fontFamily: '"Poppins", sans-serif !important',
    },
    "&.shippingSelection": {
        height: "64px !important",
    }
});
const useStyles = makeStyles({
    selectLabel: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
    shippingheading: {
        color: "#fff",
        fontSize: "20px !important",
        fontWeight: "700 !important",
        fontFamily: '"Poppins", sans-serif !important',
    },
    plusMinBtn: {
        borderRadius: "3",
        border: " 1px solid #7B809A  !important",
        background: "var(--Charcoal, #3A3E3F)",
        width: "43px !important",
        height: "52px",
        minWidth: "47px !important",
    },
    heading: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#C7CCD0',
        lineHeight: '24px',
        fontFamily: 'Poppins !important',
    },
    heading2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#FFFFFF',
        lineHeight: '24px',
        fontFamily: 'Poppins !important',
    },
    selectLabel: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
    vMLinkButton: {
        background: '#5F5F5F !important',
        borderRadius: '0px 11px 11px 0px !important',
        color: '#3A3E3F !important',
        textTransform: "none !important",
    },
});