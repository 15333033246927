import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { Requests } from "../../component/request/request";
import { Messages } from "../../component/messages/messages";
import { Audit } from "../../component/audit/audit";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Ingestion } from "../../component/ingestion/ingestion";
//import { env } from "../../env";
import { getApiUrl } from "./../../global";

import { Details } from "../../component/details/details";
import { StyledButtonOutlined } from "../Admin/AccountCreation/accountCreation";

export const OrderTracking = () => {
  const staticState = [
    // {
    //   key: "created", value: "Created", width: '10%'
    // },

    // {
    //   key: "shipment_started", value: "Shipment Started", width: '30%'
    // }, {
    //   key: "shipment_reached", value: "Shipment Reached", width: '40%'
    // }, {
    //   key: "boxes_delivered", value: "Boxes Delivered", width: '50%'
    // }, {
    //   key: "tapes_picked_up", value: "Tapes Picked Up", width: '60%'
    // }, {
    //   key: "tapes_delivered", value: "Tapes Delivered", width: '70%'
    // },
    {
      key: "ingestion_complete",
      value: "Ingestion Complete",
      width: "100%",
    },
    {
      key: "ingestion_started",
      value: "Ingestion Started",
      width: "100%",
    },
    {
      key: "audit_complete",
      value: "Audit Complete",
      width: "90%",
    },
    {
      key: "audit_started",
      value: "Audit Started",
      width: "80%",
    },
    {
      key: "verified_and_completed",
      value: "Verified & Completed",
      width: "20%",
    },
    {
      key: "out_for_delivery",
      value: "Out For Delivery",
      width: "20%",
    },
    {
      key: "shipment_collected",
      value: "Shipment Collected",
      width: "20%",
    },
    {
      key: "courier_en_route",
      value: "Courier En route",
      width: "20%",
    },
    {
      key: "preparing_for_shipment",
      value: "Preparing For Shipment",
      width: "20%",
    },
    // , {
    //   key: "request_received", value: "Request Received", width: '20%'
    // },
  ];
  const [activeHStep, setActiveHStep] = React.useState(1);
  const location = useLocation();
  // const data = location.state?.data;
  // console.log(data);
  // "/assets/json/getOrderUUID"
  // get ID from url
  const params = useParams();
  // console.log(params); //

  const [orderDetail, setOrderDetail] = React.useState({});
  useEffect(() => {
    getOrderDetail();
  }, []);
  const getOrderDetail = async () => {
    let url = await getApiUrl();
    const apiUrl =
      url + process.env.REACT_APP_API_BASE_URL + "/orders/" + params.orderId;

    axios
      .get(apiUrl)
      .then((data) => {
        const key = "status";
        data.data["_order_states"] = JSON.parse(
          JSON.stringify(data.data.order_states)
        );
        data.data.order_states = [
          ...new Map(
            data.data.order_states.map((item) => [item[key], item])
          ).values(),
        ];
        if (data.data.order_states.length < staticState.length) {
          staticState.forEach((e) => {
            let orderState = data.data._order_states.find(
              (elements) => elements.state == e.key
            );
            if (orderState?.state) {
              e["timestamp"] = orderState.timestamp;
              e["message"] = orderState.message;
              e["status"] = orderState.state;
            } else {
              e["timestamp"] = "";
              e["message"] = "";
              e["status"] = e.key;
            }
          });
          data.data.order_states = staticState;
        }
        setOrderDetail(data.data);
        // setTimeout(() => {
        setActiveHStep(getHActiveStep(data.data));
        // }, 100);
      })
      .catch((error) => console.log(error));
  };
  function getHActiveStep(_orderDetail) {
    let horizontalStepperActiveStep = 1;
    if (_orderDetail?.order_number) {
      if (
        _orderDetail.status.toLowerCase() === "verified_and_completed" ||
        _orderDetail.status.toLowerCase() === "audit_started"
      ) {
        horizontalStepperActiveStep = 2;
      } else if (
        _orderDetail.status.toLowerCase() === "audit_complete" ||
        _orderDetail.status.toLowerCase() === "ingestion_started" ||
        _orderDetail.status.toLowerCase() === "ingestion_complete"
      ) {
        horizontalStepperActiveStep = 3;
      }
    }
    return horizontalStepperActiveStep;
  }
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 40,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: "background(180deg, #EEAB10 0%, #FFC133 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EAEAEA",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "#fff",
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active &&
      {
        // backgroundImage:
        //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      }),
    ...(ownerState.completed && {
      background: "#ffc54021",
      color: "#FFC540;",
      // opacity: 0.5,
    }),
  }));
  function getStepValue() {
    let horizontalStepper = {
      1: (
        <>
          <div className="horizontalStepperIcon_Completed">
            <CheckIcon></CheckIcon>
          </div>
          <div className="progressBarText progressBarText-orderPlaced">
            Order
            <br /> Placed
          </div>
        </>
      ),
      2: (
        <>
          <div>2</div>
          <div className="progressBarText progressBarText-shipping">
            Shipping
          </div>
        </>
      ),
      3: (
        <>
          <div>3</div>
          <div className="progressBarText progressBarText-audit">Audit</div>
        </>
      ),
      4: (
        <>
          <div>4</div>
          <div className="progressBarText progressBarText-ingestion">
            Ingestion
          </div>
        </>
      ),
    };
    if (orderDetail?.order_number) {
      if (
        orderDetail.status.toLowerCase() === "verified_and_completed" ||
        orderDetail.status.toLowerCase() === "audit_started"
      ) {
        horizontalStepper = {
          1: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-orderPlaced">
                Order
                <br /> Placed
              </div>
            </>
          ),
          2: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-shipping">
                Shipping
              </div>
            </>
          ),
          3: (
            <>
              <div>3</div>
              <div className="progressBarText progressBarText-audit">Audit</div>
            </>
          ),
          4: (
            <>
              <div>4</div>
              <div className="progressBarText progressBarText-ingestion">
                Ingestion
              </div>
            </>
          ),
        };
      } else if (
        orderDetail.status.toLowerCase() === "audit_complete" ||
        orderDetail.status.toLowerCase() === "ingestion_started"
      ) {
        horizontalStepper = {
          1: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-orderPlaced">
                Order
                <br /> Placed
              </div>
            </>
          ),
          2: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-shipping">
                Shipping
              </div>
            </>
          ),
          3: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-audit">Audit</div>
            </>
          ),
          4: (
            <>
              <div>4</div>
              <div className="progressBarText progressBarText-ingestion">
                Ingestion
              </div>
            </>
          ),
        };
      } else if (orderDetail.status.toLowerCase() === "ingestion_complete") {
        horizontalStepper = {
          1: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-orderPlaced">
                Order
                <br /> Placed
              </div>
            </>
          ),
          2: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-shipping">
                Shipping
              </div>
            </>
          ),
          3: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-audit">Audit</div>
            </>
          ),
          4: (
            <>
              <div className="horizontalStepperIcon_Completed">
                <CheckIcon></CheckIcon>
              </div>
              <div className="progressBarText progressBarText-ingestion">
                Ingestion
              </div>
            </>
          ),
        };
      }
    }
    return horizontalStepper;
  }
  function ColorlibStepIcon(props) {
    const icons = getStepValue();
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  function getActiveStep() {
    let horizontalStepperActiveStep = [
      { label: "step-orderPlaced", status: "horizontalStepper-completed" },
      { label: "step-shipping", status: "horizontalStepper-active" },
      { label: "step-audit", status: "horizontalStepper-pending" },
      { label: "step-ingestion", status: "horizontalStepper-pending" },
    ];
    if (orderDetail?.order_number) {
      if (
        orderDetail.status.toLowerCase() === "verified_and_completed" ||
        orderDetail.status.toLowerCase() === "audit_started"
      ) {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-active" },
          { label: "step-ingestion", status: "horizontalStepper-pending" },
        ];
      } else if (
        orderDetail.status.toLowerCase() === "audit_complete" ||
        orderDetail.status.toLowerCase() === "ingestion_started"
      ) {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-completed" },
          { label: "step-ingestion", status: "horizontalStepper-active" },
        ];
      } else if (orderDetail.status.toLowerCase() === "ingestion_complete") {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-completed" },
          { label: "step-ingestion", status: "horizontalStepper-completed" },
        ];
      }
    }
    return horizontalStepperActiveStep;
  }
  const steps = getActiveStep();
  const handleStep = (step) => () => {
    if (orderDetail?.order_number) {
      if (
        orderDetail.status.toLowerCase() === "verified_and_completed" ||
        orderDetail.status.toLowerCase() === "audit_started"
      ) {
        if (step != 3) {
          setActiveHStep(step);
        }
      } else if (
        orderDetail.status.toLowerCase() === "audit_complete" ||
        orderDetail.status.toLowerCase() === "ingestion_started"
      ) {
        setActiveHStep(step);
      } else if (orderDetail.status.toLowerCase() === "ingestion_complete") {
        setActiveHStep(step);
      }
    }
  };
  let width = window.innerWidth;
  console.log(width);
  return (
    <>
      <Box p={4}>
        <div className="breadcrumb">
          <img src="/assets/img/cart.svg" alt="" />
          <img src="/assets/img/arrow_left.svg" alt="" />
          <Link
            to="/CustomerOrders"
            passHref
            style={{ textDecoration: "none" }}
          >
            {" "}
            <small className="grey-color">Your Orders</small>
          </Link>
          <img src="/assets/img/arrow_left.svg" alt="" />
          <small className="grey-color">{orderDetail.order_number}</small>
        </div>
        <div className="heading">
          <h1>{orderDetail.order_number}</h1>
        </div>
        <Box mb={2}>
          <hr />
        </Box>
        <div className="tab-section">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                sx={{
                  "& .MuiTabs-scroller": {
                    background:
                      "linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)",
                  },
                  "& .MuiTabs-indicator": {
                    background: "#3A3E3F",
                    mb: "10px",
                  },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="tab-bg"
              >
                <Tab
                  sx={{
                    "&.MuiTab-root": {
                      color: "#5F5F5F",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    },
                  }}
                  label="Tracking"
                  value="1"
                />
                <Tab
                  sx={{
                    "&.MuiTab-root": {
                      color: "#5F5F5F",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    },
                  }}
                  label="Details"
                  value="2"
                />
                <Tab
                  sx={{
                    "&.MuiTab-root": {
                      color: "#5F5F5F",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    },
                  }}
                  label="Uploads"
                  value="3"
                />
                {/* <Tab sx={{
                  '&.MuiTab-root': {
                    color: '#5F5F5F',
                    fontFamily: "Poppins",
                    fontSize: '14px',
                    fontWeight: 700,
                    textTransform: 'capitalize',
                  },
                }} label="Messages" value="4" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container direction="column">
                <div
                  className="stepper-section"
                  style={{ marginLeft: width > 2000 ? "-11%" : "-7.5%" }}
                >
                  <Stack sx={{ width: "100%" }}>
                    <Grid item>
                      <Stepper
                        alternativeLabel
                        activeStep={1}
                        connector={<ColorlibConnector />}
                      >
                        {steps.map((step, index) => (
                          <Step
                            key={step.label}
                            className={step.label + " " + step.status}
                          >
                            <StepLabel
                              onClick={handleStep(index)}
                              StepIconComponent={ColorlibStepIcon}
                            ></StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Stack>
                </div>
                <Grid item>
                  {(activeHStep == 0 || activeHStep == 1) && (
                    <VerticalLinearStepper orderDetail={orderDetail} />
                  )}
                  {activeHStep == 2 && (
                    <Audit value={60} orderDetail={orderDetail} />
                  )}
                  {activeHStep == 3 && <Ingestion orderDetail={orderDetail} />}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Details orderDetail={orderDetail} />
            </TabPanel>
            <TabPanel value="3">
              <Requests />
            </TabPanel>
            <TabPanel
              sx={{ paddingTop: "0px", paddingBottom: "0px" }}
              value="4"
            >
              <Messages />
            </TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  );
};

function getAddressDetails(shipments, currentState) {
  let _shipments = JSON.parse(JSON.stringify(shipments));
  _shipments = _shipments.filter((element) =>
    currentState == element.status ? true : false
  );
  if (_shipments?.length) {
    return _shipments.map((element) => {
      return {
        ...element,
        // name: element.contact_name,
        location: element.shipping_address.address_line_1,
        city: element.shipping_address.state,
        pinCode: element.shipping_address.postcode,
        status: "50",
        name: element?.first_name + " " + element?.last_name,
        // location: element?.address,
        // city: element?.city,
        // pinCode: element?.postcode,
        // status: '50'
      };
    });
  } else {
    return undefined;
  }
}
function getSteps(orderDetail) {
  if (orderDetail._order_states.length) {
    let currentIndex = orderDetail._order_states
      .map((e) => e.status.toLowerCase())
      .indexOf(orderDetail.status.toLowerCase());
    return orderDetail._order_states.map((e, i) => {
      return {
        ...e,
        id: i,
        label: mapOrderStep(e.status),
        description: e.message,
        date: e?.timestamp
          ? moment(e?.timestamp).format("MMM/DD/YYYY")
          : e?.timestamp,
        status:
          currentIndex > i ||
          orderDetail.status.toLowerCase() == "verified_and_completed"
            ? "completed"
            : currentIndex == i
            ? "pending"
            : "disabled",
        deliveryLocations: orderDetail?.shipments?.length
          ? getAddressDetails(orderDetail?.shipments, e.status)
          : undefined,
        isVisible:
          e.status != "audit_started" &&
          e.status != "audit_complete" &&
          e.status != "ingestion_started" &&
          e.status != "ingestion_complete"
            ? true
            : false,
      };
    });
  } else {
    return [];
  }
}
let state = [
  // {
  //   key: "created", value: "Created", width: '10%'
  // },
  // {
  //   key: "request_received", value: "Request Received", width: '20%'
  // },
  {
    key: "preparing_for_shipment",
    value: "Preparing For Shipment",
    width: "20%",
  },
  {
    key: "courier_en_route",
    value: "Courier En route",
    width: "20%",
  },
  {
    key: "shipment_collected",
    value: "Shipment Collected",
    width: "20%",
  },
  {
    key: "out_for_delivery",
    value: "Out For Delivery",
    width: "20%",
  },
  {
    key: "verified_and_completed",
    value: "Verified & Completed",
    width: "20%",
  },
  // {
  //   key: "shipment_started", value: "Shipment Started", width: '30%'
  // }, {
  //   key: "shipment_reached", value: "Shipment Reached", width: '40%'
  // }, {
  //   key: "boxes_delivered", value: "Boxes Delivered", width: '50%'
  // }, {
  //   key: "tapes_picked_up", value: "Tapes Picked Up", width: '60%'
  // }, {
  //   key: "tapes_delivered", value: "Tapes Delivered", width: '70%'
  // },
  {
    key: "audit_started",
    value: "Audit Started",
    width: "80%",
  },
  {
    key: "audit_complete",
    value: "Audit Complete",
    width: "90%",
  },
  {
    key: "ingestion_started",
    value: "Ingestion Started",
    width: "100%",
  },
  {
    key: "ingestion_complete",
    value: "Ingestion Complete",
    width: "100%",
  },
];
function mapOrderStep(_state) {
  let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
  return step?.value ? step.value : _state;
}

export default function VerticalLinearStepper({ orderDetail }) {
  if (orderDetail && orderDetail.order_number) {
    // console.log(getAddressDetails(orderDetail?.shipments));
    // let states = orderDetail.order_states.filter((e) => {
    //   if (e.state != "audit_started" && e.state != "audit_complete" && e.state != "ingestion_started" && e.state != "ingestion_complete") {
    //     return true;
    //   }else{
    //     return false
    //   }
    // });
    orderDetail["_order_states"] = JSON.parse(
      JSON.stringify(orderDetail.order_states)
    );

    orderDetail["_order_states"].reverse();
    // orderDetail['_order_states'].filter((e) => {
    //   if (e.state != "audit_started" && e.state != "audit_complete" && e.state != "ingestion_started" && e.state != "ingestion_complete") {
    //     return true;
    //   } else {
    //     return false
    //   }
    // })
    // orderDetail['_order_states'].filter((e) => {
    //   if (e.state != "audit_started" && e.state != "audit_complete" && e.state != "ingestion_started" && e.state != "ingestion_complete") {
    //     return true;
    //   }else{
    //     return false
    //   }
    // })
  }
  let steps =
    orderDetail && orderDetail.order_number ? getSteps(orderDetail) : [];
  const [activeStep, setActiveStep] = React.useState(1);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const goToStep = (index) => {
    setActiveStep(index);
  };
  const viewClick = (location) => {
    if (location.tracking_link) {
      window.open(location.tracking_link, "_blank");
    } else if (location.courier_partner == "dhl") {
      // window.open('https://www.dhl.com/in-en/home/tracking.html?tracking-id=78921', '_blank');
      window.open("https://www.dhl.com/in-en/home/tracking.html", "_blank");
    } else if (location.courier_partner == "fedex") {
      window.open("https://www.fedex.com/en-in/tracking.html", "_blank");
    } else if (location.courier_partner == "ups") {
      window.open(
        "https://www.ups.com/track?loc=en_IN&requester=ST/",
        "_blank"
      );
    } else if (location.courier_partner == "mnx") {
      window.open("https://www.mnx.com/tracking/", "_blank");
    }
  };
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={1} orientation="vertical" sx={{ m: 8 }}>
        {steps.map(
          (step, index) =>
            step.isVisible && (
              <Step
                active
                expanded
                key={step.id}
                className={
                  step.status != "disabled"
                    ? "activeVerticalBar"
                    : "disabledVerticalBar"
                }
              >
                {/* style={{ pointerEvents: step.status == 'disabled' ? 'none' : 'none' }} */}
                <StepLabel
                  onClick={() => goToStep(index)}
                  StepIconComponent={
                    step.status == "disabled"
                      ? DisabledStepIcon
                      : step.status == "completed"
                      ? CompletedStepIcon
                      : InprogressStepIcon
                  }
                >
                  <span
                    className={
                      step.status == "disabled"
                        ? "verticalStepLabelDisabled"
                        : "verticalStepLabel"
                    }
                  >
                    {step.label}
                  </span>
                  <br></br>
                  <small className="dateLabel">{step.date}</small>
                </StepLabel>
                {/* <Typography>All steps completed - you&apos;re finished</Typography> */}

                <StepContent>
                  <span className="stepDescription">{step.description}</span>
                  {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
                  {step &&
                    step.deliveryLocations &&
                    step?.deliveryLocations?.length && (
                      <Box sx={{ mb: 2 }}>
                        <div className="deliveryLocationsContainer">
                          <h4 className="deliveryLocationsHeadingText">
                            Pickup Locations
                          </h4>
                          <Stack spacing={2} divider={<Divider flexItem />}>
                            {step.deliveryLocations.map(
                              (location, indexOfLocation) => (
                                <div key={location.name}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                      <div className="locationIndex">
                                        <div className="locationIndexText">
                                          {indexOfLocation + 1}
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <div>
                                        <span className="deliveryLocationName">
                                          {location.name}
                                        </span>
                                        <br />
                                        <small className="deliveryLocationArea">
                                          {location.location}
                                        </small>
                                        <br />
                                        <small className="deliveryLocationCity">
                                          {location.city}
                                        </small>
                                        &nbsp;
                                        <small className="deliveryLocationPin">
                                          {location.pinCode}
                                        </small>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div>
                                        <StyledButtonOutlined
                                          variant="outlined"
                                          className="deliveryLocationButton"
                                          style={{ textTransform: "none" }}
                                          onClick={() => viewClick(location)}
                                        >
                                          View
                                        </StyledButtonOutlined>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <div className="deliveryLocationStatus"></div>
                                    </Grid>
                                  </Grid>
                                </div>
                              )
                            )}
                          </Stack>
                        </div>
                      </Box>
                    )}
                </StepContent>
              </Step>
            )
        )}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}

function CompletedStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      <div className="verticalStepperIcon_Completed">
        <CheckIcon></CheckIcon>
      </div>
    </>
    // <QontoStepIconRoot ownerState={{ active }} className={className}>
    //   {completed ? (
    //     <Check className="QontoStepIcon-completedIcon" />
    //   ) : (
    //     <div className="QontoStepIcon-circle" />
    //   )}
    // </QontoStepIconRoot>
  );
}
function InprogressStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      <div className="verticalStepperIcon_Inprogress">
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
          <circle cx="32.5" cy="32.5" r="32" fill="#3A3E3F" stroke="#FFC540" />
          <g opacity="0.5">
            <mask id="path-2-inside-1_857_6938" fill="white">
              <path d="M32.5 0C39.6389 8.51304e-08 46.5792 2.35053 52.2489 6.68853C57.9186 11.0265 62.0022 17.1106 63.8691 24.0011C65.7359 30.8916 65.2821 38.2051 62.5777 44.8119C59.8733 51.4187 55.0688 56.9512 48.9063 60.555L32.5 32.5V0Z" />
            </mask>
            <path d="M32.5 0C39.6389 8.51304e-08 46.5792 2.35053 52.2489 6.68853C57.9186 11.0265 62.0022 17.1106 63.8691 24.0011C65.7359 30.8916 65.2821 38.2051 62.5777 44.8119C59.8733 51.4187 55.0688 56.9512 48.9063 60.555L32.5 32.5V0Z" fill="#FFC540" stroke="#FFC540" stroke-width="2" mask="url(#path-2-inside-1_857_6938)" />
          </g>
        </svg> */}
      </div>
    </>
    // <QontoStepIconRoot ownerState={{ active }} className={className}>
    //   {completed ? (
    //     <Check className="QontoStepIcon-completedIcon" />
    //   ) : (
    //     <div className="QontoStepIcon-circle" />
    //   )}
    // </QontoStepIconRoot>
  );
}
function DisabledStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      <div className="verticalStepperIcon_Disabled"></div>
    </>
    // <QontoStepIconRoot ownerState={{ active }} className={className}>
    //   {completed ? (
    //     <Check className="QontoStepIcon-completedIcon" />
    //   ) : (
    //     <div className="QontoStepIcon-circle" />
    //   )}
    // </QontoStepIconRoot>
  );
}
