import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, Drawer, FormControl, Grid, MenuItem, Skeleton, Snackbar, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import AddIcon from '@mui/icons-material/Add';
import { makeStyles, styled } from '@mui/styles';
import axios from "axios";
import PropTypes from "prop-types";
import moment from "moment";
import { useForm, useFieldArray } from "react-hook-form"
import { getLocationDetails } from '../../../../../bindCountryState';
import { flexStyles } from '../../../../../component/theme/theme';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { env } from '../../../../../env';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { Add, DeleteOutline } from '@mui/icons-material';
import { getApiUrl } from "./../../../../../global";
import { StyledButtonContained } from '../../../AccountCreation/accountCreation';

const getStatusMapValue = (status) => {
    switch (status) {
        case 'preparing_for_shipment': return 0;
        case 'courier_en_route': return 1;
        case 'shipment_collected': return 2;
        case 'out_for_delivery': return 3;
        case 'verified_and_completed': return 4;
        default: return -1;
    }
}

const CustomHTabList = styled(Tabs)(() => ({
    borderRadius: '12px 0px 0px 12px',
    '& .MuiTabs-scroller': {
        background: '#3A3E3F',
    },
    '& .MuiTabs-indicator': {
        background: '#FFC540 !important',
    },
    '& .MuiTab-root': {
        color: '#FFFFFF',
    },
    textTransform: 'none',
}));

const CustomHTab = styled(Tab)(() => ({
    textTransform: 'none !important',
    fontFamily: 'Poppins !important',
    color: '#FFFFFF !important',
    '&.Mui-selected': {
        color: '#FFC540 !important',
    }
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const AdminShippingManagement = ({ shipments, customer, shippingMode, onShipmentSubmit }) => {
    const params = useParams();
    const [orderId, setOrderId] = React.useState(params.orderId);

    const [state, setState] = useState({
        openSb: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const { vertical, horizontal, openSb } = state;

    const openSnackbar = (message) => {
        setSnackbarMessage(message)
        setState({
            vertical: 'top', horizontal: 'right', openSb: true
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: 'top', horizontal: 'right', openSb: false
        });
    };

    const { register, handleSubmit, watch, formState: { errors }, setValue, control, getValues, reset } = useForm(
    );

    const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);

    useEffect(() => {
        // On mount populate forms with default values, if any
        let defaultValues = {};

        shipments?.map((shipment, index) => {

            // The following axios call is only being added for the lack of all required shipment related fields in /order/uuid -> shipments
            /*             let apiUrl =  process.env.REACT_APP_API_BASE_URL + `/shipment/${shipment.uuid}/`;
                        axios.get(apiUrl)
                        .then((res) => {
                            console.log(res);
                            
                        }).catch((err) => {
                           console.log(err);
                        }); */

            const shipmentStatus = (shipment.status) ? shipment.status : 'preparing_for_shipment';
            defaultValues = {
                ...defaultValues,
                [`shipmentStatus-${index}`]: shipmentStatus,
            }

            if (getStatusMapValue(shipmentStatus) >= 0) {
                defaultValues = {
                    ...defaultValues,

                    [`pickupConsignmentNumber-${index}`]: shipment.pickup_consignment_number,
                    [`pickupConsignmentContact-${index}`]: shipment.pickup_contact_number,

                }
            };

            if (getStatusMapValue(shipmentStatus) >= 1) {
                let pickupTime;
                if (shipment.pickup_arrival_time) {
                    pickupTime = `2023-11-21T${shipment.pickup_arrival_time}.000000Z`;
                } else {
                    pickupTime = `2023-11-21T00:00:00.000000Z`;
                }
                defaultValues = {
                    ...defaultValues,

                    [`pickupNumberOfBoxes-${index}`]: shipment.pickup_box_count,
                    [`pickupWeight-${index}`]: shipment.pickup_weight,
                    [`pickupDateOfArrival-${index}`]: shipment.pickup_arrival_date,
                    [`pickupTime-${index}`]: pickupTime,
                }
            }

            if (getStatusMapValue(shipmentStatus) >= 2) {
                defaultValues = {
                    ...defaultValues,

                    [`deliveryConsignmentNumber-${index}`]: shipment.delivery_consignment_number,
                    [`deliveryContact-${index}`]: shipment.delivery_contact_number,
                }
            }

            if (getStatusMapValue(shipmentStatus) >= 3) {
                defaultValues = {
                    ...defaultValues,

                    [`shipmentConsignmentNumber-${index}`]: shipment.shipment_consignment_number,
                    [`shippingReceivedOn-${index}`]: shipment.shipment_received_on,
                    [`shipmentPackaging-${index}`]: shipment.shipment_packaging,
                    [`shipmentNoOfBoxes-${index}`]: shipment.shipment_box_count,
                    [`shipmentDescription-${index}`]: shipment.shipment_description,

                    [`verificationTotalTapes-${index}`]: shipment.verification_tape_count,

                }
            }
        });

        console.log(defaultValues);
        reset(defaultValues);
        setIsDefaultLoaded(true);

    }, []);


    const classes = useStyles();

    const getStatusSectionText = (status) => {
        switch (status) {
            case 'courier_en_route': return 'Pickup Information';
            case 'shipment_collected': return 'Confirm Receipt';
            case 'out_for_delivery': return 'Delivery Information';
            case 'verified_and_completed': return 'Completed';
            default: return '';
        }
    }

    const [shipmentTab, setShipmentTab] = React.useState(0);
    const handleShipmentTabChange = (event, newValue) => {
        setShipmentTab(newValue);
    };

    const onSubmit = async (data) => {
        console.log('shipment mgmt data:', data);

        const shipmentStatus = data[`shipmentStatus-${shipmentTab}`];
        if (shipmentStatus) {
            let payload = {
                status: shipmentStatus,
            }

            if (getStatusMapValue(shipmentStatus) >= 1) {
                payload = {
                    ...payload,

                    pickup_consignment_number: data[`pickupConsignmentNumber-${shipmentTab}`],
                    pickup_contact_number: data[`pickupConsignmentContact-${shipmentTab}`],

                }
            };

            if (getStatusMapValue(shipmentStatus) >= 2) {
                payload = {
                    ...payload,

                    pickup_box_count: data[`pickupNumberOfBoxes-${shipmentTab}`],
                    pickup_weight: Number(data[`pickupWeight-${shipmentTab}`]),
                    pickup_arrival_date: data[`pickupDateOfArrival-${shipmentTab}`]?.slice(0, 10),
                    pickup_arrival_time: data[`pickupTime-${shipmentTab}`]?.slice(11, 19),
                }
            }

            if (getStatusMapValue(shipmentStatus) >= 3) {
                payload = {
                    ...payload,

                    delivery_consignment_number: data[`deliveryConsignmentNumber-${shipmentTab}`],
                    delivery_contact_number: data[`deliveryContact-${shipmentTab}`],
                }
            }

            if (getStatusMapValue(shipmentStatus) >= 4) {
                payload = {
                    ...payload,

                    shipment_consignment_number: data[`shipmentConsignmentNumber-${shipmentTab}`],
                    shipment_received_on: data[`shippingReceivedOn-${shipmentTab}`]?.slice(0, 10),
                    shipment_packaging: data[`shipmentPackaging-${shipmentTab}`],
                    shipment_box_count: data[`shipmentNoOfBoxes-${shipmentTab}`],
                    shipment_description: data[`shipmentDescription-${shipmentTab}`],

                    verification_tape_count: data[`verificationTotalTapes-${shipmentTab}`],

                }

                // Refactor - Shipment Verifications can be called here
            }

            console.log('shipment mgmt payload:', payload);
            let url = await getApiUrl();

            let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${orderId}/shipments/${shipments[shipmentTab].uuid}/`;
            axios.patch(apiUrl, payload, {
            }).then((res) => {
                console.log(res);

                // Saving Verifications, if any
                if (getStatusMapValue(res.data.status) >= 4) {
                    const shipmentTabVerifications = watch(`verifications-${shipments[shipmentTab]?.uuid}`);
                    if (shipmentTabVerifications?.length > 1 ||
                        (shipmentTabVerifications?.length > 0
                            && shipmentTabVerifications[0].box_number !== ''
                            && shipmentTabVerifications[0].tape_count !== '')) {

                        shipmentTabVerifications.map((v, i) => {
                            let verificationPayload = {
                                'shipment': shipments[shipmentTab]?.uuid,
                                'box_number': v.box_number,
                                'tape_count': v.tape_count,
                            }

                            const uuid = watch(`verifications-${shipments[shipmentTab]?.uuid}[${i}].uuid`);
                            if (uuid) {

                                const verificationUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + orderId + '/shipments/' + shipments[shipmentTab]?.uuid + '/verifications/' + uuid + '/';
                                axios.put(verificationUrl, verificationPayload, {
                                }).then((res) => {
                                    openSnackbar('Verification updated successfully');
                                }).catch((err) => {
                                    openSnackbar('Error while updating Verification.');
                                    console.log(err);
                                });
                            } else {
                                const verificationUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + orderId + '/shipments/' + shipments[shipmentTab]?.uuid + '/verifications/';
                                axios.post(verificationUrl, verificationPayload, {
                                }).then((res) => {
                                    openSnackbar('Verification created successfully');
                                    setValue(`verifications-${shipments[shipmentTab]?.uuid}[${i}].uuid`, res.data.uuid);

                                }).catch((err) => {
                                    openSnackbar('Error while creating Verification.');
                                    console.log(err);
                                });
                            }
                        });
                    }
                }

                openSnackbar('Shipment updated successfully');
                onShipmentSubmit();
            }).catch((err) => {
                console.log(err);
                openSnackbar('Error while updating Shipment');

            });

        }

    }

    const mapShippingModeText = (mode) => {
        const shippingMode = {
            'self': 'Self',
            'tapeark': 'Tape Ark',
            'none': 'External'
        };
        return shippingMode[mode];
    }



    return (
        <Box p={4}>
            {isDefaultLoaded ? (<>
                <Grid container direction='column' spacing={2}>
                    {shippingMode && (<>
                        <Grid item xs={12} sx={flexStyles.flexRowStart} alignItems={'center'} mb={2}>
                            <Typography align='left' sx={{
                                color: '#FFFFFF', fontFamily: 'Poppins', whiteSpace: 'pre',
                            }}>Shipping Mode:  </Typography>
                            <Typography sx={{
                                color: '#FFFFFF', fontFamily: 'Poppins', fontSize: '22px',
                            }}>{mapShippingModeText(shippingMode)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ border: '1px solid #FFFFFF', visibility: (shippingMode === 'none' ? 'hidden' : 'visible') }}>
                            <CustomHTabList
                                value={shipmentTab}
                                orientation='horizontal'
                                onChange={handleShipmentTabChange}
                                sx={{
                                    background: 'transparent',
                                    mb: 2,
                                }}
                            >
                                {shipments?.map((shipment, index) => (
                                    <CustomHTab value={index} label={`Shipment ${index + 1}`} />
                                ))}
                            </CustomHTabList>
                        </Grid>
                    </>)}
                    <Grid item xs={12}>
                        {shipments?.map((shipment, index) => (
                            <TabPanel key={index} value={shipmentTab} index={index}>
                                <form id={index} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container direction='column' spacing={2}>
                                        {isDefaultLoaded && (
                                            <Grid item sx={flexStyles.flexRowStart} alignItems={'center'} gap={2}>
                                                <Typography align='left' sx={{
                                                    color: '#FFFFFF', fontFamily: 'Poppins',
                                                }}>Shipping Status</Typography>
                                                <TextField
                                                    select
                                                    className={classes.selectLabel}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`shipmentStatus-${index}`}
                                                    key={`shipmentStatus-${index}`}
                                                    //value={watch(`shipmentStatus-${index}`)}
                                                    defaultValue={watch(`shipmentStatus-${index}`)}//) ? watch(`shipmentStatus-${index}`) : 'preparing_for_shipment'}
                                                    {...register(`shipmentStatus-${index}`)}
                                                //value={watch(`shipmentStatus-${index}`)}
                                                //onChange={(event, newValue) => setValue(`shipmentStatus-${index}`, newValue)}
                                                >
                                                    <MenuItem value="preparing_for_shipment">Preparing for Shipment</MenuItem>
                                                    <MenuItem value="courier_en_route">Courier En Route</MenuItem>
                                                    <MenuItem value="shipment_collected">Shipment Collected</MenuItem>
                                                    <MenuItem value="out_for_delivery">Out For Delivery</MenuItem>
                                                    <MenuItem value="verified_and_completed">Verified & Completed</MenuItem>
                                                </TextField>
                                            </Grid>
                                        )}
                                        <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                            <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                        </Grid>
                                        <Grid item>
                                            <Typography align='left' sx={{
                                                color: '#FFFFFF', fontFamily: 'Poppins',
                                                ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 1) && { opacity: 0.5, }),
                                            }}>Pickup Information</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            ...flexStyles.flexRowStart,
                                            gap: 8,
                                            ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 1) && { opacity: 0.5, pointerEvents: 'none' }),
                                        }}>
                                            <Stack direction={'column'}>
                                                <Typography className={classes.heading} align='left'>Consignment Number</Typography>
                                                <TextField className={classes.selectLabel}
                                                    variant="outlined" sx={{ width: '100%' }}
                                                    name={`pickupConsignmentNumber-${index}`}
                                                    {...register(`pickupConsignmentNumber-${index}`)} />
                                            </Stack>
                                            <Stack direction={'column'}>
                                                <Typography className={classes.heading} align='left'>Contact Number</Typography>
                                                <TextField className={classes.selectLabel}
                                                    variant="outlined" sx={{ width: '100%' }}
                                                    name={`pickupConsignmentContact-${index}`}
                                                    {...register(`pickupConsignmentContact-${index}`)} />
                                            </Stack>

                                            {/*                                         <Button disabled type="text" sx={{ '&.Mui-disabled':{color: '#FFC540'} }}>Edit</Button>
 */}                                    </Grid>

                                        <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                            <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                        </Grid>
                                        <Grid item sx={{ ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 2) && { opacity: 0.5, }), }}>
                                            <Typography align='left' sx={{
                                                color: '#FFFFFF', fontFamily: 'Poppins',
                                            }}>Confirm Receipt</Typography>
                                        </Grid>
                                        <Grid sx={{
                                            ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 2) && { opacity: 0.5, pointerEvents: 'none' }),
                                            ...flexStyles.flexColumnStart
                                        }} item xs={12} gap={2}>

                                            <Typography className={classes.heading} align='left'>Number Of Boxes</Typography>
                                            <TextField className={classes.selectLabel} id="outlined-basic"
                                                type="number"
                                                variant="outlined" fullWidth
                                                name={`pickupNumberOfBoxes-${index}`}
                                                {...register(`pickupNumberOfBoxes-${index}`)} />

                                            <Typography className={classes.heading} align='left'>Weight</Typography>
                                            <TextField className={classes.selectLabel} id="outlined-basic"
                                                variant="outlined" fullWidth={true}
                                                name={`pickupWeight-${index}`}
                                                {...register(`pickupWeight-${index}`)} />

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack direction="row" gap={2}>
                                                    <Box>
                                                        <Typography className={classes.heading} align='left'>Date Of Arrival</Typography>

                                                        <DatePicker
                                                            onChange={(newValue) => setValue(`pickupDateOfArrival-${index}`, newValue.toISOString())}
                                                            defaultValue={(watch(`pickupDateOfArrival-${index}`)) ? dayjs(watch(`pickupDateOfArrival-${index}`)) : undefined}
                                                            className='date-input'
                                                            sx={{ width: '100%' }}
                                                        //name={`pickupDateOfArrival-${index}`} 
                                                        //{...register(`pickupDateOfArrival-${index}`)} 
                                                        />
                                                    </Box>
                                                    <Box>

                                                        <Typography className={classes.heading} align='left'>Time Of Arrival</Typography>

                                                        <TimePicker
                                                            onChange={(newValue) => setValue(`pickupTime-${index}`, newValue.toISOString())}
                                                            defaultValue={(watch(`pickupTime-${index}`)) ? dayjs(watch(`pickupTime-${index}`)) : undefined}
                                                            className='date-input'
                                                            sx={{ width: '100%' }}
                                                            ampm={false}
                                                        //name={`pickupTime-${index}`} 
                                                        //{...register(`pickupTime-${index}`)} 
                                                        />
                                                    </Box>
                                                </Stack>

                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                            <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                        </Grid>
                                        <Grid item sx={{
                                            ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 3) && { opacity: 0.5, pointerEvents: 'none' }),
                                        }}>
                                            <Typography align='left' sx={{
                                                color: '#FFFFFF', fontFamily: 'Poppins',
                                            }}>Delivery Information</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 3) && { opacity: 0.5, pointerEvents: 'none' }),
                                            ...flexStyles.flexRowStart,
                                            gap: 8,
                                        }}>
                                            <Stack direction={'column'}>
                                                <Typography className={classes.heading} align='left'>Consignment Number</Typography>
                                                <TextField className={classes.selectLabel}
                                                    variant="outlined" sx={{ width: '100%' }}
                                                    name={`deliveryConsignmentNumber-${index}`}
                                                    {...register(`deliveryConsignmentNumber-${index}`)} />
                                            </Stack>
                                            <Stack direction={'column'}>
                                                <Typography className={classes.heading} align='left'>Contact Number</Typography>
                                                <TextField className={classes.selectLabel}
                                                    variant="outlined" sx={{ width: '100%' }}
                                                    name={`deliveryContact-${index}`}
                                                    {...register(`deliveryContact-${index}`)} />
                                            </Stack>

                                            {/*                                         <Button disabled type="text" sx={{ '&.Mui-disabled':{color: '#FFC540'} }}>Edit</Button>
 */}                                    </Grid>
                                        <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                            <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                        </Grid>
                                        {customer && (<>
                                            <Grid item sx={{
                                                ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 4) && { opacity: 0.5, }),

                                            }}>
                                                <Typography align='left' sx={{
                                                    color: '#FFFFFF', fontFamily: 'Poppins',
                                                }}>Delivery Details</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{
                                                opacity: 0.5, pointerEvents: 'none',

                                            }}>
                                                <Grid container direction='column' spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction='row' justifyContent='flex-start' spacing={2} alignItems={'flex-start'}>
                                                            <Grid item xs={7} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Company</Typography>
                                                                <TextField id="outlined-basic" variant="outlined" sx={{ width: '100%' }}
                                                                    name='company'
                                                                    value={customer?.company_name}
                                                                //{...register('company')} 
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Work Location</Typography>
                                                                <TextField className={classes.selectLabel} id="outlined-basic" sx={{ width: '100%' }}
                                                                    variant="outlined" name='workLocation'
                                                                    value={customer?.work_location}
                                                                //{...register('workLocation')} 
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction='row' justifyContent='flex-start' spacing={2} alignItems={'flex-start'}>
                                                            <Grid item xs={12} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Address Line 1</Typography>
                                                                <TextField sx={{ width: '100%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                                    name='addressLine1'
                                                                    value={customer?.contact_address?.address_line_1}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                                            <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>ZipCode</Typography>
                                                                <TextField
                                                                    className={classes.selectLabel}
                                                                    sx={{ width: '100%' }}
                                                                    name='ZipCode'
                                                                    value={customer?.contact_address?.postal_code}
                                                                //{...register('ZipCode', { onBlur: (e) => getLocationDetails(e, fillCityAndStateFieldsaddPeople) })} 
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Town City</Typography>
                                                                <TextField
                                                                    className={classes.selectLabel}
                                                                    sx={{ width: '100%' }}
                                                                    name='city'
                                                                    value={customer?.contact_address?.city}
                                                                //{...register('state')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>State</Typography>
                                                                <TextField
                                                                    className={classes.selectLabel}
                                                                    sx={{ width: '100%' }}
                                                                    name='state'
                                                                    value={customer?.contact_address?.state}
                                                                //{...register('state')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Country</Typography>
                                                                <TextField
                                                                    className={classes.selectLabel}
                                                                    sx={{ width: '100%' }}
                                                                    name='country'
                                                                    value={customer?.contact_address?.country}
                                                                //{...register('country')}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                                            {/* <Grid item xs={6} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Address Line 1</Typography>
                                                                <TextField sx={{ width: '100%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                                    name='addressLine1'
                                                                    value={customer?.contact_address?.address_line_1}
                                                                />
                                                            </Grid> */}
                                                            <Grid item xs={6} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Phone</Typography>
                                                                <TextField sx={{ width: '100%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                                    name='Phone'
                                                                    value={customer?.phone_number}
                                                                //{...register('Phone')} 
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Mobile</Typography>
                                                                <TextField sx={{ width: '100%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                                    name='mobile'
                                                                    value={customer?.mobile_number}
                                                                //{...register('mobile')} 
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>)}
                                        <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                            <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                        </Grid>
                                        <Grid item>
                                            <Typography align='left' sx={{
                                                color: '#FFFFFF', fontFamily: 'Poppins',
                                                ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 4) && { opacity: 0.5, }),

                                            }}>Shipping Details</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 4) && { opacity: 0.5, pointerEvents: 'none' }),

                                        }}>
                                            <Grid container direction='column' spacing={2}>
                                                <Grid item>
                                                    <Grid container direction='row' justifyContent='flex-start' spacing={2} alignItems={'flex-start'}>
                                                        {shipments && (
                                                            <Grid item xs={5} sx={flexStyles.flexColumnStart}>
                                                                <Typography align='left' className={classes.formInputHeading}>Carrier Name</Typography>
                                                                <TextField id="outlined-basic" variant="outlined" sx={{ width: '100%' }}
                                                                    //name={`shipmentCarrierName-${index}`} 
                                                                    //{...register(`shipmentCarrierName-${index}`)}
                                                                    value={shipment?.courier_partner}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={4} sx={flexStyles.flexColumnStart}>
                                                            <Typography align='left' className={classes.formInputHeading}>Consignment Number</Typography>
                                                            <TextField className={classes.selectLabel} id="outlined-basic" sx={{ width: '100%' }} variant="outlined"
                                                                name={`shipmentConsignmentNumber-${index}`}
                                                                {...register(`shipmentConsignmentNumber-${index}`)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Typography className={classes.heading} align='left'>Received On</Typography>
                                                                <DatePicker
                                                                    onChange={(newValue) => setValue(`shippingReceivedOn-${index}`, newValue.toISOString())}
                                                                    defaultValue={(watch(`shippingReceivedOn-${index}`)) ? dayjs(watch(`shippingReceivedOn-${index}`)) : undefined}
                                                                    className='date-input'
                                                                    sx={{ width: '100%' }}
                                                                //name={`shippingReceivedOn-${index}`}
                                                                //{...register(`shippingReceivedOn-${index}`)}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                                        <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                            <Typography align='left' className={classes.formInputHeading}>Type Of Shipment</Typography>
                                                            <TextField
                                                                select
                                                                className={classes.selectLabel}
                                                                sx={{ width: '100%' }}
                                                                name={`shipmentPackaging-${index}`}
                                                                {...register(`shipmentPackaging-${index}`)} >
                                                                <MenuItem key={'boxes'} value={'boxes'}>Boxes</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={3} sx={flexStyles.flexColumnStart}>
                                                            <Typography align='left' className={classes.formInputHeading}>Number Of Boxes</Typography>
                                                            <TextField
                                                                type="number"
                                                                className={classes.selectLabel}
                                                                sx={{ width: '100%' }}
                                                                name={`shipmentNoOfBoxes-${index}`}
                                                                {...register(`shipmentNoOfBoxes-${index}`)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                                        <Grid item xs={5} sx={flexStyles.flexColumnStart}>
                                                            <Typography align='left' className={classes.formInputHeading}>Description/Message (If any)</Typography>
                                                            <TextField multiline rows={3} sx={{ width: '100%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                                name={`shipmentDescription-${index}`}
                                                                {...register(`shipmentDescription-${index}`)} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12} mb={2} sx={flexStyles.flexRowStart}>
                                                    <Divider sx={{ background: '#5F5F5F', width: '100%' }} orientation='horizontal' />
                                                </Grid>
                                                <Grid item>
                                                    <Typography align='left' sx={{
                                                        ...((getStatusMapValue(watch(`shipmentStatus-${index}`)) < 4) && { opacity: 0.5, }),
                                                        color: '#FFFFFF', fontFamily: 'Poppins',
                                                    }}>Verification</Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={flexStyles.flexColumnStart}>
                                                    <Typography align='left' className={classes.formInputHeading}>Total Tapes</Typography>
                                                    <TextField sx={{ width: '30%' }} className={classes.selectLabel} id="outlined-basic" variant="outlined"
                                                        name={`verificationTotalTapes-${index}`}
                                                        {...register(`verificationTotalTapes-${index}`)} />
                                                </Grid>
                                                <ShipmentVerifications
                                                    shipmentIndex={index}
                                                    shipment={shipment}
                                                    control={control}
                                                    watch={watch}
                                                    register={register}
                                                    setValue={setValue}
                                                    orderId={orderId} />

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    {/* Manage Shipment Action Drawer */}
                                    <Drawer variant={'persistent'} anchor={'bottom'} open sx={{
                                        '& .MuiDrawer-paper': {
                                            backgroundColor: '#2C2C2C',
                                            boxShadow: '0px -7px 11px 0px #00000029',
                                            height: '6rem',
                                            pl: '60px', // TODO: Make width of Navbar Drawer and move it to constants
                                        },
                                    }}>
                                        <Grid container direction={'row'} my={2} px={8}>
                                            <Grid item xs={6} sx={flexStyles.flexRowStart}>
                                                <Typography sx={{ fontFamily: 'Poppins', textAlign: 'left', fontSize: 18, color: '#FFFFFF', fontWeight: 400, mt: 2 }} >
                                                    {getStatusSectionText(watch(`shipmentStatus-${index}`))}</Typography>
                                            </Grid>

                                            <Grid item xs={6} sx={flexStyles.flexRowEnd} gap={2}>

                                                <Button
                                                    variant='text'
                                                    //onClick={clearAddedRows}
                                                    sx={{
                                                        width: '220px',
                                                        color: '#FFFFFF',
                                                        fontFamily: 'Poppins',
                                                        textTransform: 'none',
                                                    }}>
                                                    Clear
                                                </Button>

                                                <StyledButtonContained variant='contained'
                                                    type='submit'
                                                    //form="mainForm"
                                                    sx={{
                                                        width: '220px',
                                                        height: '60px'
                                                        }}>
                                                    Save
                                                </StyledButtonContained>

                                            </Grid>
                                        </Grid>
                                    </Drawer>
                                </form>
                            </TabPanel>
                        ))}

                    </Grid>

                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openSb}
                    onClose={closeSnackbar}
                    message={snackbarMessage}
                    key={vertical + horizontal}
                /></>
            ) : (
                <CircularProgress sx={{ color: '#FFC540' }}></CircularProgress>
            )}
        </Box>
    )
}

const useStyles = makeStyles({
    selectLabel: {
        // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        //     color: '#C7CCD0'
        // },
        // "& .MuiSelect-icon": {
        //     color: '#C7CCD0'
        // },
        // '& .MuiInputLabel-root.Mui-focused': {
        //     color: '#C7CCD0'
        // },
        // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#C7CCD0'
        // },
        // '& .MuiOutlinedInput-input': {
        //     color: '#C7CCD0'
        // },
        // '& .MuiInputLabel-root': {
        //     color: '#C7CCD0'
        // },
        // '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#C7CCD0'
        // }
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
    mainHeading: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    formInputHeading: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    }
});

const ShipmentVerifications = ({ shipmentIndex, shipment, control, watch, register, setValue, orderId }) => {

    const classes = useStyles();

    const { fields: verificationFields,
        append: verificationAppend,
        prepend,
        remove: verificationRemove,
        swap, move, insert } = useFieldArray({
            control: control,
            name: `verifications-${shipment?.uuid}`
        });

    const [shipmentVerifications, setVerifications] = React.useState({});
    const [verificationsLoaded, setVerificationsLoaded] = React.useState(false);

    const fetchVerifications = async (shipment) => {
        let url = await getApiUrl();

        const shipmentVerificationsApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + orderId + '/shipments/' + shipment?.uuid + '/verifications/';
        axios.get(shipmentVerificationsApiUrl)
            .then((data) => {
                shipmentVerifications[shipment?.uuid] = data.data.results;
                if (shipmentVerifications[shipment?.uuid]?.length > 0) {
                    shipmentVerifications[shipment?.uuid].map((resV, index) => {
                        if (!watch(`verifications-${shipment?.uuid}`).some((v) => v?.uuid === resV?.uuid)) {
                            verificationAppend({ box_number: resV.box_number, tape_count: resV.tape_count, uuid: resV.uuid });
                        }
                    })
                } else if (watch(`verifications-${shipment?.uuid}`)?.length === 0) {
                    verificationAppend({ box_number: '', tape_count: '', uuid: null });
                }
                setVerificationsLoaded(true);
            }).catch((err) => {
                console.log(err);
                setVerificationsLoaded(true)
            });

    }

    useEffect(() => {
        if (shipment) {
            fetchVerifications(shipment);
        }
    }, []);

    const deleteShipmentVerification = async (index) => {
        const uuid = watch(`verifications-${shipment?.uuid}[${index}].uuid`);
        let url = await getApiUrl();

        const shipmentVerificationApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + orderId + "/shipments/" + shipment?.uuid + '/verifications/' + uuid + '/';
        if (uuid) {
            axios.delete(shipmentVerificationApiUrl).
                then((res) => {
                    console.log(res);
                    if (verificationFields.length === 1) {
                        setValue(`verifications-${shipment?.uuid}[0]`, { box_number: '', tape_count: '' });
                    } else {
                        verificationRemove(index);
                    }
                }).catch(err => console.log(err));
        } else {
            if (verificationFields.length === 1) {
                return;
            }
            verificationRemove(index);
        }
    }

    return (
        <>  {verificationsLoaded ?
            (
                <Grid item gap={2} sx={{
                    ...flexStyles.flexColumnStart,
                    ...((getStatusMapValue(watch(`shipmentStatus-${shipmentIndex}`)) < 4) && { opacity: 0.5, pointerEvents: 'none' }),
                }}>
                    {verificationFields.map((field, j) => (
                        <Grid key={field.id} container direction='row' justifyContent='flex-start' spacing={2} mb={1}>
                            <Grid item>
                                <Typography align='left' className={classes.heading}>Box Number</Typography>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        {...register(`verifications-${shipment?.uuid}[${j}].box_number`)}
                                        name={`verifications-${shipment?.uuid}[${j}].box_number`}
                                        className={classes.selectLabel}
                                        defaultValue={field.box_number}
                                        sx={{ width: '15ch', }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography align='left' className={classes.heading}>Tape Count</Typography>
                                <TextField
                                    {...register(`verifications-${shipment?.uuid}[${j}].tape_count`)}
                                    name={`verifications-${shipment?.uuid}[${j}].tape_count`}
                                    defaultValue={field.tape_count}
                                    className={classes.selectLabel} id="outlined-basic"
                                    variant="outlined"
                                    sx={{ width: '15ch', }} />
                            </Grid>
                            <Grid item>
                                <Box mt={3}>
                                    <Button
                                        className={classes.plusMinBtn}
                                        variant="outlined"
                                        onClick={() => { deleteShipmentVerification(j) }}
                                        sx={{ width: '10ch', }}
                                    >
                                        <DeleteOutline sx={{ color: '#FFFFFF' }} />
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box mt={3}>
                                    <Button
                                        className={classes.plusMinBtn}
                                        variant="outlined"
                                        onClick={() => verificationAppend({ box_number: '', tape_count: '' })}
                                        sx={{ width: '10ch', }}
                                    >
                                        <Add sx={{ color: '#FFFFFF' }} />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <CircularProgress sx={{ color: '#FFC540' }}></CircularProgress>
            )}
        </>
    );
}