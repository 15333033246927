import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, MenuItem } from '@mui/material';
import { makeStyles } from "@mui/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { alpha } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';
import { getLocationDetails } from "../../../bindCountryState";

function calculateValue(value) {
    return 2 ** value;
}

export const AdminIngestionForm = (props) => {
    const classes = useStyles();

    const [postIngestionAction, setPostIngestionAction] = React.useState("destroy");

    const handleChangePostIngestionAction = (event, newPostIngestionAction) => {
        if (newPostIngestionAction !== null) {
            props.setValue('ingestion.postIngestion', newPostIngestionAction);
        }
    };
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [destroyOptions, setDestroyOptions] = React.useState("shared");

    const [storageDuration, setStorageDuration] = React.useState(0);

    const handleStorageDurationChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setStorageDuration(newValue);
            props.setValue('ingestion.storageDuration', newValue);
        }
    };

    const [storageDurationUom, setStorageDurationUom] = React.useState("month");

    const [returnMode, setReturnMode] = React.useState("sender");

    const handleChangeIngestionAction = (event, newIngestionAction) => {
        setPostIngestionAction(newIngestionAction);
    };
    const handleChangeDestroyOptions = (event, newDestroyOptions) => {
        setDestroyOptions(newDestroyOptions);
    };
    const handleChangeStorageDurationUom = (event, newDuration) => {
        setStorageDuration(newDuration);
    };
    const handleChangeReturnMode = (event, newReturnMode) => {
        setReturnMode(newReturnMode);
    };

    const [tapeOptions, setTapeOptions] = useState('critical');
    const handleChangeTapeOptions = (event, newTapeOptions) => {
        props.setValue('ingestion.tapeOptions', newTapeOptions);
    };
    const fillCityAndStateFieldsaddPeople = (localities) => {
        let locality = localities[0]; //use the first city/state object
        props.setValue("ingestion.returnAddress.city", locality.city);
        props.setValue("ingestion.returnAddress.country", locality.country);
        props.setValue("ingestion.returnAddress.state", locality.state);
    }
    return (
        <>
            <Grid container
                style={{
                    border: "1px solid #C7CCD0",
                    borderRadius: "8px",
                    marginTop: "20px",
                    minHeight: "100vh",
                    height: "max-content",
                    marginBottom: '100px',
                    alignItems: "start",
                    display: "block",
                }}>
                <Grid container direction={"column"}>
                    {/* Stepper */}
                    <Grid item>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                border: "8px 8px 0px 0px",
                                border: "#272727",
                                padding: "20px",
                                background: "#272727",
                                borderRadius: "8px 8px 0px 0px",
                            }}
                        >
                            <Typography className={classes.shippingheading}>
                                Ingestion
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box p={4}>
                    <Grid container direction='column' spacing={4} px={1}>
                        <Grid item>
                            <Typography align='left' className={classes.heading}>What happens to tapes after Ingestion</Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={props.watch('ingestion.postIngestion')}
                                onChange={handleChangePostIngestionAction}
                                //defaultValue={'destroy'}
                                name='ingestion.postIngestion'
                                //{...props.register(`ingestion.postIngestion`)} 
                                exclusive
                                aria-label="Platform"
                                style={{
                                    borderRadius: "7px",
                                    border: "1px solid #7B809A",
                                    background: "var(--Charcoal, #3A3E3F)",
                                    marginTop: "12px",
                                    display: "flex",
                                    width: "492px",

                                }}
                            >
                                <ToggleButton value="destroy" className="h-40" >
                                    Destroy
                                </ToggleButton>
                                <ToggleButton value="store" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Store
                                </ToggleButton>
                                <ToggleButton value="return" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                    Return
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {props.watch('ingestion.postIngestion') === 'destroy' ? (
                            <>
                                {/* Mode Code here.. */}
                                <Grid item>
                                    <Typography align='left' className={classes.heading}>Mode</Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={props.watch('ingestion.destroyMode')}
                                        onChange={(event, newValue) => props.setValue('ingestion.destroyMode', newValue)}
                                        exclusive
                                        aria-label="Platform"
                                        style={{
                                            borderRadius: "7px",
                                            border: "1px solid #7B809A",
                                            background: "var(--Charcoal, #3A3E3F)",
                                            marginTop: "12px",
                                            display: "flex",
                                            width: "592px",

                                        }}
                                    >
                                        <ToggleButton value="shared" className="h-40" >
                                            Shred
                                        </ToggleButton>
                                        <ToggleButton value="incenerate" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>{/*misspelt*/}
                                            Incinerate
                                        </ToggleButton>
                                        <ToggleButton value="degauss" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                            Degauss
                                        </ToggleButton>
                                        <ToggleButton value="recycle" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                            Recycle
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </>
                        ) : props.watch('ingestion.postIngestion') === 'store' ? (
                            <>
                                <Grid item>
                                    <Grid container direction='column'>
                                        {/* store Duration */}
                                        <Grid item>
                                            <Typography align='left' sx={{ marginBottom: '16px' }} className={classes.heading}>Storage Duration</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                                <Grid item>
                                                    <TextField xs={2}
                                                        className={classes.selectLabel}
                                                        {...props.register('ingestion.storageDuration')} id="outlined-basic" variant="outlined" fullWidth={true} />
                                                </Grid>
                                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography align='left' className={classes.heading}>Free storage period (months)</Typography>
                                                </Grid>
                                                {/* <Grid item>
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={'days'}//props.watch('ingestion.storageDurationUom')
                                                        //onChange={(event, newValue) => props.setValue('ingestion.storageDurationUom', newValue)}
                                                        exclusive
                                                        aria-label="Platform"
                                                        style={{
                                                            borderRadius: "7px",
                                                            border: "1px solid #7B809A",
                                                            background: "var(--Charcoal, #3A3E3F)",
                                                            // marginTop: "12px",
                                                            display: "flex",
                                                            // width: "492px",
                                                        }}
                                                    >
                                                        <ToggleButton value="days" className="h-40" >
                                                            Days
                                                        </ToggleButton>
                                                        <ToggleButton value="month" className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                                            Month
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Slider code here.. */}
                                {/* <Grid item xs={6}>
                                    <Box sx={{
                                        maxWidth: '600px',
                                        marginLeft: '14px'
                                    }}>
                                        <Slider
                                            aria-label="storage-duration"
                                            defaultValue={0}
                                            value={props.watch('ingestion.storageDuration')}
                                            step={1}
                                            min={0}
                                            max={180}
                                            scale={calculateValue}
                                            onChange={handleStorageDurationChange}
                                            marks={marks}
                                        />
                                    </Box>
                                </Grid> */}
                            </>
                        ) : props.watch('ingestion.postIngestion') === "return" ? (
                            <>
                                {/* Return to code here.. */}
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography align='left' sx={{ marginBottom: '16px' }} className={classes.heading}>Return To</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                                <Grid item>
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={props.watch('ingestion.returnTo')}
                                                        onChange={(event, newValue) => {
                                                            props.setValue('ingestion.returnTo', newValue)
                                                        }}
                                                        exclusive
                                                        aria-label="Platform"
                                                        style={{
                                                            borderRadius: "7px",
                                                            border: "1px solid #7B809A",
                                                            background: "var(--Charcoal, #3A3E3F)",
                                                            // marginTop: "12px",
                                                            display: "flex",
                                                            // width: "492px",
                                                        }}
                                                    >
                                                        <ToggleButton value={true} className="h-40" >
                                                            Sender
                                                        </ToggleButton>
                                                        <ToggleButton value={false} className="h-40" style={{ borderLeft: '1px solid #C7CCD0' }}>
                                                            Other Address
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid></>
                        ) : <></>}
                        {
                            props.watch('ingestion.returnTo') === false && props.watch('ingestion.postIngestion') === "return" ? (
                                <>
                                    <Box p={1} mt={2} mb={-6} ml={4}>
                                        <Grid container direction='column' xs={6} spacing={2}>
                                            <Grid item>
                                                <TextField className={classes.selectLabel} id="outlined-basic" label="Address Line 1" variant="outlined" fullWidth={true} name={`ingestion.returnAddress.address_line_1`} {...props.register(`ingestion.returnAddress.address_line_1`)} />
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                                    <Grid item xs={3}>
                                                        <TextField className={classes.selectLabel} id="Zipcode" placeholder="Enter Zipcode" variant="outlined" fullWidth={true} name='ingestion.returnAddress.zipcode'
                                                            // {...props.register('ingestion.returnAddress.zipcode')}
                                                            {...props.register('ingestion.returnAddress.zipcode', { onBlur: (e) => getLocationDetails(e, fillCityAndStateFieldsaddPeople) })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField className={classes.selectLabel} id="City" placeholder='Town City' variant="outlined" fullWidth={true} name='ingestion.returnAddress.city'
                                                            {...props.register('ingestion.returnAddress.city')} />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField className={classes.selectLabel} id="State" placeholder='State' variant="outlined" fullWidth={true} name='ingestion.returnAddress.state'
                                                            {...props.register('ingestion.returnAddress.state')} />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField className={classes.selectLabel} id="Country" placeholder='Country' variant="outlined" fullWidth={true} name='ingestion.returnAddress.country'
                                                            {...props.register(`ingestion.returnAddress.country`)} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction='row' justifyContent='space-between' spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField className={classes.selectLabel} id="outlined-basic" label="Enter Phone Number" variant="outlined" fullWidth={true} name={`ingestion.returnAddress.phone`} {...props.register(`ingestion.returnAddress.phone`)} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField className={classes.selectLabel} id="outlined-basic" label="Enter Mobile Number" variant="outlined" fullWidth={true} name={`ingestion.returnAddress.mobile`} {...props.register(`ingestion.returnAddress.mobile`)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </>
                            ) : (<></>)
                        }
                        {/* <Grid item style={{ marginTop: '12px' }}>
                            <Typography align='left' className={classes.heading}>Tape Options</Typography>
                            <Grid container directio='row' justifyContent='flex-start'>
                                <Grid item>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={props.watch('ingestion.tapeOptions')}
                                            onChange={handleChangeTapeOptions}
                                        >
                                            <FormControlLabel sx={{ color: '#FFFFFF' }} value="critical" control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked':{color: '#FFC540'} }} />} label="Critical" />
                                            <FormControlLabel sx={{ color: '#FFFFFF' }} value="confidential" control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked':{color: '#FFC540'} }} />} label="Confidential" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Box>
            </Grid >
        </>
    )
}

export const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: "#ffc540",
        // borderRadius: "8px",
        color: "#3a3e3f",
        fontWeight: 600,
        fontSize: "14px",
    },
    "&.MuiToggleButton-root": {
        // color: "#404558",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "4em !important",
        fontSize: "14px",
        width: "164px",
        // border-radius: 8px;
        background: "#3a3e3f",
        color: " #C7CCD0",
        fontFamily: '"Poppins", sans-serif !important',
    },
    "&.shippingSelection": {
        height: "64px !important",
    }
});
const useStyles = makeStyles({
    shippingheading: {
        color: "#fff",
        fontSize: "20px !important",
        fontWeight: "700 !important",
        fontFamily: '"Poppins", sans-serif !important',
    },
    heading: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        color: '#C7CCD0',
        lineHeight: '24px'
    },
    selectLabel: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
});

const sliderLabelTypography = {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    color: '#C7CCD0',
};
const marks = [
    {
        value: 0,
        label: <>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'center', }} >0</Typography>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'center', }} noWrap>(Immediately)</Typography>
        </>,
    },
    {
        value: 90,
        label: <>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'center', }} >90 Days</Typography>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'center', }} noWrap>(Free Storage)</Typography>
        </>,
    },
    {
        value: 180,
        label: <>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'right', }} >6 Months</Typography>
            <Typography sx={{ ...sliderLabelTypography, textAlign: 'right', }} noWrap>(Paid Storage)</Typography></>,
    }
];


const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    300: '#66B2FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B3',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
const Slider = styled(BaseSlider)(
    ({ theme }) => `
    color: #FFC540;
    height: 6px;
    width: 100%;
    padding: 16px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  
    &.${sliderClasses.disabled} { 
      pointer-events: none;
      cursor: default;
      color: #FFC540;
      opacity: 0.5;
    }
  
    & .${sliderClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 12px;
      border-radius: 2px;
      background-color: #FFFFFF;
    }
  
    & .${sliderClasses.track} {
      display: block;
      position: absolute;
      height: 12px;
      border-radius: 2px;
      background-color: currentColor;
    }
  
    & .${sliderClasses.thumb} {
      position: absolute;
      width: 24px;
      height: 24px;
      margin-left: -6px;
      margin-top: -6px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      border: 3px solid currentColor;
      background-color: #FFC540;
  
      &:hover{
        box-shadow: 0 0 0 4px ${alpha(
        theme.palette.mode === 'light' ? blue[200] : blue[300],
        0.3,
    )};
      }
      
      &.${sliderClasses.focusVisible} {
        box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
        outline: none;
      }
  
      &.${sliderClasses.active} {
        box-shadow: 0 0 0 5px ${alpha(
        theme.palette.mode === 'light' ? blue[200] : blue[300],
        0.5,
    )};
        outline: none;
      }
    }
  
    & .${sliderClasses.mark} {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 99%;
      background-color: ${theme.palette.mode === 'light' ? blue[200] : blue[900]};
      top: 43%;
      transform: translateX(-50%);
    }
  
    & .${sliderClasses.markActive} {
      background-color: ${theme.palette.mode === 'light' ? "#FFC540" : "#FFC540"};
    }
  
    & .${sliderClasses.markLabel} {
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 12px;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
      margin-top: 8px;
    }
  `,
);