import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { PrimaryColor } from "../theme/theme";
import {
  Stack,
  Toolbar,
  Tooltip,
  Grid,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getLoginUser } from "../../LocalStorageService";
import { getApiUrl } from "../../global";
import { formats } from "../../global";
import axios from "axios";
import CommonTable from "../../views/Admin/Order/OrderManagement/Audit/CommonTable";

const CustomSlider = styled(Slider)({
  color: "#D9D9D9",
  height: 15,
  "& .MuiSlider-track": {
    height: 15,
    border: "none",
    color: "#FFC540",
  },
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundColor: "#FFC540",
    border: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 16,
    background: "unset",
    padding: 0,
    width: 48,
    height: 48,
    color: "#3A3E3F",
    borderRadius: "50% 50% 50% 50%",
    backgroundColor: "#FFC540",
    transformOrigin: "bottom left",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translateY(0%)",
    },
    "& > *": {},
  },
});

export const Ingestion = ({ value, orderDetail , isAdmin }) => {

  const fakeResponse = {
    "version": "1.1",
    "message": "Found 40 items",
    "result": {
      "31": {
        "data": [
          {
            "item_id": 1,
            "job_id": 1,
            "job": "Job Won",
            "mediatype_id": 2,
            "item_volser": "A00001",
            "vtl_volser": "A00001",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 2,
            "job_id": 1,
            "job": "Job Won",
            "mediatype_id": 2,
            "item_volser": "A00002",
            "vtl_volser": "A00002",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 3,
            "job_id": 2,
            "job": "Job Too",
            "mediatype_id": 2,
            "item_volser": "A00003",
            "vtl_volser": "A00003",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 4,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00004",
            "vtl_volser": "A00004",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 5,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00005",
            "vtl_volser": "A00005",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 6,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00006",
            "vtl_volser": "A00006",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 7,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00007",
            "vtl_volser": "A00007",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 8,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00008",
            "vtl_volser": "A00008",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 9,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00009",
            "vtl_volser": "A00009",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 10,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00010",
            "vtl_volser": "A00010",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 11,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00011",
            "vtl_volser": "A00011",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 12,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00012",
            "vtl_volser": "A00012",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 13,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00013",
            "vtl_volser": "A00013",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 14,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00014",
            "vtl_volser": "A00014",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 15,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00015",
            "vtl_volser": "A00015",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 16,
            "job_id": 4,
            "job": "Alpha",
            "mediatype_id": 2,
            "item_volser": "A00016",
            "vtl_volser": "A00016",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 17,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00017",
            "vtl_volser": "A00017",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 18,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00018",
            "vtl_volser": "A00018",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 19,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00019",
            "vtl_volser": "A00019",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 20,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00020",
            "vtl_volser": "A00020",
            "status_id": 3,
            "status": "Read Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 21,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00021",
            "vtl_volser": "A00012",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 22,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00022",
            "vtl_volser": "A00022",
            "status_id": 11,
            "status": "Extract Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 23,
            "job_id": 5,
            "job": "Bravo",
            "mediatype_id": 2,
            "item_volser": "A00023",
            "vtl_volser": "A00023",
            "status_id": 3,
            "status": "Read Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 24,
            "job_id": 3,
            "job": "Humans Lost",
            "mediatype_id": 2,
            "item_volser": "A00024",
            "vtl_volser": "A00024",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 25,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00025",
            "vtl_volser": "A00025",
            "status_id": 3,
            "status": "Read Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 26,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00026",
            "vtl_volser": "A00026",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 27,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00027",
            "vtl_volser": "A00027",
            "status_id": 11,
            "status": "Extract Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 28,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00028",
            "vtl_volser": "A00028",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 29,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00029",
            "vtl_volser": "A00029",
            "status_id": 3,
            "status": "Read Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 30,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00030",
            "vtl_volser": "A00030",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 31,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00031",
            "vtl_volser": "A00031",
            "status_id": 4,
            "status": "Upload Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 32,
            "job_id": 6,
            "job": "Charlie",
            "mediatype_id": 2,
            "item_volser": "A00032",
            "vtl_volser": "A00032",
            "status_id": 11,
            "status": "Extract Success",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 33,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00033",
            "vtl_volser": "A00033",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 34,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00034",
            "vtl_volser": "A00034",
            "status_id": 1,
            "status": "Created",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 35,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00035",
            "vtl_volser": "A00035",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 36,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00036",
            "vtl_volser": "A00036",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 37,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00037",
            "vtl_volser": "A00037",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 38,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00038",
            "vtl_volser": "A00038",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 39,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00039",
            "vtl_volser": "A00039",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          },
          {
            "item_id": 40,
            "job_id": 7,
            "job": "Delta",
            "mediatype_id": 2,
            "item_volser": "A00040",
            "vtl_volser": "A00040",
            "status_id": 2,
            "status": "Cataloged",
            "backup_format_id": 1,
            "mediatype": "LTO5",
            "backup_format": "Unknown"
          }
        ],
        "count": 40
      }
    }
}

  const useStyles = makeStyles({
    rootMenuItem: {
      "&$selected": {
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "green",
          borderRadius: 12,
          border: "1px solid var(--White, #FFF)",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        border: 0,
        borderRadius: 12,
        border: "1px solid var(--White, #FFF)",
      },
    },
    highlight: {
      background: "#FFC540",
      color: "#3A3E3F",
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
    selectLabel: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          borderColor: "#C7CCD0",
        },
        "&:hover fieldset": {
          borderColor: "#C7CCD0",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#C7CCD0",
        },
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root": {
        color: "rgba(255, 255, 255, 0.5)",
        "&.Mui-focused": {
          color: "#FFC540",
        },
      },
      "& svg": {
        color: "#FFFFFF",
      },
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
  });
  const classes = useStyles();
  const thumbBoxStyle = {
    width: "91px",
    height: "95px",
    borderRadius: "8px",
    border: "1px solid #FFC540",
    position: "absolute",
    top: "calc(100% + 8px)",
    left: `calc(${(value / 100) * 100}% - 45.5px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: PrimaryColor,
    zIndex: 1,
  };
  let state = [
    {
      key: "audit_complete",
      value: "Ingestion Awaited",
    },
    {
      key: "ingestion_started",
      value: "Ingestion Started",
    },
    {
      key: "ingestion_complete",
      value: "Ingestion Complete",
    },
  ];
  const getCustomerDetails = async () => {
    const customerId = getLoginUser()
      ? getLoginUser().user.customer_uuid
      : null;
    let url = await getApiUrl();
    let apiUrlcustomer =
      url + process.env.REACT_APP_API_BASE_URL + `/customers/${customerId}/`;
    axios
      .get(apiUrlcustomer, {})
      .then((response) => {
        if (response?.status == 200) {
          console.log("Data", JSON.stringify(response.data));

          // setData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    let _slice = allRestoreList.slice(
      (newPage - 1) * 10,
      (newPage - 1) * 10 + 10
    );
    setRestoreData(_slice);
  };

  function mapOrderStep(_state) {
    if (_state) {
      let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
      return step?.value ? step.value : _state;
    } else {
      return _state;
    }
  }
  const [allRestoreList, setAllRestoreList] = useState([]);
  const [restoreData, setRestoreData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tapeCount, setTapeCount] = useState(0);
  const [completedTap, setCompletedTap] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(currentPage * 10, tapeCount);
  React.useEffect(() => {
    // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
    getCustomerDetails();
    let payload = {
      type: "man_item_list",
      request: {
        cid: 15,
        type: "man_item_list",
      },
    };
    if(isAdmin){
      let dynamicArray = ''
      const dynamicObject = Object.values(fakeResponse.result)
     dynamicObject.forEach(i => 
     dynamicArray = i.data )
      setAllRestoreList(
      dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
      );
      setRestoreData(
      dynamicArray
      .filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
      .slice(0, 10)
      );
      setTotalPages(
      Math.ceil(
      dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
        .length / 10
      )
      );
      setTapeCount(
      dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3)).length
      );
      setCompletedTap(
      dynamicArray
      .filter((f) => f.job_id == (orderDetail.tapeark_job_id || 3))
      .filter(
        (i) =>
          i.status == "Read Success" ||
          i.status == "Upload Success" ||
          i.status == "Extract Success"
      ).length
);
    }
    // setRestoreData(fakeResponse.result["31"].data);
    // setTapeCount(fakeResponse.result["31"].data.length);
    // setCompletedTap(
    //   fakeResponse.result["31"].data.filter((i) => i.status == "Cataloged").length
    // );
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
          setAllRestoreList(
           dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
          );
          setRestoreData(
           dynamicArray
              .filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
              .slice(0, 10)
          );
          setTotalPages(
            Math.ceil(
             dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
                .length / 10
            )
          );
          setTapeCount(
           dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3)).length
          );
          setCompletedTap(
           dynamicArray
              .filter((f) => f.job_id == (orderDetail.tapeark_job_id || 3))
              .filter(
                (i) =>
                  i.status == "Read Success" ||
                  i.status == "Upload Success" ||
                  i.status == "Extract Success"
              ).length
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
   
    
  }, []);

  return (
    <Box padding={6} minHeight={"100vh"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            flex: "1 1 100%",
            pt: 2,
            textAlign: "left",
            px: 2,
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "22px",
            color: "#FFFFFF",
          }}
          id="state-title"
          component="div"
        >
          {/* Changes here to show appropriate status message */}
          {/* {(value === 100) ? 'Ingestion Completed' : 'Ingestion Started'} */}
          {mapOrderStep(orderDetail.status)}
        </Typography>
        <Typography
          sx={{
            pt: 2,
            pr: 2,
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
            color: "#C7CCD0",
          }}
          id="state-completion-time"
          component="div"
          noWrap
          width={"-webkit-fill-available"}
          style={{ textAlign: "right" }}
        >
          6 July, 23
        </Typography>
      </Box>

      <Typography
        sx={{
          flex: "1 1 100%",
          pt: 2,
          textAlign: "left",
          px: 2,
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          color: "#FFFFFF",
        }}
        id="state-text"
        component="div"
      >
        Your tapes are being ingested. You will be notified once it is
        completed.
      </Typography>

      <Box style={{ position: "relative" }}>
        <CustomSlider
          disabled
          value={((completedTap / tapeCount) * 100).toFixed(2)}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v}%`}
        />
        <Box style={thumbBoxStyle}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "33px",
                color: "#FFC540",
              }}
            >
              {completedTap}/{tapeCount}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
              }}
            >
              Tapes Ingested
            </Typography>
          </Stack>
        </Box>
      </Box>
      <CommonTable 
      startIndex={startIndex}
      endIndex={endIndex}
      handlePageChange={handlePageChange}
      restoreData={restoreData}
      totalPages={totalPages}
      currentPage={currentPage}
      tapeCount = {tapeCount}    
      classes = {classes}
      
      /> 
    </Box>
  );
};
