import * as React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { policies } from "../../global";
import axios from "axios";
const rows = [
  { Name : 'Tape 1', DateCreated : '21-Jan-2023', TimeRemaining : 675, color : "#BD5F5F"},
  { Name : 'Tape 1', DateCreated : '21-Jan-2023', TimeRemaining : 68, color : "#F4BF37"},
  { Name : 'Tape 1', DateCreated : '21-Jan-2023', TimeRemaining : 6.0, color : "#F4BF37"},
  { Name : 'Tape 1', DateCreated : '21-Jan-2023', TimeRemaining : 6.0, color : "#38AA58"},
  { Name : 'Tape 1', DateCreated : '21-Jan-2023', TimeRemaining : 6.0, color : "#007E23"},
]
const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export const StoragePolicies = () => {
    const classes = useStyles();
    const [storageData, setStorageData] = React.useState([]);

    React.useEffect(() => {
        let payload = {
            type:'rep_policy',
            "request": {
                "cid": 15,
                "type": "policies"
            }
        };
        axios.post(policies, payload)
            .then(response => {
                if (response?.status == 200) {
                    let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
                    setStorageData(dynamicArray);
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, []);
    return (
        <div className={classes.mainContainer}>
            <Box p={3}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                            <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Storage Policies</Typography>
                            </Grid>
                            {/* <Grid item>
                                <CloseIcon className={classes.closeIcon} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer style={{backgroundColor:'transparent'}}>
                            <Table sx={{ maxWidth:'100%'}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell  style={{color:'#FFFFFF',fontWeight:700,fontSize:'14px'}}>Name</TableCell>
                                        <TableCell  style={{color:'#FFFFFF',fontWeight:700,fontSize:'14px'}}>Count</TableCell>
                                        <TableCell  style={{color:'#FFFFFF',fontWeight:700,fontSize:'14px'}}>Storage TB</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {storageData && storageData.length > 0 && storageData.map((row)=>{
                                        return (
                                           <TableRow 
                                           key={row.policy} 
                                           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left" style={{color:'#FFFFFF',fontWeight:400,fontSize:'20px'}}>{row.policy}</TableCell>
                                            <TableCell align="left" style={{color:'#FFFFFF',fontWeight:400,fontSize:'20px'}}>{row.files}</TableCell>
                                            <TableCell align="left" style={{color:'#FFFFFF',fontWeight:400,fontSize:'20px'}}>{formatBytes(row.bytes)}</TableCell>
                                           </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

const useStyles = makeStyles({
    mainContainer: {
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
        maxWidth:'100%'
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    tableHeading:{
        color:'#FFFFFF',
        fontWeight:400,
        fontSize:'14px'
    },
    tableData:{
        color:'#FFFFFF',
        fontWeight:400,
        fontSize:'20px'
    }
});