import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

// Line 548: 55: 'visuallyHidden' is not defined  no - undef
//   Line 879:26:  'style' is not defined           no-undef
//   Line 894: 62: 'ButtonColor' is not defined     no - undef
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    Tab,
    Tabs,
    Typography,
    Divider,
    CircularProgress
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider, alpha, createTheme } from "@mui/material/styles";
import { makeStyles, styled } from '@mui/styles';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import { env } from '../../../../env';
import { ButtonColorGradient, flexStyles } from '../../../../component/theme/theme';
import { Details } from '../../../../component/details/details';
import { AdminShippingManagement } from './Shipping/shippingManagement';
import { AdminAuditManagement } from './Audit/auditManagement';
import { AdminIngestion } from './Ingestion/ingestion';
import { OrderDocuments } from './OrderDocuments/orderDocuments';

import { getApiUrl } from "./../../../../global";
import { StyledButtonContained } from '../../AccountCreation/accountCreation';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const screenHeight = window.innerHeight;

const adminOrderManagementTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    border: '1px solid #FFC540',
                    color: '#FFC540',
                    '&:hover': {
                        border: '1px solid #FFC540',
                        color: '#FFC540',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF',
                    '&:hover': {
                        border: '1px solid #FFFFFF',
                        color: '#FFFFFF',
                    },
                },
                outlinedSizeSmall: {
                    width: '90px',
                    height: '40px',
                    borderRadius: '30px',
                },
                outlinedSizeMedium: {
                    width: '220px',
                    height: '60px',
                    borderRadius: '30px',
                },
                root: {
                    textTransform: 'none',

                },
                outlinedSizeSmall: {
                    width: '90px',
                    height: '40px',
                    borderRadius: '30px',
                },
                sizeMedium: {
                    width: '220px',
                    height: '60px',
                    borderRadius: '30px',
                },
                containedPrimary: {
                    background: ButtonColorGradient,
                    color: '#3A3E3F',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    width: '450px',
                    height: '60px',
                    backgroundColor: 'rgba(217,217,217,0.1)',
                    borderRadius: '8px',
                    color: '#FFFFFF',
                    '&.Mui-focused': {
                        backgroundColor: 'rgba(217,217,217,0.1)',
                        width: '450px',
                        height: '60px',
                        borderRadius: '8px',
                    },
                },
                input: {
                    //fontFamily: 'Poppins', 
                    fontWeight: 400,
                    lineHeight: '25px',
                    fontSize: 18,
                    letterSpacing: '0em',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#C7CCD0',
                    '&.Mui-focused': {
                        color: '#FFC540'
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '&.vertical-pane': {
                        minHeight: '100vh',
                        height: '100%',
                        borderRight: 'none',
                        borderRadius: '21px, 0px, 0px, 21px',
                        textTransform: 'none',
                        '& .css-ra670y-MuiButtonBase-root-MuiTab-root': {
                            maxWidth: '100%',
                        },
                    },
                },
                vertical: {
                    '&.vertical-pane': {
                        background: 'rgba(217,217,217,0.1)',
                        color: '#FFFFFF',
                        borderRight: 'none',
                    },
                },
                flexContainer: {
                    '&.vertical-pane': {
                        paddingTop: '50px',
                        marginLeft: '30px',
                    },
                },
                indicator: {
                    '&.vertical-pane': {
                        background: '#FFC540',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.vertical-pane': {
                        alignItems: 'flex-start',
                        textTransform: 'none',
                        height: '40px',
                        fontFamily: 'Poppins',
                        color: '#FFFFFF',
                        '&.Mui-selected': {
                            color: '#FFC540',
                            //borderRadius: '21px 0px 0px 21px',
                            height: '40px',
                        },
                    }
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '25%',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '8px',
                            borderColor: '#C7CCD0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#C7CCD0',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#C7CCD0',
                        }
                    },
                    '& .MuiOutlinedInput-input': {
                        color: '#FFFFFF',
                    },
                    '& svg': {
                        color: '#FFFFFF'
                    },
                },
            },
        },
    },
});


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'stretch',
    },
    title: {
        fontFamily: 'Lato',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#FFFFFF',
        padding: 2,
    },
    divider: {
        border: '1px solid #EAEAEA',
        width: '100%'
    },
    tabs: {
        background: '#FFFFFF1A',
        color: '#FFFFFF',
        borderRight: 'none',
        alignContent: 'left'
    },
    activeTab: {
        background: '#FFC540 !important',
        color: '#3A3E3F !important',
    },
    tabPanel: {
        background: '#3A3E3F',
        color: '#FFFFFF',
        padding: 2,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CustomTabList = styled(Tabs)(() => ({
    borderRight: 0,
    maxWidth: '100% !important',
    '& .MuiTabs-flexContainer': {
        width: '100%',
    },
    '& .MuiTabs-scroller': {
        background: 'rgba(255,255,255,0.1)',
    },
    '& .MuiTabs-indicator': {
        background: '#FFC540',
    },
    '& .MuiTab-root': {
        color: '#FFFFFF',
        borderRadius: '21px, 0px, 0px, 21px',
    },
    textTransform: 'none',
}));

const CustomTab = styled(Tab)(() => ({
    mt: 4,
    textTransform: 'none',
    '&.Mui-selected': {
        color: '#FFC540',
        //borderRadius: '21px 0px 0px 21px',
        height: '40px',
    },
    '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
    }
}));

export const AdminOrderManagement = () => {


    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const screenHeight = window.innerHeight;
    console.log(screenHeight);

    const params = useParams();
    const [orderId, setOrderId] = React.useState(params.orderId);


    //const apiUrl = "/assets/json/getAdminOrderUUID";

    const [orderDetail, setOrderDetail] = React.useState({});

    const [customer, setCustomer] = React.useState(null);
    const [shipments, setShipments] = React.useState(null);

    //Can be moved to appropriate components
    const getCustomerDetail = async (order) => {
        let url = await getApiUrl();
        const customerApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/customers/" + (order ? order : orderDetail)?.customer + '/';
        axios
            .get(customerApiUrl)
            .then(data => {
                setCustomer(data.data);
            })
            .catch(err => console.log(err));
    };

    //Can be moved to appropriate components
    const getShipmentDetails = async (order) => {
        let url = await getApiUrl();
        const shipmentsApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + (order ? order : orderDetail)?.uuid + '/shipments/';
        //setShipments([]);
        axios
            .get(shipmentsApiUrl)
            .then(data => {
                setShipments(data.data.results);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        getOrderDetail();
    }, []);

    const getOrderDetail = async () => {
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + params.orderId + '/';
        axios
            .get(apiUrl)
            .then(data => {
                setOrderDetail(data.data);

                if (customer === null) {
                    getCustomerDetail(data.data);
                }
                if (shipments === null) {
                    getShipmentDetails(data.data);
                }
            }).catch(error => console.log(error));
    };

    const handleShipmentSubmit = () => {
        getShipmentDetails();
    };

    return (
        <ThemeProvider theme={adminOrderManagementTheme}>
            <Grid container direction='column' px={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <img src="/assets/img/cart.svg" alt="" />
                    <img src="/assets/img/arrow_left.svg" alt="" />
                    <Link to='/Orders' passHref style={{ textDecoration: 'none' }}> <small className="grey-color">Your Orders</small></Link>
                    <img src="/assets/img/arrow_left.svg" alt="" />
                    <small className="grey-color">{orderId}</small>
                </Box>

                {/* Header */}
                <Grid container spacing={2} paddingBottom={1} paddingTop={3}>
                    <Grid item xs={12} className="hideElement">
                        <Typography variant="h5" mt={4} sx={{
                            fontFamily: 'Lato',
                            fontSize: '28px',
                            fontWeight: 700,
                            lineHeight: '34px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            color: '#FFFFFF',
                        }}>
                            {orderDetail.order_number}
                        </Typography>
                    </Grid>
                    <Grid item md={12} mb={2} className="hideElement">
                        <Divider sx={{ background: '#EAEAEA', width: '100%' }} orientation='horizontal' />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ minHeight: 'max-content', border: '1px solid #D8D8D8', borderRadius: '5px', mb: '100px' }}>
                    <Grid container>
                        <Grid item xs={2}>
                            <CustomTabList
                                orientation='vertical'
                                value={activeTab}
                                onChange={handleTabChange}
                                className={`${'vertical-pane'} ${classes.tabs}`}>
                                <CustomTab className={`${(activeTab === 0) ? classes.activeTab : ''} ${'vertical-pane'}`} label="Order" {...a11yProps(0)} style={{ width: '100%', maxWidth: '100%' }} />
                                <CustomTab disabled={orderDetail.status === 'draft'} className={`${(activeTab === 1) ? classes.activeTab : ''} ${'vertical-pane'}`} label="Shipping" {...a11yProps(1)} style={{ width: '100%', maxWidth: '100%' }} />
                                <CustomTab disabled={orderDetail.status === 'draft'} className={`${(activeTab === 2) ? classes.activeTab : ''} ${'vertical-pane'}`} label="Audit" {...a11yProps(2)} style={{ width: '100%', maxWidth: '100%' }} />
                                <CustomTab disabled={orderDetail.status === 'draft'} className={`${(activeTab === 3) ? classes.activeTab : ''} ${'vertical-pane'}`} label="Ingestion" {...a11yProps(3)} style={{ width: '100%', maxWidth: '100%' }} />
                                <CustomTab disabled={orderDetail.status === 'draft'} className={`${(activeTab === 4) ? classes.activeTab : ''} ${'vertical-pane'}`} label="Documents" {...a11yProps(4)} style={{ width: '100%', maxWidth: '100%' }} />
                            </CustomTabList>
                        </Grid>

                        <Grid item xs={10} className={classes.tabPanel}>
                            <TabPanel value={activeTab} index={0}>
                                {(orderDetail && customer) && (
                                    <Details orderDetail={orderDetail} customer={customer} />
                                )}
                                <Box sx={{
                                    ...flexStyles.flexRowEnd,
                                    pointerEvents: (orderDetail.status === 'draft') ? 'all' : 'none',
                                    opacity: (orderDetail.status === 'draft') ? 1 : 0.5,
                                }}>
                                    <Link to={`/OrderCreation?uuid=${orderId}`} passHref state={{ data: orderDetail }}>
                                        <StyledButtonContained variant='contained' color='primary' size='medium'>Edit</StyledButtonContained>
                                    </Link>
                                </Box>
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                {(shipments) && (
                                    <AdminShippingManagement
                                        shipments={shipments}
                                        shippingMode={orderDetail.shipping_mode}
                                        customer={customer}
                                        onShipmentSubmit={() => { getShipmentDetails(); }} />
                                )}
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                                {orderDetail && <AdminAuditManagement orderDetail={orderDetail} />}
                            </TabPanel>
                            <TabPanel value={activeTab} index={3}>
                                {orderDetail && <AdminIngestion orderDetail={orderDetail} />}
                            </TabPanel>
                            <TabPanel value={activeTab} index={4}>
                                {orderDetail && <OrderDocuments orderDetail={orderDetail} />}
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
};



