import { useState,useEffect } from "react";
import { Grid, Paper, Box, Typography, Button, TextField, Tooltip, tooltipClasses, List, Link, IconButton, Tabs, Tab, Divider, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HelpOutline, Close, CloudUploadOutlined, Article } from "@mui/icons-material";
import { ButtonColor, ButtonColorGradient } from "../../theme/theme";
import { styled } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material";
import { StyledButtonContained } from "../../styled_components/styled_components";

const adminDocumentTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC540'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                    color: '#3A3E3F',
                },
                containedSecondary: {
                    background: '#3A3E3F',
                    color: '#FFC540',
                    border: '1px solid #FFC540',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 197, 64, 0.1)',
                    },
                }
            },
        },
        MuiSvgIcon: {
            defaultProps: {
                sx: {
                    color: '#FFFFFF',
                }
            }
        }

    },
});

const FileInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const allowedMimeTypes = [
];


export const AdminDocument = (props) => {

    const [contracts, setContracts] = useState([]);
    // const [signedContracts, setSignedContracts] = useState([]);
    //Indices of files where upload is in progress
    //const [signedContractUploadInProgress, setSignedContractUploadInProgress] = useState([0]);

    // const [billFile, setBillFile] = useState([]);

    const classes = useStyles();

    const handleContractFileSelect = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let selectedFiles = Array.from(e.target.files);
            setContracts([...contracts, ...selectedFiles]);

        }
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleContractFileDrop = (e) => {
        e.preventDefault();
        if (e?.dataTransfer?.files) {
            console.log(e?.dataTransfer?.files);
            let selectedFiles = Array.from(e?.dataTransfer?.files);
            setContracts([...contracts, ...selectedFiles]);
        }
    };

    const removeContractFile = (name) => {
        setContracts(contracts.filter((file) => file.name !== name));
    };

    const handleSignedContractFileSelect = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let selectedFiles = Array.from(e.target.files);
            props.uploadFile(selectedFiles, 'signed_contract');
            // setSignedContracts([...signedContracts, ...selectedFiles]);
            // updateFormSignedContracts([...signedContracts, ...selectedFiles]);
        }
    };

    const handleSignedContractFileDrop = (e) => {
        e.preventDefault();
        if (e?.dataTransfer?.files) {
            let selectedFiles = Array.from(e?.dataTransfer?.files);
            props.uploadFile(selectedFiles, 'signed_contract');

            // setSignedContracts([...signedContracts, ...selectedFiles]);
            // updateFormSignedContracts([...signedContracts, ...selectedFiles]);
        }
    };

    const removeSignedContractFile = (name, type) => {
        // setSignedContracts(signedContracts.filter((file) => file.name !== name));
        // updateFormSignedContracts(signedContracts.filter((file) => file.name !== name));
    };

    const updateFormSignedContracts = (data) => {
        setTimeout(() => {
            props.setValue("signedContracts", data)
        }, 500);
    }
    // Bill

    const handleBillFileSelect = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let selectedFiles = Array.from(e.target.files);
            props.uploadFile(selectedFiles, 'bill');
            // setBillFile([...billFile, ...selectedFiles]);
            // updateFormBillFile([...billFile, ...selectedFiles]);
        }
    };

    const handleBillFileDrop = (e) => {
        e.preventDefault();
        if (e?.dataTransfer?.files) {
            let selectedFiles = Array.from(e?.dataTransfer?.files);
            props.uploadFile(selectedFiles, 'bill');

            // setBillFile([...billFile, ...selectedFiles]);
            // updateFormBillFile([...billFile, ...selectedFiles]);
        }
    };

    const removeBillFile = (name, type) => {
        // setBillFile(billFile.filter((file) => file.name !== name));
        // updateFormBillFile(billFile.filter((file) => file.name !== name));
    };
    const updateFormBillFile = (data) => {
        setTimeout(() => {
            props.setValue("billFile", data)
        }, 500);
    }
    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    return (
        <ThemeProvider theme={adminDocumentTheme}>
            <Box height={'100vh'} sx={{
                border: '1px solid #C7CCD0',
                borderRadius: '8px',
                mt: 2,
                mb: '100px',
            }}>
                <Box
                    px={4}
                    width={'inherit'}
                    height={'4rem'}
                    bgcolor={'#272727'}
                    borderRadius={'8px 8px 0px 0px'}
                    display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }} fontFamily={'Poppins'}>
                        Documents & Contract
                    </Typography>
                </Box>

                <Grid
                    container
                    direction="row"
                    spacing={4}
                    justifyContent="flex-start"
                    height={'inherit'}
                    p={4}
                >

                    {/* Signed Contract */}
                    <Grid item xs={4}>
                        <Grid container direction="column" spacing={1}>
                            {/* Title */}
                            <Grid item display={'flex'} justifyContent={'space-between'} height={'10%'}>
                                <Typography
                                    align="left"
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Signed Contract
                                </Typography>
                            </Grid>

                            <Grid item maxHeight={'fit-content'}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    padding={2}
                                    style={{ background: '#3A3E3F', border: '1px solid #C7CCD0', borderRadius: '12px' }}
                                >
                                    {/* Tab section */}
                                    <Grid item height={'10%'}>
                                    </Grid>
                                    {/* File Attachment display section */}
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            //spacing={2}
                                            style={{ fontSize: "18px", color: "#FFFFFF" }}
                                        >

                                            {props.signedContracts.map(file => (
                                                <Grid item xs={12}>
                                                    <Grid container sx={{
                                                        borderRadius: '8px',
                                                        border: '1px solid #FFFFFF',
                                                        p: 0.5,
                                                        mb: 1,
                                                    }}>
                                                        <Grid item xs={1} display={'flex'} alignItems={'center'}>
                                                            <Article sx={{ height: '30px', width: '30px' }} />
                                                        </Grid>
                                                        <Grid item direction={'column'} textAlign={'left'} xs={8}>
                                                            <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.name}</Typography>
                                                            <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{formatBytes(file.size)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                                            <IconButton onClick={() => { props.removeFile(file.uuid, 'signed_contract') }}><Close /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    {/*                                                 <Stack direction={'column'} display={'flex'} alignItems={'flex-start'}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.name}</Typography>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.size} bytes</Typography>
                                                </Stack>
                                                <LinearProgress variant='determinate' value={70} sx={{
                                                    backgroundColor: '#3A3E3F',
                                                    height: 10,
                                                    borderRadius: 8,
                                                    border: '2px solid #FFC540',
                                                    '& .MuiLinearProgress-root': {
                                                        borderColor: '#FFC540',
                                                    },
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: '#FFC540',
                                                    }
                                                }} /> */}
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </Grid>
                                    {/* File upload section */}
                                    <Grid item minHeight={'40%'} padding={'22px'} className={classes.dashedBorder} >
                                        {/* File */}
                                        <div onDrop={handleSignedContractFileDrop} onDragOver={(e) => e.preventDefault()}>
                                            <Stack direction={'column'} spacing={2} alignItems={'center'}>
                                                <CloudUploadOutlined sx={{ color: '#FFFFFF' }} />
                                                <Typography sx={{ color: '#FFFFFF', lineHeight: '28px', }}>Browse for the files on your computer or Drag them here</Typography>
                                                <Button
                                                    size='large'
                                                    component='label'
                                                    variant='contained'
                                                    fullWidth
                                                    sx={{
                                                        width: '220px',
                                                        background: ButtonColorGradient,
                                                        borderRadius: '30px',
                                                        height: '60px',
                                                        textTransform: 'none',
                                                    }}>
                                                    Add Files
                                                    <FileInput type='file' multiple onChange={handleSignedContractFileSelect} />
                                                </Button>
                                            </Stack>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Bill */}
                    <Grid item xs={4}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item display={'flex'} justifyContent={'space-between'} height={'10%'}>
                                <Typography
                                    align="left"
                                    style={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Bill
                                </Typography>
                            </Grid>

                            <Grid item maxHeight={'fit-content'}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    padding={2}
                                    style={{ background: '#3A3E3F', border: '1px solid #C7CCD0', borderRadius: '12px' }}
                                >
                                    {/* Tab section */}
                                    <Grid item height={'10%'}>
                                    </Grid>
                                    {/* File Attachment display section */}
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            //spacing={2}
                                            style={{ fontSize: "18px", color: "#FFFFFF" }}
                                        >
                                            {props.billFile.map(file => (
                                                <Grid item xs={12}>
                                                    <Grid container sx={{
                                                        borderRadius: '8px',
                                                        border: '1px solid #FFFFFF',
                                                        p: 0.5,
                                                        mb: 1,
                                                    }}>
                                                        <Grid item xs={1} display={'flex'} alignItems={'center'}>
                                                            <Article sx={{ height: '30px', width: '30px' }} />
                                                        </Grid>
                                                        <Grid item direction={'column'} textAlign={'left'} xs={8}>
                                                            <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file?.name}</Typography>
                                                            <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{formatBytes(file.size)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                                            <IconButton onClick={() => { props.removeFile(file?.uuid, 'bill') }}><Close /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {/* {billFile && (
                                            <Grid item xs={12}>
                                                <Grid container sx={{
                                                    borderRadius: '8px',
                                                    border: '1px solid #FFFFFF',
                                                    p: 0.5,
                                                    mb: 1,
                                                }}>
                                                    <Grid item xs={1} display={'flex'} alignItems={'center'}>
                                                        <Article sx={{ height: '30px', width: '30px' }} />
                                                    </Grid>
                                                    <Grid item direction={'column'} textAlign={'left'} xs={8}>
                                                        <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{billFile.name}</Typography>
                                                        <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{billFile.size} bytes</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                                        <IconButton onClick={() => { removeBillFile() }}><Close /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )} */}
                                        </Grid>
                                    </Grid>
                                    {/* File upload section */}
                                    <Grid item minHeight={'40%'} padding={'22px'} className={classes.dashedBorder} >
                                        {/* File */}
                                        <div onDrop={handleBillFileDrop} onDragOver={(e) => e.preventDefault()}>
                                            <Stack direction={'column'} spacing={2} alignItems={'center'}>
                                                <CloudUploadOutlined sx={{ color: '#FFFFFF' }} />
                                                <Typography sx={{ color: '#FFFFFF', lineHeight: '28px', }}>Browse for the files on your computer or Drag them here</Typography>
                                                <Button
                                                    size='large'
                                                    component='label'
                                                    variant='contained'
                                                    fullWidth
                                                    sx={{
                                                        width: '220px',
                                                        background: ButtonColorGradient,
                                                        borderRadius: '30px',
                                                        height: '60px',
                                                        textTransform: 'none',
                                                    }}>
                                                    Add Files
                                                    <FileInput type='file' multiple onChange={handleBillFileSelect} />
                                                </Button>
                                            </Stack>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
        </ThemeProvider>
    );
};

const useStyles = makeStyles({
    input: {
        borderRadius: "8px 0px 0px 8px",
        border: "1px solid #FFFFFF !important",
        color: '#FFFFFF !important',
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: '#C7CCD0'
            }
        },
        "& .MuiOutlinedInput-input": {
            color: '#FFFFFF'
        },
    },
    dashedBorder: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        borderRadius: '12px',
    },
    virtualMachineLinkButton: {
        background: ButtonColorGradient,
        borderRadius: '0px 11px 11px 0px !important',
        color: '#3A3E3F !important',
        textTransform: "none !important",
    }
});
