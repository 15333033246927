import axios from "axios";
import { getApiUrl } from './global';
//import { env } from "./env";
export const getLocationDetails = async (event,callBack) => {
    let zip = event.target.value;
    // let possibleLocalities = geocodeResponseToCityState({
    //     "results" : 
    //     [
    //        {
    //           "address_components" : 
    //           [
    //              {
    //                 "long_name" : "452007",
    //                 "short_name" : "452007",
    //                 "types" : 
    //                 [
    //                    "postal_code"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "Ram Bagh",
    //                 "short_name" : "Ram Bagh",
    //                 "types" : 
    //                 [
    //                    "political",
    //                    "sublocality",
    //                    "sublocality_level_1"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "Indore",
    //                 "short_name" : "Indore",
    //                 "types" : 
    //                 [
    //                    "locality",
    //                    "political"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "Indore",
    //                 "short_name" : "Indore",
    //                 "types" : 
    //                 [
    //                    "administrative_area_level_3",
    //                    "political"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "Indore Division",
    //                 "short_name" : "Indore Division",
    //                 "types" : 
    //                 [
    //                    "administrative_area_level_2",
    //                    "political"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "Madhya Pradesh",
    //                 "short_name" : "MP",
    //                 "types" : 
    //                 [
    //                    "administrative_area_level_1",
    //                    "political"
    //                 ]
    //              },
    //              {
    //                 "long_name" : "India",
    //                 "short_name" : "IN",
    //                 "types" : 
    //                 [
    //                    "country",
    //                    "political"
    //                 ]
    //              }
    //           ],
    //           "formatted_address" : "Ram Bagh, Indore, Madhya Pradesh 452007, India",
    //           "geometry" : 
    //           {
    //              "bounds" : 
    //              {
    //                 "northeast" : 
    //                 {
    //                    "lat" : 22.734603,
    //                    "lng" : 75.86909009999999
    //                 },
    //                 "southwest" : 
    //                 {
    //                    "lat" : 22.6946552,
    //                    "lng" : 75.8330289
    //                 }
    //              },
    //              "location" : 
    //              {
    //                 "lat" : 22.7151159,
    //                 "lng" : 75.8621101
    //              },
    //              "location_type" : "APPROXIMATE",
    //              "viewport" : 
    //              {
    //                 "northeast" : 
    //                 {
    //                    "lat" : 22.734603,
    //                    "lng" : 75.86909009999999
    //                 },
    //                 "southwest" : 
    //                 {
    //                    "lat" : 22.6946552,
    //                    "lng" : 75.8330289
    //                 }
    //              }
    //           },
    //           "place_id" : "ChIJHSUpAAn9YjkRIgHVYzw5rtM",
    //           "postcode_localities" : 
    //           [
    //              "11 Bungalow Colony",
    //              "Ada Bazar",
    //              "BSF",
    //              "Bairathi Colony Number 2",
    //              "Bakshi Bag Basti",
    //              "Bakshi Gali",
    //              "Balda Colony",
    //              "Barabai",
    //              "Baxi Colony",
    //              "Bhoi Mohalla",
    //              "Biyabani",
    //              "Bombay Bazar",
    //              "Builders Colony",
    //              "Champa Bagh",
    //              "Chhatribagh",
    //              "Chhoti Gwaltoli",
    //              "Chiman Bagh",
    //              "DRP Line",
    //              "Daulatganj",
    //              "Dilip Singh Colony",
    //              "Gadi Adda",
    //              "Gautampura",
    //              "Godha Colony",
    //              "Godha Colony",
    //              "Gulzar Colony",
    //              "Guru Nanak Colony",
    //              "Gurunanak Nagar",
    //              "Harijan Mohalla",
    //              "Hemson Colony",
    //              "Imli Bazar",
    //              "Indira Gandhi Nagar",
    //              "Indore GPO",
    //              "Jabran Colony",
    //              "Jail Road",
    //              "Jairampur Colony",
    //              "Jamna Nagar",
    //              "Jawahar Nagar",
    //              "Joshi Mohalla",
    //              "Juni Indore",
    //              "Kabutar Khana",
    //              "Kagdipura",
    //              "Kamathipura",
    //              "Khatipura",
    //              "Kishanpura",
    //              "Koyla Bakhal",
    //              "Lodhipura",
    //              "Loha Mandi",
    //              "MTH Compound",
    //              "Mahal Kacheri",
    //              "Mari Mata Square",
    //              "Martand Chowk",
    //              "Moti Tabela",
    //              "Nagar Nigam",
    //              "Nandlalpura",
    //              "Narayan Bagh",
    //              "Nayapura",
    //              "New Harsiddhi Nagar",
    //              "New Siyaganj",
    //              "Normal School Colony",
    //              "North Toda",
    //              "Nursing Bazaar",
    //              "Pagnis Paga",
    //              "Palsikar Colony",
    //              "Prem Nagar",
    //              "Prem Nagar",
    //              "Rajesh Nagar",
    //              "Rajmahal Colony",
    //              "Rajwada",
    //              "Ram Bagh",
    //              "Ranipura",
    //              "Ravidas Pura",
    //              "Roop Ram Nagar",
    //              "Sadar Bazar",
    //              "Samajwadi Nagar",
    //              "Scheme 71",
    //              "Shankar Bagh",
    //              "Shekhar Nagar",
    //              "Sikh Mohalla",
    //              "Silawat Pura",
    //              "Siyaganj",
    //              "Snehlataganj",
    //              "South Hathipala",
    //              "South Toda",
    //              "Swastik Nagar",
    //              "Tilak Path",
    //              "Triveni Colony",
    //              "Triveni Colony",
    //              "Vallabh Nagar",
    //              "Vasudev Nagar",
    //              "Veer Savarkar Market"
    //           ],
    //           "types" : 
    //           [
    //              "postal_code"
    //           ]
    //        }
    //     ],
    //     "status" : "OK"
    //  });
    // fillCityAndStateFields(possibleLocalities);

    if (zip.length>3) {
/*         let apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            zip +
            "&key=" +
            process.env.REACT_APP_API_KEY_LOCATION; */
            let url = await getApiUrl();
            const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/locations/";
        axios.post(apiUrl,{address: zip}).then((response) => {
            // console.log(res)
            //parse the response for a list of matching city/state
            let possibleLocalities = geocodeResponseToCityState(response.data);
            // fillCityAndStateFields(possibleLocalities);
            callBack(possibleLocalities)
        }).catch(function (error) {
            console.log(error);
        });;
    }

}


export const geocodeResponseToCityState = (geocodeJSON) => {
    //will return and array of matching {city,state} objects
    var parsedLocalities = [];
    if (geocodeJSON.results.length) {
        for (var i = 0; i < geocodeJSON.results.length; i++) {
            var result = geocodeJSON.results[i];
            var locality = {};

            for (var j = 0; j < result.address_components.length; j++) {
                var types = result.address_components[j].types;
                for (var k = 0; k < types.length; k++) {
                    if (types[k] == "locality") {
                        locality.city = result.address_components[j].long_name;
                    } else if (types[k] == "administrative_area_level_1") {
                        locality.state = result.address_components[j].long_name;
                    } else if (types[k] == "country") {
                        locality.country = result.address_components[j].long_name;
                    }
                }
            }
            parsedLocalities.push(locality);

            // //check for additional cities within this zip code
            // if (result.postcode_localities) {
            //     for (var l = 0; l < result.postcode_localities.length; l++) {
            //         parsedLocalities.push({
            //             city: result.postcode_localities[l],
            //             state: locality.state,
            //         });
            //     }
            // }
        }
    } else {
        console.log("error: no address components found");
    }
    return parsedLocalities;
}