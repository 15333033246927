import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from "axios";
import React, { useState } from "react";
import OtpInput from 'react-otp-input';
//import { env } from "../../env";
import { makeStyles } from '@mui/styles';
import { setLoginUser } from '../../LocalStorageService';
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "./../../global";
import { StyledButtonContained } from '../Admin/AccountCreation/accountCreation';

const useStyles = makeStyles({
    heading: {
        color: " #4B4B4D",
        fontSize: "21px",
        fontWeight: "500px",
        lineHeight: "40px",
    },
    mainContainer: {
        display: "flex  !important",
        justifyContent: "center  !important",
        alignItems: "center  !important",
        height: "100%  !important",
    },
    imgContainer: {
        display: "flex !important ",
        flexDirection: "column  !important",
        height: "100%  !important",
        alignItems: "flex-start  !important",
        justifyContent: "center  !important",
        padding: "35px  !important",
    },
    loginContainer: {
        display: "flex !important",
        width: "100% !important",
        flexDirection: "column !important",
        height: "100% !important",
        justifyContent: "center !important",

    }
})
export const CustomOTPInput = (props) => {
    const classes = useStyles();
    const navigate = useNavigate()

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        color: '#fff',
        textAlign: 'left',
    }));

    const [otp, setOtp] = useState('');
    const setOtpCustom = (e) => {
        setOtp(e);
    }
    const navigateHome = () => navigate('/Home');

    const submitOtp = async () => {
        let url = await getApiUrl();

        let verifyOtpUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/verifyotp/";
        // let formValues = "";
        let payLoad = {
            // "email": formValues.username,
            // "password": formValues.password,
            "otp": Number(otp)
        }
        axios({
            method: 'post',
            url: verifyOtpUrl,
            data: payLoad,
        }).then(function (response) {
            console.log("Login Response", response);
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response?.data?.access) {
                    setLoginUser(response.data);
                    navigateHome();
                } else {
                    // ***** Show Pop Up
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const resendOtp = () => {
        // const data = getValues();
        // let payLoad = {
        //     // "username": data.username,
        //     "email": data.username,
        //     "password": data.password
        // }
        // axios({
        //     method: 'post',
        //     url: apiUrl,
        //     data: payLoad,
        // }).then(function (response) {
        //     console.log("Login Response", response);
        //     // ***** If success redirect to home, On fail present error message and do nothing.
        //     if (response.status == 200) {
        //         if (response.data.message == "OTP sent successfully") {
        //             let visiblity = {
        //                 login: false,
        //                 enterOtpMail: true,
        //                 loginFailed: false,
        //                 authenticator: false,
        //                 forgetPassword: false,
        //                 emailSentWithPassword: false,
        //                 enterOtpMailForgotPassword: false,
        //                 enterChangePasswordForgotPassword: false,

        //             }
        //             setVisibleState(visiblity);
        //         }

        //     }
        // }).catch(function (error) {
        //     console.log(error);
        // });
    };
    return (
        <>
            <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                {/*<Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            color: '#FFF',
                            fontFamily: ' "Poppins", sans-serif !important',
                            fontSize: '24px',
                            fontWeight: '700',
                        }}
                    >
                        <Typography component="h1" variant="h5" sx={{
                            fontFamily: ' "Poppins", sans-serif !important',
                            fontWeight: '600px',
                            fontSize: '24px'
                        }}>
                            Enter OTP
                        </Typography>

                        <Typography style={{ fontSize: "14px", color: "#C7CCD0", fontWeight: "400" }}>An OTP has been sent to your email address. </Typography>
                        <Box noValidate sx={{
                            width: '100%',
                            mt: 5,
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'start',
                            flexDirection: 'column'
                        }}> */}
                <OtpInput alignItems="start"
                    value={otp}
                    onChange={setOtp}
                    numInputs={5}
                    renderSeparator={<span style={{
                        fontSize: "22px",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }} ></span>}
                    renderInput={(props) => <input {...props} type="text" />}
                    inputType={'text'}
                    inputStyle={{
                        width: "60px",
                        marginBottom: "10px",
                        height: "70px",
                        borderRadius: "8px",
                        border: "1px solid var(--Gray-1, #C7CCD0)",
                        background: "var(--Charcoal, #3A3E3F)",
                        outline: "none",
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: "400"

                    }}
                />
                <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                    <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={resendOtp}>Resend OTP</a> or <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={props.changeMail}>Change Email</a>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5, gap: '15px', width: '100%' }}>
                    <StyledButtonContained variant="contained"
                        onClick={submitOtp}
                        type="button"
                        sx={{
                            width: '140px',
                            height: '50px'
                            }}>
                        Login
                    </StyledButtonContained>
                </Box>
                {/*</Box>
                    </Box>*/}
            </Grid>
        </>
        // <form >
        //     <div className="otpContainer">
        //         {[1, 2, 3, 4, 5].map((index) => (
        //             <input
        //                 key={index}
        //                 name={`otp${index}`}
        //                 type="text"
        //                 autoComplete="off"
        //                 className="otpInput"
        //                 value={props.state[`otp${index}`]}
        //                 onKeyPress={props.inputFocus}
        //                 onChange={(e) => props.handleChange(`otp${index}`, e)}
        //                 tabIndex={index}
        //                 maxLength="1"
        //                 onKeyUp={props.inputFocus}
        //             />
        //         ))}
        //     </div>
        // </form>
    );
};




