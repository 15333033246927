import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { PrimaryColor } from "../theme/theme";
import {
  Stack,
  Toolbar,
  Tooltip,
  Grid,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledButtonContained } from "../../views/Admin/AccountCreation/accountCreation";
import axios from "axios";
import { getLoginUser } from "../../LocalStorageService";
import { getApiUrl } from "../../global";
import { formats } from "../../global";
import CommonTable from "../../views/Admin/Order/OrderManagement/Audit/CommonTable";

const CustomSlider = styled(Slider)({
  color: "#D9D9D9",
  height: 15,
  "& .MuiSlider-track": {
    height: 15,
    border: "none",
    color: "#FFC540",
  },
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundColor: "#FFC540",
    border: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 16,
    background: "unset",
    padding: 0,
    width: 48,
    height: 48,
    color: "#3A3E3F",
    borderRadius: "50% 50% 50% 50%",
    backgroundColor: "#FFC540",
    transformOrigin: "bottom left",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translateY(0%)",
    },
    "& > *": {},
  },
});
const useStyles = makeStyles({
  rootMenuItem: {
    "&$selected": {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "green",
        borderRadius: 12,
        border: "1px solid var(--White, #FFF)",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      border: 0,
      borderRadius: 12,
      border: "1px solid var(--White, #FFF)",
    },
  },
  highlight: {
    background: "#FFC540",
    color: "#3A3E3F",
  },
  checkedColr: {
    "&.Mui-checked": {
      color: "red",
    },
  },
  selectLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        borderColor: "#C7CCD0",
      },
      "&:hover fieldset": {
        borderColor: "#C7CCD0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C7CCD0",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.5)",
      "&.Mui-focused": {
        color: "#FFC540",
      },
    },
    "& svg": {
      color: "#FFFFFF",
    },
  },
  checkedColr: {
    "&.Mui-checked": {
      color: "red",
    },
  },
});

const getCustomerDetails = async () => {
  const customerId = getLoginUser() ? getLoginUser().user.customer_uuid : null;
  let url = await getApiUrl();
  let apiUrlcustomer =
    url + process.env.REACT_APP_API_BASE_URL + `/customers/${customerId}/`;
  axios
    .get(apiUrlcustomer, {})
    .then((response) => {
      if (response?.status == 200) {
        console.log("Data", JSON.stringify(response.data));

        // setData(data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
function createData(item, mentioned, actual) {
  return { item, mentioned, actual };
}
const rows = [
  createData("Tapes", 4, 3),
  createData("Data (Pb)", 6.8, 6),
  createData("Tape Types", 10, 10),
  createData("Formats", 11, 12),
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3A3E3F",
    color: "#F9F9F9",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root": {
    "&:nth-of-type(odd)": {
      backgroundColor: "#979797",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#3A3E3F",
      border: "1px solid #FFC540",
    },
    "& > *": {
      color: "#FFFFFF",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Audit = ({ value, orderDetail }) => {
  const classes = useStyles();
  const [allRestoreList, setAllRestoreList] = useState([]);
  const [restoreData, setRestoreData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tapeCount, setTapeCount] = useState(0);
  const [completedTap, setCompletedTap] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    let _slice = allRestoreList.slice(
      (newPage - 1) * 10,
      (newPage - 1) * 10 + 10
    );
    setRestoreData(_slice);
  };

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(currentPage * 10, tapeCount);
  const highlightText = (id, text) => {
    if (!searchQuery || searchQuery === "") {
      return text;
    }
    const regex = new RegExp(`(${searchQuery})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <Typography sx={{ fontSize: 14 }} className={classes.highlight}>
          {part}
        </Typography>
      ) : (
        <Typography sx={{ fontSize: 14 }}>{part}</Typography>
      )
    );
  };

  let state = [
    {
      key: "verified_and_completed",
      value: "Audit Awaited",
    },
    //   {
    //   key: "tapes_delivered", value: "Audit Awaited"
    // },
    {
      key: "audit_started",
      value: "Audit Started",
    },
    {
      key: "audit_complete",
      value: "Audit Complete",
    },
    {
      key: "ingestion_started",
      value: "Audit Complete",
    },
    {
      key: "ingestion_complete",
      value: "Audit Complete",
    },
  ];
  function mapOrderStep(_state) {
    if (_state) {
      let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
      return step?.value ? step.value : _state;
    } else {
      return _state;
    }
  }
  const thumbBoxStyle = {
    width: "91px",
    height: "95px",
    borderRadius: "8px",
    border: "1px solid #FFC540",
    position: "absolute",
    top: "calc(100% + 8px)",
    left: `calc(${(value / 100) * 100}% - 45.5px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: PrimaryColor,
    zIndex: 1,
  };

  React.useEffect(() => {
    // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
    getCustomerDetails();
    let payload = {
      type: "man_item_list",
      request: {
        cid: 15,
        type: "man_item_list",
      },
    };
 
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let dynamicArray = ''
                    const dynamicObject = Object.values(response.data.result)
                   dynamicObject.forEach(i => 
                   dynamicArray = i.data )
          setAllRestoreList(
           dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
          );
          setRestoreData(
           dynamicArray
              .filter((i) => i.job_id ==  (orderDetail.tapeark_job_id || 3))
              .slice(0, 10)
          );
          setTotalPages(
            Math.ceil(
             dynamicArray.filter((i) => i.job_id ==  (orderDetail.tapeark_job_id || 3))
                .length / 10
            )
          );
          setTapeCount(
           dynamicArray.filter((i) => i.job_id ==  (orderDetail.tapeark_job_id || 3)).length
          );
          setCompletedTap(
           dynamicArray
              .filter((i) => i.job_id ==  (orderDetail.tapeark_job_id || 3))
              .filter((i) => i.status == "Cataloged").length
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Box padding={6} minHeight={"100vh"}>
      <Typography
        sx={{
          flex: "1 1 100%",
          pt: 2,
          textAlign: "left",
          px: 2,
          fontFamily: "Poppins",
          fontWeight: 700,
          fontSize: "22px",
          color: "#FFFFFF",
        }}
        id="tableTitle"
        component="div"
      >
        {mapOrderStep(orderDetail.status)}
      </Typography>
      <Typography
        sx={{
          flex: "1 1 100%",
          pt: 2,
          textAlign: "left",
          px: 2,
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          color: "#FFFFFF",
        }}
        id="tableTitle"
        component="div"
      >
        You will receive an audit report providing information about the details
        of your tapes.
      </Typography>

      <div style={{ position: "relative" }}>
        <CustomSlider
          disabled
          value={((completedTap / tapeCount) * 100).toFixed(2)}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v}%`}
        />
        <div style={thumbBoxStyle}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "33px",
                color: "#FFC540",
              }}
            >
              {completedTap}/{tapeCount}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
              }}
            >
              Tapes Audited
            </Typography>
          </Stack>
        </div>
      </div>
      
      <CommonTable 
      startIndex={startIndex}
      endIndex={endIndex}
      handlePageChange={handlePageChange}
      restoreData={restoreData}
      totalPages={totalPages}
      currentPage={currentPage}
      tapeCount = {tapeCount}    
      classes = {classes}
      
      /> 
      {/* <div className="auditTable">
      <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14 , fontWeight:"700" }}
                    >
                      Tape Volser
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape type
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape format
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Data Volume
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Retention Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Created Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Comments
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.columnHeader}>
                    <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                      Tape Photgrapgh
                    </Typography>
                  </TableCell>
                <TableCell className={classes.columnHeader}>
                <Typography
                      sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight:"700"}}
                    >
                     Status
                    </Typography>
                </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& > *": {
                    backgroundColor: "#3A3E3F",
                    borderRadius: "8px",
                  },
                }}
              >
                {restoreData
                  // .slice(0, 8)
                  .map((row, index) => (
                    <TableRow
                      key={row.item_id}
                      sx={{
                        "& > *": {
                          border: "unset",
                        },
                      }}
                    >
                      <TableCell>
                        <Typography
                          className="restore-name"
                          key={row.item_id}
                          id={`restore-name-${index}`}
                          sx={{
                            color: "#FFFFFF",
                            fontSize: 14,
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: row.selected ? 700 : 400,
                            whiteSpace: "pre",
                          }}
                        >
                          { row.item_volser}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.mediatype}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.backup_format}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.size}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.createdOn}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: row.selected ? 700 : 400,
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            padding={2}
            style={{
              width: "100%",
              border: "1px solid #C7CCD0",
              minWidth: "55%",
            }}
          >
            {/* <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing 1-10 of 100 files`}
              </Typography>
            </Grid> */}
            {/* <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing ${startIndex}-${endIndex} of ${tapeCount} Customers`}
              </Typography>
            </Grid>
            <Grid item>
              <Pagination
                component="div"
                rowsPerPage={10}
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    width: "30px",
                    height: "30px",
                    border: "1px solid #FFFFFF",
                    background: "#3A3E3F",
                    color: "#FFFFFF",
                    margin: "0 4px",
                    "&.Mui-selected": {
                      background: "#FFC540",
                      color: "#3A3E3F",
                    },
                  },
                  "& .MuiPaginationItem-page.Mui-selected:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-page:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                    width: "100px",
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    background: "#3A3E3F",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover":
                    {
                      background: "#FFC540",
                      color: "#3A3E3F",
                    },
                }}
                renderItem={(item) => <PaginationItem {...item} />}
              />
            </Grid>
          </Grid>
        </Grid>
        </div> */} 
      {value === 100 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            textAlign: "center",
          }}
        >
          {" "}
          {/* displayed at value=100% */}
          <TableContainer
            sx={{ maxWidth: 700, mt: 18, border: "1px solid #EAEAEA" }}
            component={Paper}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                pt: 2,
                textAlign: "left",
                px: 2,
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "22px",
                color: "#FFFFFF",
                backgroundColor: PrimaryColor,
              }}
              id="tableTitle"
              component="div"
            >
              Audit Report
            </Typography>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Item</StyledTableCell>
                  <StyledTableCell align="right">Mentioned</StyledTableCell>
                  <StyledTableCell align="right">Actual</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.item}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.mentioned}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.actual}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Button */}
          <StyledButtonContained
            variant="contained"
            sx={{
              width: "280px",
              height: "60px",
              mt: 4,
            }}
          >
            Download Full Report
          </StyledButtonContained>
        </Box>
      )}
    </Box>
  );
};
