import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { Requests } from "../../component/request/request";
import { Messages } from "../../component/messages/messages";
import { Audit } from "../../component/audit/audit"
import { useLocation } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import { Route, Routes, useParams } from 'react-router-dom';
import { Ingestion } from "../../component/ingestion/ingestion";
//import { env } from "../../env";
import { IconButton, Link, Slider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ButtonColor, PrimaryColor, flexStyles } from "../theme/theme";
import { CopyAll, Email, Share } from "@mui/icons-material";


const sampleRestoreOrder = {
    "uuid": "2664810f-363f-451b-97b5-9de03f13b286",
    "tag_list": [
      {
        "uuid": "fb03de6e-a245-4132-8f62-031bf578c27d",
        "created_at": "2023-10-04T08:08:37.440477Z",
        "updated_at": "2023-10-04T08:08:37.440477Z",
        "file_context": "test 1",
        "attachment": null,
        "file_type": "test",
        "file_size": 10,
        "file_size_type": "B",
        "created_by": 1,
        "updated_by": null
      }
    ],
    "customer_details": {
      "uuid": "00ea98c8-ff8b-4d13-99b3-498ac483360a",
      "created_at": "2023-10-03T09:28:30.605737Z",
      "updated_at": "2023-10-03T09:28:30.605737Z",
      "tenant_id": "44b5f6ea-4238-4948-a860-01d3f8117f30",
      "companyname": "Minfy",
      "company_logo": null,
      "phonenumber": "+918989898989",
      "quotereference": "Test123",
      "created_by": 1,
      "updated_by": null,
      "address": "61ca12cf-2c0e-41a7-8cd6-690fd1a70809"
    },
    "shipments": [
      {
        "uuid": "569cc257-9451-4ed2-ab26-468effbaf586",
        "addressess": [
            {
                "uuid": "42bace30-29fd-4f87-a897-bf853aa5536a",
                "state_name": "Telangana",
                "country_name": "India",
                "created_at": "2023-10-04T12:13:58.154076Z",
                "updated_at": "2023-10-04T12:13:58.154076Z",
                "address_line_1": "test 1",
                "address_line_2": "test 2",
                "postcode": "500072",
                "created_by": 1,
                "updated_by": 1,
                "country": "0f1c2770-2160-4c2a-a28e-07583eadb8fe",
                "state": "ef2c9f58-08cf-405a-b0ed-90b45fb6603f"
            }
        ],
        "created_at": "2023-10-03T14:53:00.843591Z",
        "updated_at": "2023-10-04T10:34:43.684704Z",
        "shipping_mode": "SELF",
        "courier_partner": "FEDEX",
        "order_tracking_number": "test123",
        "chain_of_custody": true,
        "pick_up_date": "2023-10-03",
        "created_by": 1,
        "updated_by": null,
        "tenant_id": "00ea98c8-ff8b-4d13-99b3-498ac483360a",
        "order": "2664810f-363f-451b-97b5-9de03f13b286"
      }
    ],
    "order_state": [
      {
        "uuid": "bf06ea61-0764-4b50-b2ab-feb3d58e0657",
        "created_at": "2023-10-04T08:12:27.034770Z",
        "updated_at": "2023-10-04T08:12:27.034770Z",
        "state": "SHIPMENT REACHED",
        "messsage": null,
        "created_by": null,
        "updated_by": null,
        "order": "2664810f-363f-451b-97b5-9de03f13b286"
      },
      {
        "uuid": "6d5a7e62-6678-4af0-b02c-ba1db8f1ec93",
        "created_at": "2023-10-04T08:11:50.231163Z",
        "updated_at": "2023-10-04T08:11:50.231163Z",
        "state": "REQUEST RECEIVED",
        "messsage": null,
        "created_by": null,
        "updated_by": null,
        "order": "2664810f-363f-451b-97b5-9de03f13b286"
      },
      {
        "uuid": "07134483-1599-4415-ad55-2c8c7a2b0b66",
        "created_at": "2023-10-04T08:08:37.433535Z",
        "updated_at": "2023-10-04T08:08:37.433535Z",
        "state": "CREATED",
        "messsage": null,
        "created_by": null,
        "updated_by": null,
        "order": "2664810f-363f-451b-97b5-9de03f13b286"
      }
    ],
    "created_at": "2023-10-04T08:08:37.426490Z",
    "updated_at": "2023-10-04T08:12:27.030745Z",
    "order_id": "64831696406917425",
    "order_type": "RESTORE",
    "state": "REQUEST RECEIVED",
    "backup_mode": "VIRTUALIZE",
    "account_options": "Enterprise",
    "audit_options": "No Audit",
    "number_of_boxes": 10,
    "post_ingestion": "DESTROY",
    "tape_list": null,
    "ingestion_options": "I want the tapes to be Read only",
    "created_by": 1,
    "updated_by": 1,
    "tenant_id": "00ea98c8-ff8b-4d13-99b3-498ac483360a"
  
};


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 40,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "background(180deg, #EEAB10 0%, #FFC133 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EAEAEA",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "#fff",
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active &&
    {
      // backgroundImage:
      //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
    ...(ownerState.completed && {
      background: "#ffc54021",
      color: "#FFC540;",
      // opacity: 0.5,
    }),
  }));

export const RestoreTracking = () => {
  const states = [
    {key: "tapes_delivered", value: "Tapes Delivered",}, 
    {key: "tapes_picked_up", value: "Tapes Picked Up",},
    {key: "boxes_delivered", value: "Boxes Delivered",}, 
    {key: "shipment_reached", value: "Shipment Reached",},
    {key: "shipment_started", value: "Shipment Started",},
    {key: "request_received", value: "Request Received",},]
  const [activeHStep, setActiveHStep] = React.useState(1);
  const location = useLocation();


  const [orderDetail, setOrderDetail] = React.useState(sampleRestoreOrder);
  useEffect(() => {
    getOrderDetail();
  }, [])
  const getOrderDetail = () => {
    const data = sampleRestoreOrder;
        if (data.order_state.length < states.length) {
          states.forEach((e) => {
            let orderState = data.order_state.find(elements => elements.state == e.key);
            if (orderState?.state) {
              e['created_at'] = orderState.created_at;
              e['message'] = orderState.message;
              e['state'] = orderState.state;
            } else {
              e['created_at'] = "";
              e['message'] = "";
              e['state'] = e.key;
            }
          })
          data.order_state = states;

        }
        //setOrderDetail(data);
        // setTimeout(() => {
        setActiveHStep(getHActiveStep(data));
        // }, 100);

  };
  function getHActiveStep(_orderDetail) {
    let horizontalStepperActiveStep = 1;
    if (_orderDetail?.order_id) {
      if (_orderDetail.state.toLowerCase() === 'tapes_delivered' || _orderDetail.state.toLowerCase() === 'audit_started') {
        horizontalStepperActiveStep = 2;
      } else if (_orderDetail.state.toLowerCase() === 'audit_complete' || _orderDetail.state.toLowerCase() === 'ingestion_started' || _orderDetail.state.toLowerCase() === 'ingestion_complete') {
        horizontalStepperActiveStep = 3;
      }
    }
    return horizontalStepperActiveStep;
  }
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getStepValue() {
    let horizontalStepper = {
      1: <>
        <div className="horizontalStepperIcon_Completed">
          <CheckIcon></CheckIcon>
        </div>
        <div className="progressBarText progressBarText-orderPlaced">Order<br /> Placed</div>
      </>,
      2: <><div>2</div><div className="progressBarText progressBarText-shipping">Restore<br /> in Progress</div></>,
      3: <><div>3</div><div className="progressBarText progressBarText-audit">Links</div></>,
    };
    if (orderDetail?.order_id) {
      if (orderDetail.state.toLowerCase() === 'tapes_delivered' || orderDetail.state.toLowerCase() === 'audit_started') {
        horizontalStepper = {
          1: <>
            <div className="horizontalStepperIcon_Completed">
              <CheckIcon></CheckIcon>
            </div>
            <div className="progressBarText progressBarText-orderPlaced">Order<br /> Placed</div>
          </>,
          2: <><div className="horizontalStepperIcon_Completed">
            <CheckIcon></CheckIcon>
          </div><div className="progressBarText progressBarText-shipping">Restore<br /> in Progress</div></>,
          3: <><div>3</div><div className="progressBarText progressBarText-audit">Links</div></>,
        };
      } else if (orderDetail.state.toLowerCase() === 'audit_complete' || orderDetail.state.toLowerCase() === 'ingestion_started') {
        horizontalStepper = {
          1: <>
            <div className="horizontalStepperIcon_Completed">
              <CheckIcon></CheckIcon>
            </div>
            <div className="progressBarText progressBarText-orderPlaced">Order<br /> Placed</div>
          </>,
          2: <><div className="horizontalStepperIcon_Completed">
            <CheckIcon></CheckIcon>
          </div><div className="progressBarText progressBarText-shipping">Restore<br /> in Progress</div></>,
          3: <><div className="horizontalStepperIcon_Completed">
            <CheckIcon></CheckIcon>
          </div><div className="progressBarText progressBarText-audit">Links</div></>,
        };
      }
    }
    return horizontalStepper;
  }
  function ColorlibStepIcon(props) {
    const icons = getStepValue();
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  function getActiveStep() {
    let horizontalStepperActiveStep = [
      { label: "step-orderPlaced", status: "horizontalStepper-completed" },
      { label: "step-shipping", status: "horizontalStepper-active" },
      { label: "step-audit", status: "horizontalStepper-pending" },
    ];
    if (orderDetail?.order_id) {
      if (orderDetail.state.toLowerCase() === 'tapes_delivered' || orderDetail.state.toLowerCase() === 'audit_started') {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-active" },
        ];
      } else if (orderDetail.state.toLowerCase() === 'audit_complete' || orderDetail.state.toLowerCase() === 'ingestion_started') {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-completed" },
        ];
      } else if (orderDetail.state.toLowerCase() === 'ingestion_complete') {
        horizontalStepperActiveStep = [
          { label: "step-orderPlaced", status: "horizontalStepper-completed" },
          { label: "step-shipping", status: "horizontalStepper-completed" },
          { label: "step-audit", status: "horizontalStepper-completed" },
        ];
      }
    }
    return horizontalStepperActiveStep;
  }
  const steps = getActiveStep();
  const handleStep = (step) => () => {
    setActiveHStep(step);
  };
  let width = window.innerWidth;
  console.log(width);
  return (
    <>
      <Box p={4}>
        {/* Breadcrumb */}
        <div className="breadcrumb">
          <img src="/assets/img/cart.svg" alt="" />
          <img src="/assets/img/arrow_left.svg" alt="" />
          <Link to='/CustomerOrders' passHref style={{ textDecoration: 'none' }}> <small className="grey-color">Your Orders</small></Link>
          <img src="/assets/img/arrow_left.svg" alt="" />
          <small className="grey-color">{orderDetail.order_id}</small>
        </div>
        {/* Heading */}
        <div className="heading">
          <h1>{orderDetail.order_id}</h1>
        </div>
        <Box mb={2}>
          <hr />
        </Box>
        <div className="tab-section">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" className="tab-bg">
                <Tab label="Tracking" value="1" />
                <Tab label="Details" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container direction='column'>
                <div className="stepper-section" style={{ marginLeft: width > 2000 ? '-11%' : '-7.5%' }}>
                  <Stack sx={{ width: "100%" }}>
                    <Grid item >
                      <Stepper
                        alternativeLabel
                        activeStep={1}
                        connector={<ColorlibConnector />}
                      >
                        {steps.map((step, index) => (
                          <Step key={step.label} className={step.label + " " + step.status}>
                            <StepLabel onClick={handleStep(index)}
                              StepIconComponent={ColorlibStepIcon}
                            ></StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Stack>
                </div>
                <Grid item>
                  {(activeHStep === 0 || activeHStep === 1 ) && <RestoreProgress value={60} orderDetail={orderDetail} />}
                  {(activeHStep === 2) && <RestoreProgress value={100} orderDetail={orderDetail} />}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">Details</TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  );
};

let state = [{
  key: "created", value: "Created"
}, {
  key: "restore_awaited", value: "Restore Awaited"
}, {
  key: "restore_in_progress", value: "Restore in Progress"
}, {
  key: "restore_completed", value: "Restore Completed"
},]





const CustomSlider = styled(Slider)({
    color: '#D9D9D9',
    height: 15,
    '& .MuiSlider-track': {
      height: 15,
      border: 'none',
      color: '#FFC540'
    },
    '& .MuiSlider-thumb': {
      height: 32,
      width: 32,
      backgroundColor: '#FFC540',
      border: 'none',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontFamily: 'Poppins',
      fontWeight: 400,
      lineHeight: 1.2,
      fontSize: 16,
      background: 'unset',
      padding: 0,
      width: 48,
      height: 48,
      color: '#3A3E3F',
      borderRadius: '50% 50% 50% 50%',
      backgroundColor: '#FFC540',
      transformOrigin: 'bottom left',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translateY(0%)',
      },
      '& > *': {
  
      },
    },
  });
  
  
  
const RestoreProgress = ({ value, orderDetail }) => {
  
    const thumbBoxStyle = {
      width: '91px',
      height: '95px',
      borderRadius: '8px',
      border: '1px solid #FFC540',
      position: 'absolute',
      top: 'calc(100% + 8px)',
      left: `calc(${(value / 100) * 100}% - 45.5px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: PrimaryColor,
      zIndex: 1,
    };
    let state = [  {
      key: "restore_awaited", value: "Restore Awaited"
    }, {
      key: "restore_in_progress", value: "Restore In Progress"
    }, {
      key: "restore_completed", value: "Restore Completed"
    }]
    function mapOrderStep(_state) {
      let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
      return step?.value ? step.value : _state;
    }
  
  
    return (
      <Grid container direction={'column'} p={6} minHeight={'100vh'} spacing={2}>
        <Grid item display={'flex'} justifyContent={'space-between'}>
          <Typography
            sx={{ flex: '1 1 100%', pt: 2, textAlign: 'left', px: 2, fontFamily: 'Poppins', fontWeight: 700, fontSize: '22px', color: '#FFFFFF' }}
            id="state-title"
            component="div"
          >
            
            {(value === 100) ? 'Restore Completed' : 'Restore In Progress'}
            {/* {mapOrderStep(orderDetail.state)} */}
          </Typography>
          <Typography
            sx={{ flex: '1 1 100%', textAlign: 'right', pt: 2, pr: 2, fontFamily: 'Poppins', fontWeight: 400, fontSize: '18px', lineHeight: '27px', color: '#C7CCD0' }}
            id="state-completion-time"
            component="div"
            noWrap
            style={{ textAlign: 'right' }}
          >
            Friday, 6 July, 2023, 4:45 pm
          </Typography>
        </Grid>
  
        <Grid item>
            <Paper elevation={1} sx={{
                        border: '1px solid #FFFFFF',
                        marginLeft: 3,
                        height: '2.5rem',
                        width: 'max-content',
                        padding: 0, display: 'flex',
                        backgroundColor: PrimaryColor, flexWrap: 'wrap',
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            sx={{
                                '& .MuiToggleButtonGroup-grouped': { 
                                    margin: 0.5, 
                                    border: 0,
                                    '&.Mui-selected': {
                                        color: '#3E3A3F',
                                    }
                                }
                            }}
                            //value={}
                            exclusive
                            //onChange={}
                            aria-label="Platform"
                        >
                            <ToggleButton value="thismonth"
                                sx={{ height: 32, width: 87, fontSize: 12, color: '#FFFFFF', backgroundColor: ButtonColor, textTransform: 'none' }}>Cloud</ToggleButton>
                            <ToggleButton value="lastmonth"
                                sx={{ height: 32, width: 87, fontSize: 12, color: '#FFFFFF', textTransform: 'none' }}>Physical</ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
        </Grid>

        <Grid item>
            <Typography
            sx={{ flex: '1 1 100%', pt: 2, textAlign: 'left', px: 2, fontFamily: 'Poppins', fontWeight: 400, fontSize: '18px', color: '#FFFFFF' }}
            id="state-text"
            component="div"
            >
            Your files are being restored. A link will be provided post completion.
            </Typography>
        </Grid>
  
        <Grid item style={{ position: 'relative' }}>
          <CustomSlider
            disabled
            value={value}
            valueLabelDisplay='on'
            valueLabelFormat={(v) => `${v}%`}
          />
          <Box style={thumbBoxStyle}>
            <Stack direction={'column'}>
              <Typography sx={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: '22px', lineHeight: '33px', color: '#FFC540' }}>3/3</Typography>
              <Typography sx={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '18px', lineHeight: '27px', color: '#FFFFFF' }}>Files Restored</Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item mt={thumbBoxStyle.height} sx={{visibility: (value === 100)?'visible':'hidden'}}>
            <Paper elevation={2} sx={{width: '50%', borderRadius: '8px', p: 2, backgroundColor: '#3A3E3F', color: '#FFFFFF', height: '4rem',}}>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item sx={flexStyles.flexRowStart} alignItems={'center'}>
                        <Link underline="hover" sx={{color: '#FFFFFF', lineHeight: '24px', fontSize: '16px', fontWeight: 400}}>https://aws.amazon.com/s3/12345678/ </Link> 
                    </Grid>
                    <Grid item sx={flexStyles.flexRowEnd} gap={2}>
                        <IconButton title="Share" sx={{display: 'block'}}>
                            <Share sx={{color: '#FFFFFF', height: '30px', width: '30px'}} />
                            <Typography sx={{color: '#FFFFFF', fontFamily: 'Poppins'}}>Share</Typography>
                        </IconButton>
                        <Divider orientation='vertical' variant="middle" flexItem sx={{backgroundColor: '#FFFFFF'}} />
                        <IconButton title="Copy" sx={{display: 'block'}}>
                            <CopyAll sx={{color: '#FFFFFF', height: '30px', width: '30px'}} />
                            <Typography sx={{color: '#FFFFFF', fontFamily: 'Poppins'}}>Copy</Typography>
                        </IconButton>                        
                        <Divider orientation='vertical' variant="middle" flexItem sx={{backgroundColor: '#FFFFFF'}} />
                        <IconButton title="Email" sx={{display: 'block'}}>
                            <Email sx={{color: '#FFFFFF', height: '30px', width: '30px'}} />
                            <Typography sx={{color: '#FFFFFF', fontFamily: 'Poppins'}}>Email</Typography>
                        </IconButton>                    
                    </Grid>
                </Grid>
            </Paper>
        </Grid>

      </Grid>
    );
  };