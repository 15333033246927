import React, { useRef, useEffect, useState } from 'react';
import { Grid, FormControl, Select, MenuItem, InputLabel, Paper, Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, } from 'chart.js';
import { Chart, Line } from "react-chartjs-2";
import { Close } from '@mui/icons-material';
import faker from 'faker';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from '../home/home';
import { makeStyles } from "@mui/styles";
import { CustomCategoryScale, CustomLinearScale } from '../chart/scales';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
    responsive: true,
    indexAxis: 'x',
    //maintainAspectRatio: true,
    scales: {
        x: {
            type: 'customCategoryScale',
            grid: {
                display: false,
            },
            beginAtZero: true,
        },
        y: {
            type: 'customLinearScale',
            grid: {
                display: false,
            },
            beginAtZero: true,
            max: 1000,
            ticks: {
                stepSize: 500,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: true,
            mode: 'nearest',
            position: 'average',
            backgroundColor: '#3A3E3F',
            borderColor: '#B5B5B5',
            borderWidth: 1,
            borderRadius: 1,
            caretSize: 0,
            displayColors: false,
            bodyFont: {
                size: 16,
            },
            callbacks: {
                title: function (tooltipItem) {
                    console.log(tooltipItem);
                    return `${tooltipItem[0].label}`
                },
                label: function (tooltipItem) {
                    console.log(tooltipItem);
                    return `${tooltipItem.parsed.y}TB`;
                },
                useHTML: true,
            },
            padding: 12,
        },
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Last Accessed',
        },
    },
};

const labels = ['Jan/2024-Mar/2024', 'Apr/2024-Jun/2024', 'Jul/2024-Sep/2024', 'Oct/2024-Dec/2024'];
const alternatingColors = ['#007E23', '#BD5F5F', '#38AA58'];
export const data = {
    labels: labels,
    datasets: [{
        borderColor: '#FFFFFF',
        backgroundColor: 'transparent',
        borderWidth: 4,
        label: 'Data',
        tension: 0.4,
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        pointBorderColor: labels.map((label, index) => alternatingColors[index % 3]),
        pointBackgroundColor: labels.map((label, index) => alternatingColors[index % 3]),
        pointRadius: 4,
    },],
};

export default function Usage() {

    const classes = useStyles();
    const [visualization, setVisualization] = useState('');

    const handleVisualizationChange = (event) => {
        setVisualization(event.target.value);
    };

    return (
        <div className={classes.mainContainer}>
            <Box p={3}>
                <Grid container direction='column' spacing={2.3}>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Usage</Typography>
                            </Grid>
                            {/* <Grid item className={classes.closeIcon}>
                                <Close />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item className={classes.toggleContainer}>
                                <ToggleButtonGroup
                                    sx={{ visibility: 'hidden' }}
                                    color="primary"
                                    //value={date}
                                    exclusive
                                    //onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="month">Month</ToggleButton>
                                    <ToggleButton value="year">Year</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 120, visibility: 'hidden' }} size="small">
                                    <InputLabel id="demo-select-small-label">Area Graph</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={visualization}
                                        label="Visualization"
                                        onChange={handleVisualizationChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>Bar Graph</MenuItem>
                                        <MenuItem value={20}>Split Graph</MenuItem>
                                        <MenuItem value={30}>Line Graph</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{maxWidth:'100%'}}>
                        <Line data={data} options={options} />

                    </Grid>

                </Grid>
            </Box>
        </div>
    );
}

const useStyles = makeStyles({
    mainContainer: {
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
        maxWidth:'100%'
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
});