const TapeArkLogin = "tapeArk_LoginUser";

export const setLoginUser = (data) => {
  let enc = window.btoa(JSON.stringify(data));
  localStorage.setItem(TapeArkLogin, enc);
};
export const getLoginUser = () => {
  // let user ="eyJhY2Nlc3MiOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKMGIydGxibDkwZVhCbElqb2lZV05qWlhOeklpd2laWGh3SWpveE5qazVNRGd5TnpjMExDSnBZWFFpT2pFMk9UZzROVGcxTlRBc0ltcDBhU0k2SWpKaE1UTXpOakV6TURBNVpEUmpPV1JpWkRVeU1tRmhOelF4WkROaFl6RTFJaXdpZFhObGNsOXBaQ0k2TVgwLkpCd1hIRzRMbHpVTk5NLTBVcHR5Z25pM0N5QTNuYmYzbVdKbGNsTjVXaGciLCJyZWZyZXNoIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjBiMnRsYmw5MGVYQmxJam9pY21WbWNtVnphQ0lzSW1WNGNDSTZNVFk1T1RRMk16TTFNQ3dpYVdGMElqb3hOams0T0RVNE5UVXdMQ0pxZEdraU9pSmlOR00yTUdFMk1EQmlaRGswWlRCa09ERTVZMll5TUdZNFltWXpPRGhoT1NJc0luVnpaWEpmYVdRaU9qRjkuYTdCaEJ6S0tkSTNUMUhjMDRLTnFwaXdSdWR6THQwRkNaUkliTjVnYVd5byIsInVzZXIiOnsiaWQiOjEsInBhc3N3b3JkIjoiYXJnb24yJGFyZ29uMmlkJHY9MTkkbT0xMDI0MDAsdD0yLHA9OCRPWGhVWTBWMVEwdDNabkl6TTJseU4weHhTazVqYVEkYXRxWVJoU2FKSHlTbFY4MEtkNWJiamhMZ1piMWo4SGFzM2hMcVVCM1RhbyIsImxhc3RfbG9naW4iOiIyMDIzLTExLTAxVDE3OjA5OjEwLjUyNTI5MFoiLCJpc19zdXBlcnVzZXIiOnRydWUsInVzZXJuYW1lIjoiYWRtaW4iLCJmaXJzdF9uYW1lIjoiRGF2aWQiLCJsYXN0X25hbWUiOiIiLCJlbWFpbCI6InRlc3RAdGFwZS5jb20iLCJpc19zdGFmZiI6dHJ1ZSwiaXNfYWN0aXZlIjp0cnVlLCJkYXRlX2pvaW5lZCI6IjIwMjMtMTAtMjBUMDM6Mjg6MjVaIiwidXVpZCI6ImU4YzJmNWMzLTYxMDMtNGFhNy04Y2RkLTMzMjFlYzAwNDUzMCIsImZpcnN0X2xvZ2luIjp0cnVlLCJzYWx1dGF0aW9ucyI6Im1yIiwibWlkZGxlX25hbWUiOm51bGwsImpvYl90eXBlIjoicGVybWFuZW50IiwicGhvbmVudW1iZXIiOiIrOTE5MTkxOTE5MTkxIiwibW9iaWxlbnVtYmVyIjpudWxsLCJ0aW1lX3pvbmUiOiJhdXRvbWF0aWNfdGltZXpvbmUiLCJhdXRvbWF0aWNfdGltZXpvbmUiOm51bGwsInN5bmNlZF90aW1lem9uZSI6bnVsbCwicmVzdG9yZV9hY2Nlc3MiOmZhbHNlLCJwcm9maWxlX3BpY3R1cmUiOm51bGwsImlzX2FkbWluIjpmYWxzZSwiaXNfdGFwZWFya19hZG1pbiI6ZmFsc2UsInR3b19zdGVwX3ZlcmlmaWNhdGlvbiI6bnVsbCwidGVuYW50X2lkIjoiYzcwNjViMTEtODljYS00NjMxLWEzMjItMjhhNWY2MDJmNGIwIiwiZ3JvdXBzIjpbXSwidXNlcl9wZXJtaXNzaW9ucyI6WzI1XSwibm90aWZpY2F0aW9uX3R5cGVzIjpbXX19";
  let user =localStorage.getItem(TapeArkLogin) != "undefined"
      ? localStorage.getItem(TapeArkLogin)
      : null;
  if (user) {
    user = JSON.parse(window.atob(user));
  }

  return user;
};

export const setUserRole = (features) => {
  localStorage.setItem('TapeArkFeatures', JSON.stringify(features));
};

export const getUserRole = () => {
  let user = getLoginUser();
  if(user){
    return user.user.role;
  }
  return '';
};

export const setLoginCustomerId = (id) => {
  localStorage.setItem('TapeArkCustomerId', id);
};

export const getLoginCustomerId = () => {
  let user = getLoginUser();
  return (user?.user?.customer_uuid)?user?.user?.customer_uuid:'';
};

export const removeLoginUser = () => {
  localStorage.removeItem(TapeArkLogin);
};



const TapeArkConfig = "tapeArk_Config";
export const setLocalConfig = (data) => {
    let enc = window.btoa(JSON.stringify(data));
    localStorage.setItem(TapeArkConfig, enc);
};
export const getLocalConfig = () => {
    // let user ="eyJhY2Nlc3MiOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKMGIydGxibDkwZVhCbElqb2lZV05qWlhOeklpd2laWGh3SWpveE5qazVNRGd5TnpjMExDSnBZWFFpT2pFMk9UZzROVGcxTlRBc0ltcDBhU0k2SWpKaE1UTXpOakV6TURBNVpEUmpPV1JpWkRVeU1tRmhOelF4WkROaFl6RTFJaXdpZFhObGNsOXBaQ0k2TVgwLkpCd1hIRzRMbHpVTk5NLTBVcHR5Z25pM0N5QTNuYmYzbVdKbGNsTjVXaGciLCJyZWZyZXNoIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjBiMnRsYmw5MGVYQmxJam9pY21WbWNtVnphQ0lzSW1WNGNDSTZNVFk1T1RRMk16TTFNQ3dpYVdGMElqb3hOams0T0RVNE5UVXdMQ0pxZEdraU9pSmlOR00yTUdFMk1EQmlaRGswWlRCa09ERTVZMll5TUdZNFltWXpPRGhoT1NJc0luVnpaWEpmYVdRaU9qRjkuYTdCaEJ6S0tkSTNUMUhjMDRLTnFwaXdSdWR6THQwRkNaUkliTjVnYVd5byIsInVzZXIiOnsiaWQiOjEsInBhc3N3b3JkIjoiYXJnb24yJGFyZ29uMmlkJHY9MTkkbT0xMDI0MDAsdD0yLHA9OCRPWGhVWTBWMVEwdDNabkl6TTJseU4weHhTazVqYVEkYXRxWVJoU2FKSHlTbFY4MEtkNWJiamhMZ1piMWo4SGFzM2hMcVVCM1RhbyIsImxhc3RfbG9naW4iOiIyMDIzLTExLTAxVDE3OjA5OjEwLjUyNTI5MFoiLCJpc19zdXBlcnVzZXIiOnRydWUsInVzZXJuYW1lIjoiYWRtaW4iLCJmaXJzdF9uYW1lIjoiRGF2aWQiLCJsYXN0X25hbWUiOiIiLCJlbWFpbCI6InRlc3RAdGFwZS5jb20iLCJpc19zdGFmZiI6dHJ1ZSwiaXNfYWN0aXZlIjp0cnVlLCJkYXRlX2pvaW5lZCI6IjIwMjMtMTAtMjBUMDM6Mjg6MjVaIiwidXVpZCI6ImU4YzJmNWMzLTYxMDMtNGFhNy04Y2RkLTMzMjFlYzAwNDUzMCIsImZpcnN0X2xvZ2luIjp0cnVlLCJzYWx1dGF0aW9ucyI6Im1yIiwibWlkZGxlX25hbWUiOm51bGwsImpvYl90eXBlIjoicGVybWFuZW50IiwicGhvbmVudW1iZXIiOiIrOTE5MTkxOTE5MTkxIiwibW9iaWxlbnVtYmVyIjpudWxsLCJ0aW1lX3pvbmUiOiJhdXRvbWF0aWNfdGltZXpvbmUiLCJhdXRvbWF0aWNfdGltZXpvbmUiOm51bGwsInN5bmNlZF90aW1lem9uZSI6bnVsbCwicmVzdG9yZV9hY2Nlc3MiOmZhbHNlLCJwcm9maWxlX3BpY3R1cmUiOm51bGwsImlzX2FkbWluIjpmYWxzZSwiaXNfdGFwZWFya19hZG1pbiI6ZmFsc2UsInR3b19zdGVwX3ZlcmlmaWNhdGlvbiI6bnVsbCwidGVuYW50X2lkIjoiYzcwNjViMTEtODljYS00NjMxLWEzMjItMjhhNWY2MDJmNGIwIiwiZ3JvdXBzIjpbXSwidXNlcl9wZXJtaXNzaW9ucyI6WzI1XSwibm90aWZpY2F0aW9uX3R5cGVzIjpbXX19";
    let config = localStorage.getItem(TapeArkConfig) != "undefined"
        ? localStorage.getItem(TapeArkConfig)
        : null;
    if (config) {
        config = JSON.parse(window.atob(config));
    }

    return config;
};
export const removeLocalConfig = () => {
    localStorage.removeItem(TapeArkConfig);
};