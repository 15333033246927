import react from 'react';
import { Box, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import axios from "axios";
//import { env } from "../../../env";
import dayjs from 'dayjs';
import { getApiUrl } from "./../../../global";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import Select from 'react-select'; // or your select component of choice

export const Job = (props) => {

    const classes = useStyles();
    const [customerList, setCustomerList] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        try {
            getClientList();
            return () => { };
        } catch (e) {
            console.log(e);
        }
    }, []);
    const getClientList = async () => {
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/customers";
        axios
            .get(apiUrl)
            .then(data => {
                setCustomerList(data.data);
                // if (data?.data?.length) {
                //     props.setValue('tenant_id', data.data[data.data.length - 1].uuid);
                // }

            })
            .catch(error => console.log(error));
    }
    let _orderUUID = window?.location?.href?.split("uuid=")[1];
    const onchangesecurityPrivace = (e, newValue) => {
        props.setValue('security_privace', newValue);
    }
    const onchangeBilling = (e, newValue) => {
        props.setValue('billing', newValue);
    }
    const handleCompanyChange = (e, newValue) => {
        props.setValue('tenant_id', e.target.value);
    }
    return (
        <Grid
            container
            style={{
                border: "1px solid #C7CCD0",
                borderRadius: "8px",
                marginTop: "20px",
                minHeight: "100vh",
                height: "max-content",
                marginBottom: '100px',
                alignItems: "start",
                display: "block",
            }}
        >
            <Box p={4} mb={4}>
                <ThemeProvider theme={restoreTheme}>
                    <Grid container direction='row' justifyContent='flex-start'>
                        <Grid item xs={8}>
                            <Grid container direction='column' spacing={1}>
                                <Grid item>
                                    <Typography align='left' className={classes.heading}>Customer Name</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    {/* {
                                        props?.tenant_id && props.watch('tenant_id') ? (
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    required
                                                    className={classes.selectLabel}
                                                    placeholder="Customer Name *"
                                                    fullWidth={true}
                                                    name='tenant_id'
                                                    {...props.register('tenant_id')}
                                                    defaultValue={props.watch('tenant_id')}
                                                    select>
                                                    {
                                                        customerList?.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item.uuid}>{item.company_name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                        ) : (
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    required
                                                    className={classes.selectLabel}
                                                    placeholder="Customer Name *"
                                                    fullWidth={true}
                                                    name='tenant_id'
                                                    {...props.register('tenant_id')}
                                                    select>
                                                    {
                                                        customerList?.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item.uuid}>{item.company_name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>
                                        )} */}
                                    {/* <Controller
                                        name="tenant_id"
                                        // control={props.control}
                                        render={({ field }) => ( */}
                                    {/* <Select
                                        options={customerList}
                                        getOptionLabel={(option) => option.company_name} // Display 'name' in the dropdown
                                        getOptionValue={(option) => option.uuid.toString()} // Store 'id' as the selected value
                                        required
                                        className={classes.selectLabel}
                                        placeholder="Customer Name *"
                                        fullWidth={true}
                                        name='tenant_id'
                                        {...props.register('tenant_id')}
                                        defaultValue={props.watch('tenant_id')} */}
                                    {/* // other Select props */}
                                    {/* /> */}
                                    {/* )}
                                    /> */}
                                    {customerList?.length ? (
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                required
                                                className={classes.selectLabel}
                                                placeholder="Customer Name *"
                                                fullWidth={true}
                                                name='tenant_id'
                                                // {...props.register('tenant_id')}
                                                // defaultValue={props.watch('tenant_id')}
                                                select
                                                value={props.watch('tenant_id')}
                                                onChange={handleCompanyChange}
                                            >
                                                {
                                                    customerList?.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.uuid}>{item.company_name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>) : ""}

                                </Grid>
                                <Grid item>
                                    <Typography align='left' className={classes.heading}>Quote Reference ID</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField required {...props.register('quote_reference_id')} className={classes.selectLabel} id="outlined-basic" placeholder={"Quote Reference ID *"} variant="outlined" fullWidth={true} />
                                </Grid>
                                {/* <Grid item>
                                    <Typography align='left' className={classes.heading}>Order ID</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField {...props.register('orderId')} className={classes.selectLabel} id="outlined-basic" label="Order ID" variant="outlined" fullWidth={true} />
                                </Grid> */}
                                <Grid item>
                                    <Typography align='left' className={classes.heading}>Project Scope</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField required {...props.register('project_scope')} className={classes.selectLabel} multiline rows={4} id="outlined-textarea" placeholder={"Project Scope *"} variant="outlined" fullWidth={true} />
                                </Grid>
                                <Grid item>
                                    <Typography align='left' className={classes.heading}>Project Deliverables</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField required {...props.register('project_deliverables')} className={classes.selectLabel} multiline rows={4} id="outlined-textarea" placeholder={"Project Deliverables *"} variant="outlined" fullWidth={true} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align='left' className={classes.heading}>Security and Privacy evaluation and assessment- does this project contain any of the following Data classifications pertinent to the customers' business?</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction='row' justifyContent='flex-start'>
                                        <Grid item>
                                            {
                                                props.watch('security_privace') ? (
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            onChange={onchangesecurityPrivace}
                                                            value={props.watch('security_privace')}
                                                        >
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="critical" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Critical" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="confidential" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Confidential" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="operational" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Operational" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="public" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Public" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="other" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Other" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                ) : (
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            onChange={onchangesecurityPrivace}
                                                        //value={props.watch('security_privace')}
                                                        >
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="critical" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Critical" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="confidential" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Confidential" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="operational" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Operational" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="public" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Public" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="other" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Other" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction='row'>
                                        <Grid item>
                                            <Typography className={classes.heading} align='left'>SOW Commence</Typography>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    slotProps={{
                                                        textField: {
                                                            required: true
                                                        }
                                                    }}
                                                    onChange={(newValue) => props.setValue("sow_commence", newValue)}
                                                    defaultValue={props.watch('sow_commence') ? moment(props.watch('sow_commence')) : undefined}
                                                    className='date-input'
                                                    name="sow_commence"
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.heading} align='left'>SOW Completion</Typography>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    slotProps={{
                                                        textField: {
                                                            required: true
                                                        }
                                                    }}
                                                    minDate={props.watch('sow_commence') ? moment(props.watch('sow_commence')) : undefined}
                                                    onChange={(newValue) => props.setValue("sow_complete", newValue)}
                                                    defaultValue={props.watch('sow_complete') ? moment(props.watch('sow_complete')) : undefined}
                                                    className='date-input'
                                                    value={props.watch('sow_complete') ? moment(props.watch('sow_complete')) : undefined}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        {
                                            (moment(props.watch('sow_commence')).format('DD-MM-YYYY') > moment(props.watch('sow_complete')).format('DD-MM-YYYY')) ?
                                                (<span style={{ color: 'red' }}>Commencement date must precede Completion date.</span>) : ""
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography align='left' className={classes.heading}>Billing</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction='row' justifyContent='flex-start'>
                                        <Grid item>
                                            {
                                                props.watch('billing') ? (
                                                    <FormControl>
                                                        <RadioGroup row
                                                            onChange={onchangeBilling}
                                                            value={props.watch('billing')}
                                                        >
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="monthly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Monthly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="quarterly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Quarterly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="half_yearly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Half Yearly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="annually" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Annualy" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                ) : (
                                                    <FormControl>
                                                        <RadioGroup row
                                                            onChange={onchangeBilling}
                                                        // value={props.watch('billing')}
                                                        >
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="monthly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Monthly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="quarterly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Quarterly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="half_yearly" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Half Yearly" />
                                                            <FormControlLabel sx={{ color: '#C7CCD0' }} value="annually" control={<Radio sx={{ color: '#C7CCD0' }} />} label="Annually" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Box>
        </Grid>
    )
}

const useStyles = makeStyles({
    selectLabel: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#C7CCD0',
            },
            '&:hover fieldset': {
                borderColor: '#C7CCD0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#C7CCD0',
            }
        },
        '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)',
            '&.Mui-focused': {
                color: '#FFC540'
            },
        },
        '& svg': {
            color: '#FFFFFF'
        }
    },
    heading: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#C7CCD0',
        lineHeight: '24px',
        marginBottom: '12px'
    },
});

const restoreTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC540'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-input': {
                        color: '#FFFFFF',
                    },
                    '& svg': {
                        color: '#FFFFFF'
                    },
                    '&.number-input': {
                        color: '#FFFFFF',
                        width: '20%',
                        '& fieldset': {
                            borderRadius: '8px 0px 0px 8px',
                            border: '1px solid #FFFFFF',
                        },
                        '&:hover fieldset': {
                            borderColor: '#FFC540',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FFC540',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'rgba(255, 255, 255, 0.5)',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                    },
                    '&.number-input-uom': {
                        width: '20%',
                        '& fieldset': {
                            border: '1px solid #FFFFFF',
                            borderRadius: '0px 8px 8px 0px',
                        },
                        '&:hover fieldset': {
                            borderColor: '#FFC540',
                        },
                    },
                    '&.date-input': {
                        color: '#FFFFFF',
                        // width: '85%',
                        marginRight: '16px',
                        padding: '5px',
                        '& fieldset': {
                            width: '100%',
                            borderRadius: '8px',
                            border: '1px solid #FFFFFF',
                        },
                        '&:hover fieldset': {
                            borderColor: '#FFC540',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FFC540',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#FFFFFF',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #5F5F5F',
                    '&:last-child td, &:last-child th': {
                        border: 0,
                    },
                }
            }
        }
    },
});