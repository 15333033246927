import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Typography, Pagination, PaginationItem } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { ButtonColor } from "../theme/theme";
import axios from "axios";
import { Link } from 'react-router-dom';
import moment from "moment";
//import { env } from "../../env";
import { getApiUrl } from "./../../global";
import { StyledButtonContained } from "../../views/Admin/AccountCreation/accountCreation";

export const OrderList = () => {
  // const apiUrl="/assets/json/getOrder";
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderCount, setOrderCount] = React.useState(0);
  const [alignment, setAlignment] = React.useState("current");
  const [orderList, setOrderList] = React.useState([]);
  const classes = useStyles();
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getOrderData();
  }, [currentPage])
  const getOrderData = async () => {
    const apiUrl = await getApiUrl() + `${process.env.REACT_APP_API_BASE_URL}` + "/orders" + `?page=${currentPage}`;
    axios
      .get(apiUrl)
      .then(data => {
        setOrderList(data.data.results);
        setOrderCount(data.data.count);
        setTotalPages(Math.ceil(data.data.count / 10));
      }).catch(error => console.log(error));
  };
  let state = [
    // {
    //   key: "created", value: "Created", width: '10%'
    // },
    // {
    //   key: "request_received", value: "Request Received", width: '20%'
    // },
    {
      key: "preparing_for_shipment", value: "Preparing For Shipment", width: '20%'
    }, {
      key: "courier_en_route", value: "Courier En route", width: '20%'
    }, {
      key: "shipment_collected", value: "Shipment Collected", width: '20%'
    }, {
      key: "out_for_delivery", value: "Out For Delivery", width: '20%'
    }, {
      key: "verified_and_completed", value: "Verified & Completed", width: '20%'
    },
    // {
    //   key: "shipment_started", value: "Shipment Started", width: '30%'
    // }, {
    //   key: "shipment_reached", value: "Shipment Reached", width: '40%'
    // }, {
    //   key: "boxes_delivered", value: "Boxes Delivered", width: '50%'
    // }, {
    //   key: "tapes_picked_up", value: "Tapes Picked Up", width: '60%'
    // }, {
    //   key: "tapes_delivered", value: "Tapes Delivered", width: '70%'
    // },
    {
      key: "audit_started", value: "Audit Started", width: '80%'
    }, {
      key: "audit_complete", value: "Audit Complete", width: '90%'
    }, {
      key: "ingestion_started", value: "Ingestion Started", width: '100%'
    }, {
      key: "ingestion_complete", value: "Ingestion Complete", width: '100%'
    }]
  function mapOrderStep(_state) {
    let step = state.find((e) => e.key?.toLowerCase() == _state?.toLowerCase());
    return step?.value ? step.value : _state;
  }
  function mapOrderStepWidth(_state) {
    let step = state.find((e) => e.key?.toLowerCase() == _state?.toLowerCase());
    return step?.width ? step.width : '0%';
  }

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(currentPage * 10, orderCount);
  return (
    <Box p={4} className={classes.mainContainer}>
      <Grid container direction='row' justifyContent='flex-start'>
        <Grid item>
        <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px', color :"#fff" }}>Shipping Cart</Typography>

          {/* <img style={{ width: '36px', height: '36px' }} src="/assets/img/cart.svg" alt="" /> */}
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={2} style={{ maxWidth: '85%' }}>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            {/* <Grid item className={classes.toggleContainer}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="current">This month</ToggleButton>
                <ToggleButton value="last">Last month</ToggleButton>
                <ToggleButton value="year">This Year</ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}
          </Grid>
        </Grid>
        {/*         <Grid container direction="column" padding={8} spacing={4} mt={8} style={{border: '1px solid #C7CCD0', borderRadius: '12px 12px 0px 0px', minWidth: '55%', overflowY: 'auto'}}>  */}
        {orderList &&
          orderList.map((l, i) => {
            if (l.status === 'draft') {
              return (<></>);
            }
            return (
              <Grid item key={i}>
                <Box>
                  <div className={classes.secondaryConatiner}>
                    <Box p={4}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Grid item>
                              <Typography align="left">
                                <b style={{ textTransform: 'capitalize' }}>{l.order_type}</b>
                              </Typography>
                              <Typography align="left">Created Date : <b>{moment(l.created_at).format("DD MMM YYYY")}</b></Typography>
                            </Grid>
                            <Grid item>
                              <div className={classes.orderIdContainer}>
                                <Box p={1}>
                                  <Typography
                                    className={classes.orderIdHeading}
                                  >
                                    Order ID
                                  </Typography>
                                  <Typography className={classes.orderidd}>
                                    <b>{l.order_number}</b>
                                  </Typography>
                                </Box>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Grid item>
                              <div className={classes.orderStatusContainer}>
                                <Box p={2}>
                                  <Typography
                                    align="left"
                                    className={classes.orderStatusTextHeading}
                                  >
                                    <b>Your Order Status</b>
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className={classes.orderstatusData}
                                  >
                                    {mapOrderStep(l.status)}
                                  </Typography>
                                </Box>
                              </div>
                            </Grid>
                            <Grid item>
                              <Link to={`/OrderTracking/${l.uuid}`} passHref state={{ data: l }}>
                                <Button
                                  type='submit'
                                  id={l.order_number}
                                  size="large"
                                  fullWidth={true}
                                  variant="contained"
                                  style={{
                                    width: "220px",
                                    backgroundColor: ButtonColor,//#3A3E3F
                                    color: '#3A3E3F',
                                    borderRadius: "30px",
                                    height: "60px",
                                    textTransform: "none",
                                    fontSize: '18px',
                                    fontWeight: 400
                                  }}>
                                  View
                                </Button>
                              </Link>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Box>
              </Grid>
            );
          })}
        {/*         </Grid>  */}
        {(orderCount !== 0) && (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
            padding={2}
            mt={4}
            sx={{ paddingLeft:'0px', paddingRight:'0px', width: '95%', minWidth: '55%' }}>
            <Grid item >
              {/*               <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                {`Showing ${startIndex}-${endIndex} of ${orderCount} Orders`}
              </Typography> */}
            </Grid>
            <Grid item>
              <Pagination
                component="div"
                rowsPerPage={10}
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  '& .MuiPaginationItem-root': {
                    width: '30px',
                    height: '30px',
                    border: '1px solid #FFFFFF',
                    background: '#3A3E3F',
                    color: '#FFFFFF',
                    margin: '0 4px',
                    '&.Mui-selected': {
                      background: '#FFC540',
                      color: '#3A3E3F',
                    },
                  },
                  '& .MuiPaginationItem-page.Mui-selected:hover': {
                    background: '#FFC540',
                    color: '#3A3E3F',
                  },
                  '& .MuiPaginationItem-page:hover': {
                    background: '#FFC540',
                    color: '#3A3E3F',
                  },
                  '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                    width: '100px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF',
                    background: '#3A3E3F',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                    background: '#FFC540',
                    color: '#3A3E3F',
                  },
                }}

                renderItem={(item) => (
                  <PaginationItem {...item} />
                )}
              />
            </Grid>
          </Grid>
        </div>)}
      </Grid>
    </Box>
  );
};




const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: ButtonColor,
    borderRadius: "8px",
    color: '#404558',
    fontWeight: 600,
    fontSize: "14px",
    border: `1px solid ${ButtonColor}`,
  },
  "&.MuiToggleButton-root": {
    color: "white",
    borderRadius: "8px",
    textTransform: "none !important",
    height: "34px",
    fontSize: "14px",
  },
});

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: "transparent",
  },
  secondaryConatiner: {
    borderRadius: "20px",
    border: "1px solid #C7C7C7",
    color: "white",
  },
  toggleContainer: {
    height: "40px",
    border: `1px solid ${ButtonColor}`,
    padding: "2px",
    borderRadius: "8px",
  },
  orderIdContainer: {
    backgroundColor: "transparent",
    border: "1px solid #A5A5A5",
  },
  orderIdHeading: {
    color: "#686868",
    fontSize: "18px",
    fontWeight: 400
  },
  orderidd: {
    color: '#FFC540',
    fontWeight: 700,
    fontSize: '18px'
  },
  orderIdData: {
    color: ButtonColor,
    fontWeight: 700,
  },
  orderStatusContainer: {
    backgroundColor: 'grey',
    borderRadius: "20px",
    width: '431px'
  },
  orderStatusTextHeading: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 700,
  },
  orderstatusData: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 400,
  },
  viewButton: {
    width: "280px",
    backgroundColor: ButtonColor,
    borderRadius: "30px",
    height: "60px",
    textTransform: "none",
  },
});
