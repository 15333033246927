import { Ingestion } from "../../../../../component/ingestion/ingestion";


export const AdminIngestion = (props) => {

    return (
        <>
            <Ingestion orderDetail={props.orderDetail} isAdmin = {true}/>
        </>
    )
}