import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  Select,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import { CheckCircle, Close, Error } from "@mui/icons-material";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { useForm, useWatch } from "react-hook-form";
import {
  StyledButtonContained,
  StyledButtonOutlined,
} from "../Admin/AccountCreation/accountCreation";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { getLoginUser } from "../../LocalStorageService";
import axios from "axios";

const RestoreForm = ({
  isFormOpen,
  handleCloseFormDialog,
  cloudShow,
  physicalShow,
  rowData,
  handleCloseCheckoutDialog,
  handleClickcancel,
}) => {
  // const [state, setState] = useState({
  //   open: false,
  //   vertical: "top",
  //   horizontal: "right",
  // });
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const { vertical, horizontal, open } = state;
  const { register, handleSubmit } = useForm();
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [urgencyLevel, seturgencyLevel] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [tapeIds, setTapeIds] = useState("");
  const [method, setMethod] = useState([]);
  const userDetails = getLoginUser();
  // const openSnackbar = (message) => {
  //   setSnackbarMessage(message);
  //   setState({
  //     vertical: "top",
  //     horizontal: "right",
  //     open: true,
  //   });
  // };

  // const closeSnackbar = () => {
  //   setState({
  //     vertical: "top",
  //     horizontal: "right",
  //     open: false,
  //   });
  // };
  // Validators function
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validatePhoneNumber = (phone) => {
    const formattedPhoneNumber = phone.replace(/\D/g, "");
    if (formattedPhoneNumber.length < 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };
  const useStyles = makeStyles({
    rootMenuItem: {
      "&$selected": {
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "green",
          borderRadius: 12,
          border: "1px solid var(--White, #FFF)",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        border: 0,
        borderRadius: 12,
        border: "1px solid var(--White, #FFF)",
      },
    },
    highlight: {
      background: "#FFC540",
      color: "#3A3E3F",
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
    disabledInput: {
      "&.Mui-disabled": {
        color: "white",
      },
    },
    ".MuiOutlinedInput-notchedOutline":{
      borderColor: "white !important",
    },
    selectLabel: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          borderColor: "#C7CCD0",
        },
        "&:hover fieldset": {
          borderColor: "#C7CCD0",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#C7CCD0",
        },
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root": {
        color: "rgba(255, 255, 255, 0.5)",
        "&.Mui-focused": {
          color: "#FFC540",
        },
      },
      "& svg": {
        color: "#FFFFFF",
      },
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
    select: {
      "&:disabled": {
        color: "inherit",
      },
    },
  });
  const classes = useStyles();

  const onSubmit = (data) => {
    console.log(data);
    console.log("Form Submitted");

    const apiUrl =
      "https://ppnpnm8ecz.ap-southeast-2.awsapprunner.com/api/v1/contact/";
    let payload = {
      company_name: data.companyName,
      contact_name: contactName,
      email: email,
      phone_number: phoneNumber,
      contact_type: "restore",
      message: message,
      urgency: urgencyLevel,
      tape_ids: tapeIds,
      method: method.map((i) => `${i}`).join(","),
      contact_by_phone: false,
      contact_by_email: false,
      contact_email: email,
    };
    axios
      .post(apiUrl, payload)
      .then((response) => {
        if (response?.status == 201) {
          // setSnackbarMessage(response.message);
          handleCloseFormDialog();

          handleClickcancel();
          handleCloseCheckoutDialog();
        }
      })
      .catch((error) => {
        console.log(error);
        // setSnackbarMessage(error);
      });
  };

  useEffect(() => {
    console.log({ userDetails });
    setCompanyName(
      userDetails.user.company_name
    );
    setContactName(
      userDetails.user.first_name + " " + userDetails.user.last_name
    );
    setEmail(userDetails.user.email);
    const rowIds = rowData?.map((i) => i.item_volser).join(",");
    setTapeIds(rowIds);
    if (physicalShow) {
      setMethod(["physicalRestore"]);
    }
    if (cloudShow) {
      setMethod(["cloudRestore"]);
    }
    if (cloudShow && physicalShow) {
      setMethod(["cloudRestore", "physicalRestore"]);
    }
    // if (cloudShow && physicalShow) {
    // setMethod(["cloudRestore", "physicalRestore"]);
    // else if(cloud)
    // }
  }, [physicalShow, cloudShow, rowData]);

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={closeSnackbar}
        message={snackbarMessage}
        key={vertical + horizontal}
      /> */}
      <div>
        <Dialog
          open={isFormOpen}
          maxWidth="md"
          fullWidth={true}
          PaperProps={{
            sx: { borderRadius: "12px", backgroundColor: "#3A3E3F" },
          }}
        >
          <DialogContent
            sx={{ p: 4, backgroundColor: "#3A3E3F", color: "#FFFFFF" }}
          >
            {/* <Grid container direction='column' spacing={2}> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#FFC540", fontSize: 18 }}>
                Restore Process
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl {...register("companyName")} fullWidth>
                    <TextField
                      label="Company Name"
                      name="companyName"
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                      InputProps={{
                        endAdornment: companyName !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />{" "}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("contactName")} fullWidth>
                    <TextField
                      label="Contact Name"
                      name="contactName"
                      disabled
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                      required

                      InputProps={{
                        endAdornment: contactName !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      sx={{
                        "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset":{
                            borderColor:"#ffffff !important"
                          }
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("emailAddress")} fullWidth>
                    <TextField
                      label="Email Address"
                      name="emailAddress"
                      type="email"
                      disabled
                      onBlur={(e) => {
                        if (e.target.value.length > 0) {
                          validateEmail(e.target.value);
                        }
                      }}
                      error={emailError}
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (emailError) {
                          setEmailError(null);
                        }
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      helperText={
                        emailError &&
                        "Invalid Email. It should be in the format example@domain.com"
                      }
                      sx={{
                        "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset":{
                            borderColor:"#ffffff !important"
                          }
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("phoneNumber")} fullWidth>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      // type="tel"
                      required
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        if (phoneNumberError) {
                          setPhoneNumberError(null);
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 0) {
                          validatePhoneNumber(e.target.value);
                        }
                      }}
                      error={phoneNumberError}
                      InputProps={{
                        endAdornment: phoneNumberError !== null &&
                          phoneNumber !== "" && (
                            <InputAdornment position="end">
                              {phoneNumberError ? (
                                <Error color="error" />
                              ) : (
                                <CheckCircle sx={{ color: "#009621" }} />
                              )}
                            </InputAdornment>
                          ),
                      }}
                      helperText={
                        phoneNumberError &&
                        "Invalid Phone. It should be 10 digits"
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("tapIds")} fullWidth>
                    <TextField
                      label="Tape Ids"
                      name="tapIds"
                      disabled
                      value={tapeIds}
                      onChange={(e) => setTapeIds(e.target.value)}
                      required
                      InputProps={{
                        endAdornment: tapeIds !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      sx={{
                        "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset":{
                            borderColor:"#ffffff !important"
                          }
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                  {/* <FormControl {...register("tapIds")} fullWidth>
                <TextField
                  label="Tape Ids"
                  name="tapIds"
                  value={tapeIds}
                  onChange={(e) => setTapeIds(e.target.value)}
                  className={classes.selectLabel}
                  style={{ marginBottom: "12px" }}
                  select
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "#C7CCD0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#C7CCD0",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#C7CCD0",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      //fontFamily: 'Poppins',
                      //fontWeight: 400,
                      //lineHeight: '33px',
                      //fontSize: '22px',
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(255, 255, 255, 0.5)",
                      "&.Mui-focused": {
                        color: "#FFC540",
                      },
                    },
                    "& svg": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="id1">ID 1</MenuItem>
                  <MenuItem value="id2">ID 2</MenuItem>
                  <MenuItem value="id3">ID 3</MenuItem>
                </TextField>
              </FormControl> */}
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("fileName")} fullWidth>
                    <TextField
                      label="File Folder names"
                      name="fileName"
                      type="text"
                      multiline
                      onChange={(e) => setMessage(e.target.value)}
                      inputProps={{ maxLength: 1200 }}
                      rows={4}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("method")} fullWidth>
                    {/* <TextField
                  label="Restore Method"
                  name="method"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                  required
                  select
                  InputProps={{
                    endAdornment: method !== "" && (
                      <InputAdornment position="end">
                        <CheckCircle sx={{ color: "#009621" }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.selectLabel}
                  style={{ marginBottom: "12px" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "#C7CCD0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#C7CCD0",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#C7CCD0",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      //fontFamily: 'Poppins',
                      //fontWeight: 400,
                      //lineHeight: '33px',
                      //fontSize: '22px',
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(255, 255, 255, 0.5)",
                      "&.Mui-focused": {
                        color: "#FFC540",
                      },
                    },
                    "& svg": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="physicalRestore">Physical Restore</MenuItem>
                  <MenuItem value="cloudRestore">Cloud Restore</MenuItem>
                </TextField> */}
                    <Select
                      label="Restore Method"
                      multiple
                      disabled
                      value={method}
                      inputProps={{ style: { color: "#ffff" } }}
                      onChange={(e) => console.log(e.target.value)}
                      className={classes.select}
                      style={{ marginBottom: "12px", color: "#fff",border: '1px solid white',color: 'white' }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        
                        "& svg": {
                          color: "#FFFFFF",
                        },
                        "&.Mui-disabled": {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem
                        style={{ color: "#fff" }}
                        value="physicalRestore"
                      >
                        Physical Restore
                      </MenuItem>
                      <MenuItem style={{ color: "#fff" }} value="cloudRestore">
                        Cloud Restore
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("urgencyLevel")} fullWidth>
                    <TextField
                      label="Urgency Level"
                      name="urgencyLevel"
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      value={urgencyLevel}
                      onChange={(e) => seturgencyLevel(e.target.value)}
                      select
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                            borderColor: "#C7CCD0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#C7CCD0",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#C7CCD0",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //fontFamily: 'Poppins',
                          //fontWeight: 400,
                          //lineHeight: '33px',
                          //fontSize: '22px',
                          color: "#FFFFFF",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-focused": {
                            color: "#FFC540",
                          },
                        },
                        "& svg": {
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <StyledButtonOutlined
                      variant="outlined"
                      onClick={handleCloseFormDialog}
                      sx={{
                        width: "200px",
                        height: "60px",
                      }}
                    >
                      Cancel
                    </StyledButtonOutlined>
                  </Grid>
                  <StyledButtonContained
                    variant="contained"
                    type="submit"
                    disabled={
                      phoneNumberError ||
                      phoneNumber === "" ||
                      emailError ||
                      email === ""
                    }
                    sx={{
                      width: "200px",
                      height: "60px",
                      borderRadius: "30px",
                    }}
                  >
                    Submit
                  </StyledButtonContained>
                </Grid>
              </Grid>
            </form>
            {/* </Grid> */}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default RestoreForm;
