import * as React from 'react';
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Grid, Box, Typography, Drawer, Button, DialogTitle, Divider, DialogContent, Paper, Dialog, DialogActions } from '@mui/material';
import { AirlineSeatIndividualSuiteSharp, CheckCircle, CheckOutlined, LegendToggleSharp } from '@mui/icons-material';
import { Shipping } from "../Shipping/shipping";
import { AdminDocument } from '../../../component/admin/document/adminDocument';
import { AdminFee } from '../../../component/admin/fee/adminFee';
import { useState } from 'react';
import { Account } from '../Account/Account';
import { Job } from '../Job/Job';
import { AdminTapeForm } from '../Tape/tape';
import { AdminIngestionForm } from '../Ingestion/ingestion';
import { flexStyles } from '../../../component/theme/theme';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import axios from "axios";
//import { env } from "../../../env";
import moment from "moment";
import Snackbar from '@mui/material/Snackbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { elements } from 'chart.js';
import { getApiUrl } from "./../../../global";
import { AdminAccountCreationSuccess, StyledButtonContained } from "../AccountCreation/accountCreation"


let steps = ['Job', 'Shipping', 'Tape', 'Ingestion', 'Fee', 'Documents'];

const CustomStepIconRoot = styled('div')(({ ownerState }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: '#3A3E3F',
    textAlign: 'center',
    zIndex: 2,
    opacity: (ownerState.completed) ? 1 : 0.5,

    ...(ownerState.active && {
        background: '#FFC540',
        border: '2px inset #3A3E3F',
        opacity: 1,
    }),

    ...(ownerState.completed && {
        opacity: 1,
    }),

    '& .MuiStepIcon-completed': {
        color: '#FFC540'
    },

    '& .MuiStepIcon-circle': {
        color: '#3A3E3F',
    },
}));

function CustomStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
            {completed ? (
                <CheckOutlined className='MuiStepIcon-completed' style={{ opacity: 1 }} />
            ) : (
                <div className='MuiStepIcon-circle' style={{ opacity: active ? 1 : 0.5 }} />
            )}
        </CustomStepIconRoot>
    );
}

CustomStepIcon.propTypes = {
    active: PropTypes.bool,

    completed: PropTypes.bool,

    className: PropTypes.string,
};

const CustomConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#3A3E3F',
            opacity: 1,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#3A3E3F',
            opacity: 1,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#3A3E3F',
        opacity: 0.5,
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export const OrderCreation = (props) => {
    const [disableSaveAndNext, setDisableSaveAndNext] = useState(false);
    const [signedContracts, setSignedContracts] = useState([]);
    const [billFile, setBillFile] = useState([]);
    const [twoFAMode, setTwoFAMode] = React.useState("verify_by_email");


    const [shippingMode, setShippingMode] = React.useState("tapeark");
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const { vertical, horizontal, open } = state;

    const openSnackbar = (message) => {
        setSnackbarMessage(message)
        setState({
            vertical: 'top', horizontal: 'right', open: true
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: 'top', horizontal: 'right', open: false
        });
    };
    const [orderDetail, setOrderDetail] = React.useState();
    const [startDateFilter, setStartDateFilter] = React.useState(null);
    const [customerDetail, setCustomerDetail] = React.useState(null);
    const [userDetail, setUserDetail] = React.useState(null);
    const [companyname, setCompanyname] = React.useState('');
    const [tenantid, setTenantid] = React.useState('')

    const [tapeListFile, setTapeListFile] = React.useState(null);
    const [virtualMachineFile, setVirtualMachineFile] = React.useState(null);
    const [isFromPopup, setIsFromPopup] = React.useState(false);

    let location = useLocation();
    const [isEditClient, setIsEditClient] = useState(location?.state?.isEditClient); //TODO: Edit client refactor

    React.useEffect(() => {
        if (location?.state?.uuid) {
            setValue('tenant_id', location?.state?.uuid); // Set Customer Name
        }
        getStepDetails();

    }, []);
    const getStepDetails = async (step) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();
        step = step ? step : 0;
        if (_orderUUID || isEditClient) {
            if (step == 0) {
                setValue('isJobStepLoading', true);
                if (_orderUUID) {

                    let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
                    axios.get(apiUrl).then(data => {
                        // setOrderDetail(data.data);
                        setValue('tenant_id', data.data.customer); // Set Customer Name

                        // setValue('tenant_id',)
                        setValue("project_deliverables", data.data.project_deliverables);//quote_reference_id
                        setValue("project_scope", data.data.project_scope);
                        setValue("quote_reference_id", data.data.quote_reference_id);//security_privace
                        setValue("security_privace", data.data.job_security_privacy);
                        setValue("sow_commence", data.data.sow_start);
                        setValue("sow_complete", data.data.sow_end);
                        setValue('billing', data.data.billing)
                        setValue('isJobStepLoading', false);

                    }).catch(error => {
                        console.log(error);
                        setValue('isJobStepLoading', false);
                    });
                }
            } else if (step == 1) {
                // if(orderDetails?.uuid != _orderUUID){
                let apiUrlOrder = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
                axios
                    .get(apiUrlOrder)
                    .then(data => {
                        let _orerDetail = data.data;
                        setOrderDetail(_orerDetail);
                        setValue("chainOfCustody", data.data.chain_of_custody);
                        let _setShipping_mode = data?.data?.shipping_mode ? data?.data?.shipping_mode : 'tapeark';
                        setValue("shippingMode", _setShipping_mode);
                        setShippingMode(_setShipping_mode);
                        // if (_orerDetail?.shipments?.length) {
                        //     setValue("pickUpDateFromTapeArk", _orerDetail?.shipments[0].pick_up_date);
                        //     setStartDateFilter(moment(_orerDetail?.shipments[0].pick_up_date));
                        // }
                        let allShipments = [];
                        let isRemoveDefaultData = false;
                        _orerDetail?.shipments?.length && _orerDetail?.shipments?.forEach(element => {
                            // ***** If has firstname then card view else link view
                            if (element.first_name) {
                                let apiUrlShipment = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/shipments/${element.uuid}/`;
                                allShipments.push(axios.get(apiUrlShipment))
                            } else {
                                if (!isRemoveDefaultData) {
                                    isRemoveDefaultData = true;
                                    shippingLinksRemove(0);
                                }
                                setTimeout(() => {
                                    shippingLinksAppend({ link: element.tracking_link, uuid: element.uuid })
                                }, 10);
                            }
                        });
                        getShipmetDetails(allShipments);
                    })
                    .catch(error => console.log(error));
                // }

            } else if (step == 5) {
                getOrderDocuments();
            } else if (step == 2 || step == 3) {
                setValue('isTapeStepLoading', true);
                setValue('isIngestionStepLoading', true);
                // ***** Added for default selection
                setValue("tape.accountOptions", 'enterprise');

                let ordersApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}`;

                axios.get(ordersApiUrl, {
                }).then((response) => {
                    if (step == 2) {
                        setValue("tape.numberOfBoxes", response.data.number_of_boxes);
                        setValue("tape.volserAvailable", response.data.volser_available);
                        setValue("tape.backupType", response.data.backup_type);
                        setValue("tape.accountOptions", response.data.account ? response.data.account : 'enterprise');

                        let tapesApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/tapes/`;
                        axios.get(tapesApiUrl, {
                        }).then((tapeResponse) => {

                            if (tapeResponse.data.results?.length > 0) {
                                tapeResponse.data.results.map((tape, index) => {
                                    setValue(`tape.tapeDetails[${index}].tapeType`, tape.type);
                                    setValue(`tape.tapeDetails[${index}].number`, tape.number);
                                    setValue(`tape.tapeDetails[${index}].tapeFormat`, tape.backup_format);
                                    setValue(`tape.tapeDetails[${index}].uuid`, tape.uuid);
                                })
                            }   /* else if (watch(`tape.tapeDetails`)?.length === 0){
                                setValue(`tape.tapeDetails[0]`, {tapeType: '', number: 0, tapeFormat: '', uuid: null});
                                } */

                            let documentsApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/`;
                            axios.get(documentsApiUrl, {
                            }).then((documentResponse) => {

                                if (documentResponse.data.results) {
                                    const documents = JSON.parse(JSON.stringify(documentResponse.data.results));
                                    documents.map((document, index) => {
                                        if (document.file_type === 'tape_list') {
                                            setValue("tape.orderdocuments.tapeList", (new File([""], document.name, { type: document.mime_type, lastModified: new Date() })));
                                            //setTapeListFile(new File([""], document.file_name, { type: document.mime_type, lastModified: new Date()}));
                                            setValue('tape.orderdocuments.tLuuid', document.uuid);
                                        }
                                        if (document.file_type === 'virtual_machine') {
                                            if (document.link) {
                                                setValue("tape.orderdocuments.vMLink", document.file_link);
                                                setValue('tape.orderdocuments.vMuuid', document.uuid);

                                            } else {
                                                setValue("tape.orderdocuments.virtualMachine", (new File([""], document.name, { type: document.mime_type, lastModified: new Date() })));
                                                //setVirtualMachineFile(new File([""], document.file_name, { type: document.mime_type, lastModified: new Date()}));
                                                setValue('tape.orderdocuments.vMuuid', document.uuid);

                                            }

                                        }
                                    });
                                }

                                setValue('isTapeStepLoading', false);

                            }).catch(err => {
                                console.log(err);
                                setValue('isTapeStepLoading', false);
                            });

                        }).catch(err => {
                            console.log(err);
                            setValue('isTapeStepLoading', false);
                        });

                    }
                    if (step == 3) {
                        setValue("ingestion.postIngestion", response.data.post_ingestion);
                        setValue("ingestion.destroyMode", response.data.destroy_mode);
                        setValue("ingestion.storageDuration", response.data.storage_duration);
                        setValue("ingestion.tapeOptions", response.data.tape_security_privacy);
                        setValue("ingestion.returnTo", response.data.tape_return_to_sender);

                        if (response.data.tape_return_to_sender === false) {
                            if (response?.data?.tape_return_address) {
                                setValue("ingestion.returnAddress.address_line_1", response?.data?.tape_return_address?.address_line_1);
                                setValue("ingestion.returnAddress.zipcode", response?.data?.tape_return_address?.postal_code);
                                setValue("ingestion.returnAddress.country", response?.data?.tape_return_address?.country);
                                setValue("ingestion.returnAddress.city", response?.data?.tape_return_address?.city);
                                setValue("ingestion.returnAddress.state", response?.data?.tape_return_address?.state);
                            }
                        }
                    }

                    setValue("isIngestionStepLoading", false);
                }).catch((err) => {
                    console.log(err);
                    setValue('isTapeStepLoading', false);
                    setValue("isIngestionStepLoading", false);
                });
            }
        }
    };
    const removeShipmentLink = async (index, uuid) => {
        let shipmentPayload = { uuid: uuid }
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();

        let removeShipmentApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/shipments/${shipmentPayload.uuid}/`;
        axios.delete(removeShipmentApiUrl, shipmentPayload, {}).then((res) => {
            shippingLinksRemove(index);
        }).catch(function (error) {
            console.log(error);
        });
    }
    const getOrderDocuments = async () => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/`;
        axios
            .get(apiUrl)
            .then(res => {
                console.log("Order Document Data", res.data)
                let signedDoc = JSON.parse(JSON.stringify(res.data.results));
                signedDoc = signedDoc.filter((file) => file.file_type == 'signed_contract');
                setSignedContracts(signedDoc);

                let billDoc = JSON.parse(JSON.stringify(res.data.results));
                billDoc = billDoc.filter((file) => file.file_type == 'bill');
                setBillFile(billDoc);
            })
            .catch(error => console.log(error));
    }

    const getShipmetDetails = (allRequest) => {
        axios.all(allRequest).then((res) => {
            let shipmentLocations = [];
            res.forEach((response) => {
                if (response.data.shipping_address) {
                    let element = {
                        uuid: response.data.uuid,
                        tempId: new Date().valueOf(),
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        phone: response.data.phone_number,
                        mobile: response.data.mobile_number,
                        optional_email: response.data.optional_email,
                        pickup_date: response.data.pickup_date,
                        
                        country: response.data.shipping_address.country,
                        city: response.data.shipping_address.city,
                        state: response.data.shipping_address.state,
                        zipCode: response.data.shipping_address.postal_code,
                        address: response.data.shipping_address.address_line_1,
                        ingestionSite: response.data.ingestion_site,
                        shippingPartner: response.data.courier_partner,
                    };
                    shipmentLocations.push(element);
                } else {
                    response.data.tracking_links.forEach(element => {
                        shippingLinksAppend({ link: element.link })
                    });
                }
            });
            setShippingLocations(shipmentLocations)
            // 
            //     setShippingLocations([...shippingLocations, element])
            // }
            // setOrderDetail(data.data);
        }).catch(error => console.log(error));
    }
    const { register, control, handleSubmit, setValue, getValues, watch, reset, formState: { errors }, } = useForm({
        defaultValues: {
            shippingLinks: [{ link: "" }],
            tape: {
                tapeDetails: [{ tapeType: '', number: 0, tapeFormat: '' }]
            }
        }
    });
    const { fields: shippingLinksFields, append: shippingLinksAppend, remove: shippingLinksRemove } = useFieldArray({
        control,
        name: 'shippingLinks'
    })
    const [selectedImage, setSelectedImage] = useState([{
        base64URL: "",
        file: ''
    }]);
    const [customerTid, setCustomerTid] = React.useState("")
    //Order Id - required field in Fee, Tape, Shipment, CreateOrderDocument
    const [orderId, setOrderId] = useState(null);//'632aa41d-9101-41bb-9da0-a866c592bc3f'

    const [shippingLocations, setShippingLocations] = React.useState([]);
    const objectToFormData = (_object) => {
        let formData = new FormData();
        Object.entries(_object).forEach(([key, value]) => {
            if (key === "contact_address") {
                for (let customerKey in _object[key]) {
                    formData.append(`contact_address.${customerKey}`, _object[key][customerKey]);
                }
            } else if (key === "domain") {
                for (let index = 0; index < value.length; index++) {
                    const element = value[index];
                    formData.append(`domain[]`, element);
                }
            } else {
                formData.append(key, value);
            }
        });
        return formData;
    }
    const isStepValid = (active) => {
        if (moment(watch('sow_commence')).format('DD-MM-YYYY') > moment(watch('sow_complete')).format('DD-MM-YYYY')) {
            return false;
        }
        return true;
    }
    const handleFormData = (data, event) => {
        const isNext = (event?.nativeEvent?.submitter?.value === 'saveAndContinue') ? true : false;
        console.log(data);
        if (activeStep == 0) {
            orderCreation(data, isNext);
        } else if (activeStep == 1) {
            shippingCreation(data, isNext);
        } else if (activeStep == 2) {
            tapeCreation(data.tape, isNext);
        } else if (activeStep == 3) {
            ingestionCreation(data.ingestion, isNext);
        } else if (activeStep == 4) {
            isNext && handleNext();
        } else if (activeStep == 5) {
            orderDocumentSubmission(data, isNext);
        }
    }
    let addShipment = (element) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let data = getValues();
        if (element.pickup_date) {
            let shipmentPayload = {
                "shipping_address": {
                    "address_line_1": element.address,
                    "address_line_2": '',
                    "country": element.country,
                    "city": element.city,
                    "state": element.state,
                    "postal_code": element.zipCode,
                    "city": element.city,
                },
                "first_name": element.firstName,
                "last_name": element.lastName,
                "phone_number": element.phone,
                "mobile_number": element.mobile,
                "courier_partner": element.shippingPartner,
                "optional_email": element.optional_email,
                "pickup_date": moment(element.pickup_date).format("YYYY-MM-DD"),
                "ingestion_site": element.ingestionSite,
                "order": _orderUUID
            }
            shipment(shipmentPayload, element);
        } else {
            openSnackbar("Please add date.")
        }
    }
    let updateShipment = (element) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let data = getValues();
        if (element.pickup_date) {
            let shipmentPayload = {
                "shipping_address": {
                    "address_line_1": element.address,
                    "address_line_2": '',
                    "country": element.country,
                    "city": element.city,
                    "state": element.state,
                    "postal_code": element.zipCode,
                    "city": element.city,
                },
                "first_name": element.firstName,
                "last_name": element.lastName,
                "phone_number": element.phone,
                "mobile_number": element.mobile,
                "courier_partner": element.shippingPartner,
                "optional_email": element.optional_email,
                "pickup_date": moment(element.pickup_date).format("YYYY-MM-DD"),
                "ingestion_site": element.ingestionSite,
                "order": _orderUUID,
                "uuid": element.uuid
            }
            shipment(shipmentPayload, element);
        } else {
            openSnackbar("Please add date.")
        }
    }
    let removeShipment = async (index, data) => {
        let shipmentPayload = { uuid: data[index].uuid }
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();

        let removeShipmentApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/shipments/${shipmentPayload.uuid}/`;
        axios.delete(removeShipmentApiUrl, shipmentPayload, {}).then((res) => {
            shippingLocations.splice(index, 1);
            data.splice(index, 1);
            setShippingLocations(data);
        }).catch(function (error) {
            console.log(error);
        });;
    }
    let shipment = async (data, element) => {
        let shipmentPayload = data;
        if (!data.uuid) {
            let url = await getApiUrl();

            const apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${shipmentPayload.order}/shipments/`;
            axios.post(apiUrl, shipmentPayload, {}).then((res) => {
                // console.log(res);
                // setOrderId(res?.orderId);
                // ***** Reset Form 
                if (element?.firstName) {
                    setValue("shippingLocations_firstName", null);
                    setValue("shippingLocations_lastName", null);
                    setValue("shippingLocations_phone", null);
                    setValue("shippingLocations_mobile", null);
                    setValue("shippingLocations_country", null);
                    setValue("shippingLocations_city", null);
                    setValue("shippingLocations_state", null);
                    setValue("shippingLocations_ZipCode", null);
                    setValue("shippingLocations_address", null);
                    setValue("shippingLocations_ingestionSite", "");
                    setValue("shippingLocations_partner", "");
                }
                element['uuid'] = res.data.uuid;
                setShippingLocations([...shippingLocations, element])
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            let url = await getApiUrl();

            const apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${shipmentPayload.order}/shipments/${shipmentPayload.uuid}/`;
            axios.patch(apiUrl, shipmentPayload, {
            }).then((res) => {
                let _shippingLocations = JSON.parse(JSON.stringify(shippingLocations));
                if (element?.firstName) {
                    let _index = _shippingLocations.findIndex(item => item.uuid === shipmentPayload.uuid);
                    if (-1 != _index) {
                        _shippingLocations[_index] = element;
                        setShippingLocations(_shippingLocations);
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        }

    }
    //shipment
    const shippingCreation = async (data, isNext) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();

        let apiUrlOrder = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
        axios.get(apiUrlOrder).then(orderDetailResponse => {
            setOrderDetail(orderDetailResponse.data);
            let shiipingCreationPaylod = {
                "chain_of_custody": data.chainOfCustody,
                "shipping_mode": data.shippingMode,
                "customer": orderDetailResponse?.data?.customer ? orderDetailResponse?.data?.customer : companyname,
                // "shipping_location": data.newShippingLocation,
                "shipping_location": 'single',
                // "uuid": "d6e71941-9d9c-4aa3-8b53-678011409e77"
                // ***** Orders
                // "order": "abc6a23b-f964-4c61-8275-669abf7c0ba0",
                // ***** shipment
            }
            let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
            axios.patch(apiUrl, shiipingCreationPaylod, {
            }).then((res) => {
                isNext && handleNext();
                // console.log(res);
                // setOrderId(res?.orderId);
            }).catch(function (error) {
                console.log(error);
            });;
        }).catch(error => {
            console.log(error);
            setValue('isJobStepLoading', false);
        });

        if (data.shippingMode == 'self') {
            // shippingLinks
            data.shippingLinks.forEach((element) => {
                let shipmentPayload = {
                    "tracking_link": element.link,//data.shippingLinks,
                    "pick_up_date": moment(data.pickUpDate).format("YYYY-MM-DD"),
                    "order": _orderUUID,
                    "uuid": element.uuid
                    // "shipping_address":{}
                }
                shipment(shipmentPayload);
            })

        } else if (data.shippingMode == 'tapeark') {
            if (data.newShippingLocation == "multiple") {
                data.shippingLocations.forEach(element => {
                    let shipmentPayload = {
                        "address": {
                            "address_line_1": element.address,
                            "address_line_2": '',
                            "country": element.country,
                            "city": element.city,
                            "state": element.state,
                            "phonenumber": element.phone,
                            "postcode": element.zipCode,
                            "mobilenumber": element.mobile
                        },
                        // "contact_name":  + ' ' + ,
                        "first_name": element.firstName,
                        "last_name": element.lastName,
                        "courier_partner": element.shippingPartner,
                        "optional_email": element.optional_email,
                        // "tracking_number": data.shippingLocations_tracking_number,
                        // "tracking_links": data.shippingLocations_tracking_number,

                        "pickup_date": moment(element.pickup_date).format("YYYY-MM-DD"),
                        "ingestion_site": element.ingestionSite,
                        "order": "d6e71941-9d9c-4aa3-8b53-678011409e77"
                    }
                    // shipment(shipmentPayload);
                });
            } else {
                let shipmentPayload = {
                    "address": {
                        "address_line_1": data.shippingLocations_address,
                        "address_line_2": '',
                        "country": data.shippingLocations_country,
                        "city": data.shippingLocations_city,
                        "state": data.shippingLocations_state,
                        "phonenumber": data.shippingLocations_phone,
                        "postcode": data.shippingLocations_ZipCode,
                        "mobilenumber": data.shippingLocations_mobile
                    },
                    //"contact_name": data.shippingLocations_firstName + ' ' + data.shippingLocations_lastName,
                    "first_name": data.shippingLocations_firstName,
                    "last_name": data.shippingLocations_lastName,
                    "courier_partner": data.shippingLocations_partner,
                    // "tracking_number": data.shippingLocations_tracking_number,
                    // "tracking_links": data.shippingLocations_tracking_number,
                    //  //moment(data.sow_commence).format("YYYY-MM-DD")
                    "pick_up_date": moment(data.pickUpDateFromTapeArk).format("YYYY-MM-DD"),
                    "ingestion_site": data.shippingLocations_ingestionSite,
                    "order": "d6e71941-9d9c-4aa3-8b53-678011409e77"
                }
                // shipment(shipmentPayload);
            }
        }

    }
    //Account creation
    const accountCreation = async (data, isNext) => {
        //  console.log(data.StorageOption, 'accountCraetion')
        let payLoadCustomer = {
            "contact_address": {
                "address_line_1": data.Address1,
                "address_line_2": data.Address2,
                "postal_code": data.ZipCode,
                "country": data.Country,
                "city": data.City,
                "state": data.State,
                "city": data.City,
            },
            "email": data.primaryEmail,
            "company_name": data.companyName,
            "work_location": data.WorkLocation,
            "phone_number": data.Phone,
            "mobile_number": data.Mobile,
            "storage_option": data.StorageOption,
            "company_logo": selectedImage?.base64URL?.split(',')[1],
            "domain": data?.length ? data.domain.map(e => e.name) : [],
            "mfa_sms": data.two_step_verification == 'verify_by_sms' ? true : false,
            "mfa_email": data.two_step_verification == 'verify_by_email' ? true : false,
            "mfa_authenticator": data.two_step_verification == 'verify_by_authenticator' ? true : false,
            "two_step_verification": data.two_step_verification == 'verify_by_sms' ? 'sms' :
                (data.two_step_verification == 'verify_by_authenticator' ? 'authenticator' :
                    (data.two_step_verification == 'verify_by_email' ? 'email' : 'email'))

        }
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        // let formDataPayload = objectToFormData(payLoadCustomer);
        let formDataPayload = payLoadCustomer;
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/customers/";
        //Account creation post and patch
        if (_orderUUID || customerDetail?.uuid || isEditClient) {
            let uuidCustomer = _orderUUID ? tenantid : customerDetail?.uuid;

            if (isEditClient) {  // TODO: Edit Client refactor
                uuidCustomer = location?.state?.customerId;
            }
            setValue('tenant_id', uuidCustomer);
            axios.patch(`${apiUrl}${uuidCustomer}/`, formDataPayload
                // , {
                //     headers: {
                //         "Content-Type": "multipart/form-data"
                //     },
                // }
            ).then((res) => {
                try {
                    if (res?.status == 200) {
                        accountRegistration(data, res.data);
                        setCustomerDetail(res.data);
                        openSnackbar("Data added successfully.");
                        setValue('tenant_id', res?.data?.uuid);
                        isNext && handleNext();
                    }
                } catch (e) {
                    setValue('tenant_id', '0');
                }
            }).catch(function (error) {
                setValue('tenant_id', '0');
                console.log(error);
            });;
        }
        else {
            axios.post(apiUrl, formDataPayload
                //     , {
                //     headers: {
                //         "Content-Type": "multipart/form-data"
                //     },
                // }
            ).then((res) => {
                try {
                    if (res?.status == 201) {
                        accountRegistration(data, res.data);
                        setCustomerDetail(res.data);
                        openSnackbar("Data added successfully.");
                        isNext && handleNext();
                    }
                } catch (e) {

                }
            }).catch(function (error) {
                console.log(error);
            });
        }

    }
    //registration
    const accountRegistration = (data, response) => {
        let payload = {
            first_name: data.FirstName,
            last_name: data.LastName,
            title: data.indentifyAs,
            email: data.primaryEmail,
            role: "owner",
            job_type: "permanent",
            phone_number: data.Phone,
            customer: response.uuid,
            "two_step_verification": data.two_step_verification == 'verify_by_sms' ? 'sms' :
                (data.two_step_verification == 'verify_by_authenticator' ? 'authenticator' :
                    (data.two_step_verification == 'verify_by_email' ? 'email' : 'email'))
        }
        customerUser(payload)
    }
    const customerUser = async (payload) => {
        let customerUUID = userDetail?.uuid;
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/customers/${payload.customer}/users/`;
        axios.get(`${apiUrl}`).then((res) => {
            if (res.status == 200) {
                if (res.data.results?.length > 0) {
                    axios.patch(`${apiUrl}${res.data.results[0].uuid}/`, payload).then((res) => {
                        if (res.status == 200) {
                            setUserDetail(res.data);
                            openSnackbar("Data added successfully.");
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {
                    axios.post(apiUrl, payload, {
                    }).then((res) => {
                        // console.log(res)
                        if (res.status == 201) {
                            setUserDetail(res.data);
                            openSnackbar("Data added successfully.");
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const registerUser = async (payLoadRegistration) => {
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/registration/";
        axios.post(apiUrl, payLoadRegistration, {
        }).then((res) => {
            // console.log(res)
            if (res.status == 201) {
            }
        }).catch(function (error) {
            console.log(error);
        });;
    }
    const orderCreation = async (data, isNext) => {
        let orderCreationPayload = {
            "customer": data.tenant_id,
            "quote_reference_id": data.quote_reference_id,
            "project_scope": data.project_scope,
            "project_deliverables": data.project_deliverables,
            "job_security_privacy": data.security_privace,
            "sow_start": moment(data.sow_commence).format("YYYY-MM-DD"),
            "sow_end": moment(data.sow_complete).format("YYYY-MM-DD"),
            "billing": data.billing,
        }
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/";
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        if (_orderUUID && _orderUUID !== "undefined") {
            axios.patch(`${apiUrl}${_orderUUID}/`, orderCreationPayload).then((res) => {
                if (res?.status == 201) {
                    orderCreation(data, res.data);
                }
                if (res?.status == 201 || res?.status == 200) {
                    isNext && handleNext();
                }
            }).catch(function (error) {
                console.log(error);
            });;
        }
        else {
            axios.post(apiUrl, orderCreationPayload, {}).then((res) => {
                setOrderId(res?.uuid);
                window.history.replaceState("", "", `/JobCreation?uuid=${res?.data?.uuid}`);
                isNext && handleNext();
            }).catch(function (error) {
                console.log(error);
            });
        }

    }
    const tapeCreation = async (data, isNext) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        try {
            let _orderUUID = window?.location?.href?.split("uuid=")[1];
            let url = await getApiUrl();

            let apiUrlOrderCustomer = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
            axios.get(apiUrlOrderCustomer).then(dataCustomer => {
                if (dataCustomer.data.customer) {
                    let orderPayload = {
                        'customer': dataCustomer.data.customer,
                        'number_of_boxes': data.numberOfBoxes,
                        'backup_type': data.backupType,
                        'account': data.accountOptions,
                        'volser_available': data.volserAvailable,
                    }
                    const orderApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
                    axios.patch(orderApiUrl, orderPayload, {
                        // headers: {
                        //     "Content-Type": "multipart/form-data"
                        // },

                    }).then((res) => {
                        if (!data?.tapeDetails?.length) {
                        openSnackbar('Order updated successfully');
                        isNext && handleNext();
                        }
                        data?.tapeDetails?.forEach((tapeDetail, index) => {
                            let tapePayload = {
                                'order': _orderUUID,
                                'type': tapeDetail.tapeType,
                                'number': tapeDetail.number,
                                'backup_format': tapeDetail.tapeFormat,
                            }

                            const uuid = watch(`tape.tapeDetails[${index}].uuid`);
                            if (uuid) {
                                const tapeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/tapes/' + uuid + '/';
                                axios.put(tapeApiUrl, tapePayload, {
                                    // headers: {
                                    //     "Content-Type": "multipart/form-data"
                                    // },
                                }).then((res) => {
                                    openSnackbar('Order updated successfully');
                                    isNext && handleNext();
                                    //openSnackbar('Tape updated successfully');
                                }).catch((err) => {
                                    //openSnackbar('Error while updating Tape.');
                                    console.log(err);
                                });
                            } else {
                                const tapeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/tapes/';
                                axios.post(tapeApiUrl, tapePayload, {
                                    // headers: {
                                    //     "Content-Type": "multipart/form-data"
                                    // },
                                }).then((res) => {
                                    //openSnackbar('Tape created successfully');
                                    setValue(`tape.tapeDetails[${index}].uuid`, res.data.uuid);
                                    openSnackbar('Order updated successfully');
                                    isNext && handleNext();
                                }).catch((err) => {
                                    //openSnackbar('Error while creating Tape.');
                                    console.log(err);
                                });
                            }

                        });
                    }).catch((err) => {
                        openSnackbar('Error while updating order');
                        console.log(err);
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const ingestionCreation = async (data, isNext) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let address_payload;

        let payload = {
            "customer": (watch('customer_uuid')) ? watch('customer_uuid') : companyname,
            "post_ingestion": data?.postIngestion,
            "tape_security_privacy": data?.tapeOptions,
        }

        if (data?.postIngestion === 'destroy') {
            payload = {
                ...payload,
                "destroy_mode": data.destroyMode,
            }
        }
        if (data?.postIngestion === 'store') {
            payload = {
                ...payload,
                "storage_duration": data.storageDuration,
                "storage_duration_unit": "month"
            }
        }
        if (data?.postIngestion === 'return') {
            payload = {
                ...payload,
                "tape_return_to_sender": data.returnTo,
            }

            if (data?.returnTo === false) {
                payload = {
                    ...payload,
                    "tape_return_address": {
                        "address_line_1": data.returnAddress.address_line_1,
                        //"address_line_2": data.returnAddress.address_line_2,
                        "postal_code": data.returnAddress.zipcode,
                        "country": data.returnAddress.country,
                        "city": data.returnAddress.city,
                        "state": data.returnAddress.state,
                        "phone_number": data.returnAddress.phone,
                        "mobile_number": data.returnAddress.mobile,
                    }
                }
            }
        }
        let url = await getApiUrl();

        const orderApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
        let apiUrlOrderCustomer = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
        axios.get(apiUrlOrderCustomer).then(dataCustomer => {
            if (dataCustomer.data.customer) {
                payload.customer = dataCustomer.data.customer;
                axios.patch(orderApiUrl, payload, {
                    // headers: {
                    //     "Content-Type": "multipart/form-data"
                    // },
                }).then((res) => {
                    isNext && handleNext();
                    openSnackbar('Order updated successfully');
                    console.log(res)
                }).catch((err) => {
                    openSnackbar('Error while updating order');
                    console.log(err);
                });
            }
        }).catch(error => {
            console.log(error);
        });

    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    })
    async function main(file) {
        try {
            const result = await toBase64(file);
            return result;
        } catch (error) {
            console.log(error);
            return;
        }
    }
    async function uploadFile(file, type) {
        const result = await toBase64(file[0]);
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let payLoadCustomer = {
            "file_type": type,
            "file": result.split(",")[1],
            "order": _orderUUID,
            "name": file[0].name,
            "file_size": file[0].size,
        }
        // let formDataPayload = objectToFormData(payLoadCustomer);
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/`;
        axios.post(apiUrl, payLoadCustomer, {
            // headers: {
            //     "Content-Type": "multipart/form-data"
            // },
        }).then((res) => {
            console.log(res)
            payLoadCustomer['uuid'] = res.data.uuid;
            payLoadCustomer['name'] = file[0].name;
            payLoadCustomer['size'] = file[0].size;
            payLoadCustomer['type'] = file[0].type;
            if (type == 'signed_contract') {
                setSignedContracts([...signedContracts, payLoadCustomer])
            } else {
                setBillFile([...billFile, payLoadCustomer])
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const removeFile = async (uuid, type) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let deleteDocumentPayload = { uuid: uuid }
        let url = await getApiUrl();

        let removeDocumentApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/` + deleteDocumentPayload.uuid + "/";
        axios.delete(removeDocumentApiUrl, deleteDocumentPayload, {}).then((res) => {
            if (type == 'signed_contract') {
                setSignedContracts(signedContracts.filter((file) => file.uuid !== uuid));
            } else {
                setBillFile(billFile.filter((file) => file.uuid !== uuid));
            }

        }).catch(function (error) {
            console.log(error);
        });;
    }

    const orderDocumentSubmission = async (data, isNext) => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        getOrderDetails()
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + '/';
        const payload = {
            // status: 'request_received',
            status: 'preparing_for_shipment',
            uuid: _orderUUID,
            customer: orderDetail.customer
        };
        axios.patch(apiUrl, payload, {
            /*             headers: {
                            "Content-Type": "multipart/form-data"
                        }, */
        }).then((res) => {

        }).catch(function (error) {
            console.log(error);
        });
        // let signedContractRequest = [];
        // let billFileRequest = []
        // data?.signedContracts?.length && data?.signedContracts?.forEach(element => {
        //     let payLoadCustomer = {
        //         "file_type": "signed_contract",
        //         "attachment": element,
        //         "order": _orderUUID,
        //     }
        //     let formDataPayload = objectToFormData(payLoadCustomer);
        //     let apiUrl =  process.env.REACT_APP_API_BASE_URL + "/orderdocuments/";
        //     signedContractRequest.push(axios.post(apiUrl, formDataPayload, {
        //         headers: {
        //             "Content-Type": "multipart/form-data"
        //         },
        //     }));
        // });
        // data?.billFile?.length && data?.billFile?.forEach(element => {
        //     let payLoadCustomer = {
        //         "file_type": "bill",
        //         "attachment": element,
        //         "order": _orderUUID,
        //     }
        //     let formDataPayload = objectToFormData(payLoadCustomer);
        //     let apiUrl =  process.env.REACT_APP_API_BASE_URL + "/orderdocuments/";
        //     billFileRequest.push(axios.post(apiUrl, formDataPayload, {
        //         headers: {
        //             "Content-Type": "multipart/form-data"
        //         },
        //     }));
        // });
        // let allRequest = signedContractRequest.concat(billFileRequest);
        // axios.all(allRequest).then((res) => {
        //     console.log("Document Upload", res)
        //     // if (res.status == 201) {
        //     //     // accountRegistration(data)
        //     // }
        //     handleSuccessDialogOpen();
        // }).catch(function (error) {
        //     console.log(error);
        // });;
        handleSuccessDialogOpen();
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [formKey, setFormKey] = React.useState(0);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        setDisableSaveAndNext(false)
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        setTimeout(() => {
            getStepDetails(activeStep + 1);
        }, 0);
    };

    const handleBack = () => {
        setDisableSaveAndNext(false)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setTimeout(() => {
            getStepDetails(activeStep - 1);
        }, 0);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleFormReset = () => {
        setFormKey((prevFormKey) => prevFormKey + 1);
    };

    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const handleSuccessDialogOpen = () => {
        setSuccessDialogOpen(true);
    }

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
    }
    const getOrderDetails = async () => {
        let _orderUUID = window?.location?.href?.split("uuid=")[1];
        let url = await getApiUrl();

        let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
        axios.get(apiUrl).then(data => {
            setOrderDetail(data.data);
        }).catch(error => {
            console.log(error);
            setValue('isJobStepLoading', false);
        });
    }
    const clearData = () => {
        if (activeStep == 0) {
            reset();
        } else if (activeStep == 1) {
            reset();
        } else if (activeStep == 2) {
            reset();
        } else if (activeStep == 3) {
            reset();
        } else if (activeStep == 5) {
            reset();
        }
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={closeSnackbar}
                message={snackbarMessage}
                key={vertical + horizontal}
            />
            <Grid sx={{ visibility: (isEditClient) ? 'visible' : 'hidden' }} container spacing={2} paddingBottom={4}>
                <Grid item md={12}>
                    <Typography variant="h5" mt={4} sx={{
                        fontFamily: 'Lato',
                        fontSize: '28px',
                        fontWeight: 700,
                        lineHeight: '34px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#FFFFFF',
                    }}>
                        {location?.state?.customerName}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ background: '#EAEAEA', width: '100%' }} orientation='horizontal' />
                </Grid>
            </Grid>
            <form id="mainForm" onSubmit={handleSubmit(handleFormData)}>
                {/* style={{border:'1px solid #C7CCD0',borderRadius:'8px'}} */}
                <Grid container direction={'column'}>
                    {/* Stepper */}
                    <Grid item>
                        <Box sx={{ height: '80px', borderRadius: '8px 8px 0px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: '0px 2px 6px 0px #00000040', backgroundColor: '#FFC540', justifyItems: 'center' }}>
                            <Stepper
                                sx={{ visibility: (isEditClient) ? 'hidden' : 'visible' }}  ////TODO: Edit client refactor

                                alternativeLabel
                                activeStep={activeStep}
                                connector={<CustomConnector />}
                            >
                                {steps.map((step, index) => (
                                    <Step key={step} completed={completed[index]}>
                                        <StepLabel
                                            StepIconComponent={CustomStepIcon}
                                        >
                                            <Typography sx={{
                                                fontFamily: 'Poppins',
                                                color: '#3A3E3F',
                                                fontSize: '14px',
                                                fontWeight: (activeStep === index) ? 700 : 400,
                                                lineHeight: '14px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                            }}>{step}</Typography>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Grid>
                    {/* Step view */}
                    <Grid item xs={12}>
                        {(activeStep === 0 && watch('isJobStepLoading') !== true) && (
                            <>
                                <Job register={register}
                                    watch={watch}
                                    setValue={setValue} />
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                            <Shipping
                                                startDateFilter={startDateFilter}
                                                shippingMode={shippingMode}
                                                setShippingMode={setShippingMode}
                                                shippingLocations={shippingLocations}
                                                removeShipment={(index, data) => removeShipment(index, data)}
                                                addShipment={(data) => addShipment(data)}
                                                updateShipment={(data) => updateShipment(data)}
                                                register={register}
                                                watch={watch}
                                                setValue={setValue}
                                                shippingLinksFields={shippingLinksFields}
                                                shippingLinksAppend={shippingLinksAppend}
                                                shippingLinksRemove={(index, uuid) => removeShipmentLink(index, uuid)}
                                                getValues={getValues} />
                                {/* <Account/> */}
                            </>
                        )}
                        {(activeStep === 2 && watch('isTapeStepLoading') !== true) && (
                            <>
                                <AdminTapeForm
                                    orderId={orderId}
                                    tapeListFile={tapeListFile}
                                    virtualMachineFile={virtualMachineFile}
                                    register={register}
                                    control={control}

                                    watch={watch}
                                    setValue={setValue} />
                            </>
                        )}
                        {(activeStep === 3 && watch('isIngestionStepLoading') !== true) && (
                            <>
                                <AdminIngestionForm
                                    register={register}
                                    control={control}
                                    watch={watch}
                                    setValue={setValue} />

                            </>
                        )}
                        {activeStep === 4 && (
                            <>
                                <AdminFee
                                    setDisableSaveAndNext={setDisableSaveAndNext}
                                    orderId={orderId}
                                    register={register}
                                    control={control}
                                    watch={watch}
                                    setValue={setValue}
                                />
                            </>
                        )}
                        {activeStep === 5 && (
                            <>
                                <AdminDocument billFile={billFile} signedContracts={signedContracts} setValue={setValue} uploadFile={(file, type) => uploadFile(file, type)} removeFile={(uuid, type) => removeFile(uuid, type)} />
                            </>
                        )}

                    </Grid>
                </Grid>

                {/* Bottom Drawer which opens when items selected */}
                <Drawer variant={'persistent'} anchor={'bottom'} open sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#2C2C2C',
                        boxShadow: '0px -7px 11px 0px #00000029',
                        height: '6rem',
                        pl: '60px', // TODO: Make width of Navbar Drawer and move it to constants
                    },
                }}>
                    <Grid container direction={'row'} my={2} px={8}>
                        <Grid item xs={6} sx={flexStyles.flexRowStart}>
                            {/* Previous Button */}
                            <Button variant='outlined'
                                onClick={handleBack}
                                sx={{
                                    visibility: (activeStep === 0) ? 'hidden' : 'visible',
                                    width: '220px',
                                    height: '60px',
                                    fontFamily: 'Poppins',
                                    borderRadius: '30px',
                                    border: '1px solid #FFC540',
                                    color: '#FFC540',
                                    textTransform: 'none',
                                    '&.Mui-disabled': {
                                        background: '#C7CCD0',
                                        color: '#5F5F5F'
                                    },
                                }}>
                                Previous
                            </Button>
                        </Grid>

                        {activeStep === 5 ? (
                            <Grid item xs={6} sx={flexStyles.flexRowEnd}>
                                <StyledButtonContained variant='contained'
                                    type='submit'
                                    form="mainForm"
                                    sx={{
                                        width: '220px',
                                        height: '60px'
                                        }}>
                                    Done
                                </StyledButtonContained>
                            </Grid>
                        ) : (

                            <Grid item xs={6} sx={flexStyles.flexRowEnd} gap={2}>

                                <Button
                                    variant='text'
                                    //onClick={clearAddedRows}
                                    onClick={clearData}
                                    type="button"
                                    sx={{
                                        width: '220px',
                                        color: '#FFFFFF',
                                        fontFamily: 'Poppins',
                                        textTransform: 'none',
                                    }}>
                                    Clear
                                </Button>

                                <StyledButtonContained variant='contained'
                                    type='submit'
                                    form="mainForm"
                                    sx={{
                                        width: '220px',
                                        height: '60px'
                                        }}>
                                    Save
                                </StyledButtonContained>

                                <Button
                                    disabled={disableSaveAndNext}
                                    variant='contained'
                                    type="submit"
                                    form="mainForm"
                                    value="saveAndContinue"
                                    // onClick={() => {
                                    //     setTimeout(() => {
                                    //         handleNext();
                                    //     }, 0);
                                    // }}
                                    //onClick={handleSubmit((d) => handleFormData(d, true))}
                                    sx={{
                                        visibility: (isEditClient) ? 'hidden' : 'visible',  //TODO: Edit client refactor
                                        width: '220px',
                                        height: '60px',
                                        borderRadius: '30px',
                                        background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                                        color: '#3A3E3F',
                                        fontFamily: 'Poppins',
                                        textTransform: 'none',
                                        '&.Mui-disabled': {
                                            background: '#C7CCD0',
                                            color: '#5F5F5F'
                                        },
                                    }}>
                                    {activeStep === 5 ? 'Save & Submit' : 'Save & Continue'}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Drawer>
                <AdminAccountCreationSuccess customerId={orderDetail?.customer ? orderDetail?.customer : companyname} open={successDialogOpen} onClose={() => handleSuccessDialogClose()} />
            </form>
        </>
    )
}

// export const AdminAccountCreationSuccess = ({ open, onClose, customerId }) => {
//     const [clientDetails, setClientDetails] = useState();
//     const getOrderCreation = async () => {
//         let _orderUUID = window?.location?.href?.split("uuid=")[1];
//         if (_orderUUID) {
//             let url = await getApiUrl();
//             let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/`;
//             axios.get(apiUrl).then(data => {
//                 if (data.data.customer) {
//                     let apiUrlcustomer = url + process.env.REACT_APP_API_BASE_URL + `/customers/${data.data.customer}/`;
//                     axios.get(apiUrlcustomer, {}).then(response => {
//                         setClientDetails(response.data);
//                     }).catch(err => {
//                         console.log(err);
//                     })
//                 }
//             }).catch(error => {
//                 console.log(error);
//             });
//         }
//     }
//     React.useEffect(() => {
//         getOrderCreation()
//     }, []);

//     const [clientName, setClientName] = useState('Minfy Technologies Pvt. Ltd.')
//     const handleSendCredentials = () => {
//         // Send credentials here
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <Dialog
//             open={open}
//             fullWidth
//             onClose={handleClose}
//             PaperProps={{
//                 sx: {
//                     borderRadius: "8px",
//                     backgroundColor: '#3A3E3F',
//                     color: '#FFFFFF',
//                     p: 2,
//                 }
//             }}>
//             <DialogTitle color={'primary'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
//                 <CheckCircle sx={{ color: '#FFFFFF', height: '12rem', width: '12rem' }} />
//                 <Typography variant='h4' sx={{ color: '#FFFFFF' }}>Order Created</Typography>
//             </DialogTitle>
//             <Divider sx={{ backgroundColor: '#5F5F5F', width: '100%' }} />
//             <DialogContent sx={{ px: '15%', }}>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12} textAlign={'center'}>
//                         <Typography fontSize={'16px'} variant='h6' fontWeight={400} sx={{ color: '#FFFFFF' }}>
//                             An Order has been successfully created for Client:</Typography>
//                         <Typography fontSize={'18px'} fontWeight={700} bold sx={{ color: '#FFFFFF' }}>
//                             {clientDetails?.company_name}</Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Paper
//                             sx={{
//                                 background: 'transparent',
//                                 border: '1px solid #FFFFFF',
//                                 borderRadius: '8px',
//                                 height: '8rem',
//                                 color: '#FFFFFF',
//                                 px: 4,
//                                 py: 2,
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 justifyContent: 'space-between'
//                             }}>
//                             <Stack direction={'column'}>
//                                 <Typography fontSize={'16px'} variant='h6' fontWeight={400} >Username:</Typography>
//                                 <Typography fontSize={'16px'} variant='h6' fontWeight={400} >{clientDetails?.email}</Typography>
//                             </Stack>
//                             {/* <Stack direction={'column'}><Typography fontSize={'16px'} variant='h6' fontWeight={400} >Password:</Typography>
//                                 <Typography fontSize={'16px'} variant='h6' fontWeight={400} >************</Typography></Stack> */}



//                         </Paper>
//                     </Grid>
//                 </Grid>
//             </DialogContent>
//             <DialogActions sx={{ gap: 4, }}>
//                 <Button
//                     variant='text'
//                     sx={{ color: '#FFFFFF', textTransform: 'none', fontFamily: 'Poppins', height: '3rem', }}
//                     onClick={() => { handleClose(); }}
//                 >
//                     Later
//                 </Button>
//                 <Link to={`/Orders`} passHref>
//                     <Button
//                         variant="contained"
//                         sx={{ backgroundColor: '#FFC540', textTransform: 'none', height: '3rem', borderRadius: '30px', fontFamily: 'Poppins', color: '#3A3E3F' }}
//                         onClick={() => { handleClose(); }} //
//                     >
//                         Confirm
//                     </Button>
//                 </Link>
//             </DialogActions>

//         </Dialog>
//     )
// };